import { Button, Form, Input, Modal, notification } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import API from "../../config/api";
import { POST } from "../../utils/apiCalls";
import PrefixSelector from "../prefixSelector";

function CreateCutomerModal({ open, onCancel, customerSearch, type, customer }: any) {
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useSelector((state: any) => state.User);
    const adminid = user.adminid;

    const submitHandler = async (values: any) => {
        try {
            const add_customer_url = API.CONTACT_MASTER + "add";
            setIsLoading(true);
            let mobile = `${values.code} ${values.mobile}`;
            const { data, status,message }: any = await POST(add_customer_url, {
                ...values,
                adminid,
                mobile: values.mobile ? mobile : "",
                type: type,
                ledger_category: 3,
            });
            if (status) {
                notification.success({
                    message:"Success",
                    description: type === 'customer' ? 'Customer created successfully' : 'Supplier created successfully',
                });
                await customerSearch(' ')
                customer(data.id)
                onCancel()
            } else {
                notification.error({
                    message:"Failed",
                    description: type === 'customer' ? `Failed to create customer(${message})` : `Failed to create supplier(${message})`,
                });
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            notification.error({
                message:"Server Error",
                description: "Something went wrong in server, Please try again later",
            });
        }
    };

    const initialValues = {
        name: "",
        reference: "",
        bus_name: "",
        vat_number: "",
        email: "",
        code: user?.countryInfo?.phoneCode,
        mobileNumber: null,
        telephone: null,
        town: "",
        address: "",
        postcode: "",
        notes: "",
        opening_balance: 0,
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            width='50%'
            footer={false}
        >
            <div >
                <h5>{ type == 'customer' ? 'Create Customer' : 'Create Supplier'}</h5>
                <hr />
                <Form
                    onFinish={submitHandler}
                    layout="vertical"
                    initialValues={initialValues}
                >
                    <Row>
                        <Col md={4}>
                            <label className="formLabel">Name</label>
                            <Form.Item
                                name="name"
                                style={{ marginBottom: 10 }}
                                rules={[{ required: true }]}
                            >
                                <Input
                                    placeholder="Name"
                                    size="large"
                                    className="input-field"
                                />
                            </Form.Item>
                            <label className="formLabel">Reference Code</label>
                            <Form.Item
                                name="reference"
                                style={{ marginBottom: 10 }}
                                rules={[{ required: true }]}
                            >
                                <Input
                                    placeholder="Reference Code"
                                    size="large"
                                    className="input-field"
                                />
                            </Form.Item>
                            <label className="formLabel">Business Name</label>
                            <Form.Item
                                name="bus_name"
                                rules={[{ required: true }]}
                                style={{ marginBottom: 10 }}
                            >
                                <Input
                                    placeholder="Business Name"
                                    size="large"
                                    className="input-field"
                                />
                            </Form.Item>
                            <label className="formLabel">VAT Number</label>
                            <Form.Item
                                name="vat_number"
                                style={{ marginBottom: 10 }}
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp("^[A-Z0-9]+$"),
                                        message: "Please enter only capital letters and numbers",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="VAT Number"
                                    size="large"
                                    className="input-field"
                                    onChange={(e) => {
                                        const filteredValue = e.target.value.replace(
                                            /[^A-Z0-9]/g,
                                            ""
                                        );
                                        e.target.value = filteredValue;
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={4}>
                            <label className="formLabel">Email Address</label>
                            <Form.Item name="email" style={{ marginBottom: 10 }}>
                                <Input
                                    placeholder="Email"
                                    size="large"
                                    className="input-field"
                                />
                            </Form.Item>
                            <label className="formLabel">Mobile Number</label>
                            <Form.Item name="mobile" style={{ marginBottom: 10 }}>
                                <Input
                                    placeholder="Mobile Number"
                                    size="large"
                                    className="input-field"
                                    addonBefore={<PrefixSelector />}
                                    type="text"
                                    onInput={(e) => {
                                    e.currentTarget.value = e.currentTarget.value.replace(
                                    /[^0-9]/g,
                                    ""
                                    );
                                }}                                   
                                />
                            </Form.Item>
                            <label className="formLabel">Telephone Number</label>
                            <Form.Item name="telephone" style={{ marginBottom: 10 }}>
                                <Input
                                    placeholder="Telephone Number"
                                    size="large"
                                    className="input-field"
                                    type="text"
                                    onInput={(e) => {
                                    e.currentTarget.value = e.currentTarget.value.replace(
                                    /[^0-9]/g,
                                    ""
                                    );
                                }}      
                                />
                            </Form.Item>
                            <label className="formLabel">Town/city</label>
                            <Form.Item name="city" style={{ marginBottom: 10 }}>
                                <Input
                                    placeholder="Town/city"
                                    size="large"
                                    className="input-field"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={4}>
                            <label className="formLabel">Address</label>
                            <Form.Item name="address" style={{ marginBottom: 10 }}>
                                <Input.TextArea
                                    placeholder="Address"
                                    size="large"
                                    className="input-field"
                                    rows={3}
                                />
                            </Form.Item>
                            <label className="formLabel">Postal Code</label>
                            <Form.Item name="postcode" style={{ marginBottom: 10 }}>
                                <Input
                                    placeholder="Postal Code"
                                    size="large"
                                    className="input-field"
                                />
                            </Form.Item>
                            <label className="formLabel">Notes</label>
                            <Form.Item name="notes" style={{ marginBottom: 10 }}>
                                <Input.TextArea
                                    placeholder="Notes"
                                    size="large"
                                    className="input-field"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <hr /> */}
                    <Row>
                        <Col md={4} />
                        <Col md={4} />
                        <Col md={4}>
                            <br />
                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                                disabled={isLoading}
                                block
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}

export default CreateCutomerModal;
