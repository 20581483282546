import { useEffect, useState } from "react";
import API from "../../../config/api";
import { useSelector } from "react-redux";
import { DELETE, GET } from "../../../utils/apiCalls";
import SupplierListTable from "./SupplierListTable";
import LoadingBox from "../../../components/loadingBox";
import { Button, notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../../../components/pageHeader";
import { MdAttachEmail } from "react-icons/md";

const ContactSuppliers = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchSuppliersList();
  }, [page, take]);
  const fetchSuppliersList = async () => {
    try {
      setIsLoading(true);
      let suppliers_list_url =
        API.CONTACT_MASTER_LIST +
        `supplier/${adminid}?order=DESC&page=${page}&take=${take}`;
      const { data }: any = await GET(suppliers_list_url, null);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const onPageChange = (page: any, take: any) => {
    setPage(page);
    setTake(take);
  };
  const handleDelete = async (id: number) => {
    try {
      setIsLoading(true);
      const delete_url = API.DELETE_CONTACT + id;
      const data: any = await GET(delete_url, null);
      fetchSuppliersList();
      if (data.status) {
        notification.success({ message: "Supplier Deleted Successfully" });
      } else {
        notification.error({
          message: "Something went wrong!! Please try again later",
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong!! Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageHeader
        firstPathLink={location?.pathname}
        firstPathText="Suppliers List"
        buttonTxt="ADD SUPPLIER"
        onSubmit={() => navigate("/usr/contactSuppliers/create")}
        goback="/usr/dashboard"
        title="Suppliers List"
      />
      <div className="adminTable-Box1">
        {isLoading ? (
          <LoadingBox />
        ) : (
          <SupplierListTable
            list={data}
            onPageChange={(p: any, t: any) => onPageChange(p, t)}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleDelete={handleDelete}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </div>
    </>
  );
};

export default ContactSuppliers;
