import { useState, useRef } from "react";
import DataGrid, {
  Column,
  SearchPanel,
  Export,
  Paging,
  Pager,
  HeaderFilter,
  Selection,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { Card, Popover } from "antd";
import { Container } from "react-bootstrap";
import { EXPORT } from "../../utils/exportData";
import { BsThreeDotsVertical } from "react-icons/bs";
import ViewPopover from "../../components/viewPopover";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const Table = (props: any) => {
  const dataGridRef: any = useRef(null);
  const [selectedRows, setSelectedRows] = useState();
  const [page, SetPage] = useState(1);
  const [take, setTake] = useState(10);
  const navigate = useNavigate();
  const onSelectionChanged = (e: any) => {
    setSelectedRows(e.selectedRowsData.length);
  };
  const exportFormats = ["xlsx", "pdf"];
  const handleOptionChanged = (e: any) => {
    if (e.fullName === "paging.pageIndex") {
      SetPage(e.value);
    }
    if (e.fullName === "paging.pageSize") {
      setTake(e.value);
    }
    if (e.fullName === "paging.pageSize" || e.name === "pageSize") {
      props.onPageChange(page, take);
    }
  };

  const columns = [
    {
      dataField: "userdate",
      caption: "Date",
      format: "date",
      cellRender: ({ data }: any) => (
        <div style={{ textAlign: "center" }}>
          {moment(data?.userdate).format("DD-MM-YYYY")}
        </div>
      ),
    },

    {
      dataField: "particulars",
      caption: "Particulars",
      cellRender: ({ data }: any) => (
        <div>
          {data?.contactData?.length === 0
            ? "-"
            : data?.contactData?.map((contact: any) => (
                <div>{contact?.bus_name || contact?.laccount}</div>
              ))}
        </div>
      ),
    },

    // {
    //   dataField: "id",
    //   caption: "Voucher No.",
    //   cellRender: (data: any, rowIndex: any) => {
    //     return <div>{data?.rowIndex + 1}</div>;
    //   },
    // },
    {
      dataField: "total",
      caption: "Amount",
    },
  ];

  const customizeExportCell = (type: any, gridCell: any, cell: any) => {
    if (
      gridCell.rowType === "data" &&
      (gridCell.column.dataField === "userdate" ||
        gridCell.column.dataField === "particulars") &&
      gridCell.rowType !== "header" &&
      gridCell.rowType !== "totalFooter"
    ) {
      if (gridCell.column.dataField === "userdate") {
        const userdate = moment(gridCell.data?.userdate)?.format("DD-MM-YYYY");
        if (type === "pdf") {
          cell.text = userdate ?? "";
        } else if (type === "xlsx") {
          cell.value = userdate ?? "";
        }
      }
      if (gridCell.column.dataField === "particulars") {
        const particulars = gridCell.data?.contactData.map((Item: any) => {
          return Item.bus_name || Item.laccount;
        });
        if (type === "pdf") {
          cell.text = particulars.join(", ") ?? "";
        } else if (type === "xlsx") {
          cell.value = particulars.join(", ") ?? "";
        }
      }
    }
  };

  return (
    <Container>
      <br />
      <Card>
        <DataGrid
          ref={dataGridRef}
          dataSource={props.journals}
          onExporting={(e) =>
            EXPORT(e, dataGridRef, "journal List", customizeExportCell)
          }
          onOptionChanged={handleOptionChanged}
          onSelectionChanged={onSelectionChanged}
          showRowLines={true}
          columnAutoWidth={true}
          showColumnLines={true}
          showBorders={true}
          remoteOperations={false}
          style={{ textAlign: "center" }}
          searchPanel={{
            visible: true,
            width: 240,
            placeholder: "Search Journals",
            searchVisibleColumnsOnly: true,
            highlightCaseSensitive: false,
          }}
        >
          <Selection
            mode="multiple"
            selectAllMode="allPages"
            showCheckBoxesMode="always"
          />
          <SearchPanel visible={true} width={240} />
          <HeaderFilter visible={true} />
          <HeaderFilter visible={true} />
          {columns.map((column: any, index: number) => {
            return (
              <Column
                key={index}
                dataField={column.dataField}
                caption={column.caption}
                format={column.format}
                cellRender={column.cellRender}
                allowExporting={column.caption === "id" ? false : true}
                alignment="center"
              ></Column>
            );
          })}
          <Paging defaultPageSize={10} />
          <Pager
            visible={true}
            allowedPageSizes={[10, 20, 30]}
            displayMode={"compact"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Column
            alignment={"center"}
            type="buttons"
            caption="Action"
            dataField="id"
            width={110}
            cellRender={({ data }) => {
              return (
                <div className="table-title">
                  <Popover
                    content={
                      <ViewPopover
                        onView={() => {
                          navigate(`/usr/journal/details/${data.id}`);
                        }}
                        OnEdit={() => navigate(`/usr/journal/edit/${data.id}`)}
                        OnDelete={() => {
                          props?.handleDelete(data?.id);
                        }}
                      />
                    }
                    placement="bottom"
                    trigger={"click"}
                  >
                    <BsThreeDotsVertical size={16} cursor={"pointer"} />
                  </Popover>
                </div>
              );
            }}
          ></Column>
          <Export
            enabled={true}
            allowExportSelectedData={true}
            formats={exportFormats}
          />
          <Toolbar>
            {selectedRows ? (
              <Item location="before" visible={true}>
                <div style={{ fontSize: "17px", fontWeight: 600 }}>
                  {selectedRows} selected
                </div>
              </Item>
            ) : (
              <Item location="before" visible={true}>
                <div style={{ fontSize: "17px", fontWeight: 600 }}>
                  Total Jounals :{props?.journals?.length}
                </div>
              </Item>
            )}
            <Item name="searchPanel" />
            <Item location="after" visible={true} name="exportButton" />
          </Toolbar>
        </DataGrid>
      </Card>
    </Container>
  );
};
export default Table;
