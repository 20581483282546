import { Store } from "../redux/store";
import API from "../config/api";
import { message } from "antd";
import S3 from "../config/s3";

const S3FileUpload = require("react-s3").default;
window.Buffer = window.Buffer || require("buffer").Buffer;

const GET = async (url: any, params: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const POST = async (url: any, body: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const POST2 = async (url: any, body: any) => {
  const User = Store.getState().User;
  const token: any = User.token;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(API.BASE_URL + url, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: body,
      });
      const json = await response.json();
      resolve(json);
    } catch (error) {
      reject(error);
    }
  });
};

const PUT = async (url: any, body: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())

      .then((json) => {
        resolve(json);
      })

      .catch((error) => {
        reject(error);
      });
  });
};

const DELETE = async (url: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const UPLOADEXCEL = async (url: any, formData: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(API.BASE_URL + url, {
        method: "POST",
        body: formData,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

const FILE_UPLOAD = async (file: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (file && file.name) {
        message.loading({
          type: "loading",
          content: "Action in progress..",
          duration: 1,
        });
        const response = await S3FileUpload.uploadFile(file, S3);
        if (response && response.location) {
          resolve(response.location);
        } else {
          reject("Failed");
        }
      } else {
        resolve(null);
      }
    } catch (err) {
      reject(err);
    }
  });
};

const COMPRESS_IMAGE = async (file: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const response = await fetch(`${API.BASE_URL}${API.IMAGE_COMPRESS}`, {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          const jsonResponse: any = await response.json();
          const obj = {
            ...jsonResponse,
            url: jsonResponse.Location,
            status: true,
          };
          resolve(obj);
        } else {
          let obj = {
            status: false,
            url: null,
          };
          reject(obj);
        }
      } else {
        reject("no file selected");
      }
    } catch (err) {
      reject(err);
    }
  });
};

export {
  GET,
  POST,
  PUT,
  DELETE,
  POST2,
  UPLOADEXCEL,
  COMPRESS_IMAGE,
  FILE_UPLOAD,
};
