import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import "../styles.scss";
import { useNavigate } from "react-router";
import PageHeader from "../../../components/pageHeader";
import { useLocation } from "react-router-dom";
import DataTable from "./dataTable";
import LoadingBox from "../../../components/loadingBox";
import { notification } from "antd";


const ContactCustomers = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCustomerList();
  }, [page, take]);

  const fetchCustomerList = async () => {
    try {
      setIsLoading(true);
      let customer_list_url =
        API.CONTACT_MASTER_LIST +
        `customer/${adminid}?order=DESC&page=${page}&take=${take}`;
      const { data }: any = await GET(customer_list_url, null);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    try {
      setIsLoading(true);
      const delete_customer_url = API.DELETE_CONTACT + id;
      const data: any = await GET(delete_customer_url,null);
      fetchCustomerList();
      if (data.status) {
        notification.success({message: "Customer Deleted Successfully"});
      }else{
        notification.error({
          message: "Something went wrong!! Please try again later",
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong!! Please try again later",
      });
    } finally {
      setIsModalOpen(false);
      setIsLoading(false);
    }
  };

  const onPageChange = (page: any, take: any) => {
    setPage(page);
    setTake(take);
  };
  
  return (
    <>
      <PageHeader
        firstPath={location?.pathname.slice(5)}
        firstPathLink={location.pathname.replace("/create", "")}
        buttonTxt={ "ADD"}
        onSubmit={ () => navigate("/usr/contactCustomers/create")}
        goback="/usr/dashboard"
        title="Customers List"
        secondPathLink ={location.pathname}
        secondPathText ='Customers List'
      />
      <div className="adminTable-Box1">
        {isLoading ? (
          <LoadingBox />
        ) : (
          <DataTable
            list={data}
            onItemSelect={() => {}}
            title={"Customers List"}
            onPageChange={(p: any, t: any) => onPageChange(p, t)}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setIsLoading={setIsLoading}
            handleDelete={handleDelete}
          />
        )}
      </div>
    </>
  );
};

export default ContactCustomers;
