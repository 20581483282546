import React, { useRef, useState } from "react";
import "devextreme/dist/css/dx.light.css";
import moment from "moment";
import {
  Button,
  Card,
  Pagination,
  Popover,
  Space,
  Table,
  TableProps,
  Tag,
} from "antd";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { MdRemoveRedEye } from "react-icons/md";
import exportToExcel from "../../../utils/exportexcel";

const DataTable = (props: any) => {
  console.log("PROPS ---> ", props.list);

  const dataGridRef: any = useRef(null);
  const [selectedRows, setSelectedRows] = useState();

  const navigate = useNavigate();

  const onSelectionChanged = (e: any) => {
    setSelectedRows(e.selectedRowsData.length);
  };

  const calculateLeaveDuration = (
    fromDate: string,
    toDate: string,
    durationType: string
  ) => {
    const startDate = moment(fromDate);
    const endDate = moment(toDate);

    if (durationType === "Half day") {
      return 0.5;
    } else {
      const duration = endDate.diff(startDate, "days") + 1;
      return duration;
    }
  };

  const handleExportToExcel = () => {
    const data = props.list.data.map((item: any) => ({
      RequestedDate: moment(item.createdAt).format("DD-MM-YYYY"),
      EmployeeName: item.employee.fullName,
      LeaveType: item.type,
      From: moment(item.from).format("DD-MM-YYYY"),
      To: moment(item.to).format("DD-MM-YYYY"),
      NoOfDays: calculateLeaveDuration(item.from, item.to, item.duration),
      Status: item.status,
      Document: item.document ? item.document : "No Document",
    }));

    exportToExcel(data, "exported_data.xlsx");
  };

  interface DataType {
    key: string;
    name: string;
    tags: string[];
  }

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Requested Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },

    {
      title: "Name",
      dataIndex: "employeeId",
      key: "employeeId",
      render: (_: any, record: any) => (
        <span>{record?.employee?.fullName}</span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: (text: any) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      render: (text: any) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },

    {
      title: "No. of Days",
      render: (_: any, record: any) => (
        <span>
          {calculateLeaveDuration(record.from, record.to, record.duration)}
        </span>
      ),
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text: any) => {
        const statusColor =
          text === "approved"
            ? "green"
            : text === "pending"
            ? "orange"
            : text === "rejected"
            ? "blue"
            : "orange";
        return (
          <Tag color={statusColor}>{text ? text.toUpperCase() : "PENDING"}</Tag>
        );
      },
    },

    {
      title: "Action",
      key: "id",
      render: (_: any, record: any) => (
        <Space size="middle">
          <MdRemoveRedEye
            color="blue"
            size={20}
            onClick={() => navigate(`/usr/hr-leave-details/${record?.id}`)}
          />
        </Space>
      ),
    },
  ];

  const data: DataType[] = props.list.data;

  return (
    <Container>
      <br />
      <Card>
        <Button
          onClick={handleExportToExcel}
          style={{
            position: "absolute",
            top: 5,
            right: 0,
            marginRight: "30px",
          }}
          type="primary"
        >
          Export to Excel
        </Button>

        <h4 style={{ textAlign: "center" }}>LeaveRequest details</h4>

        <h6>Total {props?.meta?.total_count} Leave Requests</h6>
        <br />
        <Table columns={columns} dataSource={data} pagination={false} />
      </Card>
      <Pagination
        total={props?.meta?.total_count}
        pageSize={props?.meta?.take}
        onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
        style={{
          display: props?.meta?.total_count > 10 ? "block" : "none",
          textAlign: "right",
          marginTop: "20px",
        }}
      />
    </Container>
  );
};
export default DataTable;
