// import { Button, Modal, Table } from "antd";
// import { DELETE } from "../../../../utils/apiCalls";
// import API from "../../../../config/api";
// import { useState } from "react";
// import { useParams } from "react-router-dom";

// const MembersModal = (props: any) => {
//   console.log("qwert===", props);
//   const { id } = useParams();
//   const [deleteData, setdeleteData] = useState<any>();
//   const DeleteData = async () => {
//     try {
//       let api = API.DELETE_MEMBERS + id;
//       const Data: any = await DELETE(api);
//       if (Data?.status) {
//         setdeleteData(Data?.data);
//       } else {
//         setdeleteData({});
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <div>
//       <Modal
//         title="Members Details"
//         visible={props.open}
//         onCancel={() => props.close()}
//       >
//         <div>
//           <table>
//             {props.data.map((user: any, i: any) => {
//               return (
//                 <tr>
//                   <td key={i}>Name: {user.employee.fullName}</td>
//                   <td>
//                     <Button onClick={DeleteData}>delete </Button>
//                   </td>
//                 </tr>
//               );
//             })}
//           </table>
//         </div>
//       </Modal>
//     </div>
//   );
// };

// export default MembersModal;
import { Button, Modal, Popconfirm, Table } from "antd";
import { DELETE } from "../../../../utils/apiCalls";
import API from "../../../../config/api";
import { useState } from "react";

const MembersModal = (props: any) => {
  const [deletedMember, setDeletedMember] = useState<any>(null);
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleDelete = async (userId: string) => {
    try {
      const api = API.DELETE_MEMBERS + userId;
      const response: any = await DELETE(api);
      if (response?.status) {
        setDeletedMember(userId);
        setSuccessMessage("Member deleted successfully.");
      } else {
        console.log("");
      }
    } catch (error) {
      console.error("Error deleting member:", error);
    }
  };

  const filteredData = props?.data?.filter(
    (user: any) => user.id !== deletedMember
  );

  return (
    <div>
      <Modal
        title="Members Details"
        open={props?.open}
        onCancel={() => props.close()}
        footer={false}
      >
        {successMessage && <div>{successMessage}</div>}
        <table>
          <tbody>
            {filteredData?.map((user: any, i: any) => (
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "300px",
                  marginBottom: "10px",
                }}
              >
                <div className="">{i + 1} </div>
                <div className="" style={{ textTransform: "capitalize" }}>
                  {user.employee.fullName}
                </div>
                <div className="">
                  <Popconfirm
                    placement="left"
                    title={"Are you sure you want to delete?"}
                    description={"You will not be able to retrieve this data."}
                    onConfirm={() => handleDelete(user.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="table-pp-btn">Delete</Button>
                  </Popconfirm>
                </div>
              </div>
            ))}
          </tbody>
        </table>
      </Modal>
    </div>
  );
};

export default MembersModal;
