import {
  Button,
  DatePicker,
  Input,
  Select,
  Spin,
  Upload,
  notification,
} from "antd";
import Form from "antd/es/form";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import API from "../../../config/api";
import { GET, POST, FILE_UPLOAD } from "../../../utils/apiCalls";
import { useParams } from "react-router-dom";
import DataTable from "./DataTable";
import Currency from "../project payment/currency.json";
import exportToExcel from "../../../utils/exportexcel";
import moment from "moment";
import PageHeader from "../../../components/pageHeader";
import { useSelector } from "react-redux";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";

const ProjectPayments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [clients, setClientData] = useState([]);
  const [form] = Form.useForm();
  const { id } = useParams();
  // const [dataCount, setDataCount] = useState<any>([]);
  const [projectpayData, setProjectpayData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedProject, setselectedProject] = useState("");
  const [projectData, setprojectData] = useState([]);
  const [selectedClient, setselectedClient] = useState("");
  const [clientData, setclientData] = useState([]);
  const [file, setFile] = useState<any>([]);
  const { Option } = Select;
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});
  const user = useSelector((state: any) => state.User.user);
  console.log("meta ---> ", meta.total_count);
  useEffect(() => {
    fetchHrProject();
    fetchClients();
  }, []);

  const reload = () => {
    fetchProjectpayment();
  };

  const fetchHrProject = async () => {
    try {
      setIsLoading(true);
      const url = API.GET_PROJECT + "?order=DESC&page=1&take=10";
      const { data }: any = await GET(url, null);
      setProjects(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClients = async () => {
    try {
      setIsLoading(true);
      const { data }: any = await GET(API.LIST_CLIENTS, {});
      setClientData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjectpayment = async () => {
    try {
      setIsLoading(true);
      const api = `${API.GET_PROJECTPAYMENTS_FILTER}?order=ASC&page=${currentPage}&take=${pageSize}`;
      const obj = {
        createdAt: selectedDate ? moment(selectedDate).toISOString() : null,
        projectId: selectedProject ? parseInt(selectedProject) : null,
        clientId: selectedClient ? parseInt(selectedClient) : null,
      };
      const { data, meta }: any = await POST(api, obj);
      setclientData(data);
      console.log("data---", data);

      setTotalItems(meta.itemCount);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectpayment();
  }, [selectedDate, selectedProject, selectedClient]);

  const getAllData = async () => {
    try {
      let obj = {
        page: page,
        take: 10,
      };
      let api = API.POST_PAGE_PAYMENTS;
      const count: any = await POST(api, obj);
      console.log("count", count);
      if (count?.status) {
        setclientData(count?.data);
        setMeta(count?.meta);
      } else {
        console.error("Failed to fetch data from the API.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllData();
  }, [page, take]);

  const handleSubmit = async (values: any) => {
    try {
      const obj = {
        projectId: Number(values.project),
        clientId: Number(values.clientId),
        amount: Number(values.Amount),
        currency: String(values.Currency),
        payment_method: String(values["payment Method"]),
        invoice_no: String(values["Invoice Number"]),
        document: String(file),
      };

      console.log("time", obj);
      let api = API.POST_PROJECTPAYMENTS;
      let postRequest: any = await POST(api, obj);
      console.log("dattataaaa", postRequest);
      if (postRequest.status) {
        notification.success({
          message: "project payments submitted successfully",
        });
      } else {
        notification.error({ message: "Something went wrong" });
      }
      reload();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something! Please try again later",
      });
    }
  };

  const handleReset = () => {
    form.resetFields();
    setFile([]);
  };

  const UploadFile = async (data: any) => {
    try {
      console.log(data);
      const myFile = data.file;
      let file = myFile.originFileObj;
      const name = moment(new Date()).unix();
      const myNewFile = new File([file], name + ".png", {
        type: myFile.type,
      });
      const url = URL.createObjectURL(myNewFile);
      let _upload = await FILE_UPLOAD(myNewFile);
      console.log("upload --> ", _upload);
      setFile(_upload);
    } catch (err) {
      console.log("UploadFile = = = >", err);
    }
  };

  const handleExportToExcel = () => {
    exportToExcel(projectpayData, "exported_data.xlsx");
  };

  const handleDateChange = (date: any, dateString: string) => {
    setSelectedDate(dateString);
    setCurrentPage(1);
  };

  const handleProjectChange = (value: string) => {
    setselectedProject(value);
  };

  const handleClientChange = (value: string) => {
    setselectedClient(value);
  };

  return (
    <>
      <PageHeader title="Project payment Details" />
      <br />
      <div className="screen-box">
        <Container>
          <div>
            <Card>
              <Form onFinish={handleSubmit} form={form} layout="vertical">
                <Row gutter={12}>
                  <Col md={3}>
                    <Form.Item
                      label="Client Name"
                      name="clientId"
                      rules={[
                        { required: true, message: "Please select a Client" },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        placeholder="Select Client"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        allowClear
                      >
                        {clients.map((Client: any) => (
                          <Option key={Client?.id} value={Client?.id}>
                            {Client?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={3}>
                    <Form.Item
                      label="Project"
                      name="project"
                      rules={[
                        { required: true, message: "Please select a project" },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        placeholder="Select Project"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        allowClear
                      >
                        {projects &&
                          projects.map((project: any) => (
                            <Select.Option key={project.id} value={project.id}>
                              {project.title}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={3}>
                    <Form.Item
                      label="Amount"
                      name="Amount"
                      rules={[
                        { required: true, message: "Please input Amount" },
                      ]}
                    >
                      <Input size="large" type="string" />
                    </Form.Item>
                  </Col>

                  <Col md={3}>
                    <Form.Item
                      label="Currency"
                      name="Currency"
                      rules={[
                        { required: true, message: "Please input Currency" },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        placeholder="Select Currency"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        allowClear
                      >
                        {Currency?.map((cu: any) => (
                          <Select.Option value={cu.title}>
                            {cu.value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col md="3">
                    <Form.Item
                      label="payment Method"
                      name="payment Method"
                      rules={[
                        {
                          required: true,
                          message: "Please input payment Method",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col md="3">
                    <Form.Item
                      label="Invoice Number"
                      name="Invoice Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input Invoice Number",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col md={3}>
                    <Form.Item label="Document (optional)" name="file">
                      <Upload onChange={(data) => UploadFile(data)}>
                        <Button
                          icon={<UploadOutlined />}
                          size="large"
                          style={{
                            width: "100%",
                          }}
                        >
                          Click to Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={5}>
                  <Col md={7} />
                  <Col md={2}>
                    <Form.Item>
                      <Button
                        size="large"
                        style={{ width: "100%" }}
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    </Form.Item>
                  </Col>

                  <Col md={3}>
                    <Form.Item>
                      <Button
                        size="large"
                        style={{ width: "100%" }}
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{ fontSize: 24, color: "#fff" }}
                                spin
                              />
                            }
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
            <br />
          </div>
          <div>
            <Card>
              <div>
                <Row>
                  <Col md="3">
                    <Form.Item name="name">
                      <Select
                        style={{ width: "200px" }}
                        placeholder="Select Client"
                        onChange={handleClientChange}
                        value={selectedClient}
                      >
                        {clients.map((Client: any) => (
                          <Option key={Client?.id} value={Client?.id}>
                            {Client?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md="3">
                    <Form.Item name="projectId">
                      <Select
                        style={{ width: "200px" }}
                        placeholder="Select Project"
                        onChange={handleProjectChange}
                        value={selectedProject}
                      >
                        {/* {projects.map((client: any) => (
                          <Option
                            key={client?.projectId}
                            value={client?.projectId}
                          >
                            {client?.proId?.title}
                          </Option>
                        ))} */}
                        {projects &&
                          projects.map((project: any) => (
                            <Select.Option key={project.id} value={project.id}>
                              {project.title}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md="3">
                    <Form.Item name="selectedDate">
                      <DatePicker
                        style={{ width: "200px" }}
                        onChange={handleDateChange}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <br />
                    <Button
                      onClick={handleExportToExcel}
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 0,
                        marginRight: "30px",
                      }}
                      type="primary"
                    >
                      Export to Excel
                    </Button>
                  </Col>
                </Row>
                <DataTable
                  dataSource={clientData}
                  isLoading={isLoading}
                  meta={meta}
                  onPageChange={(page: number, pageSize: number) => {
                    setIsLoading(true);
                    setPage(page);
                    setTake(pageSize);
                  }}
                />
              </div>
            </Card>
            <br />
          </div>
        </Container>
      </div>
    </>
  );
};

export default ProjectPayments;
