import "../styles.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Create from "./create";
import Edit from "./edit";
import { useSelector } from "react-redux";
import { GET } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { useLocation } from "react-router-dom";
import Generate from "./generate";

function ProformaInvoiceForm(props: any) {
  const { id }: any = useParams();
  const { state }: any = useLocation();
  const { user } = useSelector((state: any) => state.User);
  const adminid = user?.adminid;
  const [isLoading, setIsLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [ledgers, setLedgers] = useState([]);
  const [banks, setBanks] = useState([]);
  const [details, setDetails] = useState({});
  const [taxList, setTaxlist] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [product, setProduct] = useState([]);

  useEffect(() => {
    getProduct("non-assets");
    loadTaxList();
    if (id === "generate") {
      getLedgers();
      getBankList();
    }
  }, []);

  useEffect(() => {
    setTimeout(()=>{
      getCustomers();
    },500)
  }, [customerName]);

  const getCustomers = async () => {
    try {
      let customerapi =
        "contactMaster/searchList/both/" + adminid + `?name=${customerName}`;
      const { data: customer }: any = await GET(customerapi, null);
      setCustomers(customer);
    } catch (error) {}
  };

  const getLedgers = async () => {
    try {
      let url = "account_master/defualt-ledger/sales/" + adminid;
      const { data }: any = await GET(url, null);
      const filterData = data?.filter((item: any) => {
        return (
          item.nominalcode === "4000" ||
          item.nominalcode === "4001"
         //  || item.nominalcode === "4002"
        );
      });
      setLedgers(filterData);
    } catch (error) {}
  };
  const getBankList = async () => {
    try {
      let url = "account_master/getBankList/" + adminid;
      const { data }: any = await GET(url, null);
      setBanks(data.list);
    } catch (error) {}
  };

  const loadTaxList = async () => {
    try {
      let URL = API.TAX_MASTER + user.adminid +'/all';
      const data :any = await GET(URL, null);
      setTaxlist(data);
    } catch (error) {}
  };

  const getProduct = async (val: any) => {
    try {
      let type = val.length ? val + "/" : "";
      let productuul = `ProductMaster/user/${type}${adminid}`;
      const { data: products }: any = await GET(productuul, null);
      setProduct(products);
    } catch (error) {}
  };

  return (
    <div>
      {id === "create" ? (
        <Create
          customers={customers}
          id={state}
          products={product}
          taxList={taxList}
          customerName={(val: any) => setCustomerName(val)}
          getProduct={(val: any) => getProduct(val)}
        />
      ) : id === "generate" ? (
        <Generate
          customers={customers}
          products={product}
          ledgers={ledgers}
          banks={banks}
          taxList={taxList}
          details={details}
          id={state}
          customerName={(val: any) => setCustomerName(val)}
          getProduct={(val: any) => getProduct(val)}
        />
      ) : (
        <Edit
          customers={customers}
          ledgers={ledgers}
          banks={banks}
          adminid={adminid}
          products={product}
          taxList={taxList}
          details={details}
          id={id}
          customerName={(val: any) => setCustomerName(val)}
          getProduct={(val: any) => getProduct(val)}
        />
      )}
      <br />
    </div>
  );
}
export default ProformaInvoiceForm;
