import { useSelector } from "react-redux";
import { BiUser } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProfileSider = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.User);
  return (
    <div
      className="navigation-profile-sider-container"
      onClick={() => navigate(`/usr/profile/general`)}
    >
      <div className="navigation-user-profile">
        {user?.companyInfo?.bimage == null ||
        user?.companyInfo?.bimage == "" ? (
          <BiUser size={30} />
        ) : (
          <div className="profile-picture">
            <img
              src={user?.companyInfo?.bimage}
              className="profile-img"
              alt=""
            />
          </div>
        )}
      </div>
      {props.collapsed ? null : (
        <div className="username-container">
          <div className="username">{`${user?.firstname} ${user?.lastname}`}</div>
          <div style={{ color: "grey", fontSize: 12 }}>{t("home_page.homepage.User")}</div>
        </div>
      )}
    </div>
  );
};

export default ProfileSider;
