import { Button, Checkbox, Modal, Select, Tabs, notification } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import RetailExpressPaymentmodal from "../components/RetailExpressPaymentModal/printModal";
import LoadingBox from "../components/loadingBox";
import API from "../config/api";
import {
  addCartProducts,
  addHoldProduct,
  addProductQuantity,
  addProducts,
  clearHoldProducts,
  clearProduct,
  minusProductQuantity,
  removeHoldProduct,
  removeProducts,
} from "../redux/slices/retailExpress";
import { Store } from "../redux/store";
import { GET, POST } from "../utils/apiCalls";
import Header from "./components/header";
import ListItem from "./components/listItem";
import ProductItem from "./components/productItem";
import "./styles.scss";
import { IoAlertCircleOutline } from "react-icons/io5";
import { BsClockHistory } from "react-icons/bs";
import moment from "moment";
import { CiSquareRemove } from "react-icons/ci";
import HoldInvoice from "./components/hold";

function RetailExpress(props: any) {
  const { user } = useSelector((state: any) => state.User);
  let products: any = Store.getState().retailExpress.products;
  let productsStock: any = Store.getState().retailExpress.holdProducts;
  const [categories, setCategories] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [isChange, setIsChange] = useState<any>(false);
  const [checkbox, setCheckbox] = useState<any>(true);
  const [customerSerch, setCustomerSerch] = useState("");
  const [customerst, setCustomer] = useState([]);
  const [customersId, setCustomerId] = useState();
  const [product, setProduct] = useState();
  const [category, setCategory] = useState(0);
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [cartModal, setCartModal] = useState(false);
  const [selectBank, setSlectedBank] = useState<any>([]);
  const [loding, setLoading] = useState<any>(false);
  const [btLoding, setBtLoading] = useState<any>(false);
  const [totalQuatity, setTotalQuatity] = useState<any>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getDatas();
    getCategories();
    getInvoiceNo()
    getBankList()
    calculation()
  }, [category, product]);

  const calculation = () => {
    try {
      let taxAmount_: any = 0;
      let totalQuatity: any = 0;
      setIsChange(true);
      const sumOfProducts = products.reduce((acc: any, item: any) => {
        totalQuatity += item.quantity_no
        const total = Number(item.rate) * Number(item.quantity_no);
        const taxAmount = (Number(total) / (100 + item.vat)) * 100;
        const newTaxAmount: any = Number(total) - Number(taxAmount);
        taxAmount_ += newTaxAmount;
        const finalPrice =
          item.includevat == "1.00"
            ? Number(total) - Number(newTaxAmount)
            : Number(total);
        return acc + finalPrice;
      }, 0);
      setTotalQuatity(totalQuatity)
      setTotal(sumOfProducts);
      setTax(taxAmount_);
      setTimeout(() => {
        setIsChange(false);
      }, 1);
    } catch (error) {
      console.log(error)
    }
  };

  const getCategories = async () => {
    try {
      const url = API.PRODUCTCATEGORY_LIST_USER + user?.adminid;
      const response: any = await GET(url, {});
      if (response?.length) {
        var data: any = [{ key: 0, label: "All Products" }];
        response?.map((item: any) => {
          let obj = { key: item?.category, label: item?.category };
          data.push(obj);
        });
        setCategories(data);
      }
    } catch (error) { }
  };

  const getDatas = async () => {
    try {
      setLoading(true);
      let body = {
        id: user?.adminid,
        type: "Stock",
        category: category,
        name: product,
      };
      const url = "retail/productlist";
      const { data, status }: any = await POST(url, body);
      data.forEach((item: any) => {
        item["selection"] = false;
      });
      if (status) {
        setData(data);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleAddToCart = (item: any) => {
    const findInd = products.findIndex((val: any) => val.id === item.id);
    if (findInd >= 0) {
      dispatch(addProductQuantity(products[findInd].id))
      products = Store.getState().retailExpress.products;
      calculation();
    } else {
      if (!item.quantity_no) {
        item["quantity_no"] = 1;
      }
      dispatch(addProducts(item));
      products = Store.getState().retailExpress.products;
      calculation();
    }
  };

  const handleClearCart = () => {
    if (products.length) {
      dispatch(clearProduct([]));
      products = Store.getState().retailExpress.products;
      notification.success({ message: "Cart cleared successfully" });
      calculation();
    } else {
      notification.error({ message: "No items in the cart." });
    }
  };

  const clearAtIndex = (id: any) => {
    dispatch(removeProducts(id));
    products = Store.getState().retailExpress.products;
    calculation();
  };

  const LoadCoutemer = async () => {
    try {
      let URL =
        "contactMaster/searchList/customer/" +
        user.adminid +
        `?name=${customerSerch}`;
      const data: any = await GET(URL, null);
      setCustomer(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LoadCoutemer();
  }, [customerSerch]);

  const add = (val: any) => {
    setIsChange(true)
    dispatch(addProductQuantity(val));
    products = Store.getState().retailExpress.products;
    setTimeout(() => {
      setIsChange(false);
      calculation();
    }, 1);
  }

  const mainus = (val: any) => {
    setIsChange(true)
    dispatch(minusProductQuantity(val));
    products = Store.getState().retailExpress.products;
    setTimeout(() => {
      setIsChange(false);
      calculation();
    }, 1);
  }

  const getInvoiceNo = async () => {
    try {
      let invoiceurl = "user_settings/getInvoiceNo/" + user.adminid + "/sales";
      const { data: invnumber }: any = await GET(invoiceurl, null);
      setInvoiceNumber(invnumber);
    } catch (error) { }
  };

  const handleSubmit = async (val?: any) => {
    try {
      setBtLoading(true)
      let customerName: any = customerst.find((item: any) => {
        return item.id === customersId;
      })
      let selectBankBalance
      if (checkbox) {
        selectBankBalance = selectBank.find((item: any) => {
          return item?.id == val?.id
        })
      }
      let paymentInfo = checkbox;
      if (checkbox) {
        paymentInfo = {
          id: val.paymentBank,
          bankid: val.paymentBank,
          outstanding: val.outStanding,
          amount: val.amoutToPaid,
          date: val?.paymentDate,
          type: val.paymentMethod,
          paidmethod: val.paymentMethod,
          running_total: Number(selectBankBalance.amount) + Number(val?.amoutToPaid),
        };
      }
      let amountPaid = Number(val?.amoutToPaid) || 0;
      let totalPayable: any = (total + tax).toFixed(2);
      let outstanding = totalPayable - amountPaid;
      let outstandingStatus: any = "0";
      if (outstanding <= 0) {
        outstandingStatus = "2";
      } else if (outstanding < totalPayable) {
        outstandingStatus = "1";
      } else if (outstanding >= totalPayable) {
        outstandingStatus = "0";
      }
      let column = products.map((item: any, index: number) => {
        let foundedProduct = data.find(
          (product: any) => product.id === item.id
        );
        let productTotal = 0
        let productVat = 0
        if (item.includevat == '1.00') {
          const total = Number(item.rate) * Number(item.quantity_no);
          const taxAmount = (Number(total) / (100 + item.vat)) * 100;
          productVat = Number(total) - Number(taxAmount);
          productTotal = total
        } else {
          const total = Number(item.rate) * Number(item.quantity_no);
          const taxAmount = (Number(total) / (100 + item.vat)) * 100;
          productVat = Number(total) - Number(taxAmount);
          productTotal = total + productVat
        }

        return {
          id: item.id,
          itemtype: "Stock",
          icode: item.icode,
          idescription: item.idescription,
          barcode: item.barcode,
          userid: item.userid,
          pimage: item.pimage,
          rate: item.rate,
          quantity: item.quantity_no.toFixed(2),
          vatamt: productVat.toFixed(2),
          vatamount: productVat.toFixed(2),
          includevat: item.includevat == '1.00' ? true : false,
          costprice: item.rate,
          adminid: item.admiid,
          vat: item.vat,
          supplier: null,
          is_deleted: item.is_deleted,
          stockvalue: item.stockvalue,
          selection: item.selection,
          quantity_no: item.quantity_no,
          product_category: item.product_category,
          total: productTotal.toFixed(2),
          product: foundedProduct,
          itemorder: index + 1,
        };
      });
      let obj = {
        cname: customerName?.bus_name,
        userid: user.adminid,
        customerid: customersId,
        invoiceno: invoiceNumber,
        total_vat: tax.toFixed(2),
        taxable_value: total.toFixed(2),
        total: (total + tax).toFixed(2),
        adminid: user.adminid,
        salesType: '',
        columns: column,
        paymentInfo: paymentInfo,
        status: outstandingStatus,
      }
      let URL = 'retail/addRetail'
      const { data: createRetail, status, message }: any = await POST(URL, obj);
      if (status) {
        notification.success({ message: 'Sale Created Successfully' });
        dispatch(clearProduct([]));
        products = Store.getState().retailExpress.products;
        getDatas()
        getInvoiceNo()
        setPaymentModal(false)
        calculation()
        setCheckbox(true)
        setBtLoading(false)
        return true
      } else {
        notification.error({ message: message });
        setBtLoading(false)
        return false
      }
    } catch (error) {
      notification.error({ message: 'Error in Sale Creation' });
      setBtLoading(false)
      return false
    }
  }

  const getBankList = async () => {
    try {
      let url = "account_master/getBankList/" + user.adminid;
      const { data }: any = await GET(url, null);
      setSlectedBank(data.bankList);
    } catch (error) { }
  };

  const addProductInCart = async () => {
    setIsChange(true)
    if (products.length) {
      let holdProduct = {
        date: new Date(),
        total: (total + tax).toFixed(2),
        quatity: totalQuatity,
        item: products,
      }
      await dispatch(addHoldProduct(holdProduct));
      await dispatch(clearProduct([]));
      products = Store.getState().retailExpress.products;
      productsStock = Store.getState().retailExpress.holdProducts;
    }
    setTimeout(() => {
      setIsChange(false);
      calculation();
    }, 1);
  }

  const addCartProductInCart = async (product: any, index: number) => {
    setIsChange(true)
    await dispatch(addCartProducts(product.item));
    await dispatch(removeHoldProduct(index));
    products = Store.getState().retailExpress.products;
    productsStock = Store.getState().retailExpress.holdProducts;
    setTimeout(() => {
      setIsChange(false);
      setCartModal(false)
      calculation();
    }, 1);
  }

  const clearHoldInvoice = (index?: number) => {
    setIsChange(true)
    if (index) {
      dispatch(removeHoldProduct(index))
    } else {
      dispatch(clearHoldProducts())
      setCartModal(false)
    }
    setTimeout(() => {
      setIsChange(false);
    }, 1);
  }
  return (
    <div className="RetailExpress">
      <div className="RetailExpress-box1">
        <Container fluid>
          <Row>
            <Col sm={8} xs={12} style={{ margin: 0, padding: 0 }}>
              <Header product={(val: any) => setProduct(val)} />
              <div className="RetailExpress-box2">
                <div>
                  <Tabs
                    size="small"
                    defaultActiveKey={category.toString()}
                    items={categories}
                    onChange={(val: any) => setCategory(val)}
                    tabBarStyle={{ backgroundColor: "white", paddingLeft: 10 }}
                  />
                </div>
                <Container>
                  <Row>
                    {data?.length &&
                      data?.map((item: any, index: number) => {
                        return (
                          loding ? (
                            <LoadingBox />
                          ) : (
                            <Col
                              sm={3}
                              xs={12}
                              key={index}
                              style={{ marginBottom: 20 }}
                            >
                              <ProductItem
                                item={item}
                                onSelect={(item: any) => handleAddToCart(item)}
                                onCount={(val: any) =>
                                  dispatch(addProductQuantity(val))
                                }
                                selectedItem={products}
                                currencycode={user.countryInfo.currencycode}
                              />
                            </Col>
                          )
                        );
                      })}
                  </Row>
                </Container>
              </div>
            </Col>
            <Col sm={4} xs={12} style={{ margin: 0, padding: 0 }}>
              <div className="RetailExpress-box3">
                <div className="RetailExpress-header2">
                  <div className="RetailExpress-txt1">Order Id : {invoiceNumber}</div>
                  <div>
                    <Button style={{ marginRight: '10px' }} size="small" onClick={() => setCartModal(true)}>
                      Hold List
                    </Button>
                    <Button danger size="small" onClick={handleClearCart}>
                      Clear
                    </Button>
                  </div>
                </div>
                <div className="RetailExpress-box4">
                  <Select
                    placeholder="Select Customer"
                    allowClear
                    onSearch={(val) => setCustomerSerch(val)}
                    showSearch
                    filterOption={false}
                    size="large"
                    style={{ width: "100%" }}
                    onChange={(e: any) => setCustomerId(e)}
                  >
                    {customerst?.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.bus_name}
                      </Select.Option>
                    ))}
                  </Select>

                  {isChange
                    ? null
                    : products?.map((item: any, index: number) => {
                      return (
                        <ListItem
                          index={index}
                          item={item}
                          isChange={(val: any) => clearAtIndex(val)}
                          add={(val: any) => add(val)}
                          minus={(val: any) => mainus(val)}
                          currencycode={user.countryInfo.currencycode}
                        />
                      );
                    })}
                </div>
                <div className="RetailExpress-box7">
                  <table className="RetailExpress-table">
                    <tr>
                      <td className="RetailExpress-txt5">Total</td>
                      <td className="RetailExpress-txt6">
                        {total.toFixed(2)} {user.countryInfo.currencycode}
                      </td>
                    </tr>
                    <tr>
                      <td className="RetailExpress-txt5">Tax</td>
                      <td className="RetailExpress-txt6">
                        {tax.toFixed(2)} {user.countryInfo.currencycode}
                      </td>
                    </tr>
                    <tr>
                      <td className="RetailExpress-txt5">Payable Amount</td>
                      <td className="RetailExpress-txt6">
                        {(total + tax).toFixed(2)} {user.countryInfo.currencycode}
                      </td>
                    </tr>
                    <tr>
                      <td className="RetailExpress-txt5">Do you require payment?</td>
                      <td className="RetailExpress-txt6"><Checkbox defaultChecked={checkbox} onClick={() => setCheckbox(!checkbox)} /></td>
                    </tr>
                  </table>
                  <Row>
                    <Col sm={5} xs={12} >
                      <Button
                        block
                        size="large"
                        style={{ height: 50 }}
                        onClick={() => addProductInCart()}
                      >
                        Hold
                      </Button>
                    </Col>
                    <Col sm={7} xs={12} >
                      <Button
                        block
                        type="primary"
                        size="large"
                        style={{ height: 50 }}
                        onClick={() => checkbox ?
                          products?.length ?
                            setPaymentModal(true) :
                            setPaymentModal(false) :
                          handleSubmit()}
                        loading={btLoding}
                      >
                        Checkout
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {cartModal && (
        !products?.length ? (
          <Modal
            open={cartModal}
            onCancel={() => setCartModal(false)}
            width={500}
            maskClosable={false}
            footer={false}
            title="Cart List"
          >
            <Row>
              {productsStock.length ? (
                <>
                  <Col lg={9} />
                  <Col lg={3}>
                    <Button danger style={{ marginBottom: '10px' }} onClick={() => clearHoldInvoice()} >Clear All</Button>
                  </Col>
                  {productsStock?.map((item: any, index: number) => {
                    return (
                      <Col lg={12}>
                        <HoldInvoice
                          item={item}
                          index={index}
                          addCartProductInCart={(item: any, index: number) => addCartProductInCart(item, index)}
                          clearHoldInvoice={(index: any) => clearHoldInvoice(index)}
                        />
                      </Col>
                    )
                  })}
                </>
              ) : (
                <div>It is not on the hold list.</div>
              )}
            </Row>
          </Modal>
        ) : (
          <Modal
            open={cartModal}
            onCancel={() => setCartModal(false)}
            width={500}
            maskClosable={false}
            okText="Yes"
            cancelText="No"
            title="Warning"
            onOk={() => handleClearCart()}
          >
            <div><IoAlertCircleOutline size={25} color={'#ffe900'} /> Would you like to clear all products and display the hold list instead?</div>
          </Modal>
        )
      )}
      {paymentModal && (
        <Modal
          open={paymentModal}
          onCancel={() => setPaymentModal(false)}
          width={500}
          maskClosable={false}
          footer={false}
          title="Add Payment"
        >
          <RetailExpressPaymentmodal
            onCancel={() => setPaymentModal(false)}
            onFinish={(val: any) => handleSubmit(val)}
            outstanding={(total + tax).toFixed(2)}
            bankList={selectBank}
            amount={(total + tax).toFixed(2)}
          />
        </Modal>
      )}
    </div >
  );
}

export default RetailExpress;
