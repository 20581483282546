import "./styles.scss";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import logo2 from "../../assets/images/logo2.png";
import LanguageSwitcher from "../../language/LanguageSwitcher";
import { withTranslation } from "react-i18next";
function WebsiteHeader(props: any) {
  const { t } = props;
  const navigation = useNavigate();
  const location = useLocation();
  const [isTop, setTop] = useState(true);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const tsTop = window.scrollY < 100;
      if (tsTop !== isTop) {
        setTop(tsTop);
      }
    });
  });

  const chpath = (path: any) => {
    if (location.pathname === path) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className={
        props.white
          ? "websiteHeader scrolled"
          : isTop
          ? "websiteHeader"
          : "websiteHeader scrolled"
      }
    >
      <Container>
        <Row>
          <Col sm={2} xs={4}>
            <div onClick={() => navigation("/")}>
              <img className="websiteHeader-logo" src={logo2} />
            </div>
          </Col>
          <Col sm={6} xs={4}>
            <div className="websiteHeader-row">
              <div
                className="websiteHeader-txt1"
                style={{ color: chpath("/") ? "#18a762" : "#000" }}
                onClick={() => navigation("/")}
              >
                <div>{t("home_page.homepage.home")}</div>
              </div>
              <div
                className="websiteHeader-txt1"
                style={{ color: chpath("/plans") ? "#18a762" : "#000" }}
                onClick={() => navigation("/plans")}
              >
                {t("home_page.homepage.pricing")}
              </div>
              <div
                className="websiteHeader-txt1"
                style={{ color: chpath("/services") ? "#18a762" : "#000" }}
                onClick={() => navigation("/services")}
              >
                {t("home_page.homepage.service")}
              </div>
              <div
                className="websiteHeader-txt1"
                style={{ color: chpath("/suport") ? "#18a762" : "#000" }}
                onClick={() => navigation("/support")}
              >
                {t("home_page.homepage.Support")}
              </div>

              <div
                className="websiteHeader-txt1"
                style={{ color: chpath("/contact") ? "#18a762" : "#000" }}
                onClick={() => navigation("/contact")}
              >
                {t("sidebar.title.contact")}
              </div>
            </div>
          </Col>
          <Col sm={1} xs={2}>
            <div className="websiteHeader-row">
              <div
                className="websiteHeader-txt1"
                style={{ color: chpath("/login") ? "#18a762" : "#000" }}
                onClick={() => navigation("/login")}
              >
                {t("home_page.homepage.Sign_in")}
              </div>
            </div>
          </Col>
          <Col sm={2} xs={1}>
            <div className="websiteHeader-row">
              <button
                className="websiteHeader-btn2"
                onClick={() => navigation("/signup")}
              >
                {t("home_page.homepage.get_started")}
              </button>
              <button className="websiteHeader-btn3">
                {t("home_page.homepage.more")}
              </button>
              <div> </div>
            </div>
          </Col>
          <Col sm={1}>
            <div className="websiteHeader-row">
              <LanguageSwitcher />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withTranslation()(WebsiteHeader);
