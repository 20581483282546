// import React, { useState, useEffect } from "react";
// import {
//   Card,
//   Col,
//   Modal,
//   Select,
//   Space,
//   Table,
//   TableProps,
//   Form,
//   Row,
//   Tag,
//   Button,
// } from "antd";
// import { Container } from "react-bootstrap";
// import { MdRemoveRedEye } from "react-icons/md";
// import { GET } from "../../../../utils/apiCalls";
// import API from "../../../../config/api";
// import exportToExcel from "../../../../utils/exportexcel";

// interface DataType {
//   employeeId: number;
//   projectId: number;
//   title: string;
//   total_hours: string;
// }

// const { Option } = Select;

// const DataTable = (props: any) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [employeeData, setEmployeeData] = useState([]);
//   const [selectedEmployee, setSelectedEmployee] = useState("");
//   const [totalHours, setTotalHours] = useState<number>(0);
//   const [projects, setProjects] = useState([]);
//   const [selectedProject, setselectedProject] = useState("");

//   const fetchEmployees = async () => {
//     try {
//       setIsLoading(true);
//       const { data }: any = await GET(API.GET_HR_EMPLOYEE_LISTING, {});
//       setEmployeeData(data);
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   const fetchHrProject = async () => {
//     try {
//       setIsLoading(true);
//       const url = API.GET_PROJECT + "?order=DESC&page=1&take=100";
//       const { data }: any = await GET(url, null);
//       setProjects(data);
//       console.log(data);
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   useEffect(() => {
//     fetchEmployees();
//     fetchHrProject();
//   }, []);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCreate = () => {
//     setIsModalOpen(false);
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const handleEmployeeChange = (value: string) => {
//     setSelectedEmployee(value);
//   };
//   const handleProjectChange = (value: string) => {
//     setselectedProject(value);
//   };

//   const handleExportToExcel = () => {
//     const data = props.dataSource?.map((item: any) => ({
//       EmployeeName: item.employee.fullName,
//       ProjectName: item.project.title,

//       Total_Hours: item.total_hrs,
//     }));

//     exportToExcel(data, "exported_data.xlsx");
//   };

//   const columns: TableProps<DataType>["columns"] = [
//     {
//       title: "Name",
//       dataIndex: "employeeId",
//       key: "employeeId",
//       render: (_: any, record: any) => (
//         <span>{record?.employee?.fullName}</span>
//       ),
//     },
//     {
//       title: "Project Name",
//       dataIndex: "title",
//       key: "title",
//       render: (_: any, record: any) => <span>{record?.project?.title}</span>,
//     },
//     {
//       title: "Total Hours",

//       key: "total_hours",
//       render: (_: any, record: any) => {
//         let minutes: any = Number(
//           Number(record.total_hours) + Number(record?.total_minutes)
//         );
//         let hours = Number(minutes / 60).toFixed(0);
//         let extra_minutes = Number(minutes % 60);
//         let total_hrs = `${hours} Hrs ${extra_minutes}Min`;
//         return <Tag>{total_hrs}</Tag>;
//       },
//     },
//   ];

//   return (
//     <Container>
//       <br />
//       <br />
//       <Card>
//         <Button
//           onClick={handleExportToExcel}
//           style={{
//             position: "absolute",
//             top: 5,
//             right: 0,
//             marginRight: "30px",
//           }}
//           type="primary"
//         >
//           Export to Excel
//         </Button>
//         <br />
//         <Row>
//           <Col md={7}>
//             <Form.Item name="employeeName">
//               <Select
//                 // style={{ width: "200px" }}
//                 size="large"
//                 showSearch
//                 placeholder="Select Employee"
//                 allowClear
//                 onChange={handleEmployeeChange}
//                 value={selectedEmployee}
//               >
//                 {employeeData.map((employee: any) => (
//                   <Option key={employee.id} value={employee.id}>
//                     {employee.fullName}
//                   </Option>
//                 ))}
//               </Select>
//             </Form.Item>
//           </Col>

//           <Col md={7}>
//             <Form.Item name="project">
//               <Select
//                 style={{ marginLeft: "10px" }}
//                 size="large"
//                 showSearch
//                 placeholder="Select Project"
//                 allowClear
//                 onChange={handleProjectChange}
//                 value={selectedProject}
//               >
//                 {projects &&
//                   projects.map((project: any) => (
//                     <Select.Option key={project.id} value={project.id}>
//                       {project.title}
//                     </Select.Option>
//                   ))}
//               </Select>
//             </Form.Item>
//           </Col>
//         </Row>
//       </Card>
//       <br />
//       <h5>Project Report History</h5>
//       <Card>
//         <Table
//           columns={columns}
//           dataSource={props?.dataSource}
//           pagination={false}
//           summary={() => {
//             return (
//               <Table.Summary.Row style={{ backgroundColor: "#e3e3e3" }}>
//                 <Table.Summary.Cell index={0}>
//                   <b>Total</b>
//                 </Table.Summary.Cell>
//                 <Table.Summary.Cell index={1}></Table.Summary.Cell>
//                 <Table.Summary.Cell index={2}>
//                   <b>{totalHours}</b>
//                 </Table.Summary.Cell>
//                 <Table.Summary.Cell index={3}></Table.Summary.Cell>
//               </Table.Summary.Row>
//             );
//           }}
//         />
//       </Card>
//       <Modal
//         title="Payroll"
//         visible={isModalOpen}
//         onOk={handleCreate}
//         onCancel={handleCancel}
//         width={1000}
//       ></Modal>
//     </Container>
//   );
// };

// export default DataTable;
import React, { useState, useEffect } from "react";
import {
  Col,
  Modal,
  Select,
  Space,
  Table,
  TableProps,
  Form,
  Row,
  Card,
  Tag,
  Button,
} from "antd";
import { Container } from "react-bootstrap";
import { GET } from "../../../../utils/apiCalls";
import API from "../../../../config/api";
import exportToExcel from "../../../../utils/exportexcel";

interface DataType {
  employeeId: number;
  projectId: number;
  title: string;
  total_hours: string;
  total_minutes: string;
}

const { Option } = Select;

const DataTable = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [data, setSelecteddata] = useState("");
  const [filteredData, setFilteredData] = useState<DataType[]>(
    props?.dataSource
  );

  const fetchEmployees = async () => {
    try {
      setIsLoading(true);
      const { data }: any = await GET(API.GET_HR_EMPLOYEE_LISTING, {});
      setEmployeeData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchHrProject = async () => {
    try {
      setIsLoading(true);
      const url = API.GET_PROJECT + "?order=DESC&page=1&take=100";
      const { data }: any = await GET(url, null);
      setProjects(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
    fetchHrProject();
  }, []);

  useEffect(() => {
    filterData();
  }, [selectedEmployee, selectedProject, props.dataSource]);

  const filterData = () => {
    let filtered = props.dataSource;

    if (selectedEmployee) {
      filtered = filtered.filter(
        (item: any) => item.employeeId === selectedEmployee
      );
    }
    if (selectedProject) {
      filtered = filtered?.filter(
        (item: any) => item.projectId === selectedProject
      );
    }
    setFilteredData(filtered);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCreate = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleEmployeeChange = (value: string) => {
    setSelectedEmployee(value);
  };

  const handleProjectChange = (value: string) => {
    setSelectedProject(value);
  };

  const handleExportToExcel = () => {
    const data = filteredData?.map((item: any) => {
      const totalMinutes = Number(
        Number(item.total_hours) + Number(item.total_minutes)
      );
      let hours = Number(totalMinutes / 60).toFixed(0);
      const extra_minutes = Number(totalMinutes % 60);
      const totalHoursFormatted = `${hours} Hrs ${extra_minutes} Min`;

      return {
        EmployeeName: item.employee.fullName,
        ProjectName: item.project.title,
        Total_Hours: totalHoursFormatted,
      };
    });

    exportToExcel(data, "exported_data.xlsx");
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "employeeId",
      key: "employeeId",
      render: (_: any, record: any) => (
        <span>{record?.employee?.fullName}</span>
      ),
    },
    {
      title: "Project Name",
      dataIndex: "title",
      key: "title",
      render: (_: any, record: any) => <span>{record?.project?.title}</span>,
    },

    {
      title: "Total Hours",

      key: "total_hours",
      render: (_: any, record: any) => {
        let minutes: any = Number(
          Number(record.total_hours) + Number(record?.total_minutes)
        );
        let hours = Number(minutes / 60).toFixed(0);
        let extra_minutes = Number(minutes % 60);
        let total_hrs = `${hours} Hrs ${extra_minutes}Min`;
        return <Tag>{total_hrs}</Tag>;
      },
    },
  ];

  return (
    <Container>
      <Card>
        <Button
          onClick={handleExportToExcel}
          style={{
            position: "absolute",
            top: 5,
            right: 0,
            marginRight: "30px",
          }}
          type="primary"
        >
          Export to Excel
        </Button>
        <br />
        <Row>
          <Col md={7}>
            <Form.Item name="employeeName">
              {/* <Select
                // style={{ width: "200px" }}
                size="large"
                showSearch
                placeholder="Select Employee"
                allowClear
                onChange={handleEmployeeChange}
                value={selectedEmployee}
              > */}
              <Select
                size="large"
                showSearch
                placeholder="Select Employee"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                allowClear
                onChange={handleEmployeeChange}
                value={selectedEmployee}
              >
                {employeeData?.map((employee: any) => (
                  <Option key={employee.id} value={employee.id}>
                    {employee.fullName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={7}>
            <Form.Item name="project">
              {/* <Select
                style={{ marginLeft: "10px" }}
                size="large"
                showSearch
                placeholder="Select Project"
                allowClear
                onChange={handleProjectChange}
                value={selectedProject}
              > */}
              <Select
                size="large"
                showSearch
                placeholder="Select Project"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                allowClear
                onChange={handleProjectChange}
                value={selectedProject}
              >
                {projects &&
                  projects.map((project: any) => (
                    <Select.Option key={project.id} value={project.id}>
                      {project.title}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <br />

      <Card>
        <h5>Project Report History</h5>
        <Table columns={columns} dataSource={filteredData} pagination={false} />
      </Card>
      <Modal
        title="Payroll"
        visible={isModalOpen}
        onOk={handleCreate}
        onCancel={handleCancel}
        width={1000}
      ></Modal>
    </Container>
  );
};

export default DataTable;
