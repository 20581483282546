import { Table as AntTable } from "antd";
import moment from "moment";

function Table({ data }: any) {
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text: any) => <a>{moment(text).format("ll")}</a>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text: any) => <a>{moment(text).format("ll")}</a>,
    },
  ];

  let col = columns.map((item: any) => {
    return {
      title: item,
      dataIndex: item,
      key: item,
    };
  });
  return <AntTable dataSource={data} columns={columns} scroll={{ x: true }} />;
}

export default Table;
