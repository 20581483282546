export const CardItems = [
  {
    id: "1",
    name: "Vat/Gst",
    subheader: "Tax Report",
    link: '/usr/report/vatReturns'
  },
  {
    id: "2",
    name: "Balance Sheet",
    subheader: "Financial Report",
    link: '/usr/report/BalanceSheet'
  },
  {
    id: "3",
    name: "Profit & Loss",
    subheader: "Income Report",
    link: '/usr/report/profitloss'
  },
  {
    id: "4",
    name: "Trial Balance",
    subheader: "Tax Report",
    link: '/usr/report/TrialBalance'
  },
  {
    id: "5",
    name: "Sundry Creditors",
    subheader: "Financial Report",
    link: '/usr/report/sundryCreditors'
  },
  {
    id: "6",
    name: "Sundry Debtors",
    subheader: "Financial Report",
    link: '/usr/report/sundryDebtors'
  },
  {
    id: "7",
    name: "Stock Summary",
    subheader: "Stock Report",
    link: '/usr/report/stockSummary'
  },
];
