import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import LedgerStatementTable from '../component/statementTable'
import PageHeader from '../../../components/pageHeader'
import { useLocation, useParams } from 'react-router-dom'
import { Card } from 'antd'
import API from '../../../config/api'
import { GET } from '../../../utils/apiCalls'
import { useSelector } from 'react-redux'
import moment from 'moment'

const ViewLedger = () => {
  const [isLoading,setIsLoading] = useState(false)
  const [ledgerData,setLedgerData] = useState<any>()
  const [ledgerEntries,setLedgerEntries] = useState<any>()
  const today = new Date();
  const startDay =moment(new Date(today.setDate(1))).format("YYYY-MM-DD");

  const [currentDate, setCurrentDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [firstDate, setFirstDate] = useState(startDay);

  const location = useLocation();
  const {id} = useParams();
  const {user} = useSelector((state:any)=>state.User)
  let adminid = user.adminid;

  const fetchLedgerEntries = async() =>{
    try {
      setIsLoading(true);
      let url = API.GET_ALL_LEDGER_DETAILS + `${id}/${adminid}/${firstDate}/${currentDate}`
      const response:any = await GET(url,null)
      setLedgerEntries(response?.data)
    } catch (error) {
      console.log(error)
    }finally{
      setIsLoading(false)
    }
  }
  
  const fetchLedgerDetails = async() =>{
    try {
      setIsLoading(true)
      let url = API.GET_LEDGER_DETAILS + id;
      const res:any = await GET(url,null);
      setLedgerData(res?.data)
    } catch (error) {
      console.log(error)
    }finally{
      setIsLoading(false)
    }
  }

  useEffect(()=>{
    fetchLedgerEntries();
    fetchLedgerDetails();
  },[])

  return (
    <>
    <PageHeader
        title={"Ledger Details"}
        secondPathLink ={location.pathname}
        secondPathText ='Ledger Details'
        firstPathLink={'/usr/ledgerMyLedger'}
        firstPathText={'My Ledgers'}
        goBack={"/usr/ledgerMyCategory"}
      ></PageHeader>
    <Container>
      <br />
        <Card>
        <LedgerStatementTable
        list={ledgerEntries}
        fetchLedgerDetails={fetchLedgerDetails}
        setCurrentDate={setCurrentDate}
        setFirstDate={setFirstDate}
        currentDate={currentDate}
        firstDate={firstDate}
        openingBalance={ledgerData?.opening}
        title={ledgerData?.laccount}
        />
        </Card>
    </Container>  
    </>
  )
}

export default ViewLedger