import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import { Card } from "antd";
import { Container, Table } from "react-bootstrap";
import moment from "moment";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";

function ProposalView() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const { id } = useParams();
  const location = useLocation();
  const fetchDetails = async () => {
    try {
      setIsLoading(true);
      const url = API.GET_PROPOSAL + id;
      const { data }: any = await GET(url, null);
      setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <PageHeader
            firstPathText="Proposals"
            firstPathLink={location.pathname}
            secondPathText ='Proposal Details'
            secondPathLink ={location.pathname}
            title="Proposal Details"
          />
          <br />
          <Container>
            <Card>
              <div className="productAdd-Txt1">From Details</div>
              <Table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Company Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Website</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{moment(data?.proposal_date).format("DD-MM-YYYY")}</td>
                    <td>{data?.from_company_name}</td>
                    <td>{data?.from_email}</td>
                    <td>{data?.from_mobile}</td>
                    <td>{data?.from_website}</td>
                    <td>{data?.from_address}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            <br />
            <Card>
              <div className="productAdd-Txt1">To Details</div>
              <Table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Company Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Website</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{moment(data?.proposal_date).format("DD-MM-YYYY")}</td>
                    <td>{data?.to_company_name}</td>
                    <td>{data?.to_email}</td>
                    <td>{data?.to_mobile}</td>
                    <td>{data?.to_website}</td>
                    <td>{data?.to_address}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            <br />
            <Card>
              <div className="productAdd-Txt1">About Us</div>
              <p>{data?.about__from_company}</p>
            </Card>
            <br />
            <Card>
              <div className="productAdd-Txt1">Services</div>
              <p>{data?.about_from_services}</p>
            </Card><br />
            <Card>
              <div className="productAdd-Txt1">Technologies</div>
              <p>{data?.about_from_technologies}</p>
            </Card>
            <br />
            <Card>
              <div className="productAdd-Txt1">Proposal Details</div>
              <h6>
                {data?.proposal_title} - {data?.proposal_subtitle}
              </h6>
              <p>{data?.proposal_details}</p>
              <br />
              <div className="productAdd-Txt1">Billings</div>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.billing?.map((item: any) => (
                    <tr>
                      <td>{item?.description}</td>
                      <td>{item?.qty}</td>
                      <td>{item?.price}</td>
                      <td>{item?.total}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="productAdd-Txt1">Project Plan</div>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Module</th>
                    <th>Screens</th>
                    <th>Features</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.project_plan?.map((item: any) => (
                    <tr>
                      <td>{item?.module}</td>
                      <td>{item?.screens}</td>
                      <td>{item?.features}</td>
                      <td>{item?.details}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <br />
              <div className="productAdd-Txt1">Terms & Conditions</div>
              <p>{data?.proposal_terms}</p>

              <br />
              <div className="productAdd-Txt1">Conclusions</div>
              <p>{data?.conclusion}</p>
            </Card>
            <br />
          </Container>
        </>
      )}{" "}
    </>
  );
}

export default ProposalView;
