import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import { GET } from "../../../utils/apiCalls";
import AddLedger from "./components/addLedger";
import Table from "./components/table";

function PayHead() {
  const { user } = useSelector((state: any) => state.User);
  const [isLoading, setIsLoading] = useState(true);
  const [addPayheadModal, setAddPayheadModal] = useState(false);
  const location = useLocation();
  const [edit, setEdit] = useState<any>();

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const adminid = user?.adminid;

  const columns = [
    {
      name: "laccount",
      title: "Pay Head Name",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "payheadType",
      title: "Pay Head Type",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "calculationPeriod",
      title: "Period",
      dataType: "string",
      alignment: "center",
      cellRender: ({data}: any) =>
      <div>{data?.calculationPeriod == 1 ? 'Weekly' : data?.calculationPeriod == 2 ? 'Monthly' : data?.calculationPeriod == 3 ? 'Yearly' : ''}</div>,
    },
    {
      name: "categoryDetails.category",
      title: "Category",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "groupDetails.categorygroup",
      title: "Category Group",
      dataType: "string",
      alignment: "center",
    },
  ];

  useEffect(() => {
    fetcEmployeeCategory();
  }, []);

  const fetcEmployeeCategory = async () => {
    try {
      setIsLoading(true);
      let unit_url = `account_master/getPayHead/${adminid}`;
      const data: any = await GET(unit_url, null);

      setData(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageHeader
        title={"Pay Head"}
        firstPath={location?.pathname.slice(5)}
        secondPathLink = {location.pathname}
        secondPathText ='Pay Head'
        buttonTxt={"ADD NEW"}
        onSubmit={() => setAddPayheadModal(true)}
        goBack={"/usr/productStock"}
      />

      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
        <br />
        <Table
          products={data}
          columns={columns}
          onItemSelect={() => {}}
          handleEditClick={(val: any) => {
            setAddPayheadModal(true);
            setEdit(val);
          }}
          onPageChange={(p: any, t: any) => {}}
          title={"Pay Head"}
        />
        </>
      )}

      {addPayheadModal && (
        <AddLedger
          onOpen={addPayheadModal}
          onClose={() => {
            setAddPayheadModal(false);
          }}
          onSuccess={() => {
            setAddPayheadModal(false);
          }}
          reload={fetcEmployeeCategory}
          edit={edit}
        />
      )}
    </>
  );
}

export default PayHead;
