import WebsiteHeader from "../../components/websiteHeader";
import WebsiteFooter from "../../components/websiteFooter";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.scss";
function Terms() {
  const { t } = useTranslation();
  return (
    <>
      <WebsiteHeader />
      <div className="website-screens">
        <Container>
          <div className="Terms-mainTaitil">
            {t("home_page.homepage.LEGAL_INFORMATION")}
            <br />
            {t("home_page.homepage.OUR_TAX_SERVICES")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.Tax_GO_is_a")}
          </div>
          <div className="Terms_taitil">
            {t("home_page.homepage.Our_Guarantee")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.Tax_GO_guarantees_that")}{" "}
            <span className="Terms-desc1">
              <Link
                target="_blank"
                to="https://www.taxgoglobal.com/"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                taxgoglobal.com
              </Link>
            </span>
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.Tax_will_organise")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.Where_there_is")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.Tax_will_provide_the")}
          </div>
          <div className="Terms_taitil">
            {t("home_page.homepage.Exceptions_to")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.Tax_GO_is_not")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.the_customer_has_provided")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.the_customer")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.the_customer_circumstance")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.the_customer_has")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.the_has_already")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.the_customer_owes")}
          </div>
          <div className="Terms-desc">{t("home_page.homepage.the_tax")}</div>
          <div className="Terms-desc">
            {t("home_page.homepage.the_tax_authority")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.Where_there_is_a")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.Tax_GO_will")}
          </div>
          <div className="Terms_taitil">
            {t("home_page.homepage.Power_of_Attorney")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.When_the_customer_signs")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.If_the_relevant_tax")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.If_the_customer_directs")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.If_the_customer_does")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.Take_legal_action_to")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.Once_authorised_and")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.By_signing_and")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.You_understand_that")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.You_understand_that_Tax_GO")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.You_understand_that_while")}
          </div>
          <div className="Terms_taitil">{t("home_page.homepage.Emails")}</div>
          <div className="Terms-desc">
            {t("home_page.homepage.All_emails_to_the_customer")}
          </div>
          <div className="Terms_taitil">
            {t("home_page.homepage.Minimum_Fee")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.Tax_GO_has_a_minimum")}
          </div>
          <div className="Terms-desc">
            {t("home_page.homepage.For_additional_value")}
          </div>
        </Container>
        <br />
        <br />
      </div>
      <WebsiteFooter />
    </>
  );
}

export default Terms;
