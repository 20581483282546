import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Checkbox,
  InputNumber,
  DatePicker,
  notification,
  Upload,
  Spin,
  Card,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import API from "../../../config/api";
import { useSelector } from "react-redux";
import { GET, POST, POST2, PUT } from "../../../utils/apiCalls";
import PageHeader from "../../../components/pageHeader";
import CreateSettings from "../../settings/components/form";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { CiImageOff } from "react-icons/ci";
import { LoadingOutlined } from "@ant-design/icons";
import LoadingBox from "../../../components/loadingBox";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

function ProductAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { service: path, id } = useParams();
  const { user } = useSelector((state: any) => state.User);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [category, setCategory] = useState([]);
  const [ledgerCategoryList, setLedgerCategoryList] = useState([]);
  const [unit, setUnit] = useState([]);
  const [taxlist, setTaxlist] = useState([]);
  const [includeVats, setIncludeVat] = useState(false);
  const [image, setImage] = useState();
  const [isForm, setIsForm] = useState(false);
  const [formType, setFormType] = useState();
  const [locationdata, setLocationData] = useState([]);
  const [loading, setLoading] = useState(false);

  const businessStartDate: any = user?.companyInfo?.financial_year_start
    ? user?.companyInfo?.financial_year_start
    : user?.companyInfo?.books_begining_from;

  const LoadCategory = async () => {
    let URL = API.GET_PRODUCT_CATEGORY + user.adminid;
    const data: any = await GET(URL, null);
    setCategory(data);
    form.setFieldsValue({
      product_category: "",
    });
  };

  const LoadUnit = async () => {
    let URL = API.PRODUCT_UNIT_BY_USER + user.adminid;
    const data: any = await GET(URL, null);
    setUnit(data);
    form.setFieldsValue({
      unit: "",
    });
  };

  const loadTaxList = async () => {
    let URL = API.TAX_MASTER + user.adminid + '/all';
    const data : any = await GET(URL, null);
    setTaxlist(data);
  };
  const getLedger = async (val: any) => {
    try {
      let url = API.GET_FIXED_ASSET_LEDJERS + user.adminid;
      const response: any = await GET(url, null);
      const filteredLedgers = response.data.filter(
        (item: any) => item?.nominalcode == val
      );
      return filteredLedgers;
    } catch (error) {
      console.log(error);
    }
  };

  const loadProductById = async () => {
    setIsLoading2(true);
    let URL = API.GET_PRODUCT_MASTER_BY_ID + id;
    const { data }: any = await GET(URL, null);
    let rate = Number(data?.sp_price);
    let vatPercent = Number(data?.vat);
    let totalPrice;
    if (rate && vatPercent) {
      let vatAmount: any = rate * (vatPercent / 100);
      if (data.includevat === "1.00") {
        vatAmount = (rate / (100 + vatPercent)) * 100;
        vatAmount = (rate - vatAmount).toFixed(2);
        totalPrice = Number(rate.toFixed(2));
        rate = rate - vatAmount;
      } else {
        totalPrice = Number(rate + vatAmount).toFixed(2);
      }
      form.setFieldsValue({
        vatamt: Number(vatAmount),
        total_price: Number(totalPrice),
      });
    }
    let ledgerData = await getLedger(data?.saccount);
    form.setFieldsValue({
      includevat: data.includevat === "1.00" ? true : false,
      icode: data?.icode,
      idescription: data?.idescription,
      product_category: data?.product_category,
      unit: data?.unit,
      barcode: data?.barcode,
      location: data?.location,
      saccount: ledgerData[0]?.nominalcode,
      notes: data?.notes,
      sp_price: data?.sp_price,
      vat: data?.vat,
      wholesale: data.wholesale,
      costprice: data?.c_price,
      rlevel: data?.rlevel,
      quantity: data?.quantity,
      stockquantity: data?.stockquantity,
      rquantity: data?.rquantity,
      stock: data?.quantity,
      date: dayjs(businessStartDate),
      c_price: data?.c_price,
      cost_price_with_vat:data?.cost_price_with_vat,
      rate: data?.sp_price,
    });
    setImage(data?.pimage);
    setIsLoading2(false);
  };

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      let reqObj = {
        itemtype: path,
        icode: val.icode || "",
        idescription: val.idescription,
        saccount: Number(val.saccount) || 4000, //
        price: 0, //
        sp_price: val.sp_price,
        c_price: Number(val.c_price) || 0, //
        trade_price: 0, //
        rate: val.sp_price, //
        notes: val.notes, //
        type: path, //
        logintype: "user",
        paccount: 0,
        includevat: val.includevat === true ? 1 : 0,
        userid: user.id,
        adminid: user.adminid,
        vat: Number(val.vat) || 0,
        vatamt: Number(val.vatamt),
        product_category: val.product_category,
        existingstock: false,
        costprice: val.c_price || 0,
        wholesale: val.wholesale || 0,
        rlevel: val.rlevel || 0,
        quantity: val.quantity || 0,
        stockquantity: val.stockquantity || 0,
        rquantity: val.rquantity || 0,
        stock: val.quantity || 0,
        date: dayjs(businessStartDate),
        unit: val.unit,
        location: val.location,
        barcode: val.barcode,
        pimage: image,
        cost_price_with_vat:Number(val.cost_price_with_vat) || 0 ,
      };
  
      let URL = id === "create" ? API.ADD_PRODUCT : API.GET_PRODUCT_UPDATE + id;
      let METHOD = id === "create" ? POST : PUT;
      const response: any = await METHOD(URL, reqObj);
      if (response.status) {
        notification.success({
          message: "Success",
          description:response.data.itemtype === "Service" ? "Service created successfully" : response.message
        });
        setIsLoading(false);
        navigate(-1);
      } else{
        notification.error({
          message:path === "Service" ? "Failed to create service" : "Failed to create product",
          description:response.message
        });
        setIsLoading(false);
      }
    } catch (error:any) {
      console.log(error)
      notification.error({
        message: "Server Error",
        description:path === "Service" ? "Failed to create service" : "Failed to create product"
      });
      setIsLoading(false);
    }
   
  };

  const onValuesChange = async (val: any, arr: any) => {
    let rate = Number(arr.sp_price);
    let vatPercent = Number(arr.vat);
    let cost_price = Number(arr.c_price)
    let cost_price_with_vat ;
    let totalPrice;
    if (rate && (vatPercent || Number(vatPercent) === 0)) {
      let vatAmount: any = rate * (vatPercent / 100);
      let c_p_vatAmount:any = cost_price * (vatPercent / 100);
      if (val.includevat || arr.includevat) {

        vatAmount = (rate / (100 + vatPercent)) * 100;
        c_p_vatAmount = (cost_price / (100 + vatPercent)) * 100;

        vatAmount = (rate - vatAmount).toFixed(2);
        c_p_vatAmount = (cost_price - vatAmount).toFixed(2);

        totalPrice = Number(rate.toFixed(2));
        cost_price_with_vat = Number(cost_price.toFixed(2))

        rate = rate - vatAmount;
        cost_price = cost_price - c_p_vatAmount
      } else {
        totalPrice = Number(rate + vatAmount).toFixed(2);
        cost_price_with_vat = Number(cost_price + c_p_vatAmount).toFixed(2)
      }
      form.setFieldsValue({
        vatamt: Number(vatAmount).toFixed(2),
        total_price: Number(totalPrice).toFixed(2),
        cost_price_with_vat : Number(cost_price_with_vat).toFixed(2)
      });
    }
  };

  const checkIfItemExist = async (type: any, item: any) => {
    if (item.length > 2) {
      let url = API.CHECK_IF_EXIST + `${user.adminid}/${type}/${item}`;
      const res: any = await GET(url, null);
      if (res.status) {
        notification.error({message:res.message,description:`Please choose different one`});
      }
    }
  };

  const ledgerCategoryDetails = async () => {
    try {
      let url = API.GET_FIXED_ASSET_LEDJERS + user.adminid;
      const response: any = await GET(url, null);
      setLedgerCategoryList(response.data);
    } catch {}
  };

  useEffect(() => {
    ledgerCategoryDetails();
    LoadCategory();
    loadTaxList();
    LoadUnit();
    fetchUnits();
    if (id !== "create") {
      loadProductById();
    }
  }, []);
  const addCategory = (val: any) => {
    let type: any =
      val === "unit"
        ? "unit"
        : val === "productCategory"
        ? "productCategory"
        : val === "tax"
        ? "tax"
        : "location";
    if (val == type) {
      setFormType(type);
      setIsForm(true);
    }
  };

  const fetchUnits = async () => {
    try {
      setIsLoading(true);
      let unit_url = API.LOCATION_GET_BY_USER + user.adminid;
      const data: any = await GET(unit_url, null);
      setLocationData(data);
      form.setFieldsValue({
        location: "",
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onUpload = async (info: any) => {
    const { file } = info;
    if (file.status !== "uploading") {
      await uploadLogo(file.originFileObj);
    }
  };

  const uploadLogo = async (imagedata: any) => {
    var formdata = new FormData();
    setLoading(true);
    formdata.append("productid", "create");
    formdata.append("file", imagedata, imagedata.name);
    let graphData_url = API.PRODUCTMASTER_IMAGE_UPLOADER;
    try {
      const { data }: any = await POST2(graphData_url, formdata);

      if (data?.location) {
        setLoading(false);
        setImage(data.location);
        notification.success({
          message: " Image Uploaded",
          description: "Your image has been uploaded successfully.",
        });
      }
    } catch (error) {
      // Handle error if necessary
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageHeader
        onSubmit={() => navigate("/usr/create-product/service")}
        goBack={"/usr/productStock"}
        title={
          id === "create"
            ? `${t("home_page.homepage.Create")}` + path
            : `${t("home_page.homepage.Update")}` + path
        }
        secondPathLink={`/usr/product${path}`}
        secondPathText={
          id === "create" ? `Product ${path} Add` : `Product ${path} Update`
        }
      />
      {isLoading2 ? (
        <LoadingBox />
      ) : (
        <Container>
          <br />
          <div className="adminTable-Box1">
            <div className="adminTable-Box2">
              <Card>
                <Form
                  {...layout}
                  form={form}
                  initialValues={{
                    date: dayjs(user?.companyInfo?.financial_year_start),
                    location: "main",
                  }}
                  onFinish={onFinish}
                  onValuesChange={onValuesChange}
                >
                  <Row>
                    <Col md={path == "Stock" || path == "Nonstock" ? 4 : 6}>
                      <div className="productAdd-Txt1">
                        {path === "Service"
                          ? "SERVICE"
                          : `${t("home_page.homepage.product")}`}{" "}
                        {t("home_page.homepage.INFORMATION")}
                      </div>
                      {path != "Asset" ? (
                        <div className="formItem">
                          <label className="formLabel">
                            {t("home_page.homepage.Item_Code")}
                          </label>
                          <Form.Item
                            name="icode"
                            rules={[
                              {
                                required: true,
                                message: `${t(
                                  "home_page.homepage.Please_enter_a_Item_Code"
                                )}`,
                              },
                            ]}
                          >
                            <Input
                              onChange={(val) =>
                                checkIfItemExist("icode", val.target.value)
                              }
                            />
                          </Form.Item>
                        </div>
                      ) : null}

                      <div className="formItem">
                        <label className="formLabel">
                          {t("home_page.homepage.Item_Name")}
                        </label>
                        <Form.Item
                          name="idescription"
                          rules={[
                            {
                              required: true,
                              message: `${t(
                                "home_page.homepage.Please_enter_a_Item_Name"
                              )}`,
                            },
                          ]}
                        >
                          <Input
                            onChange={(val) =>
                              checkIfItemExist("idescription", val.target.value)
                            }
                          />
                        </Form.Item>
                      </div>
                      {path != "Asset" ? (
                        <div className="formItem">
                          <label className="formLabel">
                            {t("home_page.homepage.Product_Category")}{" "}
                          </label>
                          <Form.Item
                            name="product_category"
                            rules={[
                              {
                                required: true,
                                message: `${t(
                                  "home_page.homepage.Please_enter_Product_Category"
                                )}`,
                              },
                            ]}
                          >
                            <Select onChange={addCategory}>
                              <Select.Option
                                value={"productCategory"}
                                style={{
                                  color: "gray",
                                  fontSize: 15,
                                  fontWeight: "bold",
                                }}
                              >
                                {t("home_page.homepage.Add_Product_Category")}
                              </Select.Option>
                              {category.length &&
                                category.map((item: any) => (
                                  <Select.Option
                                    value={item.category}
                                    key={item.id}
                                  >
                                    {item.category}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </div>
                      ) : null}

                      {path === "Asset" ? (
                        <div className="formItem">
                          <label className="formLabel">
                            {t("home_page.homepage.Ledger_Category")}{" "}
                          </label>
                          <Form.Item
                            name="saccount"
                            rules={[
                              {
                                required: true,
                                message: `${t(
                                  "home_page.homepage.Please_enter_Ledger_Category"
                                )}`,
                              },
                            ]}
                          >
                            <Select onChange={addCategory}>
                              {ledgerCategoryList.length &&
                                ledgerCategoryList.map((item: any) => (
                                  <Select.Option key={item.nominalcode}>
                                    {item.laccount}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </div>
                      ) : null}
                      {path != "Service" && path != "Asset" ? (
                        <>
                          <div className="formItem">
                            <label className="formLabel">
                              {t("home_page.homepage.Unit")}
                            </label>
                            <Form.Item
                              name="unit"
                              rules={[
                                {
                                  required: true,
                                  message: `${t(
                                    "home_page.homepage.Please_enter_a_Unit"
                                  )}`,
                                },
                              ]}
                            >
                              <Select onChange={addCategory}>
                                <Select.Option
                                  value={"unit"}
                                  style={{
                                    color: "gray",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("home_page.homepage.Add_Unit")}
                                </Select.Option>
                                {unit.length &&
                                  unit.map((item: any) => (
                                    <Select.Option
                                      value={item.unit}
                                      key={item.id}
                                    >
                                      {item.unit}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="formItem">
                            <label className="formLabel">
                              {t("home_page.homepage.Barcode")}
                            </label>
                            <Form.Item name="barcode">
                              <Input />
                            </Form.Item>
                          </div>
                          <div className="formItem">
                            <label className="formLabel">
                              {t("home_page.homepage.Location")}
                            </label>
                            <Form.Item
                              name="location"
                              rules={[
                                {
                                  required: true,
                                  message: `${t(
                                    "home_page.homepage.Please_enter_a_Location"
                                  )}`,
                                },
                              ]}
                            >
                              <Select
                                onChange={addCategory}
                                allowClear
                                showSearch
                                filterOption={(
                                  input: any,
                                  option: any
                                ): any => {
                                  let isInclude = false;
                                  isInclude = option.children
                                    .toString()
                                    .toLowerCase()
                                    .includes(input.toLowerCase());

                                  if (option.value === "location") {
                                    isInclude = true;
                                  }
                                  return isInclude;
                                }}
                              >
                                <Select.Option
                                  value={"location"}
                                  style={{
                                    color: "gray",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("home_page.homepage.Add_Location")}
                                </Select.Option>
                                {locationdata.length &&
                                  locationdata.map((item: any) => (
                                    <Select.Option
                                      value={item.location}
                                      key={item.id}
                                    >
                                      {item.location}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </>
                      ) : null}
                      <div className="formItem">
                        <label className="formLabel">
                          {t("home_page.homepage.Notes")}
                        </label>
                        <Form.Item name="notes">
                          <Input.TextArea rows={3} />
                        </Form.Item>
                      </div>
                    </Col>

                    <Col md={path == "Stock" || path == "Nonstock" ? 4 : 6}>
                      {path != "Asset" ? (
                        <>
                          <div className="productAdd-Txt1">
                            {t("home_page.homepage.SALES_INFORMATION")}
                          </div>
                          <div className="formItem">
                            <label className="formLabel">
                              {path === "Service"
                                ? "Rate"
                                : `${t("home_page.homepage.Sale_Price")}`}{" "}
                            </label>
                            <Form.Item
                              name="sp_price"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter a Rate",
                              //   },
                              // ]}
                            >
                              <InputNumber
                                type="number"
                                controls={false}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </div>
                          <div className="formItem">
                            <label className="formLabel">
                              {t("home_page.homepage.Vat")}
                            </label>
                            <Form.Item
                              name="vat"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter a Vat",
                              //   },
                              // ]}
                            >
                              <Select onChange={addCategory}>
                                {taxlist?.length &&
                                  taxlist?.map((item: any) => (
                                    <Select.Option key={item?.percentage}>
                                      {`${item?.percentage} %`}
                                    </Select.Option>
                                  ))}

                                <Select.Option
                                  value={"tax"}
                                  style={{
                                    color: "gray",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Add Vat
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            className=""
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label className="formLabel">
                              {t(
                                "home_page.homepage.Select_if_sales_price_inc_vat"
                              )}
                            </label>
                            <Form.Item
                              name="includevat"
                              style={{ border: 0, margin: 0 }}
                              valuePropName="checked"
                            >
                              <Checkbox
                                onChange={(e) => {
                                  form.setFieldsValue({
                                    includevat: e.target.checked,
                                  });
                                  setIncludeVat(!includeVats);
                                }}
                                checked={includeVats}
                              />
                            </Form.Item>
                          </div>
                          <div className="formItem">
                            <label className="formLabel">
                              {t("home_page.homepage.Vat_Amount")}
                            </label>
                            <Form.Item
                              name="vatamt"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter a Vat Amount",
                              //   },
                              // ]}
                            >
                              <InputNumber
                                type="number"
                                disabled={includeVats}
                                controls={false}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </div>
                          {path != "Service" ? (
                            <div className="formItem">
                              <label className="formLabel">
                                {t("home_page.homepage.Wholesale_Price")}
                              </label>
                              <Form.Item
                                name="wholesale"
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Please enter a Wholesale Price",
                                //   },
                                // ]}
                              >
                                <InputNumber
                                  type="number"
                                  controls={false}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          ) : null}
                          <div className="formItem">
                            <label className="formLabel">
                              {t("home_page.homepage.Total_Price")}
                            </label>
                            <Form.Item name="total_price">
                              <InputNumber
                                type="number"
                                controls={false}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </div>
                        </>
                      ) : null}

                      {path == "Stock" ? (
                        <>
                          <div className="formItem">
                            <label className="formLabel">
                              {t("home_page.homepage.Reorder_Level")}
                            </label>
                            <Form.Item name="rlevel">
                              <InputNumber
                                type="number"
                                controls={false}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </div>
                          <div className="formItem">
                            <label className="formLabel">
                              {t("home_page.homepage.Reorder_Quantity")}
                            </label>
                            <Form.Item name="rquantity">
                              <InputNumber
                                type="number"
                                controls={false}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </div>
                        </>
                      ) : null}
                    </Col>

                    <Col md={4}>
                      {path === "Stock" || path == "Nonstock" ? (
                        <>
                          <div className="productAdd-Txt1">
                            {path === "Stock"
                              ? `${t("home_page.homepage.OPENING_BALANCE")}`
                              : "Upload Image"}
                          </div>
                          {path === "Stock" ? (
                            <>
                              <div className="formItem">
                                <label className="formLabel">
                                  {t("home_page.homepage.Quantity_On_Hand")}
                                </label>
                                <Form.Item
                                  name="stockquantity"
                                  rules={[
                                    {
                                      required: true,
                                      message: `${t(
                                        "home_page.homepage.Please_enter_a_Quantity_On_Hand"
                                      )}`,
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    type="number"
                                    controls={false}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </div>
                              <div className="formItem">
                                <label className="formLabel">
                                  {t("home_page.homepage.Cost_Price")}
                                </label>
                                <Form.Item
                                  name="c_price"
                                  rules={[
                                    {
                                      required: true,
                                      message: `${t(
                                        "home_page.homepage.Please_enter_a_Cost_Price"
                                      )}`,
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    type="number"
                                    controls={false}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </div>

                              {/* <div className="formItem">
                                <label className="formLabel">
                                 Cost Price with Vat
                                </label>
                                <Form.Item
                                  name="cost_price_with_vat"
                                  rules={[
                                    {
                                      required: true,
                                      message: `${t(
                                        "home_page.homepage.Please_enter_a_Cost_Price"
                                      )}`,
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    type="number"
                                    controls={false}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </div> */}
                              
                              <div className="formItem">
                                <label className="formLabel">
                                  {t(
                                    "home_page.homepage.Business_Starting_Date"
                                  )}
                                </label>
                                <Form.Item
                                  name="date"
                                  rules={[
                                    {
                                      required: true,
                                      message: `${t(
                                        "home_page.homepage.Please_add_business_starting_date"
                                      )}`,
                                    },
                                  ]}
                                >
                                  {!businessStartDate ? (
                                    <Button
                                      onClick={() =>
                                        navigate("/usr/profile/accounting")
                                      }
                                    >
                                      {t(
                                        "home_page.homepage.Add_Business_Starting_Date"
                                      )}
                                    </Button>
                                  ) : (
                                    <DatePicker
                                      style={{ width: "100%" }}
                                      format={"YYYY-MM-DD"}
                                      disabled={businessStartDate}
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            </>
                          ) : null}
                          <div className="ProductAdd-Box1">
                            <div>
                              <Spin
                                spinning={loading}
                                size="large"
                                tip="Uploading..."
                                indicator={
                                  <LoadingOutlined
                                    style={{ fontSize: 24, color: "#ff9800" }}
                                    spin
                                  />
                                }
                              >
                                {image ? (
                                  <div className="ProductAdd-Box2">
                                    <img
                                      className="ProductAdd-Img"
                                      src={image}
                                    />
                                  </div>
                                ) : (
                                  <div className="ProductAdd-Box2">
                                    <CiImageOff
                                      size={100}
                                      color="rgb(160 158 158)"
                                    />
                                  </div>
                                )}
                              </Spin>
                            </div>

                            <hr />

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Upload
                                // accept="image/*"
                                style={{ color: "" }}
                                listType="picture"
                                showUploadList={false}
                                onChange={onUpload}
                              >
                                <br />
                                <p>
                                  <MdOutlinePhotoCamera
                                    size="20"
                                    color="rgb(160 158 158)"
                                  />
                                  &nbsp;
                                  {t(
                                    "home_page.homepage.Image_Upload_Here"
                                  )}{" "}
                                  <br />
                                </p>
                              </Upload>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </Col>

                    <Col md={path == "Stock" ? 8 : 6}></Col>
                    <Col md={path == "Stock" ? 2 : 3}>
                      <br />
                      <br />
                      <Button onClick={() => navigate(-1)} block size="large">
                        {t("home_page.homepage.Cancel")}
                      </Button>
                    </Col>
                    <Col md={path == "Stock" ? 2 : 3}>
                      <br />
                      <br />
                      <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                        block
                        size="large"
                      >
                        {id === "create"
                          ? `${t("home_page.homepage.submit")}`
                          : `${t("home_page.homepage.Update")}`}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </div>
          </div>
        </Container>
      )}
      ;
      {isForm ? (
        <CreateSettings
          open={isForm}
          close={() => setIsForm(false)}
          source={formType}
          id={"create"}
          reload={
            formType == "unit"
              ? LoadUnit
              : formType == "productCategory"
              ? LoadCategory
              : formType == "tax"
              ? loadTaxList
              : fetchUnits
          }
        />
      ) : null}
    </>
  );
}
export default ProductAdd;
