//PurchaseAssets
import { useEffect, useState } from "react";
import "../styles.scss";
import { useSelector } from "react-redux";

import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";

import LoadingBox from "../../../components/loadingBox";

import PageHeader from "../../../components/pageHeader";
import { Button } from "antd";
import { Container } from "react-bootstrap";
import { useNavigate, Outlet } from "react-router-dom";
import Table from "./component/table";

const PurchaceDebitnotes = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const [isLoading, seisLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    fetchSaleInvoiceList();
  }, []);

  const fetchSaleInvoiceList = async () => {
    try {
      const url = API.PURCHASE_INVOICE_LIST + `${adminid}/pcredit`;
      const { data }: any = await GET(url, null);
      if (data.length) {
        setList(data);
      } else {
        setList([]);
      }
      seisLoading(false);
    } catch (error) {
      console.log(error);
      seisLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        title="Debit Notes"
        goBack={"/dashboard"}
        secondPathText="Purchase Debitnotes"
        secondPathLink={"/usr/purchase-debit-note"}
      >
        <div>
          <Button
            type="primary"
            onClick={() => navigate(`/usr/purchace-debitnote-form/create`)}
          >
            + Create Invoice
          </Button>
        </div>
      </PageHeader>
      <br />
      <Container>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Table List={list} onItemSelect={() => {}} />
        )}
      </Container>
      <Outlet />
    </div>
  );
};

export default PurchaceDebitnotes;
