import "../../styles.scss"; // Import your custom styles
import { useState, useEffect, useRef } from "react";
import DataGrid, {
  Column,
  SearchPanel,
  Export,
  Paging,
  Pager,
  HeaderFilter,
  Selection,
  Item,
  Toolbar,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { Card, Popover, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import {
  MdEditDocument,
  MdOutlineContentCopy,
  MdPreview,
} from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { EXPORT } from "../../../../utils/exportData";
import moment from "moment";
const Table = (props: any) => {
  const dataGridRef: any = useRef(null);
  const [data, setData] = useState(props.List);
  const navigate = useNavigate();
  useEffect(() => {
    setData(props.List);
  }, [props.List]);

  const columns = [
    {
      dataField: "sdate",
      caption: "Invoice Date",
      dataType: "date",
      format: "dd-MM-yyyy",
      alignment: "center",
    },
    {
      dataField: "invoiceno",
      caption: "Invoice No.",
      dataType: "string",
      alignment: "center",
    },
    {
      dataField: "bus_name",
      caption: "Supplier",
      dataType: "string",
      alignment: "center",
    },
    {
      dataField: "total",
      caption: "Total",
      dataType: "number",
      alignment: "center",
    },
    {
      dataField: "outstanding",
      caption: "Outstanding",
      dataType: "number",
      alignment: "center",
    },
    {
      dataField: "status",
      caption: "Status",
      dataType: "number",
      cellRender: (item: any) => {
        let status = null;
        if (item.value == 0) {
          status = <Tag color="red">Unpaid</Tag>;
        } else if (item.value == 2) {
          status = <Tag color="green">Paid</Tag>;
        } else if (item.value === 1) {
          status = <Tag color="orange">Part Paid</Tag>;
        }
        return status;
      },
    },
    {
      caption: "Action",
      alignment: "center",
      cellRender: (data: any) => {
        return (
          <div className="table-title">
            <Popover
              content={
                <div className="table-actionBox">
                  <div
                    className="table-actionBoxItem"
                    onClick={() => {
                      navigate(
                        `/usr/purchace-debitnote-view/${data?.data?.id}`
                      );
                    }}
                  >
                    <div>View</div>
                    <MdPreview size={18} color="grey" />
                  </div>
                  <div
                    className="table-actionBoxItem"
                    onClick={() =>
                      navigate(`/usr/purchace-debitnote-form/${data.data.id}`)
                    }
                  >
                    <div>Edit</div>
                    <MdEditDocument size={18} color="grey" />
                  </div>
                  {/* <div className="table-actionBoxItem">
                    <div>Copy</div>
                    <MdOutlineContentCopy size={18} color="grey" />
                  </div> */}
                </div>
              }
              placement="leftTop"
              trigger={"click"}
            >
              <BsThreeDotsVertical size={16} cursor={"pointer"} />
            </Popover>
          </div>
        );
      },
    },
  ];
  const exportFormats = ["pdf", "xlsx"];

  const customizeExportCell = (type: any, gridCell: any, cell: any) => {
    if (gridCell.rowType === "data" && gridCell.column.dataField === "status") {
      let status = gridCell.data.status;
      if (status == "0") {
        status = "Unpaid";
      } else if (status == "2") {
        status = "Paid";
      } else if (status == "1") {
        status = "Part Paid";
      }
      gridCell.data.status = status;
      if (type === "pdf") {
        cell.text = status;
      } else if (type === "xlsx") {
        cell.value = status;
      }
    }
    if (
      gridCell.column.dataField === "sdate" &&
      gridCell.rowType !== "header" &&
      gridCell.rowType !== "totalFooter"
    ) {
      const sdate = moment(gridCell.data?.sdate)?.format("DD-MM-YYYY");
      if (type === "pdf") {
        cell.text = sdate ?? "";
      } else if (type === "xlsx") {
        cell.value = sdate ?? "";
      }
    }
  };

  return (
    <Card>
      <DataGrid
        ref={dataGridRef}
        dataSource={data}
        columnAutoWidth={true}
        showBorders={true}
        showRowLines={true}
        remoteOperations={false}
        onExporting={(e) =>
          EXPORT(e, dataGridRef, "Debit Note List", customizeExportCell)
        }
      >
        <Selection
          mode="multiple"
          selectAllMode="allPages"
          showCheckBoxesMode="always"
        />
        <SearchPanel visible={true} width={240} />
        <HeaderFilter visible={true} />
        {columns.map((column: any) => (
          <Column
            dataField={column.dataField}
            caption={column.caption}
            cellRender={column.cellRender}
            format={column.format}
            alignment={column.alignment}
            dataType={column.dataType}
            allowExporting={column.caption === "Action" ? false : true}
          />
        ))}
        <Paging defaultPageSize={10} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 30]}
          displayMode={"compact"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Export
          enabled={true}
          allowExportSelectedData={true}
          formats={exportFormats}
        />

        <Toolbar>
          <Item name="searchPanel" />
          <Item location="after" visible={true} name="exportButton" />
        </Toolbar>
        <Summary>
          <TotalItem
            column="total"
            summaryType="sum"
            displayFormat="Total : {0}"
            alignment={"center"}
          />
        </Summary>
      </DataGrid>
    </Card>
  );
};
export default Table;
