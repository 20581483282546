import API from "../../../config/api";
import Salesproformatable from "./datatable";
import { GET } from "../../../utils/apiCalls";
import { useEffect, useState } from "react";
import PageHeader from "../../../components/pageHeader";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import LoadingBox from "../../../components/loadingBox";

const SaleInvoiceProforma = () => {
  const { id }: any = useParams;
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.User);
  const [data, setData] = useState([]);
  const adminid = user.adminid;

  const [isLoading, setisLoading] = useState(true);

  const fetchSalesProformaInvoice = async () => {
    const sales_proforma_invoice_url =
      API.PROFORMA_INVOICE_LIST + `${adminid}/proforma`;
    try {
      const { data }: any = await GET(sales_proforma_invoice_url, null);
      setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
    }
  };
  useEffect(() => {
    fetchSalesProformaInvoice();
  }, []);
  return (
    <div>
      <PageHeader
        title="Proforma Invoice"
        goBack={"/dashboard"}
        secondPathText="Proforma Invoice"
        secondPathLink={"/usr/sales-proforma-invoice"}
      >
        <div>
          <Button
            type="primary"
            onClick={() => navigate(`/usr/proforma-invoice-form/create`)}
          >
            + Create Invoice
          </Button>
        </div>
      </PageHeader>
      <br />
      <Container>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Salesproformatable List={data} onItemSelect={() => {}} />
        )}
      </Container>
      {/* <div style={{ padding: "10px", backgroundColor: "#ffff" }}></div> */}
    </div>
  );
};

export default SaleInvoiceProforma;
