import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/pageHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import LoadingBox from "../../../components/loadingBox";
import DetailsTable from "./DetailsTable";
import { Button, Card, Tooltip, notification } from "antd";
import { Container, Table } from "react-bootstrap";
import moment from "moment";
import { MdFileDownload } from "react-icons/md";

import { useSelector } from "react-redux";
import { journalTemplate } from "./template";

const column = [
  {
    name: "details",
    title: "Details",
    dataType: "string",
    alignment: "center",
  },
  {
    name: "debit",
    title: "Debit",
    alignment: "center",
  },
  {
    name: "credit",
    title: "Credit",
    alignment: "center",
  },
];
function JournalDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { user } = useSelector((state: any) => state.User);
  const [data, setData] = useState<any>([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchDetails = async () => {
    try {
      setIsLoading(true);
      const url = API.JOURNAL + `getJournalById/${id}`;
      const { data }: any = await GET(url, null);
      setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  async function generateTemplate(type: any, emaildata: any) {
    try {
      setDownloadLoading(true);
      let obj = {
        user,
        personalData: data,
        invoiceData: "saleList?.ledgerList",

        openingBalance: "saleList?.openingBalance",

        type: "Customer",
      };
      let templates = journalTemplate(obj);
      await downLoadPdf(templates);
      setDownloadLoading(false);
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  }

  const downLoadPdf = async (templates: any) => {
    try {
      let templateContent = templates?.replace("\r\n", "");
      templateContent = templateContent?.replace('\\"', '"');
      const encodedString = btoa(templateContent);
      const pdf_url = API.PDF_GENERATE_URL;
      const pdfData = {
        filename: "Sales Invoice",
        html: encodedString,
        isDownload: true,
        sendEmail: false,
        type: "",
        userid: "",
      };
      const token = user.token;

      const response = await fetch(pdf_url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(pdfData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const pdfBlob = await response.arrayBuffer();
      const blob = new Blob([pdfBlob], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Journaldetails${moment(new Date()).format("DD-MM-YYYY")}`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);
  return (
    <div>
      <PageHeader
        firstPathLink={location.pathname.replace(`/details/${id}`, "")}
        firstPathText="Journal List"
        secondPathLink={location?.pathname}
        secondPathText="Journal Details"
        buttonTxt="EDIT"
        onSubmit={() => navigate(`/usr/journal/edit/${id}`)}
        goback="/usr/journal"
        title="Journal Details"
        children={
          <div>
            <Tooltip
              title="Download Invoice"
              mouseEnterDelay={0.5}
              arrow={false}
              color="white"
              overlayClassName="toolTip-Card"
              overlayInnerStyle={{
                color: "#000000",
                marginTop: 5,
                fontSize: "14px",
              }}
              placement={"bottom"}
            >
              <Button
                loading={downloadLoading}
                onClick={() => generateTemplate(data?.type, data)}
              >
                <MdFileDownload size={20} />
              </Button>
            </Tooltip>
          </div>
        }
      />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <br />
          <Container>
            <Card>
              <Table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Reference</th>
                    <th>Description</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{moment(data?.userdate).format("DD-MM-YYYY")}</td>
                    <td>{data?.reference}</td>
                    <td>{data?.description}</td>
                    <td>{data?.total}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Container>
          <div>
            <DetailsTable columns={column} data={data?.column} />
          </div>
        </>
      )}
    </div>
  );
}
export default JournalDetails;
