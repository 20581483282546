import dayjs from "dayjs";
import moment from "moment";
import { Spin } from "antd/es";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Select, notification } from "antd";

import "../styles.scss";
import API from "../../../config/api";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import { GET } from "../../../utils/apiCalls";
import { vatTemplate } from "./template";
import { MdAttachEmail, MdFileDownload } from "react-icons/md";
import SendMailModal from "../../../components/sendMailModal";

const customIcon = <LoadingOutlined type="loading" spin size={50} />;

const VatReturns = () => {
  const { edate, sdate, id } = useParams();
  const newDate: any = moment(new Date()).format("YYYY-MM-DD");
  const navigate = useNavigate();
  const User = useSelector((state: any) => state.User.user);
  const year = moment(newDate).format("YYYY");
  

  const FirstQuater: any = [
    moment(`${year}-01-01`, "YYYY-MM-DD").format("YYYY-MM-DD"),
    moment(`${year}-03-31`, "YYYY-MM-DD").format("YYYY-MM-DD"),
  ];
  const SecondQuater: any = [
    moment(`${year}-04-01`, "YYYY-MM-DD").format("YYYY-MM-DD"),
    moment(`${year}-06-30`, "YYYY-MM-DD").format("YYYY-MM-DD"),
  ];
  const ThirdQuater: any = [
    moment(`${year}-07-01`, "YYYY-MM-DD").format("YYYY-MM-DD"),
    moment(`${year}-10-01`, "YYYY-MM-DD").format("YYYY-MM-DD"),
  ];
  const FourthQuater: any = [
    moment(`${year}-10-01`, "YYYY-MM-DD").format("YYYY-MM-DD"),
    moment(`${year}-12-31`, "YYYY-MM-DD").format("YYYY-MM-DD"),
  ];

  const [vatSales, setVatSales] = useState<any>([]);
  const [vatPurchase, setVatPurchase] = useState<any>([]);
  let totalSales: any = 0;
  let totalPurchase: any = 0;
  const [totSaleValue, setTotSaleValue] = useState<any>(0);
  const [totPurchaseValue, setTotPurchaseValue] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [emailModal, setEmailModal] = useState(false);

  const Dtoday = moment(new Date());
  const DoneMonthAgo = moment(new Date().setMonth(Dtoday.month() - 1));

  const today = Dtoday.format("YYYY-MM-DD");
  const oneMonthAgo = DoneMonthAgo.format("YYYY-MM-DD");
  const [currentDate, setCurrentDate] = useState(today);
  const [oneMonthAgoDate, setOneMonthAgoDate] = useState(oneMonthAgo);
  const [startDate, setStartDate] = useState(sdate);
  const [endDate, setEndDate] = useState(edate);

  const [vat_on_sales, set_vat_on_sales] = useState<any>([]);
  const [total_vat_on_sales, set_total_vat_on_sales] = useState<any>([]);
  const [vat_on_purchase, set_vat_on_purchase] = useState<any>(0);
  const [total_vat_on_purchase, set_total_vat_on_purchase] = useState<any>(0);

  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("DD-MM-YYYY"));

  const [dateRangeValue, setDateRangeValue] = useState<any>([
    moment(sdate).format("YYYY-MM-DD"),
    moment(edate).format("YYYY-MM-DD"),
  ]);

  const location = useLocation();
  useEffect(() => {
    setStartDate(dateRangeValue[0]);
    setEndDate(dateRangeValue[1]);
    setTimeout(() => {
      setIsLoading2(true);
      getVatReturn(dateRangeValue[0], dateRangeValue[1]);
    }, 200);
  }, [dateRangeValue]);

  const getVatReturn = async (startDate?: any, endDate?: any) => {
    try {
      setIsLoading2(true);
      const formattedSDate = moment(new Date(startDate)).format("YYYY-MM-DD");
      const formattedEDate = moment(new Date(endDate)).format("YYYY-MM-DD");
      let url =
        API.VAT_RETURN +
        User?.adminid +
        "/" +
        formattedSDate +
        "/" +
        formattedEDate;
      const vatReturn: any = await GET(url, null);

        let data = vatReturn?.data;
        const vatSales: any = data.map((item: any) => item?.vatSales);
        setVatSales(vatSales);
        const vatPurchase: any = data.map((item: any) => item?.vatPurchase);
        setVatPurchase(vatPurchase);

        const sumVatSales = sumDataBasedOnPercentage(vatSales);
        const sumVatPurchase = sumDataBasedOnPercentage(vatPurchase);

        const totalVatSales = calculateTotal(vatSales);
        setTotSaleValue(totalVatSales);

        const totalVatPurchase = calculateTotal(vatPurchase);
        setTotPurchaseValue(totalVatPurchase);
        set_vat_on_sales(vatSales);
        set_vat_on_purchase(vatPurchase);
        set_total_vat_on_sales(Number(sumVatSales));
        set_total_vat_on_purchase(Number(sumVatPurchase));
        setIsLoading2(false);
        setIsLoading(false);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error",
        description: "Network error. Please try again later.",
      });
    } finally {
      setIsLoading2(false);
      setIsLoading(false);
    }
  };

  function sumDataBasedOnPercentage(array: any) {
    return array.reduce((acc: any, item: any) => {
      const percentage = item.percentage;
      const total = parseFloat(item.total);
      acc[percentage] = (acc[percentage] || 0) + total;
      return acc;
    }, {});
  }

  function calculateTotal(array: any) {
    return array.reduce(
      (acc: any, item: any) => acc + parseFloat(item.total),
      0
    );
  }

  const OnPeriodChange = (period: any) => {
    if (period?.children === "First Quater") {
      setDateRangeValue(FirstQuater);
    } else if (period?.children === "Second Quater") {
      setDateRangeValue(SecondQuater);
    } else if (period?.children === "Third Quater") {
      setDateRangeValue(ThirdQuater);
    } else if (period?.children === "Fourth Quater") {
      setDateRangeValue(FourthQuater);
    }
  };

  const sendMailPdf = async (templates: any, email: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    const encodedString = btoa(templateContent);
    const pdf_url = API.PDF_GENERATE_URL;
    const pdfData = {
      email: email,
      filename: "Sales Invoice",
      html: encodedString,
      isDownload: false,
      sendEmail: true,
      type: "",
      userid: "",
    };
    const token = User.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (response.ok) {
      notification.success({ message: "Email Successfully Sent" });
      setEmailModal(false);
    }
    if (!response.ok) {
      notification.success({
        message:
          "Apologies, there was an error when attempting to send the email.",
      });
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  };

  async function genrateTemplate(type: any, emaildata: any) {
    try {
      setDownloadLoading(true);
      let obj = {
        User,
        vatSales:vat_on_sales,
        vatPurchase:vat_on_purchase,
        totalSalesValue:totSaleValue,
        totalPurchaseValue:totPurchaseValue,
        personalData:User.companyInfo,
        currentDate,
        oneMonthAgoDate,
        type:'Customer',
        toDate
      };
      
      let templates: any = null;
      if (!User) {
        notification.error({
          message: <div>Please select an email template</div>,
          description: (
            <Button
              type={"link"}
              onClick={() => navigate("/usr/settings/customize")}
            >
              Click to select
            </Button>
          ),
        });
        return;
      }
      if (!User) {
        notification.error({
          message: <div>Please select default Bank </div>,
          description: (
            <Button
              type={"link"}
              onClick={() => navigate("/usr/profile/business")}
            >
              Click to select
            </Button>
          ),
        });
        return;
      }
      
      if(User){
        templates = vatTemplate(obj)
      }
      if (type === "email") {
        sendMailPdf(templates, emaildata);
      } else {
        await downLoadPdf(templates);
      }

      setDownloadLoading(false);
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  }
  async function generateTemplate(type: any, emaildata: any) {
    try {
      setDownloadLoading(true);
      let obj = {
        User,
        vatSales:vat_on_sales,
        vatPurchase:vat_on_purchase,
        totalSalesValue:totSaleValue,
        totalPurchaseValue:totPurchaseValue,
        personalData:User.companyInfo,
        currentDate,
        oneMonthAgoDate,
        type:'Customer'
      }
      let templates = vatTemplate(obj);
      await downLoadPdf(templates);
      setDownloadLoading(false);
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  }

  const downLoadPdf = async (templates: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    const encodedString = btoa(templateContent);
    const pdf_url = API.PDF_GENERATE_URL;
    const pdfData = {
      filename: "Sales Invoice",
      html: encodedString,
      isDownload: true,
      sendEmail: false,
      type: "",
      userid: "",
    };
    const token = User.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const pdfBlob = await response.arrayBuffer();
    const blob = new Blob([pdfBlob], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Vat/Gst${moment(new Date()).format("DD-MM-YYYY")}`;
    a.click();
    URL.revokeObjectURL(url);

  }

 
  const OnDateChange = (val: any) => {
    if (val?.length) {
      let sdate: any = dayjs(val[0]).format("YYYY-MM-DD");
      let edate: any = dayjs(val[1]).format("YYYY-MM-DD");
      if (val[0]) setStartDate(sdate);
      if (val[1]) setEndDate(edate);
      startDate &&
        endDate &&
        setTimeout(() => {
          setIsLoading2(true);
          getVatReturn(sdate, edate);
        }, 300);
    }
  };

  return (
    <>
      <PageHeader
        title="VAT/GST"
        firstPathText="Report"
        firstPathLink="/usr/report"
        secondPathText="VAT/GST"
        secondPathLink={location.pathname}
        children={
          <div>
            <Button className="Report-HeaderButton-dwnld" onClick={() => generateTemplate("downLoad", {})}><MdFileDownload size={20} /></Button>{" "}
            <Button className="Report-HeaderButton-print" onClick={() => setEmailModal(true)}><MdAttachEmail size={20} /></Button>
          </div>
        }
      />
      <Container>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <>
            <br />
            <Card>
              <Row>
                <Col md={"3"}>
                  <div className="formLabel">Period</div>
                  <Select
                    size="large"
                    allowClear
                    placeholder={"Period"}
                    className="width100"
                    defaultValue={"Custom"}
                    onChange={(val: any, data: any) => {
                      OnPeriodChange(data);
                    }}
                  >
                    {[
                  "First Quater",
                  "Second Quater",
                  "Third Quater",
                  "Fourth Quater",
                  "Custom",
                ].map((item: any, i: number) => (
                  <Select.Option key={i}>{item}</Select.Option>
                ))}
              </Select>
                    
                </Col>
                <Col md={"6"}>
                  <div className="formLabel">From Date - To Date</div>
                  <DatePicker.RangePicker
                    size="large"
                    className="width100"
                    
                    defaultValue={[
                      dayjs(edate, "YYYY/MM/DD"),
                      dayjs(sdate, "YYYY/MM/DD"),
                    ]}
                    value={
                      dateRangeValue
                        ? [dayjs(dateRangeValue[0]), dayjs(dateRangeValue[1])]
                        : null
                    }
                    format={"YYYY-MM-DD"}
                    onCalendarChange={(val: any) => {
                      OnDateChange(val);
                    }}
                  />
                </Col>
              </Row>
            </Card>
            <br />
            <Card title="Overall VAT Report">
              <div className="Report-pageDetails">
                <div className="ReportHeadBox">
                  Business Name
                  <b>{User?.companyInfo?.bname}</b>
                </div>

                <div className="ReportHeadBox">
                  <b>VAT/GST Period</b>
                  <div>
                    From : <b>{moment(startDate).format("DD-MM-YYYY")}</b>
                  </div>
                  <div>
                    To : <b>{moment(endDate).format("DD-MM-YYYY")}</b>
                  </div>
                </div>
              </div>
            </Card>
            <br />

            {isLoading2 ? (
              <div className="LoaderBox">
                <Spin indicator={customIcon} />
              </div>
            ) : (
              <>
                <Card title={<strong>VAT on Sales</strong>}>
                  <Table bordered hover striped>
                    <thead className="Report-thead">
                      <th className="Report-table-th">Rate</th>
                      <th className="Report-table-th">Amount</th>
                    </thead>
                    <tbody>
                      {vat_on_sales && vat_on_sales?.map((item: any, i: number) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>{item?.percentage}%</td>
                              <td className="Report-table-td">{item?.total}</td>
                            </tr>
                          </>
                        );
                      })}
                      <tr>
                        <td>
                          <b>Total Vat on Sales</b>
                        </td>
                        <td
                          onClick={() =>
                            navigate(
                              `/usr/report/vatReturnView/54/${moment(
                                new Date(oneMonthAgoDate)
                              ).format("YYYY-MM-DD")}/${moment(
                                new Date(currentDate)
                              ).format("YYYY-MM-DD")}`
                            )
                          }
                          className="Report-table-td-total Report-td-link"
                        >
                          {totSaleValue && Number(totSaleValue).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card>
                <br />
                <Card title={<strong>VAT on Purchase</strong>}>
                  <Table bordered hover striped>
                    <thead className="Report-thead">
                      <th className="Report-table-th">Rate</th>
                      <th className="Report-table-th">Amount</th>
                    </thead>
                    <tbody>
                      {vat_on_purchase && vat_on_purchase?.map((item: any, i: number) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>{item?.percentage}%</td>
                              <td className="Report-table-td">{item?.total}</td>
                            </tr>
                          </>
                        );
                      })}
                      <tr>
                        <td>
                          <b>Total Vat on Purchase</b>
                        </td>

                        <td
                          onClick={() =>
                            navigate(
                              `/usr/report/vatReturnView/55/${moment(
                                new Date(oneMonthAgoDate)
                              ).format("YYYY-MM-DD")}/${moment(
                                new Date(currentDate)
                              ).format("YYYY-MM-DD")}`
                            )
                          }
                          className="Report-table-td-total Report-td-link"
                        >
                          {totPurchaseValue &&
                            Number(totPurchaseValue).toFixed(2)}
                        </td>
                      </tr>

                      <br />
                      <tr>
                        <td>
                          <b>Overall VAT</b>
                        </td>
                        <td>
                          <b>
                            {Number(
                              Number(totSaleValue) - Number(totPurchaseValue)
                            ).toFixed(2)}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card>
                <br />
              </>
            )}
          </>
        )}
        {emailModal ? (
          <SendMailModal
            open={emailModal}
            close={() => setEmailModal(false)}
            onFinish={(val: any) => genrateTemplate("email", val)}
            ownMail={User.email}
            fileName={`vatDetails${new Date()}.pdf`}
            Attachment={`${User.companyInfo.bname}_vatDetails_${moment(new Date()).format("DD-MM-YYYY")}`}
            defaultValue={{
              to: User.email,
              subject: `VAT/GST`,
              content: 'VAT/GST Details',
            }}
          />
        ) : null}
      </Container>
    </>
  );
};

export default VatReturns;
