import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import API from "../../../config/api";
import { DELETE, GET, POST } from "../../../utils/apiCalls";
import {
  Avatar,
  Card,
  Col,
  Row,
  Select,
  SelectProps,
  Tooltip,
  notification,
  Form,
  Space,
  Table,
  Pagination,
  Tag,
  Modal,
} from "antd";
import { Button } from "antd";
import { Container } from "react-bootstrap";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import "./styles.scss";
import DataTableTask from "../hr-task/dataTable";

import MembersModal from "./components/membersModal";
import FormItem from "antd/es/form/FormItem";
import ExcelImport from "../hr-task/components/excelImport";
import { useTranslation } from "react-i18next";
import { SiMicrosoftexcel } from "react-icons/si";
import moment from "moment";

function ViewHrProject() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [Projectdata, setProjectData] = useState<any>();
  const [getdata, setGetData] = useState<any>([]);

  const [openMembers, setOpemMembers] = useState(false);
  console.log("member", openMembers);
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [userData, setuserData] = useState<any>([]);
  const [members, setMembers] = useState<Number[]>([]);
  const [meta, setMeta] = useState<any>({});
  const [excelModal, setExcelModal] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<any>(null);
  const { t } = useTranslation();
  const options: SelectProps["options"] = [];
  const colorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
  const { id, proid } = useParams();
  const [form] = useForm();

  console.log("data====", data);

  const reload = () => {
    getOneProjectData();
    listUser();
    getallmemberslist();
    fetchHrTask(1, 10);
  };

  const getOneProjectData = async () => {
    try {
      let api = API.VIEW_PROJECT + id;
      const Data: any = await GET(api, {});
      if (Data?.status) {
        setProjectData(Data?.data);
      } else {
        setProjectData({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postAllMembers = async (values: any) => {
    setIsLoading(true);
    try {
      let obj = {
        userId: members,
        projectId: Number(id),
      };

      let api = API.POST_MEMBERS;

      let postRequest: any = await POST(api, obj);
      if (postRequest.status) {
        notification.success({ message: " create" });
        getallmemberslist();
      } else {
        notification.error({ message: "something went wrong" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getallmemberslist = async () => {
    try {
      let api = API.GET_PROJECT_MEMBERS + id + "?order=ASC&page=1&take=10";
      const Data: any = await GET(api, {});
      if (Data?.status) {
        setGetData(Data?.data);

        for (let i = 0; i < Data?.data?.length; i++) {
          options.push({
            label: Data?.data[i].fullName.toString(),
            value: Data?.data[i].id.toString(),
          });
        }
      } else {
        setGetData({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const listUser = async () => {
    try {
      let api = API.GET_HR_EMPLOYEE_LISTING + "?order=ASC&page=1&take=20";
      const Data: any = await GET(api, {});
      if (Data?.status) {
        setuserData(Data?.data);

        for (let i = 0; i < Data?.data?.length; i++) {
          options.push({
            label: Data?.data[i].fullName.toString(),
            value: Data?.data[i].id.toString(),
          });
        }
      } else {
        setuserData({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: any) => {
    setProjectToDelete(id);
  };
  const confirmDelete = async () => {
    try {
      setIsLoading2(true);
      let api = API.DELETE_PROJECT + projectToDelete;
      let Delete: any = await DELETE(api);
      if (Delete?.status) {
        setIsLoading2(false);
        notification["success"]({
          message: "Project Deleted",
        });
        getOneProjectData();
        navigate(-1);
      } else {
        setIsLoading2(false);
        notification["error"]({
          message: "Project not deleted",
        });
      }
    } catch (err) {
      setIsLoading2(false);
      notification["error"]({
        message: "Network error. Project not deleted",
      });
    } finally {
      setProjectToDelete(null);
    }
  };
  const cancelDelete = () => {
    setProjectToDelete(null);
  };

  const handleView = (id: string) => {
    navigate(`/usr/hr-task/details/${id}`);
  };

  const handleEdit = (id: string) => {
    navigate(`/usr/hr-task/edit/${Projectdata.id}/${id}`);
  };

  useEffect(() => {
    getOneProjectData();
    listUser();
    getallmemberslist();
    fetchHrTask(1, 10);
  }, []);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any) => (
        <>
          <Tag
            color={
              text === "pending"
                ? "red"
                : text === "in_progress"
                ? "blue"
                : "green"
            }
            key={text}
          >
            <a>{text?.toUpperCase()}</a>
          </Tag>
        </>
      ),
    },

    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text: any) => <a>{moment(text).format("ll")}</a>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text: any) => {
        const isTodayOrPast = moment(text).isSameOrBefore(moment(), "day");
        return (
          <a style={{ color: isTodayOrPast ? "red" : "black" }}>
            {moment(text).format("ll")}
          </a>
        );
      },
    },
    {
      title: "Assigned To",
      dataIndex: "empinfo",
      key: "empinfo",
      render: (record: any) => {
        console.log("data", record);
        return <div>{record?.fullName}</div>;
      },
    },

    // {
    //   title: "Assigned To",
    //   dataIndex: "assignedTo",
    //   key: "assignedTo",
    // },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleView(record?.id)}
          >
            View
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleEdit(record?.id)}
          >
            Edit
          </div>
        </Space>
      ),
    },
  ];

  const fetchHrTask = async (page: any, take: any) => {
    try {
      setIsLoading(true);
      let obj = {
        query: form.getFieldValue("query"),
        page: page,
        take: take,
        projectId: Number(id),
      };
      const url = API.POST_PAGE_TASK;
      const tasks: any = await POST(url, obj);
      if (tasks?.status) {
        setData(tasks.data);
        console.log(tasks.data);
        setMeta(tasks?.meta);
      } else {
        setData([]);
        setMeta({});
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <PageHeader
            firstPathText="Project"
            title="Project Details"
            buttonTxt={"ADD TASK"}
            onSubmit={() => navigate(`/usr/hr-task/create/${id}/null`)}
          >
            <Tooltip title={t("home_page.homepage.Import_from_Excel")}>
              <Button onClick={() => setExcelModal(true)}>
                <SiMicrosoftexcel size={20} />
              </Button>
            </Tooltip>
            <ExcelImport
              visible={excelModal}
              proId={id}
              onCancel={() => setExcelModal(false)}
              onSucess={() => fetchHrTask(1, 10)}
              URL={API.TASK_EXCEL}
              reload={reload}
            />
          </PageHeader>
          <br />
          <Container>
            <Card>
              <Space size={20}>
                <Button
                  onClick={() => navigate(`/usr/hr-projects/edit/${id}`)}
                  className="button-style"
                >
                  Edit
                </Button>
                <Button
                  onClick={() => navigate("/usr/projectpayments")}
                  className="button-style"
                >
                  PAYMENT
                </Button>
              </Space>
            </Card>
            <Card>
              <div className="productAdd-Txt1">Project Details</div>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Title :</strong>
                </Col>
                <Col span={18}>
                  <span>{Projectdata?.title}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Client :</strong>
                </Col>
                <Col span={18}>
                  <span>{Projectdata?.client1?.name}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Description :</strong>
                </Col>
                <Col span={18}>
                  <span>{Projectdata?.description}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Priority :</strong>
                </Col>
                <Col span={18}>
                  <Tag
                    color={
                      Projectdata?.priority === "high"
                        ? "red"
                        : Projectdata?.priority === "medium"
                        ? "blue"
                        : "green"
                    }
                    key={Projectdata?.priority}
                  >
                    <a>{Projectdata?.priority?.toUpperCase()}</a>
                  </Tag>
                </Col>
              </Row>
              {/* <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Sprint :</strong>
                </Col>
                <Col span={18}>
                  <span>{Projectdata?.sprint}</span>
                </Col>
              </Row> */}
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Start Date :</strong>
                </Col>
                <Col span={18}>
                  <span>{moment(Projectdata?.startDate).format("ll")}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>End Date :</strong>
                </Col>
                <Col span={18}>
                  <span> {moment(Projectdata?.endDate).format("ll")} </span>
                </Col>
              </Row>
              <Row justify="end" style={{ marginTop: "16px" }}>
                <Col>
                  <Button
                    onClick={() => {
                      handleDelete(Projectdata?.id);
                    }}
                    // style={{
                    //   position: "absolute",
                    //   top: 10,
                    //   right: 10,
                    //   zIndex: 1,
                    //   // marginRight: "30px",
                    // }}
                    type="primary"
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </Card>
            <br />
            {/* members details */}
            <Card>
              <div className="productAdd-Txt1">Members details</div>
              <Form onFinish={postAllMembers}>
                <Row gutter={[16, 16]}>
                  <Col md={18}>
                    <Form.Item
                      name="members"
                      rules={[
                        {
                          required: true,
                          message: "Please select at least one member!",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: "100%" }}
                        onChange={(id: any) => {
                          setMembers(id);
                        }}
                        placeholder="ADD Members"
                      >
                        {userData?.map((users: any) => (
                          <Select.Option key={users.id} value={users.id}>
                            {users.fullName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item>
                      <Button htmlType="submit">ADD</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <div>
                <br />
                <div className="userList">
                  {Array.isArray(getdata) &&
                    getdata?.slice(0, 7)?.map((users: any, i: any) => (
                      <Tooltip
                        key={i}
                        placement="top"
                        title={users?.employee?.fullName}
                      >
                        <Avatar
                          style={{
                            color: "#fff",
                            backgroundColor:
                              colorList[Math.floor(Math.random() * 4)],
                          }}
                        >
                          {users?.employee?.fullName.charAt(0).toUpperCase()}
                        </Avatar>
                      </Tooltip>
                    ))}
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div className="txt1" onClick={() => setOpemMembers(true)}>
                    View members
                  </div>
                </div>
              </div>
            </Card>
            <br />
            <Card>
              <h3 style={{ textAlign: "center" }}>Task Details</h3>
              <br />
              <h6>Total {meta?.total_count} Task</h6>
              <Table dataSource={data} columns={columns} pagination={false} />
              <br />
            </Card>
            <Pagination
              defaultCurrent={meta?.page}
              total={meta?.total_count}
              onChange={(page, take) => {
                fetchHrTask(page, take);
              }}
              style={{
                display: meta?.total_count > 10 ? "block" : "none",
                textAlign: "right",
                marginTop: "20px",
              }}
            />
            <br />
            <br />
          </Container>
          <Modal
            title="Confirm Deletion"
            visible={projectToDelete !== null}
            onOk={confirmDelete}
            onCancel={cancelDelete}
            okText="Delete"
            cancelText="Cancel"
          >
            <p>Are you sure you want to delete this Project?</p>
          </Modal>
        </>
      )}

      {openMembers ? (
        <MembersModal
          open={openMembers}
          data={getdata}
          close={() => setOpemMembers(false)}
        />
      ) : null}
    </>
  );
}

export default ViewHrProject;
