import React, { useRef, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import DataGrid, {
  Column,
  SearchPanel,
  Export,
  Paging,
  Pager,
  HeaderFilter,
  Selection,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { Card, Popover } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import ViewPopover from "../../../components/viewPopover";
import { useNavigate } from "react-router-dom";
import { EXPORT } from "../../../utils/exportData";
import { Container } from "react-bootstrap";

const SupplierListTable = (props: any) => {
  const dataGridRef: any = useRef(null);
  const [selectedRows, setSelectedRows] = useState();
  const [page, SetPage] = useState(1);
  const [take, setTake] = useState(10);

  const navigate = useNavigate();
  const onSelectionChanged = (e: any) => {
    setSelectedRows(e.selectedRowsData?.length);
  };
  const exportFormats = ["xlsx", "pdf"];

  const handleOptionChanged = (e: any) => {
    if (e.fullName === "paging.pageIndex") {
      SetPage(e.value);
    }
    if (e.fullName === "paging.pageSize") {
      setTake(e.value);
    }
    if (e.fullName === "paging.pageSize" || e.name === "pageSize") {
      props?.onPageChange(page, take);
    }
  };

  const columns = [
    {
      dataField: "name",
      caption: "Supplier Name",
      cellRender: ({ data }: any) => (
        <div style={{ textAlign: "center" }}>{data?.name}</div>
      ),
    },
    {
      dataField: "bus_name",
      caption: "Business Name",
      cellRender: ({ data }: any) => (
        <div style={{ textAlign: "center" }}>{data?.bus_name}</div>
      ),
    },
    {
      dataField: "reference",
      caption: "Reference",
      cellRender: ({ data }: any) => (
        <div style={{ textAlign: "center" }}>{data?.reference}</div>
      ),
    },
    {
      dataField: "email",
      caption: "Email",
      cellRender: ({ data }: any) => (
        <div style={{ textAlign: "center" }}>
          {data?.email ? data?.email : "-"}
        </div>
      ),
    },
    {
      dataField: "mobile",
      caption: "Phone",
      cellRender: ({ data }: any) => (
        <div style={{ textAlign: "center" }}>
          {data?.mobile ? data?.mobile : "-"}
        </div>
      ),
    },
    {
      caption: "Action",
      cellRender: ({ data }: any) => {
        return (
          <div className="d-flex justify-content-center">
            <Popover
              content={
                <ViewPopover
                  onView={() => navigate(`details/${data?.id}`)}
                  OnEdit={() => navigate(`edit/${data?.id}`)}
                  OnDelete={() => {
                    props?.handleDelete(data?.id);
                  }}
                />
              }
              placement="bottom"
              trigger={"click"}
            >
              <BsThreeDotsVertical size={16} cursor={"pointer"} />
            </Popover>
          </div>
        );
      },
    },
  ];

  return (
    <Container>
      <br />
      <Card>
        <DataGrid
          ref={dataGridRef}
          dataSource={props?.list}
          columnAutoWidth={true}
          showBorders={true}
          onExporting={(e) => EXPORT(e, dataGridRef, "suppliers", () => {})}
          onOptionChanged={handleOptionChanged}
          showRowLines={true}
          onSelectionChanged={onSelectionChanged}
          showColumnLines={true}
          style={{ textAlign: "center" }}
          searchPanel={{
            visible: true,
            width: 240,
            placeholder: "Search Supplier",
            searchVisibleColumnsOnly: true,
            highlightCaseSensitive: false,
          }}
        >
          <Selection
            mode="multiple"
            selectAllMode="allPages"
            showCheckBoxesMode="always"
          />
          <HeaderFilter visible={true} />
          {columns.map((column, index) => (
            <Column
              key={index}
              dataField={column.dataField}
              caption={column.caption}
              cellRender={column.cellRender}
              allowExporting = {column?.caption === 'Action'?false:true}
            />
          ))}
          <Paging defaultPageSize={10} />

          <Pager
            visible={true}
            allowedPageSizes={[10, 20, 30]}
            displayMode={"compact"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Export
            enabled={true}
            allowExportSelectedData={true}
            formats={exportFormats}
          />
          <Toolbar>
            {selectedRows ? (
              <Item location="before" visible={true}>
                <div style={{ fontSize: "17px", fontWeight: 600 }}>
                  {selectedRows} selected
                </div>
              </Item>
            ) : (
              <Item location="before" visible={true}>
                <div style={{ fontSize: "17px", fontWeight: 600 }}>
                  Total Suppliers {props?.list?.length}
                </div>
              </Item>
            )}
            <Item name="searchPanel" />
            <Item location="after" visible={true} name="exportButton" />
          </Toolbar>
        </DataGrid>
      </Card>
    </Container>
  );
};
export default SupplierListTable;
