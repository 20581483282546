import { Button, Input, Select, Spin, notification } from "antd";
import Form from "antd/es/form";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import API from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";
import country from "../hr-client/country.json";
import DataTable from "./Datatable";
import PageHeader from "../../../components/pageHeader";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

const Client = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [clientData, setClientData] = useState<any>([]);
  const [dataCount, setDataCount] = useState<any>([]);
  const [take, setTake] = useState(10);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  console.log("dataCount", dataCount);

  const reload = () => {
    getAllData();
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Company", dataIndex: "company", key: "company" },
    { title: "Country", dataIndex: "country", key: "country" },
    { title: "Phone", key: "phone", dataIndex: "phone" },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "contactPerson",
      dataIndex: "contactPerson",
      key: "contactPerson",
    },
    { title: "altPhone", dataIndex: "altPhone", key: "altPhone" },
  ];
  const getAllData = async () => {
    try {
      let obj = {
        query: form.getFieldValue("query"),
        page: page,
        take: 10,
      };
      let api = API.POST_PAGE_CLIENTS;
      const count: any = await POST(api, obj);
      console.log("count", count);
      if (count?.status) {
        setDataCount(count?.data);
        setMeta(count?.meta);
      } else {
        console.error("Failed to fetch data from the API.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllData();
  }, [page, take]);

  const handleSubmit = async (values: any) => {
    console.log("====== ", values);

    try {
      const obj = {
        name: String(values.name),
        company: String(values.company),
        country: String(values.country),
        phone: values.phone,
        email: String(values.email),
        contactPerson: String(values.contactPerson),
        altPhone: values.altPhone,
        clientId: Number(moment().unix()),
      };

      console.log("time", obj);
      let api = API.HR_CLIENTS;
      let postRequest: any = await POST(api, obj);
      console.log("dattataaaa", postRequest);
      if (postRequest.status) {
        notification.success({
          message: "client  submitted successfully",
        });
      } else {
        notification.error({ message: "Something went wrong" });
      }
      reload();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something! Please try again later",
      });
    }
  };

  const handleReset = () => {
    form.resetFields();
  };

  const validatePhone = (_: any, value: string) => {
    if (value && /[a-zA-Z]/.test(value)) {
      return Promise.reject(new Error("Phone number cannot contain letters"));
    }
    return Promise.resolve();
  };

  return (
    <>
      <PageHeader title="Client Details" />
      <br />
      <div className="screen-box">
        <Container>
          <Card>
            <Form onFinish={handleSubmit} form={form} layout="vertical">
              <Row gutter={12}>
                <Col md="4">
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: "Please input Name" }]}
                  >
                    <Input size="large" type="string" />
                  </Form.Item>
                </Col>
                <Col md="4">
                  <Form.Item
                    label="Company"
                    name="company"
                    rules={[
                      { required: true, message: "Please input Company" },
                    ]}
                  >
                    <Input size="large" type="string" />
                  </Form.Item>
                </Col>
                <Col md="4">
                  <Form.Item
                    label="Country"
                    name="country"
                    rules={[
                      { required: true, message: "Please select a Country" },
                    ]}
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="Select Country"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      allowClear
                    >
                      {country?.map((cou: any) => (
                        <Select.Option value={cou.value}>
                          {cou.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col md="4">
                  <Form.Item
                    label="phone "
                    name="phone"
                    rules={[{ required: true, message: "Please input phone" }]}
                  >
                    <Input
                      size="large"
                      type="tel"
                      onKeyDown={(e) => {
                        if (e.keyCode !== 8 && e.key.match(/[a-zA-Z]/)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md="4">
                  <Form.Item
                    label="email"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      { required: true, message: "Please input email" },
                    ]}
                  >
                    <Input size="large" type="string" />
                  </Form.Item>
                </Col>
                <Col md="4">
                  <Form.Item
                    label="Contact Person"
                    name="contactPerson"
                    rules={[
                      {
                        required: true,
                        message: "Please input Contact Person",
                      },
                    ]}
                  >
                    <Input size="large" type="string" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col md="4">
                  <Form.Item
                    label="altPhone"
                    name="altPhone"
                    rules={[
                      { required: false, message: "Please input altphone" },
                    ]}
                  >
                    <Input
                      size="large"
                      type="tel"
                      onKeyDown={(e) => {
                        if (e.keyCode !== 8 && e.key.match(/[a-zA-Z]/)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={5}>
                <Col md={7} />
                <Col md={2}>
                  <Form.Item>
                    <Button
                      size="large"
                      style={{ width: "100%" }}
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </Form.Item>
                </Col>

                <Col md={3}>
                  <Form.Item>
                    <Button
                      size="large"
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 24, color: "#fff" }}
                              spin
                            />
                          }
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <br />
          <Card>
            <div>
              <DataTable
                dataSource={dataCount}
                columns={columns}
                isLoading={isLoading}
                meta={meta}
                onPageChange={(page: number, pageSize: number) => {
                  setIsLoading(true);
                  setPage(page);
                  setTake(pageSize);
                }}
              />
            </div>
          </Card>
          <br />
        </Container>
      </div>
    </>
  );
};

export default Client;
