import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageHeader from "../../../components/pageHeader";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../config/api";
import { GET, POST, PUT } from "../../../utils/apiCalls";
import { useSelector } from "react-redux";

function UpdateTask() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [data, setData] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);

  const { type, proId, id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [getdatas, setGetData] = useState<any>([]);

  console.log("data", getdatas);
  useEffect(() => {
    setisUpdate(true);
    getData(id);

    getallmemberslist();
  }, []);

  console.log("getdatas ---> ", getdatas);

  const getData = async (id: any) => {
    try {
      setIsLoading(true);
      const url = API.GET_ONE_TASK + id;
      const { data }: any = await GET(url, {});
      setData(data);
      form.setFieldsValue({
        title: data.title,
        description: data.description,
        startDate: data?.startDate ? dayjs(data.startDate, "YYYY/MM/DD") : null,
        endDate: data?.endDate ? dayjs(data.endDate, "YYYY/MM/DD") : null,
        projectId: data.projectId,
        priority: data.priority,
        assignedTo: data.assignedTo,
      });

      console.log(data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getallmemberslist = async () => {
    try {
      let api = API.GET_PROJECT_MEMBERS + proId + "?order=ASC&page=1&take=20";
      const Data: any = await GET(api, null);
      if (Data?.status) {
        console.log(Data);
        setGetData(Data?.data);
      } else {
        setGetData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = async (values: any) => {
    console.log(values);
    try {
      setIsLoading(true);
      console.log("values --> ", values);
      let obj: any = {
        key: "Key",
        taskId: String(values.taskId),
        title: values.title,
        description: values.description,
        type: "Type",
        startDate: values.startDate.toISOString(),
        endDate: values.endDate.toISOString(),
        createdBy: 2,
        assignedTo: Number(values.assignedTo),
        priority: values.priority,
        tag: values.title,
        label: values.title,
        weightage: 5,
        status: "pending",
        isStatusUpdate: true,
      };

      setIsLoading2(true);
      let updateUrl = API.EDIT_TASK + id;

      let result: any = await PUT(updateUrl, obj);
      if (result.status) {
        getData(id);
        notification.success({
          message: `Item ${isUpdate ? "Updated" : "Added"} Successfully`,
        });
        setIsLoading(false);
        navigate(-1);
      } else {
        setIsLoading(false);
        notification.error({ message: "something went wrong" });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    } finally {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  return (
    <>
      <PageHeader
        firstPathText="Project"
        // firstPathLink={location.pathname.replace("/update", "")}
        secondPathText="Update"
        // secondPathLink={location.pathname}
        title={"update"}
      />
      <Container>
        <Form form={form} onFinish={submitHandler}>
          <Card>
            <div className="productAdd-Txt1"> Task Details</div>

            <Row>
              <Col md={6}>
                <label className="formLabel">Title</label>
                <Form.Item
                  name="title"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="" />
                </Form.Item>

                <label className="formLabel">Start Date</label>
                <Form.Item
                  name="startDate"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    placeholder=""
                    size="large"
                    style={{ width: "100%" }}
                  />
                </Form.Item>

                <label className="formLabel">End Date</label>
                <Form.Item
                  name="endDate"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    placeholder=""
                    size="large"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col md={6}>
                <label className="formLabel">Priority</label>
                <Form.Item
                  name="priority"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select a Priority" size="large">
                    <Select.Option value="low">Low</Select.Option>
                    <Select.Option value="medium">Medium</Select.Option>
                    <Select.Option value="high">High</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="assignedTo"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Select an employee"
                    size="large"
                    value={"Hello"}
                  >
                    {getdatas?.map((user: any) => (
                      <Select.Option
                        key={user.employee.id}
                        value={user.employee.id}
                      >
                        {user.employee.fullName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <label className="formLabel">Description</label>
                <Form.Item name="description" rules={[{ required: true }]}>
                  <Input.TextArea size="large" placeholder="" rows={4} />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <hr />
            <br />

            <Row>
              <Col md={3}>
                <Button
                  block
                  style={{ height: 45 }}
                  onClick={() => navigate("/usr/hr-projects")}
                >
                  Cancel
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  style={{ height: 45 }}
                  onClick={() => console.log("")}
                >
                  update
                </Button>
              </Col>
            </Row>
          </Card>
          <br />
        </Form>
      </Container>
    </>
  );
}

export default UpdateTask;
