import { message, notification } from "antd";
import { signInWithPopup } from "firebase/auth";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FcGoogle } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../config/api";
import { Auth, GoogleProvider } from "../../config/firebase";
import { login, setToken } from "../../redux/slices/userSlice";
import { POST } from "../../utils/apiCalls";
import { withTranslation } from "react-i18next";
function GmailLogin(props:any) {
  const { t } = props;
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginWithGmail = async () => {
    try {
      signInWithPopup(Auth, GoogleProvider).then((res: any) => {
        setIsLoading(true);
        let data = res?._tokenResponse;
        let credentials = {
          first_name: data?.firstName,
          last_name: data?.lastName,
          name: data?.displayName,
          email: data?.email,
          image: data?.photoUrl,
          type: "user",
        };
        LoginEmail(credentials);
        setIsLoading(false);
      });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const LoginEmail = async (values: any) => {
    try {
      setIsLoading(true);
      let url = API.LOGIN_GMAIL;
      var loginRes: any = await POST(url, values);
      if (loginRes.status) {
        dispatch(setToken(loginRes?.data?.token));
        dispatch(login(loginRes?.data));
        notification.success({
          message: "Success",
          description: "Logged in successfully",
        });
        let isOpen = false;
        const data = loginRes?.data?.companyInfo;

        if (
          !data?.bname ||
          !data?.cemail ||
          !data?.taxno ||
          !data?.bcategory ||
          !data?.financial_year_start ||
          !data?.books_begining_from ||
          !data?.fulladdress ||
          !data?.defaultBank
        ) {
          isOpen = true;
        }
        navigate("/usr/dashboard", { state: { isOpen } });
        setIsLoading(false);
      } else {
        notification.error({
          message: "Failed",
          description: `User not found with this email`,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Server Error",
        description: 'Failed to login,Please try again later',
      });
      navigate("/login");
      setIsLoading(false);
    }
  };
  return (
          <div className="website-LoginBtn1" onClick={() => loginWithGmail()}>
            <FcGoogle size={20} style={{ marginRight: 10 }} />
            {t("home_page.homepage.Login_with_google")}
          </div>
  );
}

export default withTranslation()(GmailLogin);
