import { Form, Input } from "antd";
import { Col, Row } from "react-bootstrap";

function LocationForm() {
  return (
    <div>
      <Row>
        <Col md={12}>
          <div className="formItem">
            <label className="formLabel">Location</label>
            <Form.Item
              name="location"
              rules={[
                {
                  required: true,
                  message: "Please enter your category",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default LocationForm;
