import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import LedgerTable from "../component/table";
import AddLedger from "./addLedger";
import { Button } from "antd";

const LedgerMyLedger = () => {
  const { user } = useSelector((state: any) => state.User);
  const location = useLocation();
  const [edit, setEdit] = useState<any>();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addLedgerModal, setAddLedgerModal] = useState(false);

  useEffect(() => {
    fetchLedgerList();
  }, []);

  const fetchLedgerList = async () => {
    try {
      setIsLoading(true);
      let URL = API.GET_MY_LEDGERS + user.adminid;
      const { data }: any = await GET(URL, null);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const column = [
    {
      name: "nominalcode",
      title: "Nominal Code",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "laccount",
      title: "Ledger Account",
      alignment: "center",
      cellRender: "LedgerAccount",
    },
    {
      name: "categoryDetails.category",
      title: "Category",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "groupDetails.categorygroup",
      title: "Category Group",
      dataType: "string",
      alignment: "center",
    },
  ];
  const handleOnEdit = (val: any) => {
    setEdit(val?.row?.data);
    setAddLedgerModal(true);
  };

  return (
    <>
      <PageHeader
        title={"My Ledger"}
        firstPath={location?.pathname.slice(5)}
        secondPathLink={location.pathname}
        secondPathText='My Ledger'
        goBack={"/usr/productStock"}
      >
        <div>
          <Button type="primary" onClick={() => setAddLedgerModal(true)}>
            + Add Ledger
          </Button>
        </div>
        </PageHeader>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <LedgerTable
          products={data}
          columns={column}
          onItemSelect={() => {}}
          onPageChange={(p: any, t: any) => {}}
          onSuccess={() => fetchLedgerList()}
          title={"My Ledgers"}
          myLedgerOnEdit={(item: any) => handleOnEdit(item)}
        />
      )}
      {addLedgerModal && (
        <AddLedger
          onOpen={addLedgerModal}
          onClose={() => {
            setAddLedgerModal(false);
            setEdit(null);
          }}
          onSuccess={() => fetchLedgerList()}
          edit={edit}
        />
      )}
    </>
  );
};

export default LedgerMyLedger;
