import { Container, Row, Col } from "react-bootstrap";
import { Button, Form, Input, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Logo2 from "../../assets/images/logo2.png";
import "./styles.scss";
import { POST } from "../../utils/apiCalls";
import API from "../../config/api";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
function ResetPassword() {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state: any) => state.User);
  const [passwordValidation, setPasswordValidation] = useState<any>({
    status: "",
    help: "",
  });
  const [form] = useForm();

  const { token } = useParams();
  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const url = API.RESETPASSWORD;
      const data = {
        password: values.password,
        token: token,
      };
      const response: any = await POST(url, data);
      if (response.status) {
        setIsLoading(true);
        notification.success({
          message: "Success",
          description: "Your Password has been updated successfully.",
        });
        navigation("/login");
      }else{
        notification.error({message:"Failed",description:"Failed to update password"})
      }
    } catch (error) {
      console.error(error, "something went wrong");
      notification.error({message:"Server Error",description:"Failed to update password"})
    } finally {
      setIsLoading(false);
    }
  };


  const validatePassword = (value: any) => {

    const minLength = 8;
    const specialCharacterRegex = /[.*@!#%&()^~]/;
    const digitRegex = /\d/;

    if (value.length < minLength) {
      setPasswordValidation({
        status: "error",
        help: "Password must be at least 8 characters long.",
      });
    } else if (!specialCharacterRegex.test(value)) {
      setPasswordValidation({
        status: "error",
        help: "Password must contain at least one special character.",
      });
    } else if (!digitRegex.test(value)) {
      setPasswordValidation({
        status: "error",
        help: "Password must contain at least one digit.",
      });
    } else {
      setPasswordValidation({
        status: "success",
        help: "",
      });
    }
  };

  const compareToFirstPassword = (item:any, value:any) => {
    const targetField = item.field === 'confirm' ? 'password' : 'confirm';
    const firstPassword = form.getFieldValue(targetField);
  
    if (value && value !== firstPassword) {
      return Promise.reject('The two passwords do not match!');
    } else {
      return Promise.resolve();
    }
  };



  return (
    <div>
      <Container fluid>
        <Row>
          <Col sm={8} style={{ margin: 0, padding: 0 }}>
            <div className="website-ResetPasswordBox1">
              <img src={Logo2} style={{ width: 300 }} alt="taxgo" />
            </div>
          </Col>
          <Col sm={4} style={{ margin: 0, padding: 0 }}>
            <div
              className="website-ResetPasswordBack"
              onClick={() => navigation(-1)}
            >
              <BiArrowBack />
            </div>
            <div className="website-ResetPasswordBox2">
              <div>
                <div className="website-ResetPasswordtxt1">New Password</div>
                <br />
                <div className="website-ResetPasswordtxt2">
                  Your new password must meet these criteria: minimum 8
                  characters, include at least one special character from . * @
                  ! # % & ( ) ^ ~, contain at least one number, and cannot be
                  the same as any previous password.
                </div>
                <Form onFinish={onFinish} form={form}>
                  <Form.Item
                    name="password"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter your new password!",
                      },
                      {
                        validator: compareToFirstPassword,
                      },
                      
                    ]}
                    validateStatus={passwordValidation.status}
                    help={passwordValidation.help}
                  >
                    <Input.Password placeholder="New Password" onChange={(e) => validatePassword(e.target.value)}

                    />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        validator: compareToFirstPassword,
                      },
                      
                    ]}
                  >
                    <Input.Password placeholder="Confirm Password" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      block
                      size="large"
                      type="primary"
                      htmlType="submit"
                      style={{ height: 45 }}
                      loading={isLoading}
                    >
                      Reset Password
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <div className="website-ResetPasswordtxt4">
                Not a member yet ?{" "}
                <Button
                   type="link"
                  onClick={() => navigation("/signup")}
                >
                  Sign Up
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ResetPassword;
