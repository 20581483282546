import API from "../../config/api";
import "./styles.scss";
import { Link } from "react-router-dom";
const OldLogin = () => {
  return (
    <Link to={API.TAXGO_V1} target="_blank">
      <div className="website-LoginBtn1">Switch to Old Account</div>
    </Link>
  );
};

export default OldLogin;
