import { useState, useRef } from "react";
import DataGrid, {
  Column,
  SearchPanel,
  Export,
  Paging,
  Pager,
  HeaderFilter,
  Selection,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { Card, Popover } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Container } from "react-bootstrap";
import { EXPORT } from "../../../utils/exportData";
import ActionPopover from "./actionPopover";
import { useTranslation } from "react-i18next";

const Table = (props: any) => {
  const { t } = useTranslation();
  const dataGridRef: any = useRef(null);
  const [selectedRows, setSelectedRows] = useState();
  const [page, SetPage] = useState(1);
  const [take, setTake] = useState(props.take);
  const onSelectionChanged = (e: any) => {
    setSelectedRows(e.selectedRowsData.length);
  };
  const exportFormats = ["xlsx","pdf"];

  const onPageChange = (type: any, e: any) => {
    if (type === "page") {
      props.onPageChange(e, take);
    } else if (type === "take") {
      setTake(e);
      props.onPageChange(page, e);
    }
  };

  return (
    <Container>
      <br />
      <Card>
        <DataGrid
          ref={dataGridRef}
          dataSource={props.products}
          columnAutoWidth={true}
          showBorders={true}
          onExporting={(e) => EXPORT(e, dataGridRef,"products",() =>{})}
          showRowLines={true}
          onSelectionChanged={onSelectionChanged}
          remoteOperations={false}
        >
          <Selection
            mode="multiple"
            selectAllMode="allPages"
            showCheckBoxesMode="always"
          />
          <SearchPanel visible={true} width={240} />
          <HeaderFilter visible={true} />
          {props?.columns.map((column: any, index: number) => {
            return (
              <Column
                dataField={column.name}
                caption={column.title}
                dataType={column.dataType}
                format={column.format}
                alignment={column.alignment}
                allowExporting={column?.name === "product_category" ||column?.name === "location"||column?.name === "vat"?false:true}
                cellRender={column?.cellRender}
              ></Column>
            );
          })}
          <Paging
            defaultPageSize={take}
            pageSize={take}
            onPageIndexChange={(e) => onPageChange("page", e)}
            onPageSizeChange={(e) => onPageChange("take", e)}
          />
          <Pager
            visible={true}
            allowedPageSizes={[10, 20, 30,40,50,70,100]}
            displayMode={"compact"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Column
            alignment={"center"}
            type="buttons"
            caption={t("home_page.homepage.Action")}
            width={110}
            cellRender={(item) => {
              return (
                <div className="table-title">
                  <Popover
                    content={
                      <ActionPopover data={item} onSuccess={props.onSuccess} handleEditAsset={props.handleEditClick} />
                    }
                    placement="bottom"
                    trigger={"click"}
                  >
                    <BsThreeDotsVertical size={16} cursor={"pointer"} />
                  </Popover>
                </div>
              );
            }}
          ></Column>
          <Export
            enabled={true}
            allowExportSelectedData={true}
            formats={exportFormats}
          />
          <Toolbar>
            {selectedRows ? (
              <Item location="before" visible={true}>
                <div className="Table-Txt">{selectedRows} selected</div>
              </Item>
            ) : (
              <Item location="before" visible={true}>
                <div className="Table-Txt">{props.title}</div>
              </Item>
            )}
            <Item name="searchPanel" />
            <Item location="after" visible={true} name="exportButton" />
          </Toolbar>
        </DataGrid>
      </Card>
    </Container>
  );
};

export default Table;
