import React, { useEffect, useState } from "react";
import "../styles.scss";
import { useSelector } from "react-redux";
import API from "../../../config/api";
import { DELETE, GET } from "../../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/pageHeader";
import LoadingBox from "../../../components/loadingBox";
import CreditNotesTable from "./CreditNotesTable";
import { Button, notification } from "antd";
import { Container } from "react-bootstrap";

const CreditNotes = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.User);
  const [data, setData] = useState([]); //CREDIT INVOICE LIST DATA
  const adminid = user.adminid;
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [netTotal, setNetTotal] = useState<number>(0);

  useEffect(() => {
    fetchSalesCreditInvoiceList();
  }, []);

  useEffect(() => {
    const sumOfTotal = calculateTotalSum(data);
    setNetTotal(sumOfTotal);
  }, [data]);

  //get credit notes list
  const fetchSalesCreditInvoiceList = async () => {
    const scredit_url = API.CREDIT_INVOICE_LIST + `${adminid}/scredit`;
    try {
      const { data }: any = await GET(scredit_url, null);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //funtion to find net total of credit notes
  function calculateTotalSum(objects: any) {
    let totalSum = 0;
    for (let i = 0; i < objects.length; i++) {
      totalSum += parseFloat(objects[i].total.replace(",", ""));
    }

    return totalSum;
  }

  //delete table row
  const handleDelete = async (id: any) => {
    try {
      setIsLoading(true);
      const delete_sales_invoice = API.DELETE_SALES_INVOICE + `/${id}`;
      const data: any = await DELETE(delete_sales_invoice);
      fetchSalesCreditInvoiceList();
      if (data.status === true) {
        notification.success({
          message: "Deleted",
          description: "Credit Note Deleted Successfully",
        });
      } else if (data.status === false) {
        notification.error({
          message: "Not Deleted",
          description: "Credit Note Deletion Failed",
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong xx!!",
        description: "Something went wrong!! Please try again later",
      });
    } finally {
      setIsModalOpen(false);
      setIsLoading(false);
    }
  };

  //on page change
  const onPageChange = (page: any, take: any) => {
    setPage(page);
    setTake(take);
  };

  return (
    <div>
      <PageHeader
        firstPathLink={"/usr/salesCredit"}
        firstPathText={"Credit Notes"}
        goback="/usr/dashboard"
        title="Credit Note"
      >
        <Button type="primary" onClick={() => navigate(`screditform/${0}`)}>
          + New Credit Note
        </Button>
      </PageHeader>

      <Container>
        <br />
        {isLoading ? (
          <LoadingBox />
        ) : (
          <CreditNotesTable
            list={data}
            onItemSelect={() => {}}
            title={"Sales Credit List"}
            onPageChange={(p: any, t: any) => onPageChange(p, t)}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setIsLoading={setIsLoading}
            netTotal={netTotal}
          />
        )}
      </Container>
    </div>
  );
};

export default CreditNotes;
