import { Col, Container, Row } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Logo2 from "../../assets/images/logo2.png";
import "./styles.scss";
import { useState } from "react";
import { FaMobileAlt } from "react-icons/fa";
import { GrMailOption } from "react-icons/gr";
import EmailLogin from "./emailLogin";
import GmailLogin from "./gmailLogin";
import PhoneLogin from "./phoneLogin";
import OldLogin from "./oldLogin";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";

function Login(props:any) {
  const { t } = props;
  const location = useLocation();

  const [useEmail, setUseEmail] = useState(true);
  const navigate = useNavigate();
  return (
    <div>
      <Container fluid>
        <Row>
          <Col sm={8} style={{ margin: 0, padding: 0 }}>
            <div className="website-LoginBox1">
              <img src={Logo2} style={{ width: 300 }} alt="taxgo" />
            </div>
          </Col>
          <Col sm={4} style={{ margin: 0, padding: 0 }}>
            <div className="website-LoginBack" onClick={() => navigate(-1)}>
              <BiArrowBack />
            </div>
            <div className="website-LoginBox2">
              <div>
                <div className="website-Logintxt1">
                  {t("home_page.homepage.Sign_In")} <span style={{fontSize:12}}>v2</span>
                </div>
                <br />
                <br />
                <div className="website-Logintxt2">
                  {t("home_page.homepage.First_time_on_Tax_")}
                  <br />
                  {t(
                    "home_page.homepage.Please reset your password to continue accounting."
                  )}
                </div>
                <OldLogin/>
                <br/>
                <div className="website-LoginLine" />
                <div className="website-Logintxt6">
                  <div className="website-Logintxt5">or</div>
                </div>
                <br />
                {useEmail ? (
                  <>
                    <EmailLogin type={location?.state?.type}/>
                    <div
                      className="website-LoginBtn1"
                      onClick={() => setUseEmail(false)}
                    >
                      <FaMobileAlt size={20} style={{ marginRight: 10 }} />
                      {t("home_page.homepage.Login_with_Phone")}
                    </div>
                  </>
                ) : (
                  <>
                    <PhoneLogin />
                    <div
                      className="website-LoginBtn1"
                      onClick={() => setUseEmail(true)}
                    >
                      <GrMailOption size={20} style={{ marginRight: 10 }} />
                      {t("home_page.homepage.Login_with_Email")}
                    </div>
                  </>
                )}
                <>
                  <GmailLogin />
                </>
                <div className="website-Logintxt4">
                  {t("home_page.homepage.Not a member yet ?")}{" "}
                  <span
                    className="website-Logintxt3"
                    onClick={() => navigate("/signup")}
                  >
                    {t("home_page.homepage.Sign_Up")}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withTranslation()(Login);
