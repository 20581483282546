import React, { useEffect, useState } from "react";
import "../styles.scss";
import { useSelector } from "react-redux";

import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";

import LoadingBox from "../../../components/loadingBox";

import Table from "./table";
import PageHeader from "../../../components/pageHeader";
import { Button, notification } from "antd";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SalesInvoice = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const [isLoading, seisLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    fetchSaleInvoiceList();
  }, []);

  const fetchSaleInvoiceList = async () => {
    try {
      const url = API.PROFORMA_INVOICE_LIST + `${adminid}/sales`;
      const { data }: any = await GET(url, null);
      if (data.length) {
        setList(data);
      } else {
        setList([]);
      }
      seisLoading(false);
    } catch (error) {
      notification.error({message:"Oops,Somthing Went Wrong... "})
      console.log(error);
      seisLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        title="Sales Invoice"
        goBack={"/dashboard"}
        secondPathText="Sales Invoice"
        secondPathLink={"/usr/sales-invoice"}
      >
        <div>
          <Button
            type="primary"
            onClick={() => navigate(`/usr/sale-invoice-form/${0}`)}
          >
            + Create Invoice
          </Button>
        </div>
      </PageHeader>
      <br />
      <Container>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Table List={list} onItemSelect={() => {}} />
        )}
      </Container>
    </div>
  );
};

export default SalesInvoice;
