import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  notification,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";

import API from "../../../config/api";
import { GET, POST, PUT } from "../../../utils/apiCalls";

import PageHeader from "../../../components/pageHeader";
import { Container } from "react-bootstrap";
import departments from "./helpers/department.json";
import dayjs from "dayjs";
import designation from "./helpers/designation.json";

function FormHrEmployee() {
  const [isLoading, setIsLoading] = useState(false);
  const { id, type } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading2, setIsLoading2] = useState(false);
  const [form] = Form.useForm();
  const [isUpdate, setisUpdate] = useState(false);

  useEffect(() => {
    setisUpdate(true);
    getData(id);
  }, []);

  const getData = async (id: any) => {
    try {
      setIsLoading(true);
      const url = API.GET_HR_EMPLOYEE_LIST + id;
      const { data }: any = await GET(url, {});
      setData(data);
      form.setFieldsValue({
        role: data.role,
        title: data.title,
        fullName: data.fullName,
        email: data.email,
        officeEmail: data.officeEmail,
        phone: data.phone,
        altPhone: data.altPhone,
        gender: data.gender,
        department: data.department,
        salary: data.salary,
        designation: data.designation,
        workLocation: data.workLocation,
        dob: data?.dob ? dayjs(data.dob, "YYYY/MM/DD") : null,
        hireDate: data?.hireDate ? dayjs(data.hireDate, "YYYY/MM/DD") : null,
        address: data.address,
      });
      console.log(data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      let updateUrl = API.EDIT_EMPLOYEE_DETAILS + id;
      let obj = {
        role: "user",
        title: values.title,
        fullName: values.fullName,
        email: values.email,
        officeEmail: values.officeEmail,
        phone: values.phone,
        altPhone: values.altPhone,
        gender: values.gender,
        department: values.department,
        designation: values.designation,
        workLocation: values.workLocation,
        dob: values.dob,
        hireDate: values.hireDate,
        salary: values.salary,
        address: values.address,
      };

      let update: any = await PUT(updateUrl, obj);

      if (update?.status) {
        getData(id);
        notification.success({
          message: `Employee  "updated"  successfully`,
        });
        setIsLoading(false);
        navigate("/usr/hr-employees");
      } else {
        notification.error({ message: "something went wrong" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    } finally {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  return (
    <>
      <PageHeader
        firstPathText="Employee"
        secondPathText="update"
        title={"update"}
      />
      <Container>
        <Form form={form} onFinish={onSubmit}>
          <Card>
            <div className="productAdd-Txt1"> Employeee Details</div>
            <Row gutter={16}>
              <Col md={8}>
                <label className="formLabel">Title</label>
                <Form.Item
                  name="title"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true, message: "Select a title" }]}
                >
                  <Select placeholder="Select a title">
                    <Select.Option value="mr">Mr</Select.Option>
                    <Select.Option value="ms">Ms</Select.Option>
                    <Select.Option value="miss">Miss</Select.Option>
                  </Select>
                </Form.Item>

                <label className="formLabel">Gender</label>
                <Form.Item
                  name="gender"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true, message: " Select gender" }]}
                >
                  <Select placeholder="Select gender">
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                  </Select>
                </Form.Item>

                <label className="formLabel"> Office Email</label>
                <Form.Item
                  name="email"
                  style={{ marginBottom: 10 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your  Office email",
                    },
                    {
                      message: " Enter a valid  Office email address",
                    },
                  ]}
                >
                  <Input size="large" placeholder="email" />
                </Form.Item>

                <label className="formLabel">Work Location</label>
                <Form.Item
                  name="workLocation"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true, message: " Enter work location" }]}
                >
                  <Input size="large" placeholder="Enter work location" />
                </Form.Item>

                <label className="formLabel">Designation</label>
                <Form.Item
                  name="designation"
                  style={{ marginBottom: 10 }}
                  rules={[
                    { required: true, message: "Please select desiganation" },
                  ]}
                >
                  <Select size="large" placeholder="Select desiganation">
                    {designation.map((des) => (
                      <Select.Option key={des.id} value={des.value}>
                        {des.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <br />
              <Col md={8}>
                <label className="formLabel"> Name</label>
                <Form.Item
                  name="fullName"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true, message: "Enter name" }]}
                >
                  <Input size="large" placeholder="Enter name" />
                </Form.Item>

                <label className="formLabel">Email</label>
                <Form.Item
                  name="officeEmail"
                  style={{ marginBottom: 10 }}
                  rules={[
                    { required: true, message: " enter office email" },
                    {
                      message: "Enter a valid email address",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter  email" />
                </Form.Item>
                <label className="formLabel">Phone</label>
                <Form.Item
                  name="phone"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true, message: " enter phone number" }]}
                >
                  <Input size="large" placeholder="Enter phone number" />
                </Form.Item>
                <label className="formLabel">Salary</label>
                <Form.Item name="salary" style={{ marginBottom: 10 }}>
                  <Input size="large" placeholder="Enter salary" />
                </Form.Item>
                <label className="formLabel">Alternate Phone </label>
                <Form.Item
                  name="altPhone"
                  style={{ marginBottom: 10 }}
                  rules={[
                    {
                      required: true,
                      message: " enter alternate phone number",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter alternate phone number"
                  />
                </Form.Item>
              </Col>
              <Col md={8}>
                {/* <label className="formLabel">Role</label>
                <Form.Item name="role">
                  <p>user</p>
                </Form.Item> */}

                <label className="formLabel">Department</label>
                <Form.Item
                  name="department"
                  style={{ marginBottom: 10 }}
                  rules={[
                    { required: true, message: "Please select department" },
                  ]}
                >
                  <Select size="large" placeholder="Select department">
                    {departments.map((dep) => (
                      <Select.Option key={dep.id} value={dep.value}>
                        {dep.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <label className="formLabel">Date of Birth</label>
                <Form.Item
                  name="dob"
                  style={{ marginBottom: 10 }}
                  rules={[
                    {
                      required: true,
                      message: "Please select date of birth",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Select date of birth"
                  />
                </Form.Item>

                <label className="formLabel">Hire Date</label>
                <Form.Item
                  name="hireDate"
                  style={{ marginBottom: 10 }}
                  rules={[
                    { required: true, message: "Please select hire date" },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Select hire date"
                  />
                </Form.Item>

                <label className="formLabel">Address</label>
                <Form.Item
                  name="address"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true, message: "Please enter address" }]}
                >
                  <Input.TextArea
                    size="large"
                    placeholder="Enter address"
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <hr />
            <br />

            <Row>
              <Col md={3}>
                <Button
                  block
                  style={{ height: 45 }}
                  onClick={() => navigate("/usr/hr-employees")}
                >
                  Cancel
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  style={{ height: 45 }}
                  onClick={() => console.log("")}
                >
                  update
                </Button>
              </Col>
            </Row>
          </Card>
          <br />
        </Form>
      </Container>
    </>
  );
}

export default FormHrEmployee;
