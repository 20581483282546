import React, { useEffect, useState } from "react";
import Table from "../components/table";
import API from "../../../config/api";
import { DELETE, GET } from "../../../utils/apiCalls";
import { useSelector } from "react-redux";
import LoadingBox from "../../../components/loadingBox";
import CreateSettings from "../components/form";
import { notification } from "antd";

function Tax() {
  const { user } = useSelector((state: any) => state.User);
  const [isLoading, setIsLoading] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [initalValue, setInitalValue] = useState({});
  const [id, setId] = useState("create");
  const [data, setData] = useState([]);
  const adminid = user?.adminid;

  const columns = [
    {
      name: "type",
      title: "Tax Type",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "percentage",
      title: "Percentage",
      dataType: "string",
      alignment: "center",
    },
  ];
  useEffect(() => {
    fetchTaxList();
  }, []);
  const fetchTaxList = async () => {
    try {
      setIsLoading(true);
      let url = API.TAX_MASTER  + `${adminid}/all`;
      const data: any = await GET(url, null);
      setData(data);
    } catch (error) {
      console.log(error)
    }finally{
      setIsLoading(false);
    }
  };

  const deleteTaxPercentage = async (id: any) => {

    try {
      let url = API.TAX_MASTER + id;
      const data: any = await DELETE(url);
      if (data) {
        notification.success({
          message: "Deleted Succesfully",
          description: "Tax percentage deleted successfully.",
        });
        fetchTaxList();
      }
    } catch(error) {
      console.log(error);
      notification.error({message:"something went wrong"});
    }
  };
  const fetchTaxById = async (id: any) => {
    try {
      let url = API.TAX_MASTER + id;
      let data: any = await GET(url, {});

      let inital = {
        type: data?.type,
        percentage: data?.percentage,
        countryid:data?.countryid
      };

      setInitalValue(inital);
      setId(id);
      setTimeout(() => {
        setIsForm(true);
      }, 100);
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Table
          data={data}
          columns={columns}
          title={"Tax List"}
          onBtnClick={() => {
            setId("create");
            setIsForm(true);
          }}
          handleDeleteClick={deleteTaxPercentage}
          handleEditClick={fetchTaxById}
        />
      )}
      {isForm ? (
        <CreateSettings
          open={isForm}
          close={() => {
            setIsForm(false);
            setInitalValue({});
          }}
          source={"tax"}
          id={id}
          reload={fetchTaxList}
          initalValue={initalValue}
        />
      ) : null}
    </div>
  );
}

export default Tax;
