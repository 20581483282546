import React, { useEffect, useState } from "react";
import PageHeader from "../../../../components/pageHeader";
import API from "../../../../config/api";
import { POST } from "../../../../utils/apiCalls";
import DataTable from "./salaryreport";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  notification,
} from "antd";
import { Container } from "react-bootstrap";
import form from "devextreme/ui/form";
import exportToExcel from "../../../../utils/exportexcel";

function Salaryreport() {
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState<any>([]);
  const [data, setData] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});
  const [form] = Form.useForm();

  useEffect(() => {
    fetchHrFinance({}, page, 10);
  }, [page, take]);
  const fetchHrFinance = async (values: any, page: any, take: any) => {
    try {
      setIsLoading(true);
      let obj = {
        page: page,
        take: take,
        employeeId: values.employeeId,
        date: values.date,
      };
      console.log("asdf------", obj);
      const url = API.POST_HR_REPORT_SALARY;
      const response: any = await POST(url, obj);
      console.log("response==", response);
      if (response) {
        setReportData(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notification.error({
          message: "Oops !",
          description: "salary data not found",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      notification.error({
        message: "Oops !",
        description: "salary data not found",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const handleExportToExcel = () => {
  //   exportToExcel(reportData, "exported_data.xlsx");
  // };
  console.log("reportData=========", reportData);

  const handleExportToExcel = () => {
    const data = reportData?.projects?.map((item: any) => {
      return {
        EmployeeName: item?.name,
        salary: item?.salary,
        in_amount: item?.in_amount,
        out_amount: item?.out_amount,
        total_amount: item?.total_amount,
      };
    });

    exportToExcel(data, "exported_data.xlsx");
  };
  return (
    <>
      <PageHeader title=" Salary Report " />
      <div>
        <div>
          <div className="">
            <Container>
              <Card>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={(values: any) => fetchHrFinance(values, page, take)}
                >
                  <Row style={{ marginTop: "20px" }}>
                    <Col md="3">
                      <Form.Item name="employeeId">
                        {/* <Select
                          showSearch
                          style={{ width: "200px" }}
                          placeholder="Select Employee"
                          onChange={(values: any) =>
                            fetchHrFinance({ employeeId: values }, page, take)
                          }
                          allowClear
                        > */}
                        <Select
                          style={{ width: "200px" }}
                          showSearch
                          placeholder="Select Employee"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.children as unknown as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          allowClear
                          onChange={(values: any) =>
                            fetchHrFinance({ employeeId: values }, page, take)
                          }
                        >
                          {reportData?.projects?.map((employee: any) => (
                            <Select.Option
                              key={employee.id}
                              value={employee.id}
                            >
                              {employee.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md="3">
                      <Form.Item name={"date"} noStyle>
                        <DatePicker
                          picker="month"
                          style={{ width: "250px" }}
                          onChange={(values: any) =>
                            fetchHrFinance({ date: values }, page, take)
                          }
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <br />
                    <Col md="3">
                      <Form.Item>
                        <Button htmlType="submit">Search</Button>
                        <Input bordered={false} />
                      </Form.Item>
                    </Col>
                    <Col md="3">
                      <Form.Item name="Excel">
                        <Button
                          type="primary"
                          onClick={handleExportToExcel}
                          // size="large"
                        >
                          Export to Excel
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Container>
            <br />
          </div>
        </div>
        <DataTable
          dataSource={reportData}
          isLoading={isLoading}
          meta={meta}
          onPageChange={(page: number, pageSize: number) => {
            setIsLoading(true);
            setPage(page);
            setTake(pageSize);
          }}
        />
      </div>
    </>
  );
}

export default Salaryreport;
