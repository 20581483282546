import {
  Button,
  Checkbox,
  Modal,
  Card,
  Timeline,
  notification,
  Tooltip,
} from "antd";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Table } from "react-bootstrap";
import "../../styles.scss";
import { BsCheckCircleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { GET, POST } from "../../../../utils/apiCalls";
import PageHeader from "../../../../components/pageHeader";
import LoadingBox from "../../../../components/loadingBox";
import moment from "moment";
import { IoMdAlarm } from "react-icons/io";
import { SiGridsome } from "react-icons/si";
import { IoShareSocial } from "react-icons/io5";
import Paymentmodal from "../../../../components/paymentmodal/paymentmodal";
import {
  ClockCircleOutlined,
  DollarCircleOutlined,
  EditOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import SendMailModal from "../../../../components/sendMailModal";
import { MdAttachEmail, MdFileDownload } from "react-icons/md";
import { useSelector } from "react-redux";
import API from "../../../../config/api";
import { template1, template2, template3 } from "./template";

function PurchaceInvoiceView(props: any) {
  const { id }: any = useParams();
  const [isFullLoading, setIsFullLoading] = useState(false);
  const [isBttLoding, setIsBttLoding] = useState(false);
  const [details, setDetails] = useState<any>({});
  const [subTottal, setSubTottal] = useState<any>();
  const [taxAmount, setTaxAmount] = useState<any>();
  const [roundOff, setRoundOff] = useState<any>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectBank, setSlectedBank] = useState<any>();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const User = useSelector((state: any) => state.User);
  const adminid = User.adminid;

  let overalDiscount = 0;
  let subAllTottal = 0;

  useEffect(() => {
    getInvoiceDetails();
    getBankList();
  }, []);

  const getInvoiceDetails = async () => {
    setIsFullLoading(true);
    try {
      let url = API.VIEW_PURCHASE_INVOICE + id + "/purchase";
      const { data: invoiceDatas, status }: any = await GET(url, null);
      if (status) {
        setDetails(invoiceDatas);
        setSubTottal(
          invoiceDatas?.invoiceItems?.reduce((sum: any, item: any) => {
            if (item.includevat === 0) {
              return sum + Number(item.costprice) * Number(item.quantity);
            } else {
              return (
                sum +
                (Number(item.costprice) - Number(item.vatamt)) *
                  Number(item.quantity)
              );
            }
          }, 0)
        );

        setTaxAmount(
          invoiceDatas?.invoiceItems?.reduce(
            (sum: any, item: any) => sum + Number(item.vatamt),
            0
          )
        );
        let total = invoiceDatas?.invoiceItems?.reduce(
          (sum: any, item: any) => sum + Number(item.total),
          0
        );
        setRoundOff(
          Number(invoiceDatas?.invoiceDetails?.total) - Number(total)
        );
        setIsFullLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsFullLoading(false);
    }
  };
  const getBankList = async () => {
    try {
      let url = "account_master/getBankList/" + User.user.adminid;
      const { data }: any = await GET(url, null);

      setSlectedBank(data.bankList);
    } catch (error) {}
  };

  async function paymentFinish(val: any) {
    try {
      setIsBttLoding(true)
      let status = null;
      if (val.outStanding <= 0) {
        status = "2"; //paid
      } else if (val.outStanding < details?.invoiceDetails?.total) {
        status = "1"; //part Paid
      } else if (val.outStanding >= details?.invoiceDetails?.total) {
        status = "0"; //unpaid
      }

      let payload = {
        userid: User.user.adminid,
        adminid: User.user.adminid,
        status: status,
        supplierid: details?.invoiceDetails?.supplier?.id,
        outstanding: val?.outStanding,
        bankid: val?.paymentBank,
        sinvoice: id,
        logintype: "usertype",
        type: "Supplier Payment",
        date: val?.paymentDate,
        paidmethod: val?.paymentMethod,
        amount: val?.amoutToPaid,
        userdate: new Date(),
      };
      let url = API.PURCHASE_PAYMENT;
      let response: any = await POST(url, payload);
      if (response.status) {
        notification.success({ message: response.message });
        setIsBttLoding(false)
        setPaymentModal(false);
        await getInvoiceDetails();
      }
    } catch (error) {
      console.log( error);
      notification.error({ message: "Something went wrong to your payment." });
      setIsBttLoding(false)
      setPaymentModal(false);
    }
  }

  async function genrateTemplate(type: any, emaildata: any) {
    try {
      setDownloadLoading(true);
      let obj = {
        user: User.user,
        customer: details?.invoiceDetails?.supplier,
        purchase: details?.invoiceDetails,
        productlist: details?.invoiceItems,
        sale: details?.invoiceDetails,
        // customer: details?.invoiceDetails?.customer,
        bankList: details?.banking,
        vatTotal: taxAmount,
        netTotal: subAllTottal,
        total: details?.invoiceDetails?.total,
        vatRate: taxAmount,
        isPaymentInfo: false,
        pagetype: "Invoice",
      };
      let templates: any = null;
      if (User.user.companyInfo.defaultinvoice === "1") {
        templates = template1(obj);
      } else if (User.user.companyInfo.defaultinvoice === "2") {
        templates = template2(obj);
      } else if (User.user.companyInfo.defaultinvoice === "3") {
        templates = template3(obj);
      }
      await downLoadPdf(templates);
      setDownloadLoading(false);
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  }
  const downLoadPdf = async (templates: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    const encodedString = btoa(templateContent);
    const pdf_url = API.PDF_GENERATE_URL;
    const pdfData = {
      filename: "Purchase Invoice",
      html: encodedString,
      isDownload: true,
      sendEmail: false,
      type: "",
      userid: "",
    };
    const token = User.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const pdfBlob = await response.arrayBuffer();
    const blob = new Blob([pdfBlob], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Purchase${details?.invoiceDetails?.supplier?.bus_name}_${
      details?.invoiceDetails?.invoiceno
    }_${moment(new Date()).format("DD-MM-YYYY")}`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <PageHeader
        title="Purchase Invoice View"
        goBack={"/dashboard"}
        secondPathText="Purchase Invoice View"
        secondPathLink={`/usr/purchase-invoice-view/${id}`}
        firstPathText="Purchase Invoice"
        firstPathLink={"/usr/purchace-invoice"}
        children={
          <div>
            <Tooltip
              title="Download Invoice"
              mouseEnterDelay={0.5}
              arrow={false}
              color="white"
              overlayInnerStyle={{
                color: "#000000",
                marginTop: 5,
                fontSize: "14px",
              }}
              placement={"bottom"}
            >
              {/* <Button
                onClick={() => genrateTemplate("downLoad", {})}
                loading={downloadLoading}
              >
                <MdFileDownload size={20} />
              </Button> */}
            </Tooltip>
          </div>
        }
      />
      <>
        <Container>
          <br />
          {isFullLoading ? (
            <LoadingBox />
          ) : (
            <Card>
              <Row>
                <Col md="12">
                  <div className="salesInvoice-Header">Purchase Invoice</div>

                  <Table bordered>
                    <tbody>
                      <tr>
                        <td className="items-head">Invoice No</td>
                        <td>
                          <strong>{details?.invoiceDetails?.invoiceno}</strong>
                        </td>
                        <td className="items-head">Supplier Name</td>
                        <td>
                          <strong>{details?.invoiceDetails?.sname}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="items-head"> Invoice Date</td>
                        <td className="items-value">
                          {moment(details?.invoiceDetails?.sdate).format(
                            "DD MMMM YYYY"
                          )}
                        </td>
                        <td className="items-head">Due Date</td>
                        <td className="items-value">
                          {moment(details?.invoiceDetails?.ldate).format(
                            "DD MMMM YYYY"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={12}>
                  <div className="salesInvoice-SubHeader ">Invoice Items</div>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>PRODUCT</th>
                        <th>QUANTITY</th>
                        <th>PRICE</th>
                        <th>TAX</th>
                        <th>TAX AMT</th>
                        <th>INC TAX</th>
                        <th>DISC %</th>
                        <th>DISC AMT</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {details?.invoiceItems?.length &&
                        details?.invoiceItems.map((item: any) => {
                          let discountAmount = 0;
                          let total = 0;
                          if (item.includevat === 0) {
                            total =
                              Number(item.costprice) * Number(item.quantity) +
                              Number(item.vatamt);
                            subAllTottal =
                              subAllTottal +
                              Number(item.costprice) * Number(item.quantity);
                          } else {
                            total =
                              Number(item.costprice) * Number(item.quantity);
                            subAllTottal =
                              subAllTottal +
                              Number(item.costprice) * Number(item.quantity) -
                              item.vatamt;
                          }
                          if (item.discount > 0) {
                            const discountRate = Number(item.discount) / 100;
                            discountAmount = Number(total) * discountRate;
                            overalDiscount = overalDiscount + discountAmount;
                          }

                          return (
                            <tr>
                              <td>{item?.product?.idescription}</td>
                              <td>
                                {Number(item.quantity)}&nbsp;&nbsp;
                                {item?.product?.unit}
                              </td>
                              <td>{Number(item.costprice)}</td>
                              <td>{Number(item.vat)} %</td>
                              <td>{Number(item.vatamt)}</td>
                              <td>
                                <Checkbox
                                  checked={item.includevat == 1 ? true : false}
                                />
                              </td>
                              <td>{Number(item.discount)} %</td>
                              <td>
                                {Math.round(Number(discountAmount))?.toFixed(2)}
                              </td>
                              <td>{Number(item.total)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>

                <Col md={8}></Col>
                <Col md={4}>
                  <Table bordered>
                    <tbody>
                      <tr>
                        <td>TAXABLE VALUE	</td>
                        <td>{subAllTottal?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>TOTAL VAT</td>
                        <td>{taxAmount?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>OVERALL DISCOUNT</td>
                        <td>{Math.round(overalDiscount)?.toFixed(2)}</td>
                      </tr>
                      {/* <tr>
                        <td>ROUND OFF</td>
                        <td>{roundOff?.toFixed(2)}</td>
                      </tr> */}
                      <tr>
                        <td>TOTAL AMOUNT</td>
                        <td>{details?.invoiceDetails?.total}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Table bordered>
                <tbody>
                  <tr>
                    <td>
                      Payment Status: &nbsp;
                      {details?.invoiceDetails?.status === 0 ? (
                        <>
                          {" "}
                          <IoMdAlarm size={25} color="red" />
                          &nbsp; Unsettled
                        </>
                      ) : details?.invoiceDetails?.status === 1 ? (
                        <>
                          {" "}
                          <SiGridsome size={25} color="orange" />
                          &nbsp; Partially Paid
                        </>
                      ) : (
                        <>
                          <BsCheckCircleFill size={25} color="green" />
                          &nbsp; Paid
                        </>
                      )}
                    </td>
                    <td>
                      Outstanding:&nbsp;
                      <strong>
                        {details?.invoiceDetails?.outstanding || 0.0}
                      </strong>
                    </td>
                    {details?.invoiceDetails?.status === 2 ? null : (
                      <td>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => setPaymentModal(true)}
                          block
                        >
                          Add Payment
                        </Button>
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
              <Modal
                open={paymentModal}
                onCancel={() => setPaymentModal(false)}
                width={800}
                maskClosable={false}
                footer={false}
                title="Add Payment"
              >
                <Paymentmodal
                  onCancel={() => setPaymentModal(false)}
                  onFinish={(val: any) => paymentFinish(val)}
                  outstanding={details?.invoiceDetails?.outstanding}
                  bankList={selectBank}
                  loding={isBttLoding}
                />
              </Modal>
              <div className="salesInvoice-SubHeader ">Invoice TimeLine</div>
              <br />
              <Timeline mode="alternate">
                {details?.invoiceDetails?.status === 2 && (
                  <Timeline.Item
                    dot={<DollarCircleOutlined style={{ color: "green" }} />}
                    color="green"
                  >
                    <div className="heading-txt3">Paid</div>
                    <br />
                    Payment Recorded on{" "}
                    {details?.invoiceDetails.paymentdate &&
                      moment(details?.invoiceDetails.paymentdate).format(
                        "MMMM Do YYYY @ h:mm a"
                      )}
                  </Timeline.Item>
                )}

                {details?.invoiceDetails?.status === 0 && (
                  <Timeline.Item
                    dot={<FileDoneOutlined style={{ fontSize: 20 }} />}
                    color="gray"
                  >
                    <div>Pending Payment</div>
                    <br />
                    No payment yet
                  </Timeline.Item>
                )}

                {details.banking?.map((item: any, index: any) => {
                  return (
                    <Timeline.Item
                      key={index}
                      dot={<DollarCircleOutlined style={{ fontSize: 20 }} />}
                      color="blue"
                    >
                      <div className="heading-txt3">
                        {details?.invoiceDetails?.status === 2
                          ? "Invoice Paid Full"
                          : "Part of Invoice Paid"}
                      </div>
                      <br />
                      Made a payment on{" "}
                      {moment(new Date(item.date)).format(
                        "DD-MMM-YYYY"
                      )} of <strong>{Math.abs(parseInt(item.amount))}</strong> to{" "}
                      {item?.bankInf?.laccount}
                    </Timeline.Item>
                  );
                })}

                {details.banking?.map((item: any, index: any) => {
                  if (index > 0) {
                    return null;
                  }
                  return (
                    <>
                      {item.type === "Live Payment" ? null : (
                        <Timeline.Item
                          // key={index}
                          dot={<ClockCircleOutlined style={{ fontSize: 20 }} />}
                          color="red"
                        >
                          <div className="heading-txt3">Due</div>
                          <br />
                          Invoice Due on{" "}
                          {moment(details?.invoiceDetails?.ldate).format(
                            "DD-MMM-yyyy"
                          )}
                        </Timeline.Item>
                      )}
                    </>
                  );
                })}

                <Timeline.Item
                  dot={<EditOutlined style={{ fontSize: 20 }} />}
                  color="gray"
                  className="heading-txt"
                >
                  <div className="heading-txt3">Created</div>
                  <br />
                  Invoice placed on{" "}
                  {moment(new Date(details?.invoiceDetails?.created_at)).format(
                    "DD-MMM-yyyy @ h:mm a"
                  ) || moment(new Date()).format("DD-MMM-yyyy @ h:mm a")}
                </Timeline.Item>
              </Timeline>
            </Card>
          )}
        </Container>
      </>
    </>
  );
}

export default PurchaceInvoiceView;
