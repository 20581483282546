import React, { useState } from "react";
import { Card, Pagination, Spin } from "antd";
import { Col, Row } from "react-bootstrap";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

interface Project {
  id: string;
  title: string;
}

interface DataTableProps {
  columns: { dataField: string; title: string }[];
  list: Project[];
  isLoading: boolean;
  onPageChange: (page: number, pageSize: number) => void;
  meta: { total_count: number; take: number };
}

const DataTable = (props: DataTableProps) => {
  const navigate = useNavigate();

  const handleView = (id: string) => {
    navigate(`/usr/hr-projects/details/${id}`);
  };
  console.log(props, "total_count");
  return (
    <>
      <div style={{ padding: "24px" }}>
        {props?.isLoading ? (
          <Spin tip="Loading...">
            <div style={{ minHeight: "300px" }} />
          </Spin>
        ) : (
          <div style={{ fontSize: "20px" }}>
            <Card
              className="main-card"
              style={{
                marginBottom: "16px",
                cursor: "pointer",
              }}
            >
              <h6> Total {props?.meta?.total_count} projects</h6>
              <Row>
                {props?.list?.map((project: Project, index: number) => (
                  <Col key={index} md={3}>
                    <div className="project-item">
                      <Card
                        title={project.title}
                        className="cards"
                        style={{
                          marginBottom: "16px",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => handleView(project.id)}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Card>
          </div>
        )}

        <div>
          <br />
          <Pagination
            total={props?.meta?.total_count}
            pageSize={props?.meta?.take}
            onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
            style={{
              display: props?.meta?.total_count > 10 ? "block" : "none",
              textAlign: "right",
              marginTop: "20px",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DataTable;
