import { Table as AntTable } from "antd";

function Table({ data, columns }: any) {
  let col = columns.map((item: any) => {
    return {
      title: item,
      dataIndex: item,
      key: item,
    };
  });
  return <AntTable dataSource={data} columns={col} scroll={{ x: true }} />;
}

export default Table;
