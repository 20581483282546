import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Checkbox,
  InputNumber,
  notification,
  Upload,
  Spin,
  Modal,
} from "antd";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { CiImageOff } from "react-icons/ci";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import API from "../../config/api";
import { GET, POST, POST2 } from "../../utils/apiCalls";

function ProductAddModal({ open, onCancel, productRefrush, type }: any) {
  const [form] = Form.useForm();
  const { user } = useSelector((state: any) => state.User);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [ledgerCategoryList, setLedgerCategoryList] = useState([]);
  const [unit, setUnit] = useState([]);
  const [taxlist, setTaxlist] = useState([]);
  const [includeVats, setIncludeVat] = useState(false);
  const [image, setImage] = useState();
  const [locationdata, setLocationData] = useState([]);
  const [loading, setLoading] = useState(false);

  const businessStartDate: any = user?.companyInfo?.books_begining_from
    ? user?.companyInfo?.books_begining_from
    : user?.companyInfo?.financial_year_start;

  const LoadCategory = async () => {
    let URL = API.GET_PRODUCT_CATEGORY + user.adminid;
    const data: any = await GET(URL, null);
    setCategory(data);
    form.setFieldsValue({
      product_category: "",
    });
  };

  const LoadUnit = async () => {
    let URL = API.PRODUCT_UNIT_BY_USER + user.adminid;
    const data: any = await GET(URL, null);
    setUnit(data);
    form.setFieldsValue({
      unit: "",
    });
  };

  const loadTaxList = async () => {
    let URL = API.TAXLIST + user.countryid;
    const { data }: any = await GET(URL, null);
    setTaxlist(data);
  };
  // const getLedger = async (val: any) => {
  //   try {
  //     let url = API.GET_FIXED_ASSET_LEDJERS + user.adminid;
  //     const response: any = await GET(url, null);
  //     const filteredLedgers = response.data.filter(
  //       (item: any) => item?.nominalcode == val
  //     );
  //     return filteredLedgers;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
    let reqObj = {
      itemtype: type,
      icode: val.icode || "",
      idescription: val.idescription,
      saccount: Number(val.saccount) || 4000, //
      price: 0, //
      sp_price: val.sp_price,
      c_price: Number(val.c_price) || 0, //
      trade_price: 0, //
      rate: val.sp_price, //
      notes: val.notes, //
      type: type, //
      logintype: "user",
      paccount: 0,
      includevat: val.includevat === true ? 1 : 0,
      userid: user.id,
      adminid: user.adminid,
      vat: Number(val.vat) || 0,
      vatamt: Number(val.vatamt),
      product_category: val.product_category,
      existingstock: false,
      costprice: val.c_price || 0,
      wholesale: val.wholesale || 0,
      rlevel: val.rlevel || 0,
      quantity: val.quantity || 0,
      rquantity: val.rquantity || 0,
      stock: val.quantity || 0,
      date: businessStartDate,
      unit: val.unit,
      location: val.location,
      barcode: val.barcode,
      pimage: image,
    };

    let URL = API.ADD_PRODUCT;
    let METHOD = POST;
    const response: any = await METHOD(URL, reqObj);
    if (response.status) {
      notification.success({message:"Success",description:`New ${type === "Service" ? "service" : "product"} added successfully`});
      let data = response.data;
      let obj = {
        id: data.id,
        quantity: 1,
        price: data.price,
        incomeTaxAmount: data.vatamt,
        vatamt: data.vatamt,
        description: data.idescription,
        vat: data.vat,
        vatamount: data.vatamt,
        discountamt: 0,
        discount: 0,
        total: data.price,
        includeVat: data.price,
      };
      await productRefrush();
      // product(obj)
      setIsLoading(false);
      onCancel();
    } else {
      notification.error({
        message: 'Failed',description:`Failed to create ${type === "Service" ? "service" : "product"}`
      });
      setIsLoading(false);
    }
    } catch (error) {
      console.log(error)
      notification.error({
        message: 'Server Error',description:`Failed to create ${type === "Service" ? "service" : "product"}`
      });
    }
    
  };

  const onValuesChange = async (val: any, arr: any) => {
    let rate = Number(arr.sp_price);
    let vatPercent = Number(arr.vat);
    let totalPrice;
    if (rate && (vatPercent || Number(vatPercent) === 0)) {
      let vatAmount: any = rate * (vatPercent / 100);
      if (val.includevat || arr.includevat) {
        vatAmount = (rate / (100 + vatPercent)) * 100;
        vatAmount = (rate - vatAmount).toFixed(2);
        totalPrice = Number(rate.toFixed(2));
        rate = rate - vatAmount;
      } else {
        totalPrice = Number(rate + vatAmount).toFixed(2);
      }
      form.setFieldsValue({
        vatamt: Number(vatAmount).toFixed(2),
        total_price: Number(totalPrice).toFixed(2),
      });
    }
  };

  const checkIfItemExist = async (type: any, item: any) => {
    if (item.length > 2) {
      let url = API.CHECK_IF_EXIST + `${user.adminid}/${type}/${item}`;
      const res: any = await GET(url, null);
      if (res.status) {
        message.error(res.message);
      }
    }
  };

  const ledgerCategoryDetails = async () => {
    try {
      let url = API.GET_FIXED_ASSET_LEDJERS + user.adminid;
      const response: any = await GET(url, null);
      setLedgerCategoryList(response.data);
    } catch {}
  };

  useEffect(() => {
    ledgerCategoryDetails();
    LoadCategory();
    loadTaxList();
    LoadUnit();
    fetchUnits();
  }, []);

  const fetchUnits = async () => {
    try {
      let unit_url = API.LOCATION_GET_BY_USER + user.adminid;
      const data: any = await GET(unit_url, null);
      setLocationData(data);
      form.setFieldsValue({
        location: "",
      });
    } catch (error) {
    } finally {
    }
  };

  const onUpload = async (info: any) => {
    const { file } = info;
    if (file.status !== "uploading") {
      await uploadLogo(file.originFileObj);
    }
  };

  const uploadLogo = async (imagedata: any) => {
    var formdata = new FormData();
    setLoading(true);
    formdata.append("productid", "create");
    formdata.append("file", imagedata, imagedata.name);
    let graphData_url = API.PRODUCTMASTER_IMAGE_UPLOADER;
    try {
      const { data }: any = await POST2(graphData_url, formdata);

      if (data?.location) {
        setLoading(false);
        setImage(data.location);
        notification.success({
          message: " Image Uploaded",
          description: "Your Image has been uploaded successfully.",
        });
      }
    } catch (error) {
      // Handle error if necessary
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal open={open} onCancel={onCancel} width="75%" footer={false}>
      <Form
        {...layout}
        form={form}
        initialValues={{
          date: dayjs(user?.companyInfo?.financial_year_start),
          location: "main",
        }}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col md={type == "Stock" ? 4 : 6}>
            <div className="productAdd-Txt1">
              {type === "Service" ? "SERVICE" : "PRODUCT"} INFORMATION
            </div>
            {type != "Asset" ? (
              <div className="formItem">
                <label className="formLabel">Item Code</label>
                <Form.Item
                  name="icode"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a Item Code",
                    },
                  ]}
                >
                  <Input
                    onChange={(val) =>
                      checkIfItemExist("icode", val.target.value)
                    }
                  />
                </Form.Item>
              </div>
            ) : null}

            <div className="formItem">
              <label className="formLabel">Item Name</label>
              <Form.Item
                name="idescription"
                rules={[
                  {
                    required: true,
                    message: "Please enter a Item Name",
                  },
                ]}
              >
                <Input
                  onChange={(val) =>
                    checkIfItemExist("idescription", val.target.value)
                  }
                />
              </Form.Item>
            </div>
            <div className="formItem">
              <label className="formLabel">Product Category </label>
              <Form.Item
                name="product_category"
                rules={[
                  {
                    required: true,
                    message: "Please enter a Product Category",
                  },
                ]}
              >
                <Select>
                  {category.length &&
                    category.map((item: any) => (
                      <Select.Option value={item.category} key={item.id}>
                        {item.category}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            {type != "Service" ? (
              <>
                <div className="formItem">
                  <label className="formLabel">Unit</label>
                  <Form.Item
                    name="unit"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a Unit",
                      },
                    ]}
                  >
                    <Select>
                      {unit.length &&
                        unit.map((item: any) => (
                          <Select.Option value={item.unit} key={item.id}>
                            {item.unit}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="formItem">
                  <label className="formLabel">Barcode</label>
                  <Form.Item name="barcode">
                    <Input />
                  </Form.Item>
                </div>
                <div className="formItem">
                  <label className="formLabel">Location</label>
                  <Form.Item
                    name="location"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a Location",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any): any => {
                        let isInclude = false;
                        isInclude = option.children
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase());

                        if (option.value === "location") {
                          isInclude = true;
                        }
                        return isInclude;
                      }}
                    >
                      {locationdata.length &&
                        locationdata.map((item: any) => (
                          <Select.Option value={item.location} key={item.id}>
                            {item.location}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </>
            ) : null}
            <div className="formItem">
              <label className="formLabel">Notes</label>
              <Form.Item name="notes">
                <Input.TextArea rows={3} />
              </Form.Item>
            </div>
          </Col>

          <Col md={type == "Stock" || type == "Nonstock" ? 4 : 6}>
            <>
              <div className="productAdd-Txt1">SALES INFORMATION</div>
              <div className="formItem">
                <label className="formLabel">
                  {type === "Service" ? "Rate" : "Sale Price"}{" "}
                </label>
                <Form.Item
                  name="sp_price"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter a Rate",
                  //   },
                  // ]}
                >
                  <InputNumber
                    type="number"
                    controls={false}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
              <div className="formItem">
                <label className="formLabel">Vat %</label>
                <Form.Item
                  name="vat"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter a Vat",
                  //   },
                  // ]}
                >
                  <Select>
                    {taxlist?.length &&
                      taxlist?.map((item: any) => (
                        <Select.Option key={item?.percentage}>
                          {`${item?.percentage} %`}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label className="formLabel">
                  Select if sales price inc. vat
                </label>
                <Form.Item
                  name="includevat"
                  style={{ border: 0, margin: 0 }}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={(e) => {
                      form.setFieldsValue({
                        includevat: e.target.checked,
                      });
                      setIncludeVat(!includeVats);
                    }}
                    checked={includeVats}
                  />
                </Form.Item>
              </div>
              <div className="formItem">
                <label className="formLabel">Vat Amount</label>
                <Form.Item
                  name="vatamt"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter a Vat Amount",
                  //   },
                  // ]}
                >
                  <InputNumber
                    type="number"
                    disabled={includeVats}
                    controls={false}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
              {type != "Service" ? (
                <div className="formItem">
                  <label className="formLabel">Wholesale Price</label>
                  <Form.Item
                    name="wholesale"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter a Wholesale Price",
                    //   },
                    // ]}
                  >
                    <InputNumber
                      type="number"
                      controls={false}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
              ) : null}
              <div className="formItem">
                <label className="formLabel">Total Price</label>
                <Form.Item name="total_price">
                  <InputNumber
                    type="number"
                    controls={false}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
            </>
            {type == "Stock" ? (
              <>
                <div className="formItem">
                  <label className="formLabel">Reorder Level</label>
                  <Form.Item name="rlevel">
                    <InputNumber
                      type="number"
                      controls={false}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
                <div className="formItem">
                  <label className="formLabel">Reorder Quantity</label>
                  <Form.Item name="rquantity">
                    <InputNumber
                      type="number"
                      controls={false}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
              </>
            ) : null}
          </Col>
          <Col md={4}>
            <div className="ProductAdd-Box1">
              <div>
                <Spin
                  spinning={loading}
                  size="large"
                  tip="Uploading..."
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: "#ff9800" }}
                      spin
                    />
                  }
                >
                  {image ? (
                    <div className="ProductAdd-Box2">
                      <img className="ProductAdd-Img" src={image} />
                    </div>
                  ) : (
                    <div className="ProductAdd-Box2">
                      <CiImageOff size={100} color="rgb(160 158 158)" />
                    </div>
                  )}
                </Spin>
              </div>

              <hr />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Upload
                  // accept="image/*"
                  style={{ color: "" }}
                  listType="picture"
                  showUploadList={false}
                  onChange={onUpload}
                >
                  <br />
                  <p>
                    <MdOutlinePhotoCamera size="20" color="rgb(160 158 158)" />
                    &nbsp;Image Upload Here
                  </p>
                </Upload>
              </div>
            </div>
          </Col>

          <Col md={type == "Stock" ? 8 : 6}></Col>
          <Col md={type == "Stock" ? 2 : 3}>
            <br />
            <br />
            <Button onClick={() => onCancel()} block size="large">
              Cancel
            </Button>
          </Col>
          <Col md={type == "Stock" ? 2 : 3}>
            <br />
            <br />
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              block
              size="large"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default ProductAddModal;

// {type === "Stock" ? (
//                          <>
//                              <div className="productAdd-Txt1">
//                                  {type === "Stock"
//                                      ? " OPENING BALANCE"
//                                      : "Upload Image"}
//                              </div>
//                              {type === "Stock" ? (
//                              <>
//                              <div className="formItem">
//                                  <label className="formLabel">
//                                      Quantity On Hand
//                                  </label>
//                                  <Form.Item
//                                      name="quantity"
//                                      rules={[
//                                          {
//                                              required: true,
//                                              message:
//                                                  "Please enter a Quantity On Hand",
//                                          },
//                                      ]}
//                                  >
//                                      <InputNumber
//                                          type="number"
//                                          controls={false}
//                                          style={{ width: "100%" }}
//                                      />
//                                  </Form.Item>
//                              </div>
//                              <div className="formItem">
//                                  <label className="formLabel">
//                                      Cost Price
//                                  </label>
//                                  <Form.Item
//                                      name="c_price"
//                                      rules={[
//                                          {
//                                              required: true,
//                                              message: "Please enter a Cost Price",
//                                          },
//                                      ]}
//                                  >
//                                      <InputNumber
//                                          type="number"
//                                          controls={false}
//                                          style={{ width: "100%" }}
//                                      />
//                                  </Form.Item>
//                              </div>
//                              <div className="formItem">
//                                  <label className="formLabel">
//                                      Business Starting Date
//                                  </label>
//                                  <Form.Item
//                                      name="date"
//                                      rules={[
//                                          {
//                                              required: true,
//                                              message: "Please add business starting date",
//                                          },
//                                      ]}
//                                  >
//                                      {!businessStartDate ? (
//                                          <Button onClick={() => navigate('/usr/profile/accounting')}>
//                                              Add Business Starting Date
//                                          </Button>) :
//                                          <DatePicker
//                                              style={{ width: "100%" }}
//                                              format={"YYYY-MM-DD"}
//                                              disabled={businessStartDate}
//                                          />}
//                                  </Form.Item>
//
//                              </div>
//                          </>
//                       ) : null}
//                      <div className="ProductAdd-Box1">
//                          <div>
//                              <Spin
//                                  spinning={loading}
//                                  size="large"
//                                  tip="Uploading..."
//                                  indicator={
//                                      <LoadingOutlined
//                                          style={{ fontSize: 24, color: "#ff9800" }}
//                                          spin
//                                      />
//                                  }
//                              >
//                                  {image ? (
//                                      <div className="ProductAdd-Box2">
//                                          <img
//                                              className="ProductAdd-Img"
//                                              src={image}
//                                          />
//                                      </div>
//                                  ) : (
//                                      <div className="ProductAdd-Box2">
//                                          <CiImageOff
//                                              size={100}
//                                              color="rgb(160 158 158)"
//                                          />
//                                      </div>
//                                  )}
//                              </Spin>
//                          </div>
//
//                          <hr />
//
//                          <div
//                              style={{
//                                  display: "flex",
//                                  justifyContent: "center",
//                                  alignItems: "center",
//                              }}
//                          >
//                              <Upload
//                                  // accept="image/*"
//                                  style={{ color: "" }}
//                                  listType="picture"
//                                  showUploadList={false}
//                                  onChange={onUpload}
//                              >
//                                  <br />
//                                  <p>
//                                      <MdOutlinePhotoCamera
//                                          size="20"
//                                          color="rgb(160 158 158)"
//                                      />
//                                      &nbsp;Image Upload Here
//                                  </p>
//                              </Upload>
//                          </div>
//                      </div>
//                  </>
//              ) : null}
