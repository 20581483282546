import { Button, Card, Popover } from "antd";
import DataGrid, {
  Column,
  Export,
  HeaderFilter,
  Item,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  Toolbar,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { BiSolidPencil } from "react-icons/bi";
import { EXPORT } from "../../../utils/exportData";
import AddDefualtCategory from "../ledger-MyCategory/addCategory";
import AddLedger from "../ledger-MyLedger/addLedger";
import LedgerVisibility from "./visibility";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdEditDocument, MdPreview } from "react-icons/md";

const LedgerTable = (props: any) => {
  const dataGridRef: any = useRef(null);
  const [selectedRows, setSelectedRows] = useState();
  const [page, SetPage] = useState(1);
  const [take, setTake] = useState(10);
  const [open, setOpen] = useState(false);
  const [categoryCreate, setCategoryCreate] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [data, setData] = useState();
  const onSelectionChanged = (e: any) => {
    setSelectedRows(e.selectedRowsData.length);
  };
  const exportFormats = ["xlsx", "pdf"];

  const handleOptionChanged = (e: any) => {
    if (e.fullName === "paging.pageIndex") {
      SetPage(e.value);
    }
    if (e.fullName === "paging.pageSize") {
      setTake(e.value);
    }
    if (e.fullName === "paging.pageSize" || e.name === "pageSize") {
      props.onPageChange(page, take);
    }
  };
  const onVisible = (val: any) => {
    setData(val);
    setVisibility(true);
  };

  const navigate = useNavigate();

  return (
    <Container>
      <br />
      <Card>
        <DataGrid
          ref={dataGridRef}
          dataSource={props.products}
          columnAutoWidth={true}
          showBorders={true}
          onExporting={(e) => EXPORT(e, dataGridRef, "ledgers", () => {})}
          showRowLines={true}
          onSelectionChanged={onSelectionChanged}
          onOptionChanged={handleOptionChanged}
          remoteOperations={false}
        >
          <Selection
            mode="multiple"
            selectAllMode="allPages"
            showCheckBoxesMode="always"
          />
          <SearchPanel visible={true} width={240} />
          <HeaderFilter visible={true} />
          {props.columns.map((column: any, index: number) => {
            return (
              <Column
                dataField={column.name}
                caption={column.title}
                dataType={column.dataType}
                format={column.format}
                alignment={column.alignment}
              ></Column>
            );
          })}
          <Paging defaultPageSize={take} />

          <Pager
            visible={true}
            allowedPageSizes={[10, 20]}
            displayMode={"compact"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />

          <Column
            alignment={"center"}
            // type="buttons"
            caption="Status"
            cellRender={(item) => {
              return (
                <Popover
                  content={
                    props.title && props.title === "My Ledgers" ? (
                      <>
                        <div
                          className="table-actionBoxItem"
                          onClick={() =>
                            navigate(`/usr/ledger-view/${item?.data?.id}`)
                          }
                        >
                          <div>View</div>
                          <MdPreview size={18} color="grey" />
                        </div>
                        <div
                          className="table-actionBoxItem"
                          onClick={() => props.myLedgerOnEdit(item)}
                        >
                          <div>Edit</div>
                          <MdEditDocument size={18} color="grey" />
                        </div>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => onVisible(item)}
                        >
                          Visibility
                        </Button>
                      </>
                    ) : props.title === "Default Ledger" ? (
                      <>
                        <div className="table-title">
                          <div
                            className="table-actionBoxItem"
                            onClick={() =>{
                              if(item?.data?.id == 47 ){
                                navigate(`/usr/report/sundryDebtors`)
                              }else if(item?.data?.id == 51){
                                navigate(`/usr/report/sundryCreditors`)
                              }else{
                                navigate(`/usr/ledger-view/${item?.data?.id}`)
                              }
                            }
                            }
                          >
                            <div>View</div>
                            <MdPreview size={18} color="grey" />
                          </div>
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => onVisible(item)}
                          >
                            Visibility
                          </Button>
                        </div>
                      </>
                    ) : props.title === "My Ledgers" ||
                      props?.title === "Default Ledger" ||
                      props?.title === "Default Category" ? null : (
                      <>
                        <div
                          className="table-actionBoxItem"
                          onClick={() =>
                            props.title === "My Category"
                              ? props.defualtCategoryOnEdit(item)
                              : props?.title === "Default Ledger"
                              ? props.defualtLedgeronEdit(item)
                              : null
                          }
                          style={{display:'flex',alignItems:'center'}}
                        >
                          <div>View</div>
                          <MdPreview size={18} color="grey" />
                        </div>
                      </>
                    )
                  }
                  placement="bottom"
                  trigger={"click"}
                >
                  <BsThreeDotsVertical size={16} cursor={"pointer"} />
                </Popover>
              );
            }}
          ></Column>

          <Export
            enabled={true}
            allowExportSelectedData={true}
            formats={exportFormats}
          />
          <Toolbar>
            {selectedRows ? (
              <Item location="before" visible={true}>
                <div style={{ fontSize: "17px", fontWeight: 600 }}>
                  {selectedRows} selected
                </div>
              </Item>
            ) : (
              <Item location="before" visible={true}>
                <div style={{ fontSize: "17px", fontWeight: 600 }}>
                  {props.title}
                </div>
              </Item>
            )}
            <Item name="searchPanel" />
            <Item location="after" visible={true} name="exportButton" />
          </Toolbar>
        </DataGrid>
      </Card>
      {open && (
        <AddLedger data={data} onOpen={open} onClose={() => setOpen(false)} />
      )}
      {categoryCreate && (
        <AddDefualtCategory
          onOpen={categoryCreate}
          onClose={() => setCategoryCreate(false)}
        />
      )}
      {visibility ? (
        <LedgerVisibility
          data={data}
          open={visibility}
          onSuccess={props.onSuccess}
          onClose={() => setVisibility(false)}
        />
      ) : null}
    </Container>
  );
};

export default LedgerTable;
