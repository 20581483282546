import {
    Button,
    Card,
    DatePicker,
    Form,
    Input,
    Select,
    notification,
  } from "antd";
  import dayjs from "dayjs";
  import { useEffect, useState } from "react";
  import { Col, Container, Row, Table } from "react-bootstrap";
  import { useSelector } from "react-redux";
  import { useNavigate } from "react-router-dom";
  import LoadingBox from "../../../components/loadingBox";
  import PageHeader from "../../../components/pageHeader";
  import API from "../../../config/api";
  import { GET, PUT } from "../../../utils/apiCalls";
  import Items from "../components/items";
  import { useParams } from "react-router-dom";
  import { useLocation } from "react-router-dom";
  
  function ReccuringEdit(props: any) {
    const {id} = useParams()
    const [form] = Form.useForm();
    const { user } = useSelector((state: any) => state.User);
    const navigate = useNavigate();
    const adminid = user.adminid;
    const [details, setDetails] = useState({});
    const [isFullLoading, setIsFullLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [subTotal, setSubTotal] = useState(0);
    const [tatalVat, setTatalVat] = useState(0);
    const [roundOff, setRoundOff] = useState(0);
    const [overollDiscount, setOverolDiscount] = useState(0);
    const [defaltOutStanding, setDefaltOutStanding] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isPaymentInfo, setIsPaymentInfo] = useState<any>(false);
    const [qSuffix, setqSuffix] = useState("");
    const [pStock, setPStock] = useState(0);
    const [selectBank, setSlectedBank] = useState<any>({});
    const [selectBankBalance, setSelectBankBalance] = useState(0);
    const [paidedValue, setPaidedValue] = useState<any>({});
    const [isReccNotification, setIsReccNotification] = useState<any>(false);
    const { Option } = Select;
    const location = useLocation()
    const reccid = location.state.id
  
    const onFinish = async (val: any) => {
      setIsLoading(true);
      
      
     
      try {
         
        //   if (isReccNotification) {
        //     reccObj = {
        //       invoiceNo: val.invoiceno,
        //       period: val.period,
        //       date: val.date,
        //       daybefore: val?.daybefore,
        //     };
        //   } 
        let payload = {
              period: val.period,
              date: val.date,
              daybefore: val?.daybefore,   
        };
        let reccuringUrl = API.EDIT_RECCURING + reccid;
        const response: any = await PUT(reccuringUrl, payload);
        if (response.status) {
          setIsLoading(false);
          notification.success({ message: "sales updated successfully" });
          navigate(-1);
        } else {
          notification.error({ message: "oops.., something went wrong" });
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        notification.error({
          message: "catchh" + "Oops .. something went wrong",
        });
        setIsLoading(false);
      }
    };
  
    useEffect(() => {
      getInvoiceDetails();
      form.setFieldsValue({
        terms: user?.companyInfo?.defaultTerms,
        quotes: user?.companyInfo?.cusNotes,
      });
    }, []);
  
    const getInvoiceDetails = async () => {
      setIsFullLoading(true);
      try {
        let url = API.GET_RECCURING + id 
        const getreccDetails: any = await GET(url, null);
        if (getreccDetails.status) {
          setDetails(getreccDetails?.data);
          let initialValus = getInitialValues(getreccDetails?.data);
  
          form.setFieldsValue(initialValus);
          setIsFullLoading(false);
        }
      } catch (err) {
        console.log(err);
        setIsFullLoading(false);
      }
    };
  
    // const formValue = form.getFieldsValue();
    
    const getInitialValues = (data: any) => {
      try {
        const initialValue = {
          date: dayjs(data?.date),
          period: data?.period,
          daybefore: data?.daybefore,
        };
        return initialValue;
      } catch (error) {
        return {};
      }
    };
    return (
      <div>
        <PageHeader
          title="Edit Reccuring Notification"
          goBack={"/dashboard"}
          secondPathText="Edit Reccuring Notification"
          secondPathLink={"/usr/sales-invoice"}
        >
          
        </PageHeader>
        <br />
  
        <Container>
          <Card>
            {isFullLoading ? (
              <LoadingBox />
            ) : (
              <Form
                form={form}
                onFinish={onFinish}
              >
                <>
                  <Row>
                    <Col sm={4}>
                      <div className="formLabel" style={{ marginTop: 10 }}>
                        Start Date :
                      </div>
                      <Form.Item
                        noStyle
                        name="date"
                        // initialValue={dayjs(new Date())}
                      >
                        <DatePicker
                          defaultValue={dayjs(new Date(), "YYYY-MM-DD")}
                          style={{ width: "100%" }}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4}>
                      <div className="formLabel" style={{ marginTop: 10 }}>
                        Period:
                      </div>
                      <Form.Item
                        noStyle
                        name="period"
                      >
                        <Select placeholder="Select period" size="large" 
                        style={{width:"100%"}}>
                          <Option value="daily">Daily</Option>
                          <Option value="weekly">Weekly</Option>
                          <Option value="monthly">Monthly</Option>
                          <Option value="yearly">Yearly</Option>
                        </Select>
                      </Form.Item>
                    </Col>
  
  
                    {/* <Col sm={2}>
                      <div className="formLabel" style={{ marginTop: 10 }}>
                        Days Before :
                      </div>
                      <Form.Item
                        noStyle
                        name="daybefore"
                      >
                        <Input
                          placeholder="days before"
                          size="large"
                          type="number"
                        />
                      </Form.Item>
                    </Col> */}
                    <Col sm={4}>
                      <div className="formLabel" style={{ marginTop: 10 }}>
                      Send Via
                      </div>
                      <Form.Item
                        name="mailto"
                        rules={[{ message: "Select Notification" }]}
                      >
                        <Select size="large" defaultValue="Mail">
                          <Select.Option>Mail</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <br/>
                </>
                <Row>
                  <Col sm={6}></Col>
                  <Col sm={6}>
                    <Row>
                      <Col sm={6}>
                        {/* <Button size="large" block>
                          Close
                        </Button> */}
                      </Col>
                      <Col sm={6}>
                        <Button
                          size="large"
                          block
                          type="primary"
                          htmlType="submit"
                          loading={isLoading}
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </Card>
        </Container>
      </div>
    );
  }
  export default ReccuringEdit;
  