import { Button, Col, Row } from "antd";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { BsCheckCircleFill } from "react-icons/bs";
import image1 from "../../assets/images/pricing-image1.png";
import image3 from "../../assets/images/pricing-image2.png";
import image2 from "../../assets/images/pricing-image3.png";
import WebsiteFooter from "../../components/websiteFooter";
import WebsiteHeader from "../../components/websiteHeader";
import { withTranslation } from "react-i18next";
import "./style.scss";
function Plans(props:any) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const { t } = props;

  const card = [
    {
      id: 1,
      head: (
        <div>
          {t("home_page.homepage.TAX AND PAY RETURN")}
          <br /> {t("home_page.homepage.PREPARATION")}
        </div>
      ),
      image: image1,
      para: `${t(
        "home_page.homepage.Tax Refund is on a no win no fee basis 5% of refunded claimed for self-employed individuals, investors and Professionals"
      )}`,
    },
    {
      id: 2,
      head: (
        <div>
          {t("home_page.homepage.FEE FOR BUSINESSES AND")} <br />{" "}
          {t("home_page.homepage.ACCOUNTING")}
        </div>
      ),
      image: image2,
      para: `${t(
        "home_page.homepage.Covers the preparation of financial statements and annual tax returns. Tax GO Accounting/Retail Xpress included"
      )}`,
    },
    {
      id: 3,
      head: (
        <div>
          {" "}
          {t("home_page.homepage.FEE FOR COMPANY OFFICE")}
          <br />
          {t("home_page.homepage.COMPILIANCE")}
        </div>
      ),
      image: image3,
      para: `${t(
        "home_page.homepage.Covers the annual companies Office Compliance Customize software based on your business and your requirements"
      )}`,
    },
  ];
  const card1 = [
    {
      text: `${t("home_page.homepage.Issue_up_to_10_Invoices")}`,
    },
    {
      text: `${t("home_page.homepage.Manage_up_to_10_Contacts")}`,
    },
    {
      text: `${t("home_page.homepage.Card_and_Cash_Bank_Account")}`,
    },
    {
      text: `${t("home_page.homepage.Small_Business")}`,
    },
    {
      text: `${t("home_page.homepage.Freelancer")}`,
    },
  ];
  const card2 = [
    {
      text: `${t("home_page.homepage.Issue_upto_250_Invoices")}`,
    },
    {
      text: `${t("home_page.homepage.Manage_upto_100_Contacts")}`,
    },
    {
      text: `${t("home_page.homepage.Customize_Bank_Account")}`,
    },
    {
      text: `${t("home_page.homepage.Medium_Business")}`,
    },
    {
      text: `${t("home_page.homepage.Retail_Management")}`,
    },
    {
      text: `${t("home_page.homepage.Invoice_Designs")}`,
    },
  ];
  const card3 = [
    {
      text: `${t("home_page.homepage.Issue_Unlimited_Invoices")}`,
    },
    {
      text: `${t("home_page.homepage.Manage_Unlimited_Contacts")}`,
    },
    {
      text: `${t("home_page.homepage.Unlimited_Banking_for_all")}`,
    },
    {
      text: `${t("home_page.homepage.Automated_Reports")}`,
    },
    {
      text: `${t("home_page.homepage.Enterprise/Business")}`,
    },
  ];
  return (
    <>
      <div className="bg-image">
        <WebsiteHeader />
        <div className="pricing-componentCover">
          <div className="pricing-text1">{t("home_page.homepage.Pricing")}</div>
          <div className="pricing-text2">
            {t("home_page.homepage.flexible")}
            <br />
            {t("home_page.homepage.level")}
          </div>
          <div className="pricing-Box1">
            <Container>
              <Row
                className="pricing-row1"
                justify={"center"}
                align={"bottom"}
                gutter={[40, 40]}
              >
                <Col
                  className="order-sm-3 order-lg-1 picing-colPadding"
                  xl={7}
                  lg={8}
                  md={12}
                  sm={24}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="pricing-card1">
                      <div className="pricing-box2-head1">
                        {t("home_page.homepage.Basic")}
                      </div>
                      <div className="pricing-box2-head2-subhead">
                        <span className="pricing-box2-head2-subhead1">€</span>0
                        <span className="pricing-box2-head2-subhead1">/MO</span>
                      </div>
                      {card1.map((item: any) => (
                        <div className="pricing-card2-body">
                          <BsCheckCircleFill
                            size={15}
                            style={{ marginRight: "9px", color: "#BCBCBC" }}
                          />
                          {item.text}
                          <br />
                        </div>
                      ))}
                      <Button className="pricing-button" size="large" block>
                        {t("home_page.homepage.Sign_up_for_free")}
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col
                  className="order-sm-1 order-lg-2 picing-colPadding"
                  xl={7}
                  lg={8}
                  md={24}
                  sm={24}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="pricing-card2">
                      <div className="pricing-whiteColor pricing-box2 pricing-box2-head1">
                        {t("home_page.homepage.Standard")}
                        <div className="pricing-whiteColor pricing-box2-head2-subhead">
                          <span className="pricing-whiteColor pricing-box2-head2-subhead1">
                            €
                          </span>
                          19.99
                          <span className="pricing-whiteColor pricing-box2-head2-subhead1">
                            /MO
                          </span>
                        </div>
                      </div>
                      {card2.map((item: any) => (
                        <div key={item.id} className="pricing-card2-body">
                          <BsCheckCircleFill
                            size={15}
                            style={{ marginRight: "9px", color: "#FFFFFF" }}
                          />
                          {item.text}
                          <br />
                        </div>
                      ))}
                      <Button
                        className="pricing-button pricing-buttonColor"
                        size="large"
                        block
                      >
                        {t("home_page.homepage.get_started")}
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col
                  className="order-sm-2 order-lg-3 picing-colPadding"
                  xl={7}
                  lg={8}
                  md={12}
                  sm={24}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="pricing-card1">
                      <div className="pricing-box2-head1">
                        {" "}
                        {t("home_page.homepage.Enterprise")}
                      </div>
                      <div className="pricing-box2-head2-subhead">
                        <span className="pricing-box2-head2-subhead1">€</span>
                        39.99
                        <span className="pricing-box2-head2-subhead1">/MO</span>
                      </div>
                      {card3.map((item: any) => (
                        <div className="pricing-card2-body">
                          <BsCheckCircleFill
                            size={15}
                            style={{ marginRight: "9px", color: "#BCBCBC" }}
                          />
                          {item.text}
                          <br />
                        </div>
                      ))}
                      <Button className="pricing-button" size="large" block>
                        {t("home_page.homepage.Contact_us")}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <div className="pricing-miniCardCover">
        <Row justify={"center"} gutter={[32, 32]}>
          {card?.map((item: any) => (
            <Col lg={8} md={12} sm={24} className="pricing-card3">
              <div className="pricing-miniCard-head1">{item?.head}</div>
              <img
                src={item?.image}
                alt=""
                className="pricing-minicard-image"
              />
              <br />
              <div className="pricing-miniCard-head2">{item?.para}</div>
            </Col>
          ))}
        </Row>
      </div>
      <WebsiteFooter />
    </>
  );
}

export default withTranslation()(Plans);
