import BankCard from "./component/cashCard";
import PageHeader from "../../components/pageHeader";
import { Col, Row } from "antd";
import { Container } from "react-bootstrap";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../../components/loadingBox";
import CashCard from "./component/cashCard";

function Cash() {
  const { user } = useSelector((state: any) => state.User);
  const [cash, setCash] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(false);
  const navigate = useNavigate();

  const loadCash = async () => {
    setIsLoading(true);
    let URL = API.GET_CASH_LIST + user.adminid;
    const { data }: any = await GET(URL, null);
    if (data) {
      setIsLoading(false);
      setCash(data.bankList);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadCash();
  }, []);
  return (
    <div>
      <PageHeader
        firstPathLink={"/usr/cash"}
        firstPathText={"Cash Statement"}
        secondPathLink={"/usr/cash"}
        goback={-1}
        title="Cash Statement"
      />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Container>
          <br />
          <Row>
            {cash?.map((item: any) => {
              return (
                <Col md={8} style={{ marginBottom: "25px" }}>
                  <CashCard
                    bankDetails={item}
                    countryCode={user.countryInfo.currencycode}
                    navigate={() => navigate(`cashTable/${item.list.id}`)}
                    edit={() => navigate(`addCash/${item?.list?.id}`)}
                  />
                </Col>
              );
            })}
          </Row>
          <br />
        </Container>
      )}
    </div>
  );
}

export default Cash;
