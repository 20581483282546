import "./styles.scss";
import { BiUser } from "react-icons/bi";
import { MdNotifications } from "react-icons/md";
import { Select } from "antd";
import Clock from "react-live-clock";
import { BiSolidArrowToLeft, BiSolidArrowToRight } from "react-icons/bi";
import { IoHelpCircleOutline, IoCalculatorOutline } from "react-icons/io5";
import { Popover } from "antd";
import Calculator from "../components/calculator";
import QuickMenu from "./components/quickMenu";
import ProfileBox from "./components/profileBox";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { storeDirection } from "../redux/slices/languageSlice";
function Header(props: any) {
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.User);
  const dispatch = useDispatch();
  function handleChangeLanguage(language: string) {
    i18next.changeLanguage(language);
    if (language === "ar") {
      dispatch(storeDirection("RTL"));
    } else {
      dispatch(storeDirection("LTR"));
    }
  }
  return (
    <div className="Navigation-Header">
      <div className="Navigation-HeaderBox">
        <div
          className="Navigation-HeaderIcon3"
          onClick={() => props.onCollapse()}
        >
          {props.collapsed ? <BiSolidArrowToRight /> : <BiSolidArrowToLeft />}
        </div>
        <Popover content={<QuickMenu />} placement="bottomLeft">
          <div className="Navigation-quickMenu">
            {t("home_page.homepage.Quick_Menu")}
          </div>
        </Popover>
      </div>
      <div className="Navigation-HeaderBox">
        <Popover content={<Calculator />} placement="bottomRight">
          <div className="Navigation-HeaderIcon2">
            <IoCalculatorOutline />
          </div>
        </Popover>
        <div style={{ marginRight: 10, marginLeft: 10 }}>
          <Select
            onChange={handleChangeLanguage}
            style={{ width: 100 }}
            defaultValue={"English"}
          >
            <Select.Option value={"en"}>English</Select.Option>
            <Select.Option value={"ar"}>Arabic</Select.Option>
            <Select.Option value={"hi"}>Hindi</Select.Option>
          </Select>
        </div>
        <div className="Navigation-HeaderTimeBox">
          <Clock format={"h:mm:ss A"} ticking={true} />
        </div>
        <div className="Navigation-HeaderIcon2" onClick={() => props.help()}>
          <IoHelpCircleOutline />
        </div>
        <div
          className="Navigation-HeaderIcon2"
          onClick={() => props.notification()}
        >
          <MdNotifications />
        </div>
        <Popover content={<ProfileBox />} placement="bottomRight">
          <div className="Navigation-HeaderBox">
            <div className="Navigation-HeaderIcon1">
              {user?.companyInfo?.bimage == null ||
              user?.companyInfo?.bimage == "" ? (
                <BiUser />
              ) : (
                <div className="profile-picture">
                  <img
                    src={user?.companyInfo?.bimage}
                    className="profile-img"
                    style={{ padding: 0 }}
                    alt=""
                  />
                </div>
              )}
            </div>
            <div>
              <div className="Navigation-Headertxt1">
                {user?.firstname} {user?.lastname}
              </div>
              <div className="Navigation-Headertxt2">
                {user?.companyInfo?.bname}
              </div>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default Header;
