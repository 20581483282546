import React from "react";
import { FaEye } from "react-icons/fa";
import { HiPencil } from "react-icons/hi2";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { FaCopy } from "react-icons/fa";
import "./styles.scss";
import { Popconfirm } from "antd";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MdEditDocument, MdPreview } from "react-icons/md";

function ViewPopover(props: any) {
  const navigate = useNavigate();

  return (
    <>
       <div className="table-actionBox">
        <div
          className="table-actionBoxItem"
          onClick={(data: any) => props?.onView(data)}
        >
          <div>View</div>
            <MdPreview size={18} color="grey" />
        </div>
        <div
          className="table-actionBoxItem"
          onClick={() => {
            props?.OnEdit();
          }}
        >
          <div>Edit</div>
            <MdEditDocument size={18} color="grey" />
        </div>

        {props.OnDelete ? (
          <Popconfirm
            title="Delete"
            description="Are you sure to delete ?"
            icon={<AiOutlineQuestionCircle style={{ color: "red" }} />}
            onConfirm={() => props?.OnDelete()}
            placement="topRight" 
          >
            <div className="table-actionBoxItem">
            <div>Delete</div>
              <RiDeleteBinLine size={18} color="grey" />
            </div>
          </Popconfirm>
        ) : null}
      </div>
    </>
  );
}

export default ViewPopover;
