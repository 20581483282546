import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../../components/pageHeader";
import DataTable from "./components/Table";
import { useSelector } from "react-redux";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import moment from "moment";
import LoadingBox from "../../components/loadingBox";
import { notification } from "antd";

function Proposal() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;

  const columns = [
    {
      dataField: "proposal_date",
      title: "Date",
      cellRender: (data: any) =>
        moment(data?.proposal_date).format("DD-MM-YYYY"),
    },
    {
      dataField: "from_company_name",
      title: "From",
    },
    {
      dataField: "to_company_name",
      title: "To",
    },
    {
      dataField: "proposal_title",
      title: "Proposal",
    },
  ];

  const fetchProposals = async () => {
    try {
      setIsLoading(true);
      const url = API.GET_PROPOSAL_LIST + adminid;
      const { data }: any = await GET(url, null);
      setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    try {
      setIsLoading(true);
      const url = API.DELETE_PROPOSAL + id;
      const data: any = await GET(url, null);
      fetchProposals();
      if (data.status) {
        notification.success({ message: "Item Deleted Successfully" });
      } else {
        notification.error({
          message: "something went wrong!! Please try again later",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProposals();
  }, []);

  return (
    <>
      <PageHeader
        firstPathText="Proposals"
        firstPathLink={location.pathname.replace("/create", "")}
        buttonTxt={"CREATE"}
        onSubmit={() => navigate("/usr/proposal/create")}
        title="Proposals List"
      />

      <div className="adminTable-Box1">
        {isLoading ? (
          <LoadingBox />
        ) : (
          <DataTable
            columns={columns}
            list={data}
            handleDelete={handleDelete}
          />
        )}
      </div>
    </>
  );
}

export default Proposal;
