import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Radio,
  Spin,
  Upload,
  message,
  notification,
} from "antd";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import InvImage1 from "../../../assets/images/Invoicev1.png";
import InvImage2 from "../../../assets/images/Invoicev2.png";
import InvImage3 from "../../../assets/images/Invoicev3.png";
import API from "../../../config/api";
import { update } from "../../../redux/slices/userSlice";
import { GET, POST2, PUT } from "../../../utils/apiCalls";
import "../styles.scss";
import { CiEdit } from "react-icons/ci";
function Customize() {
  const [form] = Form.useForm();
  const { user } = useSelector((state: any) => state.User);

  const dispatch = useDispatch();
  const adminid = user?.adminid;

  const [getData, SetGetData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [invEditModal, setInvEditModal] = useState(false);
  const [editData, setEditData] = useState<any>();
  const [updateLoading, setUpdateLoading] = useState(false);

  const GetCustimedData = async () => {
    try {
      let url = API.CUSTOMISE_DATA + adminid;
      const { value }: any = await GET(url, null);
      let filtered: any = value?.filter(
        (item: any) => item.type !== "purchase"
      );
      SetGetData(filtered);
      // SetGetData(value);
    } catch (error) {
      console.log(error);
    }
  };
  const GetUserDetals = async () => {
    try {
      let url = API.GET_USER_DETAILS + adminid;
      const { data }: any = await GET(url, null);
      let imageUrl = `${API.FILE_PATH}logo/${data.companyInfo.logo}`;
    } catch (error) {
      console.log(error);
    }
  };

  const onUpload = async (info: any) => {
    const { file } = info;
    if (file.status !== "uploading") {
      await uploadLogo(file.originFileObj);
    }
  };

  const uploadLogo = async (imagedata: any) => {
    try {
      setIsLoading(true);
      var formdata = new FormData();
      formdata.append("userid", user?.id);
      formdata.append("file", imagedata, imagedata.name);
      let graphData_url = API.UPDATE_LOGO;
      const { data, status }: any = await POST2(graphData_url, formdata);
      if (status) {
       dispatch(update(data?.updatedData));
        notification.success({
          message: " Logo Updated",
          description: "Your Logo picture has been updated successfully.",
        });
        setIsLoading(false);
      } else {
        notification.error({
          message: "Failed to update Logo",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateTemplate = async (selectedInvoice: any) => {
    let url = `${API.UPADATE_TEMPLATE}${adminid}/${selectedInvoice}`;
    let { data, status, bankInfo }: any = await GET(url, "");
    if (status) {
      notification.success({
        message: " Invoice Format Updated",
        description: "Your Invoice Format has been updated successfully.",
      });
      data["bankInfo"] = bankInfo;
      dispatch(update(data));
    }
  };

  const columns = [
    {
      dataField: "type",
      caption: "Type",

      cellRender: ({ data }: any) => (
        <div style={{ textAlign: "center" }}>{data?.type === 'sales' ? 'Sales' :
        data?.type === 'scredit' ? 'Credit Note' :
        data?.type === 'pcredit' ? 'Debit Note' :
        data?.type === 'proforma' ? 'Proforma' :
        data?.type === 'reccuring' ? 'Reccuring' : ''
        }</div>
      ),
    },
    {
      dataField: "prefix",
      caption: "Prefix",
      cellRender: ({ data }: any) => (
        <div style={{ textAlign: "center" }}>{data?.prefix}</div>
      ),
    },
    {
      dataField: "startNumber",
      caption: "Start Number",
      cellRender: ({ data }: any) => (
        <div style={{ textAlign: "center" }}>{data?.startNumber}</div>
      ),
    },
    {
      dataField: "currentInvNumber",
      caption: "Current Invoice Number",
      cellRender: ({ data }: any) => (
        <div style={{ textAlign: "center" }}>{data?.currentInvNumber}</div>
      ),
    },
    {
      dataField: "",
      caption: "Edit",
      cellRender: (data: any) => (
        <CiEdit
          onClick={() => {
            setInvEditModal(true);
            setEditData(data?.data);
          }}
          size={23}
        />
      ),
    },
  ];

  useEffect(() => {
    GetCustimedData();
    GetUserDetals();
  }, []);

  const updateInvoiceInfo = async (val: any) => {
    try {
      setUpdateLoading(true);
      let api = API.UPDATE_INVOICE_DETAILS + user?.adminid;
      let payload = {
        id: editData?.id,
        prefix: val?.prefix,
        startNumber: val?.startNumber,
        currentInvNumber: editData?.currentInvNumber,
        desc: editData?.desc,
        type: editData?.type,
      };
      let response: any = await PUT(api, payload);
      if (response?.id) {
        message.success("Invoice Configuration Updated successfully");
        GetCustimedData();
      } else {
        message.error("something went wrong..!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setEditData({});
      setUpdateLoading(false);
      setInvEditModal(false);
    }
  };

  return (
    <>
      <Container>
        <Card>
          <p className="heading-txt2">INVOICE FORMAT</p>
          <hr />
          <Radio.Group
            value={user?.companyInfo?.defaultinvoice}
            onChange={(e) => updateTemplate(e.target.value)}
          >
            <Row>
              <Col sm={6} md={6}>
                <div style={{ textAlign: "center" }} className="customize-Box3">
                  Invoice 1
                </div>
                <Card
                  hoverable
                  style={{ width: 240, height: 400 }}
                  cover={<img src={InvImage1} />}
                >
                  <div className="settings-customize-Box1">
                    <Radio value="1"></Radio>
                  </div>
                </Card>
              </Col>
              {/* <Col sm={4} md={4}>
              <div className="settings-customize-Box3">Invoice 2</div>
              <Card
                hoverable
                style={{ width: 240, height: 400 }}
                cover={<img src={InvImage2} />}
              >
                <br />
                <div className="settings-customize-Box1">
                  <Radio value="2"></Radio>
                </div>
              </Card>
            </Col> */}
              <Col sm={6} md={6}>
                <div className="settings-customize-Box3">Invoice 3</div>
                <Card
                  hoverable
                  style={{ width: 240, height: 400 }}
                  cover={<img src={InvImage3} />}
                >
                  <div className="settings-customize-Box1">
                    <Radio value="3"></Radio>
                  </div>
                </Card>
              </Col>
            </Row>
          </Radio.Group>
          <br /> <br />
          <div className="heading-txt2">INVOICE LOGO</div>
          <hr />
          <div className="settings-customize-Box2">
            <div className="settings-customize-Box4">
              <img
               src={`${API.FILE_PATH}logo/${user?.companyInfo?.logo}`}
               className="settings-customize-Img1" />
            </div>
            <div className="settings-customize-Box6">
              <Upload
                accept="image/*"
                listType="picture"
                showUploadList={false}
                onChange={onUpload}
              >
                <>
                  {isLoading ? (
                    <Spin />
                  ) : (
                    <>
                      <MdOutlinePhotoCamera size="20" />
                      &nbsp; Upload
                    </>
                  )}
                </>
              </Upload>
            </div>
          </div>
          <br />
          <div className="heading-txt2">INVOICE NUMBER CONFIGURATION</div>
          <hr />
          <DataGrid
            dataSource={getData}
            columnAutoWidth={true}
            showBorders={true}
            showRowLines={true}
          >
            {columns.map((column: any, index: any) => (
              <Column
                key={index}
                dataField={column.dataField}
                caption={column.caption}
                cellRender={column.cellRender}
                alignment="center"
              />
            ))}
          </DataGrid>
        </Card>
        {/* --- */}
        <Modal
          open={invEditModal}
          centered
          onCancel={() => {
            setInvEditModal(false);
            setEditData({});
          }}
          footer={false}
          title={"Edit Invoice Numbers"}
          width={500}
        >
          <br />
          <Form
            form={form}
            initialValues={{
              prefix: editData?.prefix,
              startNumber: editData?.startNumber,
            }}
            onFinish={updateInvoiceInfo}
          >
            <Row>
              <Col md={6}>
                <div>
                  <label className="formLabel">Prefix</label>
                  <Form.Item
                    name="prefix"
                    rules={[
                      {
                        required: true,
                        message: "Prefix is required",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <label className="formLabel">Start Number</label>
                  <Form.Item
                    name="startNumber"
                    rules={[
                      {
                        required: true,
                        message: "Start Number is required",
                      },
                    ]}
                  >
                    <Input type="number" size="large" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Button
                  size="large"
                  block
                  danger
                  onClick={() => setInvEditModal(false)}
                >
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  block
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={updateLoading}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Container>
      <br />
    </>
  );
}

export default Customize;
