import "../styles.scss";
import { useState, useEffect, useRef } from "react";
import DataGrid, {
  Column,
  SearchPanel,
  Export,
  Paging,
  Pager,
  HeaderFilter,
  Selection,
  Item,
  Toolbar,
  TotalItem,
  Summary,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { Card, Popover, Tag } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  MdPreview,
  MdEditDocument,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { EXPORT } from "../../../utils/exportData";

const Table = (props: any) => {
  const dataGridRef: any = useRef(null);
  const [data, setData] = useState(props.List);
  const [selectedRows, setSelectedRows] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setData(props.List);
  }, [props.List]);

  const columns = [
    {
      dataField: "sdate",
      caption: "Invoice Date",
      dataType: "date",
      cellRender: (item: any) => moment(item.value).format("DD-MM-YYYY"),
    },
    {
      dataField: "invoiceno",
      caption: "Invoice No.",
      dataType: "string",
    },
    {
      dataField: "cname",
      caption: "A/C Name",
    },
    {
      dataField: "total",
      caption: "Total",
    },
    {
      dataField: "outstanding",
      caption: "Outstanding",
    },

    {
      dataField: "status",
      caption: "Status",
      dataType: "number",
      cellRender: (item: any) => {
        let status = null;
        if (item.value === 0) {
          status = <Tag color="red">Unpaid</Tag>;
        } else if (item.value === 2) {
          status = <Tag color="green">Paid</Tag>;
        } else if (item.value === 1) {
          status = <Tag color="orange">Part Paid</Tag>;
        }
        return status;
      },
    },

    {
      caption: "Action",
      cellRender: (data: any) => {
        return (
          <div className="table-title">
            <Popover
              content={
                <div className="table-actionBox">
                  <div
                    className="table-actionBoxItem"
                    onClick={() => {
                      navigate(`/usr/sale-invoice-view/${data?.data?.id}`);
                    }}
                  >
                    <div>View</div>
                    <MdPreview size={18} color="grey" />
                  </div>
                  <div
                    className="table-actionBoxItem"
                    onClick={() =>
                      navigate(`/usr/sale-invoice-form/${data?.data?.id}`)
                    }
                  >
                    <div>Edit</div>
                    <MdEditDocument size={18} color="grey" />
                  </div>
                </div>
              }
              placement="leftTop"
              trigger={"click"}
            >
              <BsThreeDotsVertical size={16} cursor={"pointer"} />
            </Popover>
          </div>
        );
      },
    },
  ];
  const exportFormats = ["pdf", "xlsx"];
  const onSelectionChanged = (e: any) => {
    setSelectedRows(e.selectedRowsData.length);
  };

  const customizeExportCell = (type: any, gridCell: any, cell: any) => {
    if (gridCell.rowType === "data" && gridCell.column.dataField === "status") {
      let status = gridCell.data.status;
      if (status == "0") {
        status = "Unpaid";
      } else if (status == "2") {
        status = "Paid";
      } else if (status == "1") {
        status = "Part Paid";
      }
      gridCell.data.status = status;
      if (type === "pdf") {
        cell.text = status;
      } else if (type === "xlsx") {
        cell.value = status;
      }
    }
    if (
      gridCell.column.dataField === "sdate" &&
      gridCell.rowType !== "header" &&
      gridCell.rowType !== "totalFooter"
    ) {
      const sdate = moment(gridCell.data?.sdate)?.format("DD-MM-YYYY");
      if (type === "pdf") {
        cell.text = sdate ?? "";
      } else if (type === "xlsx") {
        cell.value = sdate ?? "";
      }

    }
  };

  return (
    <Card>
      <DataGrid
        ref={dataGridRef}
        dataSource={data}
        onExporting={(e) =>
          EXPORT(e, dataGridRef, "Sales-invoice-Report", customizeExportCell)
        }
        columnAutoWidth={true}
        showBorders={true}
        showRowLines={true}
        remoteOperations={false}
        onSelectionChanged={onSelectionChanged}
      >
        <Selection
          mode="multiple"
          selectAllMode="allPages"
          showCheckBoxesMode="always"
        />
        <SearchPanel visible={true} width={240} />
        <HeaderFilter visible={true} />
        {columns.map((column, index) => (
          <Column
            key={index}
            dataField={column.dataField}
            caption={column.caption}
            alignment="center"
            cellRender={column?.cellRender}
            allowExporting={column.caption === "Action" ? false : true}
          />
        ))}
        <Paging defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 20, "all"]}
          showInfo={true}
        />
        <Export
          enabled={true}
          allowExportSelectedData={true}
          formats={exportFormats}
        />

        <Summary>
          <TotalItem
            column="total"
            summaryType="sum"
            displayFormat="Total:{0}"
            alignment={"left"}
            valueFormat={{ precision: 2 }}
          />

          <TotalItem
            column="outstanding"
            summaryType="sum"
            displayFormat="Outstanding:{0}"
            alignment={"left"}
            valueFormat={{ precision: 2 }}
          />
        </Summary>

        <Toolbar>
          {selectedRows ? (
            <Item location="before" visible={true}>
              <div style={{ fontSize: "17px", fontWeight: 600 }}>
                {selectedRows} selected
              </div>
            </Item>
          ) : (
            <Item location="before" visible={true}>
              <div style={{ fontSize: "17px", fontWeight: 600 }}>
                Total Invoice : {data?.length}
              </div>
            </Item>
          )}
          <Item name="searchPanel" />
          <Item location="after" visible={true} name="exportButton" />
        </Toolbar>
      </DataGrid>
    </Card>
  );
};
export default Table;
