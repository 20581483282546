import { Button, DatePicker, Form, Input, Select, notification } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "antd/es/form/Form";

function RetailExpressPaymentmodal(props: any) {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);

  let choosedBank: any = {}
  function handleValuesChange(val: any) {
    if (val?.paymentBank) {
      choosedBank = props?.bankList?.find(
        (item: any) => item?.list?.id === val?.paymentBank
      );
      // dayjs(new Date(), "YYYY-MM-DD")
      form.setFieldsValue({
        outStanding: props.amount ? props?.outstanding - props.amount : props?.outstanding,
        amoutToPaid: props?.amount || 0
      });
      if (choosedBank?.list?.laccount?.toLowerCase() === "Cash".toLowerCase()) {
        form.setFieldsValue({
          paymentMethod: 'cash'
        })
      }
    }

    if (val?.amoutToPaid) {
      let newValue = Number(props?.outstanding) - Number(val?.amoutToPaid);
      form.setFieldsValue({
        outStanding: newValue,
      });
      if (newValue < 0) {
        form.setFieldsValue({
          amoutToPaid: props?.outstanding,
          outStanding: 0,
        });
        notification.error({
          message:
            "The amount cannot be greater than the outstanding balance. ",
        });
      }
    }
    if (val?.amoutToPaid === "" || val?.amoutToPaid === null) {
      form.setFieldsValue({
        outStanding: props?.outstanding,
      });
    }
  }

  const onFinish = async (val: any) => {
    setIsLoading(true);
    const valData = {
      ...val,
      holderName: choosedBank?.list?.laccount,
      accountNumber: choosedBank?.list?.accnum,
      bicnum: choosedBank?.list?.bicnum,
      ibanNumber: choosedBank?.list?.ibannum,
      availableBalance: choosedBank?.amount,
      paymentDate:dayjs(new Date(), "YYYY-MM-DD"),
    }
    let responce = await props.onFinish(val);
    if (responce) {
      form.setFieldsValue({
        holderName: null,
        accountNumber: null,
        bicnum: null,
        ibanNumber: null,
        availableBalance: null,
        outStanding: null,
        amoutToPaid: null,
        paymentBank: null,
      });
    }
    setIsLoading(false);
  }
  return (
    <div>
      <Form
        onFinish={(val: any) => onFinish(val)}
        onValuesChange={handleValuesChange}
        form={form}
      >
        <Row>
          <Col sm={12}>
            <div className="formLabel" style={{ marginTop: 10 }}>
              Choose Payment Bank :
            </div>
            <Form.Item
              noStyle
              name="paymentBank"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="slecet payment bank"
              >
                {props.bankList?.length &&
                  props.bankList?.map((item: any) => {
                    return (
                      <Select.Option
                        key={item?.list?.id}
                        value={item?.list?.id}
                      >
                        {item?.list?.laccount}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={12}>
            <div className="formLabel" style={{ marginTop: 10 }}>
              Outstanding :
            </div>
            <Form.Item name={"outStanding"} noStyle>
              <Input placeholder="out standing amount" readOnly />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <div className="formLabel" style={{ marginTop: 10 }}>
              Amount To Be Paid :
            </div>
            <Form.Item
              noStyle
              name="amoutToPaid"
              rules={[{ required: true, message: "enter amount to be paid" }]}
            >
              <Input type="number" placeholder="amount to be paid" />
            </Form.Item>
          </Col>

          <Col sm={12}>
            <div className="formLabel" style={{ marginTop: 10 }}>
              Paid Method:
            </div>
            <Form.Item
              noStyle
              name="paymentMethod"
              rules={[{ required: true, message: "choose payment method" }]}
            >
              <Select
                style={{ width: "100%" }}
                allowClear
                placeholder="choose payment method"
                options={[
                  { label: "Cash", value: "cash" },
                  { label: "Cheque", value: "cheque" },
                  { label: "Electronic", value: "other" },
                  { label: "Credit/Debit Card", value: "card" },
                  { label: "PayPal", value: "loan" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={6}>
            <Button size="large" block onClick={() => {
              props?.onCancel()
              form.setFieldsValue({
                holderName: null,
                accountNumber: null,
                bicnum: null,
                ibanNumber: null,
                availableBalance: null,
                outStanding: null,
                amoutToPaid: null,
                paymentBank: null,
              });
            }}>
              Close
            </Button>
          </Col>
          <Col sm={6}>
            <Button
              size="large"
              block
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Complete
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default RetailExpressPaymentmodal;
