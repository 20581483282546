import { Button, Card, Tooltip } from "antd";
import "../styles.scss";
import { IoWalletOutline } from "react-icons/io5";
import { PiDotsThreeOutlineVerticalDuotone } from "react-icons/pi";
import { RiEditFill } from "react-icons/ri";
import { IoIosMore } from "react-icons/io";

function CashCard({ bankDetails, countryCode, navigate, edit }: any) {
  return (
    <Card className="bank-Card">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="cardHead">
            <strong>{bankDetails.list.laccount}</strong>
            <span className="cardSubHead">
              (
              {bankDetails.list.laccount &&
                (bankDetails.list.laccount.toString().toLowerCase() ===
                  "current" ||
                  bankDetails.list.laccount.toString().toLowerCase() === "cash")
                ? "default"
                : bankDetails.list.acctype}
              )
            </span>
          </div>
          <div>
            {" "}
            <Tooltip
              arrow={false}
              title="Edit Bank"
              placement="bottom"
              overlayInnerStyle={{ fontSize: "10px" }}
            >
              <Button onClick={() => edit()} >
                <RiEditFill size={22} />
              </Button>
            </Tooltip>{' '}
            <Tooltip
              arrow={false}
              title="View Cash"
              placement="bottom"
              overlayInnerStyle={{ fontSize: "10px" }}
            >
              <Button
                onClick={() => navigate()}
              >
                <IoIosMore size={20} />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center text-end py-2">
        <div>
          <IoWalletOutline size={36} color="#18a762" />
        </div>
        <div className="pt-2">
          <span style={{ color: "#6c757d", fontWeight: 600, fontSize: "12px" }}>
            Balance
          </span>
          <h2 style={{ fontWeight: 700, fontSize: "22px" }}>
            {Number(bankDetails.list.total) +
              Number(bankDetails.list.opening || 0) ||
              Number(bankDetails.list.amount) ||
              0}
          </h2>
        </div>
      </div>

      <div>
        <div className="d-flex justify-content-between">
          <span className="NormalText">Opening Balance:</span>
          <strong className="">{Number(bankDetails.list.opening) || 0}</strong>
        </div>
      </div>
    </Card>
  );
}

export default CashCard;
