import { Button, Card, Popconfirm, notification } from 'antd'
import React from 'react'
import { Container } from 'react-bootstrap'
import { GET } from '../../../utils/apiCalls'
import API from '../../../config/api'
import { useSelector } from 'react-redux'
import moment from 'moment'

function EndOfYear() {

    const { user } = useSelector((state: any) => state.User);
    const financialYearStart =  moment(user?.companyInfo?.financial_year_start).format('YYYY-MM-DD');

    const handleEndOfYear = async() =>{
        try {
            let url = API.END_OF_YEAR + user?.id + `/${financialYearStart}`
            const response:any = await GET(url,null)
            if(response.status){
                notification.success({message:"Success",description:"Successfully set the end-of-year for the current fiscal year"})
            }else{
                notification.error({
                    message:"Failed",
                    description:"Failed to set the end-of-year for the current fiscal year "
                })
            }   
        } catch (error) {
            console.log(error)
            notification.error({
                message:"Server Error",
                description:"Failed to set the end-of-year for the current fiscal year "
            })
        }
    }
  return (
    <Container>
      <Card>
        <div className="subscription-Txt2">End of Year Accounting</div>
        <br />
        <p>
          The End of Year process is a crucial step in maintaining accurate financial records
          and preparing for the upcoming fiscal year. This feature allows you to finalize
          and close out your accounting activities for the current year.
        </p>
        <p>
          <strong>Set End of Year:</strong> By clicking the "Set End of Year" button, you
          initiate the process of finalizing your financial data for the current year.
          This step may involve various activities such as closing books, generating
          year-end reports, and preparing for tax filings.
        </p>
        <Popconfirm
          title='Do you want to proceed with ending this accounting year?'
          onConfirm={handleEndOfYear}
        >
          <Button size="large" type='primary'> Set End of Year</Button>
        </Popconfirm>
      </Card>
    </Container>
  )
}

export default EndOfYear
