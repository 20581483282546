import "../../styles.scss";
import { Card } from "antd";
import { Container } from "react-bootstrap";
import BankTable from "../table";
import { useParams } from "react-router-dom";
import PageHeader from "../../../../../components/pageHeader";

function Reconcile(props: any) {
  const { status, id } = useParams();
  const columns = [
    {
      name: "date",
      title: "Date",
      dataType: "date",
      alignment: "center",
      format: "dd-MM-yyyy",
    },
    {
      name: "customer",
      title: "Particulars",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "reference",
      title: "Reference",
      dataType: "string",
      alignment: "center",
      cellRender: "Reference",
    },
    {
      name: "type",
      title: "Account Type",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "debit",
      title: "Debit",
      dataType: "number",
      alignment: "center",
    },
    {
      name: "credit",
      title: "Credit",
      dataType: "number",
      alignment: "center",
    },
  ];

  return (
    <>
      {status == "1" ? (
        <PageHeader
          firstPathLink={`/usr/cashBank/${id}/details/reconcile`}
          firstPathText={"Reconciliation"}
          title="Reconciliation Statement"
        />
      ) : (
        ""
      )}
      <Container>
        <br />
        <Card>
          {status == "1" ? (
            <div className="Reconcile-Txt1">RECONCILIATION STATEMENT</div>
          ) : (
            <div className="Reconcile-Txt1">RECONCILE TRANSACTIONS</div>
          )}
          <BankTable
            columns={columns}
            products={props?.data}
            onUpdate={(date: any, id: any) => props?.onUpdateDate(date, id)}
            onHandleDate={(date: any) => props?.onLoadData(date)}
            startDate={props.startDate}
            endDate={props.endDate}
            type="reconcile"
          />
        </Card>
      </Container>
    </>
  );
}
export default Reconcile;
