import { Col, Container, Row } from "react-bootstrap";
import restuarent from "../../assets/images/restuarent (2).png";
import healthcare from "../../assets/images/healthcare.png";
import financeimg from "../../assets/images/finance2.png";
import retail from "../../assets/images/retail.png";
import logistics from "../../assets/images/logistics.png";
import instructor from "../../assets/images/instructor.png";
import photo from "../../assets/images/photo.png";
import bussiness from "../../assets/images/bussiness.png";
import { withTranslation } from "react-i18next";
function TaxgoSystem(props:any) {
  const { t } = props;
  return (
    <div style={{ backgroundColor: "#f4f6f8" }}>
      <Container>
        {" "}
        <br />
        <br />
        <br />
        <br />
        <div className="TaxgoSystem-head1">
          {t("home_page.homepage.use_case")}
        </div>
        <br />
        <div className="TaxgoSystem-text1">
          {t("home_page.homepage.use_case_body1")}
          <br />
          {t("home_page.homepage.use_case_body2")}
          <br />
          {t("home_page.homepage.use_case_body3")}
        </div>
        </Container>

        <div className="TaxgoSystem-coverDiv">
        <Row className="d-flex justify-content-center gy-3 m-0">
          <Col style={{padding:"0px"}} lg={2} md={4} sm={4} xs={12} className="order-4 order-lg-1">
            <div className="TaxgoSystem-imgDiv1">
              <img className="TaxgoSystem-Card2" src={restuarent} alt="img"/>
            </div>
          </Col>
          <Col style={{padding:"0px"}} lg={2} md={4} sm={4} xs={12} className="order-3 order-lg-2 order-1 order-sm-3">
          <div className="TaxgoSystem-CardDiv">
          <div className="TaxgoSystem-imgDiv">
              <img className="TaxgoSystem-Card" src={healthcare} alt="img"/>
            </div>
            <div className="TaxgoSystem-imgDiv">
              <img className="TaxgoSystem-Card" src={financeimg} alt="img" />
            </div>
            </div>
          </Col>
          <Col style={{padding:"0px"}} lg={3} md={4} sm={4} xs={12}  className="order-2 order-lg-3">
          <div className="TaxgoSystem-CardDiv">
          <div className="TaxgoSystem-imgDiv2">
              <img className="TaxgoSystem-Card1" src={retail} alt="img"/>
            </div>
            <div className="TaxgoSystem-imgDiv2">
              <img className="TaxgoSystem-Card1" src={logistics} alt="img"/>
            </div>
            </div>
          </Col>
          <Col style={{padding:"0px"}} lg={2} md={4} sm={4} xs={12} className="order-1 order-lg-4 order-3 order-sm-1">
          <div className="TaxgoSystem-CardDiv">
          <div className="TaxgoSystem-imgDiv">
              <img className="TaxgoSystem-Card" src={instructor} alt="img"/>
            </div>
            <div className="TaxgoSystem-imgDiv">
              <img className="TaxgoSystem-Card" src={photo} />
            </div>
            </div>
          </Col>
          <Col style={{padding:"0px"}} lg={2} md={4} sm={4} xs={12}  className="order-5">
          <div className="TaxgoSystem-imgDiv1">
              <img className="TaxgoSystem-Card2" src={bussiness} alt="img"/>
            </div>
          </Col>
        </Row>
        </div> 
    </div>
  );
}

export default withTranslation()(TaxgoSystem);
