import WebsiteHeader from "../../components/websiteHeader";
import WebsiteFooter from "../../components/websiteFooter";
import "../consulting/style.scss";
import { Container, Row, Col } from "react-bootstrap";
import ladyimg from "../../assets/images/smiling-confident-businesswoman-posing-with-arms-folded 1.png";
import rectangle from "../../assets/images/Rectangle 67.png";
import CardsPage from "./component/CardsPage";
import ConsultingForm from "./component/ConsultingForm";
import { withTranslation } from "react-i18next";

function Consulting(props:any) {
  const { t } = props;
  return (
    <>
    <div className="Consulting-componentBg">
      <WebsiteHeader />
        <Container>
          <Row>
            <Col lg={6} xs={12}>
              <div className="Consulting-ladyImgDiv">
                <img className="Consulting-ladyImg" src={ladyimg} />
              </div>
            </Col>
            <Col lg={6} xs={12}>
              <div className="Consulting-mainTextDiv">
                <div className="Consulting-Header1">{t("home_page.homepage.Accounting_consulting_head")}</div>
                <div className="Consulting-miniText">
                {t("home_page.homepage.Accounting_consulting_body")}
                </div>
                <div className="Consulting-Header1 Consulting-Header2">
                {t("home_page.homepage.what_we_do_head")}
                </div>
                <div>
                  <div className="Consulting-miniText">
                    <div style={{ paddingRight: "15px" }}>
                      <img src={rectangle} />
                    </div>{" "}
                    {t("home_page.homepage.what_we_do_body1")}
                  </div>
                  <div className="Consulting-miniText">
                    <div style={{ paddingRight: "15px" }}>
                      <img src={rectangle} />
                    </div>{" "}
                    {t("home_page.homepage.what_we_do_body2")}
                  </div>
                  <div className="Consulting-miniText">
                    <div style={{ paddingRight: "15px" }}>
                      <img src={rectangle} />
                    </div>{" "}
                    {t("home_page.homepage.what_we_do_body3")}
                  </div>
                  <div className="Consulting-miniText">
                    <div style={{ paddingRight: "15px" }}>
                      <img src={rectangle} />
                    </div>
                    {t("home_page.homepage.what_we_do_body4")}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <CardsPage />
      <ConsultingForm />
      <WebsiteFooter />
    </>
  );
}

export default withTranslation()(Consulting);
