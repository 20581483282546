import React from "react";
import { Card, Tag } from "antd";
import { Container } from "react-bootstrap";
import "./styles.scss";
import moment from "moment";

const CommentsDataTable = (props: any) => {
  console.log("props==>", props);

  return (
    <Container>
      <br />
      {props?.dataSource?.map((comment: any, index: any) => (
        <div className="comment-item">
          <div className="comment-user">
            {comment?.employee?.fullName.slice(0, 1)}
          </div>
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="comment-text">{comment.text}</div>
            <div className="comment-text2">
              {moment(comment.createdAt).format("lll")}
            </div>
          </div>
        </div>
      ))}
    </Container>
  );
};

export default CommentsDataTable;
