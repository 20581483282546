import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  message,
  notification,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaInbox } from "react-icons/fa";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingBox from "../../../../../../components/loadingBox";
import API from "../../../../../../config/api";
import { GET, POST, PUT } from "../../../../../../utils/apiCalls";
import RecieptTable from "../component/recieptTable";
import PageHeader from "../../../../../../components/pageHeader";

function CustomerReciept() {
  const { user } = useSelector((state: any) => state.User);
  const [form] = Form.useForm();
  const { Option } = Select;
  const { id, update } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [customerId, setCustomerId] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState<any>();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [customerSerch, setCustomerSerch] = useState("");
  const [saleId, setSaleId] = useState("");
  const [ledgerDeatails, setLedgerDeatails] = useState<any>({});
  const [routAmount, setRoutAmount] = useState<any>();

  useEffect(() => {
    customerList();
    legderDetails();
  }, []);
  
  const legderDetails = async () => {
    let url = API.LEDGER_DEATAILS + update;
    try {
      const data: any = await GET(url, null);
      let edit = data?.data;
      setEdit(edit?.id);
      setSaleId(edit?.saleid);
      handleSelectCustomer(edit?.cname);
      setLedgerDeatails(data?.data);
      form.setFieldsValue({
        custmore_name: edit?.cname,
        reciept_date: dayjs(edit?.sdate),
        reference: edit?.reference,
        amount_paid: Number(edit?.debit),
        payment_mode: edit?.paidmethod,
      });
    } catch (err) {
      console.log(err);
    }
  };

  let paymentMode = [
    { id: 1, value: "Cheque", name: "Cheque" },
    { id: 2, value: "Electronic", name: "Electronic" },
    { id: 3, value: "credit_card", name: "Credit Card" },
    { id: 4, value: "Debit_card", name: "Debit Card" },
    { id: 5, value: "PayPal", name: "PayPal" },
  ];

  const customerList = async () => {
    try {
      form.setFieldsValue({
        sdate: moment(new Date()),
      });
      let URL =
        "contactMaster/searchList/both/" +
        user.adminid +
        `?name=${customerSerch}`;
      const data: any = await GET(URL, null);
      setCustomer(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    customerList();
  }, [customerSerch]);

  const handleSelectCustomer = async (val: any) => {
    setCustomerId(val);
    let url = API.GET_SALE_INVOICE_BY_ID + `${val}/` + user.adminid;
    try {
      const data: any = await GET(url, null);
      if (data.status === true) {
        let result = data?.data?.filter(
          (data: any) => data?.rout !== "0.00" && data?.rout !== 0
        );
        setSalesData(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectedData = (val: any) => {
    setLoad(true);
    if (val) {
      setSelectedRow(val);
      setLoad(false);

      const totalOutstanding = val
        ?.map((item: any) => parseFloat(item?.rout))
        .filter((value: number) => !isNaN(value))
        .reduce((sum: number, value: number) => sum + value, 0);
      // const roundedTotal = Math.round(totalSum);
      setRoutAmount(Number(totalOutstanding) || 0);

      form.setFieldsValue({
        amount_paid: Number(totalOutstanding) || 0,
        reference: val?.reference,
      });
    }
  };
  const onValuesChange = (val: any) => {
    if (Number(val.amount_paid) > Number(routAmount)) {
      notification.error({
        message: "You cannot pay more than the outstanding amount.",
      });
      form.setFieldsValue({
        amount: Number(routAmount),
      });
    }
  };

  const onFinish = async (val: any) => {
    setIsLoading(true);
    let url = update
      ? API.UPDATE_BANK_DETAILS + edit
      : "SaleInvoice/addCustReceiptCash";
    let rowData;
    let outstandingss;
    if (selectedRow.length) {
      rowData = selectedRow?.map((row: any) => {
        let remainout = Number(row.rout) - Number(val.amount_paid);
        outstandingss = Number(row.total) - Number(val.amount_paid);
        return {
          ...row,
          amountpaid: row?.duplicateout,
          remainout: remainout,
          outstanding: 0,
        };
      });
    } else {
      outstandingss = Number(ledgerDeatails?.amount) - Number(val.amount_paid);
    }
    let reqObjPost = {
      userid: user?.adminid,
      item: rowData,
      amount: val?.amount_paid?.toString(),
      cname: val.custmore_name,
      paidto: Number(id),
      paidmethod: val?.payment_mode,
      sdate: dayjs(val?.reciept_date),
      reference: val?.reference,
      receipttype: "Customer Receipt",
      adminid: user.adminid,
      logintype: "user",
      userdate: val?.reciept_date,
      customer_name: selectedRow[0]?.cname || selectedRow[0]?.sname,
    };
    const amt = Number(ledgerDeatails?.credit) - Number(val?.amount_paid);
    let running = ledgerDeatails?.running_total - amt;
    let reqObjPut = {
      cname: val?.custmore_name?.toString(),
      reference: val?.reference,
      debit: Number(val?.amount_paid),
      sdate: val?.reciept_date,
      outstanding: outstandingss,
      saleid: rowData ? rowData[0]?.id : null,
      rout: rowData ? rowData[0]?.rout : null,
      paidmethod: val?.payment_mode,
      running_total: running,
    };
    try {
      let data: any;
      if (update || edit) {
        data = await PUT(url, reqObjPut);
      } else {
        data = await POST(url, reqObjPost);
      }
      if (data?.status) {
        notification.success({
          message:"Success" ,description: update ? "Customer receipt updated successfully" : "Customer receipt created successfully"
        });
        navigate(`/usr/cashBank/${id}/details/transaction`);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notification.error({
          message:"Failed" ,description: update ? "Failed to update customer receipt" : "Failed to create customer receipt"
        });
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      notification.error({
        message:"Server Error" ,description: update ? "Failed to update customer receipt!! Please try again later" : "Failed to create customer receipt!! Please try again later"
      });
    }
  };

  const column = [
    {
      name: "data",
      title: "Date",
      dataType: "date",
      alignment: "center",
      format: "dd-MM-yyyy",
    },
    {
      name: "invoiceno",
      title: "Invoiceno",
      dataType: "string",
      alignment: "center",
      cellRender: "Reference",
    },
    {
      name: "reference",
      title: "Reference",
      dataType: "string",
      alignment: "center",
      cellRender: "Reference",
    },
    {
      name: "type",
      title: "Type",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "total",
      title: "Total",
      dataType: "number",
      alignment: "center",
    },
    {
      name: "rout",
      title: "Out-Standing",
      dataType: "number",
      alignment: "center",
    },
  ];
  const selectedRowColumn = [
    {
      name: "data",
      title: "Date",
      dataType: "date",
      alignment: "center",
      format: "dd-MM-yyyy",
    },
    {
      name: "reference",
      title: "Reference",
      dataType: "string",
      alignment: "center",
      cellRender: "Reference",
    },
    {
      name: "type",
      title: "Type",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "total",
      title: "Total",
      dataType: "number",
      alignment: "center",
    },
    {
      name: "rout",
      title: "Out-Standing",
      dataType: "number",
      alignment: "center",
    },
  ];
  return (
    <>
      {update && (
        <>
          <PageHeader
            title="Payment"
            firstPathLink={"/usr/cashBank"}
            firstPathText={"Bank"}
            secondPathLink={`/usr/cashBank/${id}/details`}
            secondPathText={"Bank Details"}
            thirdPathLink={`/usr/cashBank/${id}/details/reciept/customer`}
            thirdPathText={"payment"}
            // goback={() => navigate(`/usr/cashBank/${id}/details/transaction`)}
          />
          <br />
        </>
      )}
      <Container>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <>
            <Card>
              <Col className="Table-Txt" md={12}>
                Add/Update Customer Receipt
              </Col>
              <Col md={12}>
                Manage your non invoice payments to customer with ledgers
              </Col>
              <br />
              <hr />
              <Form
                onFinish={onFinish}
                form={form}
                onValuesChange={onValuesChange}
              >
                <Row>
                  <Col md={4}>
                    <label className="formLabel">Customer name</label>
                    <Form.Item
                      name={"custmore_name"}
                      rules={[{ required: true }]}
                    >
                      <Select
                        onChange={handleSelectCustomer}
                        allowClear
                        onSearch={(val) => setCustomerSerch(val)}
                        showSearch
                        filterOption={false}
                      >
                        {customer?.map((item: any) => (
                          <Select.Option key={item.id}>
                            {item?.bus_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <label className="formLabel">Payment Method</label>
                    <Form.Item
                      name={"payment_mode"}
                      rules={[{ required: true }]}
                    >
                      <Select>
                        {paymentMode?.map((item: any) => (
                          <Option key={item.value}>{item.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <label className="formLabel">Reciept Date</label>
                    <Form.Item name={"reciept_date"}>
                      <DatePicker
                        style={{ width: "100%" }}
                        format={"YYYY-MM-DD"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={4}>
                    <label className="formLabel">Reference</label>
                    <Form.Item name={"reference"}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <label className="formLabel">Amount Paid</label>
                    <Form.Item
                      name={"amount_paid"}
                      rules={[{ required: true }]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                </Row>
                {selectedRow && selectedRow?.length > 0 && !update && (
                  <>
                    {load ? (
                      <LoadingBox />
                    ) : (
                      <>
                        <RecieptTable
                          tableHead={"Select Customer Receipts and Invoices"}
                          products={selectedRow}
                          columns={selectedRowColumn}
                          type="sales_reciept_amount"
                        />
                        <br />
                        {selectedRow?.length > 0 && (
                          <Col md={{ span: 3, offset: 9 }}>
                            <Button
                              block
                              type="primary"
                              htmlType="submit"
                              loading={isLoading}
                            >
                              Submit
                            </Button>
                          </Col>
                        )}
                      </>
                    )}
                  </>
                )}
                {update ? (
                  <Col md={{ span: 3, offset: 9 }}>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                ) : null}
              </Form>
              {salesData?.length > 0 ? null : (
                <>
                  <br />
                  <hr />
                  <br />
                  <div className="salesReciept-Box1">
                    <div>
                      <FaInbox color="grey" size={54} />
                    </div>
                    <div className="salesReciept-Txt1">
                      Please Choose Different Customer, No Receipts or Invoice
                      Available
                    </div>
                  </div>
                </>
              )}
            </Card>

            <br />
            {salesData?.length && salesData?.length > 0 ? (
              <Card>
                <RecieptTable
                  products={salesData}
                  columns={column}
                  tableHead={"Select Customer Receipts and Invoices"}
                  onSelectedData={(data: any) => handleSelectedData(data)}
                  type={"sales-reciept"}
                  saleId={saleId}
                />
              </Card>
            ) : null}
          </>
        )}
      </Container>
    </>
  );
}

export default CustomerReciept;
