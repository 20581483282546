import { Col, Container, Row, Table } from "react-bootstrap";
import PageHeader from "../../../components/pageHeader";
import {
  Card,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  notification,
} from "antd";

import Items from "../components/items";
import { GET, PUT } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../../../components/loadingBox";
import { GoPlus } from "react-icons/go";
import CreateCutomerModal from "../../../components/contactCreateModal";

function Edit(props: any) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const { customers, products } = props;
  const [subTotal, setSubTotal] = useState(0);
  const [totalVat, setTatalVat] = useState(0);
  const [roundOff, setRoundOff] = useState(0);
  const [overollDiscount, setOverolDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [qSuffix, setqSuffix] = useState("");
  const [pStock, setPStock] = useState(0);
  const [isFullLoading, setIsFullLoading] = useState(false);
  const [details, setDetails] = useState({}) as any;
  const [defaltOutStanding, setDefaltOutStanding] = useState(0);
  const [isPaymentInfo, setIsPaymentInfo] = useState<any>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerCreate, setCustomerCreate] = useState(false);

  useEffect(() => {
    getInvoiceDetails();
    form.setFieldsValue({
      terms: user?.companyInfo?.defaultTerms,
      quotes: user?.companyInfo?.cusNotes,
    });
  }, []);

  const onFinish = async (val: any) => {
    let amountPaid = Number(val.amoutToPaid) || 0;
    let totalPayable = totalAmount;
    let outstanding = totalPayable - defaltOutStanding - amountPaid;
    let status = "0";
    if (outstanding <= 0) {
      status = "2"; //paid
    } else if (outstanding < totalPayable) {
      status = "1"; //part Paid
    } else if (outstanding >= totalPayable) {
      status = "0"; //unpaid
    }
    setIsLoading(true);
    let paymentInfo = isPaymentInfo;
    if (isPaymentInfo) {
      paymentInfo = {
        id: val.paymentBank,
        bankid: val.paymentBank,
        outstanding: val.outStanding,
        amount: amountPaid,
        date: val?.paymentDate,
        type: val.paymentMethod,
        paidmethod: val.paymentMethod,
      };
    }
    try {
      let selectedCustomer =
        customers &&
        customers?.length &&
        customers?.find((item: any) => item.id === val.customerid);

      let ledger =
        props.ledgers &&
        props.ledgers?.length &&
        props?.ledgers?.find((item: any) => item.id === val.ledger);

      let column = val.columns.map((item: any) => {
        let foundedProduct = products.find(
          (product: any) => product.id === item.id
        );
        let productLedger = {};
        if (foundedProduct?.itemtype === "Stock") {
          productLedger = {
            category: "13",
            id: 1,
            laccount: "Sales-Products",
            nominalcode: "4000",
          };
        } else if (foundedProduct?.itemtype === " Service") {
          productLedger = {
            category: "13",
            id: 2,
            laccount: "Sales-Services",
            nominalcode: "4001",
          };
        }
        return {
          id: item?.id,
          discount: item?.discount,
          discountamt: item?.discountamt,
          productId: item?.id,
          product: foundedProduct,
          idescription: foundedProduct?.idescription,
          description: foundedProduct?.idescription,
          vat: item?.vat,
          incomeTax: item?.vat,
          includevat: item.includeVat,
          percentage: item?.vat,
          costprice: item?.price,
          quantity: item.quantity,
          total: item?.total,
          vatamt: item?.vat,
          vatamount: item?.vatamt,
          incomeTaxAmount: item?.vatamt,
          itemorder: 1,
        };
      });
      let payload = {
        cname: selectedCustomer?.name,
        customerid: val?.customerid,
        columns: column,
        invoiceno: val?.invoiceno,
        sdate: val?.sdate,
        ldate: val?.ldate,
        inaddress: val?.inaddress,
        deladdress: val?.deladdress,
        terms: val?.terms,
        quotes: val?.quotes,
        issued: "yes",
        type: "proforma",
        status: "0",
        pagetype: "1",
        total: Number(totalAmount).toFixed(2),
        userid: adminid,
        adminid: adminid,
        userdate: new Date(),
        paymentInfo: false,
        reference: val?.reference,
        salesType: "",
        ledger: ledger,
        email: user.email,
        outstanding,
      };
      let salesUrl = API.UPDATE_SALES + props?.id;
      const response: any = await PUT(salesUrl, payload);
      if (response.status) {
        notification.success({ message: "proforma updated successfully" });
        form.resetFields();
        navigate(-1);
        setIsLoading(false);
      } else {
        notification.error({ message: "Failed to update proforma invoice!!" });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Server Error:Failed to update proforma invoice!!",
      });
      setIsLoading(false);
    }
  };

  function containsNull(arr: any) {
    let isThereNull = false;
    for (let i = 0; i < arr?.length; i++) {
      const element = arr[i];
      if (element === undefined) {
        isThereNull = true;
      }
    }
    return isThereNull;
  }

  const onValuesChange = (column: any, allarray: any) => {
    try {
      if (column?.columns?.length < 1) {
        setSubTotal(0);
        setTatalVat(0);
        setOverolDiscount(0);
        setRoundOff(0);
        setTotalAmount(0);
      }
      if (column.customerid) {
        props.getProduct("non-assets");
      }
      if (allarray?.columns && allarray?.columns?.length) {
        let _subTotal = 0;
        let _tatalVat = 0;
        let _overollDiscount = 0;
        const updatedColumns = allarray?.columns?.map(
          (item: any, index: any) => {
            if (column?.columns?.length > 1) {
              if (containsNull(column.columns)) {
                let productId = column.columns[index]?.id || null;
                if (productId) {
                  let array = allarray?.columns;
                  array[index].price = null;
                  array[index].vat = null;
                  array[index].discount = null;
                  array[index].discountamt = null;
                  array[index].quantity = 1;
                  if (array?.length - 1 === index) {
                    array[index].includeVat = null;
                  }
                  form.setFieldsValue({ columns: array });
                }
              }
            } else {
              let productId = column.columns[index]?.id || null;
              if (productId) {
                let array = allarray?.columns;
                array[index].price = null;
                array[index].vat = null;
                array[index].discount = null;
                array[index].discountamt = null;
                array[index].includeVat = null;
                array[index].quantity = 1;
                form.setFieldsValue({ columns: array });
              }
            }
            if (item && item.id) {
              let foundProduct = products.find(
                (product: any) => Number(product?.id) === Number(item.id)
              );
              let columnDiscountAmt = Number(
                column?.columns[index]?.discountamt
              );
              let itemDiscountAmt = Number(item?.discountamt);
              let discountAmount: any =
                Number.isNaN(columnDiscountAmt) && Number.isNaN(itemDiscountAmt)
                  ? 0
                  : columnDiscountAmt || itemDiscountAmt || 0;

              let columnDiscount = Number(column?.columns[index]?.discount);
              let itemDiscount = Number(item?.discount);
              let discount: any =
                Number.isNaN(columnDiscount) && Number.isNaN(itemDiscount)
                  ? 0
                  : columnDiscount || itemDiscount || 0;

              setqSuffix(foundProduct.unit);
              setPStock(foundProduct.stock);
              let quantity =
                column?.columns[index]?.quantity === undefined
                  ? item.quantity || 1
                  : column?.columns[index].quantity;
              let price =
                item?.price === undefined || item?.price === null
                  ? Number(foundProduct?.rate)
                  : item?.price;
              let total = price * quantity;

              let vatPercent =
                item?.vat === undefined ||
                item?.vat === null ||
                item?.vat === ""
                  ? Number(foundProduct.vat)
                  : Number(item.vat);
              let vatAmount = Number(foundProduct.vatamt);

              if (
                column?.columns[index]?.id === undefined ||
                column?.columns[index]?.includeVat !== undefined ||
                column?.columns[index]?.vat !== undefined ||
                column?.columns[index]?.quantity !== undefined ||
                column?.columns[index]?.discount !== undefined ||
                column?.columns[index]?.discountamt !== undefined ||
                column?.columns[index]?.price !== undefined
              ) {
                vatAmount = (price * quantity * vatPercent) / 100;
              }

              let includeVat;
              const selectedIncludeVat = column?.columns[index]?.includeVat;
              if (selectedIncludeVat === undefined) {
                if (foundProduct.includevat === "1.00") {
                  includeVat = item.includeVat === false ? false : true || true;
                } else {
                  includeVat = item.includeVat || false;
                }
              } else {
                includeVat = selectedIncludeVat;
              }
              if (includeVat) {
                let totalItemsRate = price * quantity;
                vatAmount = (totalItemsRate / (100 + vatPercent)) * 100;
                vatAmount = Number((price * quantity - vatAmount).toFixed(2));
                total = price * quantity;
              } else {
                total = Number((price * quantity + vatAmount).toFixed(2));
              }
              if (column?.columns[index]?.discount > 0) {
                const discountRate = Number(item.discount) / 100;
                discountAmount = total * discountRate;
                discount = Number(item.discount);
                if (column?.columns[index]?.discount > 100) {
                  let disRate = 100 / 100;
                  discountAmount = total * disRate;
                  discount = 100;
                  notification.error({
                    message:
                      "Discount cannot exceed the total amount of the invoice.",
                  });
                }
              } else if (
                column?.columns[index]?.discount == null ||
                column?.columns[index]?.discount == 0 ||
                column?.columns[index]?.discount == "" ||
                column?.columns[index]?.discount === undefined
              ) {
                if (item?.discountamt > 0) {
                  const discountpecentage =
                    (Number(item?.discountamt) / total) * 100;
                  discountAmount = Number(item?.discountamt);
                  discount = Number(discountpecentage);
                } else {
                  discountAmount = 0;
                }
              }
              if (0 >= column?.columns[index]?.discountamt) {
                discount = 0;
              }

              if (column?.columns[index]?.discountamt > 0) {
                const discountpecentage =
                  (Number(item?.discountamt) / total) * 100;
                discountAmount = Number(item?.discountamt);
                discount = Number(discountpecentage.toFixed(2));
                if (column?.columns[index]?.discountamt >= total) {
                  let disRate = 100 / 100;
                  discountAmount = total * disRate;
                  discount = 100;
                  notification.error({
                    message:
                      "Discount cannot exceed the total amount of the invoice.",
                  });
                }
              } else if (column?.columns[index]?.discountamt === "") {
                discount = "";
              }
              //here total calculation
              if (includeVat) {
                _subTotal = price * quantity - vatAmount + _subTotal;
              } else {
                _subTotal = price * quantity + _subTotal;
              }
              _tatalVat = _tatalVat + vatAmount;
              _overollDiscount = _overollDiscount + discountAmount;

              if (selectedIncludeVat === undefined) {
                //here we check if they not select incule vat now
                if (foundProduct.includevat === "1.00") {
                  includeVat = item.includeVat === false ? false : true || true;
                } else {
                  includeVat = item.includeVat || false;
                }
              } else {
                includeVat = selectedIncludeVat;
              }

              // Use 'includeVat' as needed in your code

              const updatedColumn = {
                id: item.id,
                quantity:
                  column?.columns[index]?.quantity === undefined
                    ? quantity
                    : column?.columns[index]?.quantity?.length == "0"
                    ? null
                    : item.quantity,
                price:
                  column?.columns[index]?.price === undefined
                    ? price
                    : column?.columns[index]?.price.length == "0"
                    ? null
                    : column?.columns[index]?.price,
                incomeTaxAmount: foundProduct?.vatamt,
                vatamt: foundProduct?.vatamt,
                description: foundProduct?.idescription,
                vat:
                  item?.vat === undefined || item?.vat === null
                    ? foundProduct?.vat
                    : item?.vat,
                vatamount: vatAmount.toFixed(2),
                discountamt: discountAmount,
                discount: discount,
                total: total - discountAmount,
                includeVat,
              };
              return updatedColumn;
            } else {
              let newColumn = {
                id: null,
                quantity: null,
                price: null,
                incomeTaxAmount: null,
                vatamt: null,
                description: null,
                vat: null,
                vatamount: null,
                discountamt: null,
                discount: null,
                total: null,
                includeVat: null,
              };
              return newColumn;
            }
          }
        );
        if (updatedColumns.length) {
          form.setFieldsValue({ columns: updatedColumns });
        }
        setSubTotal(_subTotal);
        setTatalVat(_tatalVat);
        setOverolDiscount(_overollDiscount);
        let _totalAmount = _subTotal + _tatalVat - _overollDiscount;
        let roundedNumber = Math.round(_totalAmount);
        let amountAdded = roundedNumber - _totalAmount;
        // setRoundOff(Number(amountAdded.toFixed(2)));
        //setTotalAmount(Number(roundedNumber.toFixed(2)));
        setTotalAmount(_totalAmount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getInvoiceDetails = async () => {
    setIsFullLoading(true);
    try {
      let url = API.VIEW_SALE_INVOICE + props?.id + "/proforma";
      const getInvDetails: any = await GET(url, null);
      if (getInvDetails.status) {
        setDetails(getInvDetails?.data);
        let initialValus = getInitialValues(getInvDetails?.data);
        form.setFieldsValue(initialValus);
        setIsFullLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsFullLoading(false);
    }
  };

  const getInitialValues = (data: any) => {
    try {
      props.getProduct("non-assets");
      let _subTotal = 0;
      let _tatalVat = 0;
      let _overollDiscount = data.invoiceItems.reduce(
        (acc: any, sum: any) => acc + Number(sum.discount_amount),
        0
      );
      let columns = data.invoiceItems.map((item: any) => {
        let vatPercent = Number(item.vat);
        let vatAmount = Number(item.vatamt);

        if (item.includevat == 1) {
          _subTotal =
            Number(item.costprice) * Number(item.quantity) -
            vatAmount +
            _subTotal;
        } else {
          _subTotal =
            Number(item.costprice) * Number(item.quantity) + _subTotal;
        }
        // if (item.discount > 0) {
        //   const discountRate = item.discount / 100;
        //   discountAmount =
        //     item.includevat == 1
        //       ? Number(item.costprice) * Number(item.quantity) * discountRate
        //       : (Number(item.costprice) * Number(item.quantity) + vatAmount) *
        //         discountRate;
        // }
        //here total calculation

        _tatalVat = _tatalVat + vatAmount;
        return {
          id: item?.product?.id,
          quantity: Number(item.quantity),
          price: Number(item.costprice),
          vatamt: item.vatamt,
          description: item.product.idescription,
          vat: item.vat,
          vatamount: item.vatamt,
          discount: item.discount,
          discountamt: item.discount_amount,
          total: item.total,
          includeVat: item.includevat == 1 ? true : false,
        };
      });

      const initialValue = {
        invoiceno: data.invoiceDetails.invoiceno,
        customerid: data.invoiceDetails.customerid,
        ledger: 1,
        sdate: dayjs(data?.invoiceDetails?.sdate),
        ldate: dayjs(data?.invoiceDetails?.ldate),
        reference: data?.invoiceDetails?.reference,
        columns: columns,
        inaddress: data?.invoiceDetails?.inaddress,
        deladdress: data?.invoiceDetails?.deladdress,
        terms: data?.invoiceDetails?.terms,
        quotes: data?.invoiceDetails?.quotes,
      };
      setSubTotal(_subTotal);
      setTatalVat(Number(_tatalVat.toFixed(2)));
      // setRoundOff(Number(data?.invoiceDetails?.roundOff) || 0);
      setOverolDiscount(Number(_overollDiscount.toFixed(2)));
      let _totalAmount =
        _subTotal +
        _tatalVat -
        //   + Number(data?.invoiceDetails?.roundOff)
        _overollDiscount;
      setTotalAmount(Number(_totalAmount.toFixed(2)));
      return initialValue;
    } catch (error) {
      return {};
    }
  };
  return (
    <div>
      <PageHeader
        title="Edit Proforma Invoice"
        goBack={"/dashboard"}
        secondPathText="Proforma Invoice"
        secondPathLink={"/usr/sales-proforma-invoice"}
        thirdPathText={"Edit Proforma Invoice"}
        thirdPathLink={`/usr/proforma-invoice-form/${1}/`}
      />
      <br />
      <Container>
        <Card>
          {isFullLoading ? (
            <LoadingBox />
          ) : (
            <Form
              form={form}
              onFinish={onFinish}
              onValuesChange={onValuesChange}
            >
              <Row>
                <Col sm={3}>
                  <div className="formLabel">Invoice No.</div>
                  <Form.Item name={"invoiceno"}>
                    <Input readOnly size="large" />
                  </Form.Item>
                </Col>
                <Col sm={3}>
                  <div className="formLabel">Customer Name</div>
                  <Form.Item
                    name={"customerid"}
                    rules={[{ required: true, message: "choose customer" }]}
                  >
                    <Select
                      size="large"
                      showSearch
                      onSearch={(val: any) => props?.customerName(val)}
                      onChange={(val: any) => {
                        let selectCustomers = customers?.find(
                          (item: any) => item.id === val
                        );
                        form.setFieldsValue({
                          inaddress: selectCustomers?.address,
                          deladdress: selectCustomers?.address,
                        });
                      }}
                    >
                      {customers &&
                        customers?.length &&
                        customers?.map((item: any) => {
                          return (
                            <Select.Option key={item.id} value={item.id}>
                              {item.bus_name}
                            </Select.Option>
                          );
                        })}
                      <Select.Option key="addButton" value="addButton">
                        <Button
                          type="primary"
                          block
                          onClick={() => setCustomerCreate(true)}
                        >
                          <GoPlus /> Add New
                        </Button>
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={3}>
                  <div className="formLabel">Invoice Date</div>
                  <Form.Item name={"sdate"}>
                    <DatePicker style={{ width: "100%" }} size="large" />
                  </Form.Item>
                </Col>
                <Col sm={3}>
                  <div className="formLabel">Reference</div>
                  <Form.Item name={"reference"}>
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Items
                form={form}
                products={products}
                qSuffix={qSuffix}
                // stock={pStock}
                taxLists={props.taxList}
                // isCheckChange={(val: any) => setIsCheck(val)}
              />
              <br />
              <Row>
                <Col sm={3}>
                  <div className="formLabel">Invoice Address</div>
                  <Form.Item name={"inaddress"}>
                    <Input.TextArea rows={4} size="large" />
                  </Form.Item>
                </Col>
                <Col sm={3}>
                  <div className="formLabel">Delivery Address</div>
                  <Form.Item name={"deladdress"}>
                    <Input.TextArea rows={4} size="large" />
                  </Form.Item>
                </Col>
                <Col sm={3}>
                  <div className="formLabel">Terms and Conditions</div>
                  <Form.Item name={"terms"}>
                    <Input.TextArea rows={4} size="large" />
                  </Form.Item>
                </Col>
                <Col sm={3}>
                  <div className="formLabel">Note</div>
                  <Form.Item name={"quotes"}>
                    <Input.TextArea rows={4} size="large" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  <Table bordered>
                    <tbody>
                      <tr>
                        <td>TAXABLE VALUE</td>
                        <td>{Number(subTotal).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>TOTAL VAT</td>
                        <td>{Number(totalVat).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>OVERALL DISCOUNT</td>
                        <td>{Number(overollDiscount).toFixed(2)}</td>
                      </tr>
                      {/* <tr>
                        <td>ROUND OFF</td>
                        <Input
                          className="p-1"
                          type="number"
                          bordered={false}
                          value={roundOff}
                          onChange={(e: any) => {
                            setRoundOff(e.target.value);
                            let round = Number(e.target.value);
                            let _totalAmount =
                              subTotal + totalVat - overollDiscount;
                            _totalAmount = _totalAmount - round;
                            setTotalAmount(_totalAmount);
                          }}
                        />
                      </tr> */}
                      <tr>
                        <td>TOTAL AMOUNT</td>
                        <td>{Number(totalAmount).toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Row>
                    <Col sm={6}>
                      <Button size="large" block onClick={() => navigate(-1)}>
                        Close
                      </Button>
                    </Col>
                    <Col sm={6}>
                      <Button
                        size="large"
                        block
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
        </Card>
      </Container>
      {customerCreate ? (
        <CreateCutomerModal
          open={customerCreate}
          onCancel={() => setCustomerCreate(false)}
          customerSearch={props.customerName}
          type="customer"
          customer={(val: any) =>
            form.setFieldsValue({ customerid: Number(val) })
          }
        />
      ) : null}
    </div>
  );
}
export default Edit;
