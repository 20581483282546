import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import API from "../../../config/api";
import { GET, POST, PUT } from "../../../utils/apiCalls";
import { Button, Form, Input, Modal, Select, Spin, notification } from "antd";

function FixedAssetModal(props:any) {
    const [isLoading,setIsLoading] = useState(false)
    const [ledgerCategoryList, setLedgerCategoryList] = useState([]);
    const [isInitialLoading,setIsInitialLoading] = useState(false)
    const {edit,setEdit,setIsOpen,isOpen,loadData,page,take} = props
    const [form] = Form.useForm();
    const { user } = useSelector((state: any) => state.User);

    const businessStartDate: any = user?.companyInfo?.books_begining_from
    ? user?.companyInfo?.books_begining_from
    : user?.companyInfo?.financial_year_start;

    useEffect(() => {
        ledgerCategoryDetails();
      }, []);
    
      const ledgerCategoryDetails = async () => {
        try {
          let url = API.GET_FIXED_ASSET_LEDJERS + user.adminid;
          const response: any = await GET(url, null);
          setLedgerCategoryList(response.data);
        } catch {}
      };

    const checkIfItemExist = async (type: any, item: any) => {
        if (item.length > 2) {
          let url = API.CHECK_IF_EXIST + `${user.adminid}/${type}/${item}`;
          const res: any = await GET(url, null);
          if (res.status) {
            notification.error({message:'Asset Already Exists'});
          }
        }
      };
    
    
    
      const onFinish = async (val: any) => {
        try {
            setIsLoading(true)
          let reqObj = {
            idescription: val.idescription,
            saccount: Number(val.saccount) || 4000, //
            notes: val.notes, //
            userid: user.id,
            adminid: user.adminid,
    
            itemtype: "Asset",
            type: 'Asset',
    
            icode: val.icode || "",
            price: 0, //
            sp_price: val.sp_price || 0,
            c_price: Number(val.c_price) || 0, //
            trade_price: 0, //
            rate: val.sp_price || 0, //
            logintype: "user",
            paccount: 0,
            includevat: val.includevat === true ? 1 : 0,
            vat: Number(val.vat) || 0,
            vatamt: Number(val.vatamt) || 0,
            product_category: val.product_category,
            existingstock: false,
            costprice: val.c_price || 0,
            wholesale: val.wholesale || 0,
            rlevel: val.rlevel || 0,
            quantity: val.quantity || 0,
            stockquantity: val.stockquantity || 0,
            rquantity: val.rquantity || 0,
            stock: val.quantity || 0,
            date: businessStartDate,
            unit: '',
            location: '',
            barcode:'',
            pimage: '',
          };
      
      
          let URL = edit === "create" ? API.ADD_PRODUCT : API.GET_PRODUCT_UPDATE + edit;
          const response: any = edit === "create" ? await POST(URL, reqObj) : await PUT(URL, reqObj);
          if (response.status) {
            notification.success({
              message:"Success",description: edit === "create" ? 'Fixed Asset Created Successfully' : "Fixed Asset Updated Successfully",
            });
            setIsOpen(false)
            edit !== "create" && setEdit("create")
            loadData && loadData(page,take)
            form.setFieldsValue({
              idescription: '',
              saccount: '',
              notes:'',
            });
          } else {
            notification.error({
              message:"Failed", description:edit === "create" ? "Faild to create fixed asset" : "Failed to update fixed asset ",
            });
           
          }
        } catch (error) {
          console.log(error)
          notification.error({
            message:"Server Error", description:edit === "create" ? "Faild to create fixed asset!! Please try again later" : "Failed to update fixed asset!! Please try again later ",
          });
        }finally{
          setIsLoading(false);
         
        }
      };
    
      const handleCancel = () =>{
        setIsOpen(false) 
        setEdit && setEdit('create')
        form.setFieldsValue({
          idescription: '',
          saccount: '',
          notes:'',
        });
      }
    
      const getLedger = async (val: any) => {
        try {
          let url = API.GET_FIXED_ASSET_LEDJERS + user.adminid;
          const response: any = await GET(url, null);
          const filteredLedgers = response.data.filter(
            (item: any) => item?.nominalcode == val
          );
          return filteredLedgers;
        } catch (error) {
          console.log(error);
        }
      };
    
      const loadProductById = async () => {
        try {
          setIsInitialLoading(true)
          let URL = API.GET_PRODUCT_MASTER_BY_ID + edit;
          const { data }: any = await GET(URL, null);
          let ledgerData = await getLedger(data?.saccount);
          form.setFieldsValue({
            idescription: data?.idescription,
            saccount: ledgerData[0]?.nominalcode,
            notes: data?.notes,
          });
          setIsLoading(false);
        } catch (error) {
          console.log(error)
        }finally{
            setIsInitialLoading(false)
        }
      };
      useEffect(() => {
        edit !== 'create' && loadProductById()
      }, [edit]);
  return (
    <Modal
    open={isOpen}
    onCancel={handleCancel}
    footer={false}
    closable={false}
    maskClosable={false} 
  >
    <div className="productAdd-Txt1 d-flex justify-content-between"> {edit === 'create' ? "Create Asset" : "Update Asset Information"}
         {isInitialLoading ? <Spin/> : null}
            </div>
    <Form
    onFinish={onFinish}
    form={form}
    >
    
    <div className="formItem">
      <label className="formLabel">Item Name</label>
      <Form.Item
        name="idescription"
        rules={[
          {
            required: true,
            message: "Please enter a Item Name",
          },
        ]}
      >
        <Input
          onChange={(e) =>
            checkIfItemExist(
              "idescription",
              e.target.value
            )
          }
        />
      </Form.Item>
    </div>
      <div className="formItem">
        <label className="formLabel">Ledger Category </label>
        <Form.Item
          name="saccount"
          rules={[
            {
              required: true,
              message: "Please enter a Ledger Category",
            },
          ]}
        >
          <Select
          size="large"
          >
            {ledgerCategoryList.length &&
              ledgerCategoryList.map((item: any) => (
                <Select.Option key={item.nominalcode}>
                  {item.laccount}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </div>
      <div className="formItem">
        <label className="formLabel">Notes</label>
        <Form.Item name="notes">
          <Input.TextArea rows={3} size="large"/>
        </Form.Item>
      </div>
      <div className="d-flex justify-content-end">
          <Button onClick={handleCancel} 
          size="large"
          style={{height:40, marginRight:5}}>
            Cancel
          </Button>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            style={{height:40}}
            size="large"
          >
            {edit === "create" ? "Submit" : "Update"}
          </Button>
          </div>
    </Form>
  </Modal>
  )
}

export default FixedAssetModal