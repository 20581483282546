import WebsiteHeader from "../../components/websiteHeader";
import WebsiteFooter from "../../components/websiteFooter";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./style.scss";
function Privacy() {
  const { t } = useTranslation();
  return (
    <>
      <WebsiteHeader />
      <div className="website-screens">
        <Container>
          <div className="Privacy-Policy">{t("home_page.homepage.Privacy_Policy")}</div>
          <div className="Privacy-Policy-desc">{t("home_page.homepage.Thank_you_for_visiting_our")}</div>
          <div className="Privacy-Policy-desc">{t("home_page.homepage.Note_the_privacy_practices")}</div>
          <div className="Privacy-Policy-taitil">{t("home_page.homepage.Collection_of_Information")}</div>
          <div className="Privacy-Policy-desc">{t("home_page.homepage.We_collect_personally")}</div>
          <div className="Privacy-Policy-taitil">{t("home_page.homepage.Cookie_Tracking_Technology")}</div>
          <div className="Privacy-Policy-desc">{t("home_page.homepage.The_Site_may_use_cookie")}</div>
          <div className="Privacy-Policy-taitil">{t("home_page.homepage.Distribution_of_Information")}</div>
          <div className="Privacy-Policy-desc">{t("home_page.homepage.We_may_share_information_with")}</div>
          <div className="Privacy-Policy-taitil">{t("home_page.homepage.Commitment_to_Data_Security")}</div>
          <div className="Privacy-Policy-desc">{t("home_page.homepage.Your_personally")}</div>
          <div className="Privacy-Policy-taitil">{t("home_page.homepage.Privacy_Contact_Information")}</div>
          <div className="Privacy-Policy-desc">{t("home_page.homepage.If_you_have_any")}</div>
          {/* <div>{t("home_page.homepage.LEGAL_INFORMATION")}</div>
          <div>{t("home_page.homepage.LEGAL_INFORMATION")}</div> */}
        </Container >
      </div>
      <br /><br />
      <WebsiteFooter />
    </>
  );
}

export default Privacy;
