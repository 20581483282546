import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

function PrintModal({ open, modalClose, template }: any) {
  const navigate = useNavigate();

  const handlePrint = () => {
    const printContent = document.createElement("div");
    printContent.innerHTML = template;

    const printWindow: any = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title></head><body>");
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const handleCancel = () => {
    modalClose(false);
    navigate(-1);
  };
  return (
    <Modal
      open={open}
      onOk={handlePrint}
      onCancel={handleCancel}
      width={"75%"}
      okText="Print"
      cancelText="Cancel"
    >
      <div dangerouslySetInnerHTML={{ __html: template }} />
    </Modal>
  );
}

export default PrintModal;
