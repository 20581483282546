import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import { Button, Form, notification } from "antd";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import moment from "moment";

import PickImage from "../../../components/imagePicker/components/pickImage";
import { COMPRESS_IMAGE } from "../../../utils/apiCalls";
// const update = "update";

const LogoPicker = (props: any) => {
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<any>({});
  const fileInputRef = useRef(null);

  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [aspectRatio, setAspectRatio] = useState(1 / 1);
  const [btnACtive,setBtnActive] = useState(true)



  const handler = async (e:any) => {
      
      let imageUrl :any;
      if (croppedImage) {
        setIsLoading(true)
        const ImageBlob = await fetch(croppedImage).then((r) => r.blob());
        let name = moment(new Date()).unix();
        let file = new File([ImageBlob], name + "N.jpg");
        imageUrl  = file;
        imageUrl = await COMPRESS_IMAGE(file);
        props.setImg(imageUrl?.Location)
      }
      props?.form?.setFieldsValue({ logo: imageUrl?.Location });

      props?.modalClose();
      setImage({});
      setIsLoading(false)
  };
  const handleCrop = async () => {
    if (cropperRef.current) {
      const canvas: HTMLCanvasElement | null =
        cropperRef.current.cropper.getCroppedCanvas();
      if (canvas) {
        const croppedData = canvas.toDataURL("image/jpeg");
        setCroppedImage(croppedData);
        setCropModalOpen(false);
        setBtnActive(false)
      }
    }
  };
  return (
    <Modal
      title={"Add Logo"}
      open={props?.open}
      okText="Add"
      centered
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={() => {
        props?.modalClose();
        setImage({});
      }}
    >
      {contextHolder}
        {cropModalOpen ? (
          <div className="mt-2">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                const selectedFile = e.target.files?.[0];
                if (selectedFile) {
                  setImage({
                    file: selectedFile,
                    url: URL.createObjectURL(selectedFile),
                  });
                  setCropModalOpen(true);
                }
              }}
            />
            <Cropper
              ref={cropperRef as React.RefObject<ReactCropperElement>}
              src={image?.url}
              style={{ height: 400, width: "100%" }}
              aspectRatio={aspectRatio}
              guides={true}
            />
            <div style={{ textAlign: "center", marginTop: 16 }}>
              <Button type="primary" onClick={handleCrop}>
                Crop Image
              </Button>
              <Button
                style={{ marginLeft: 16 }}
                onClick={() => {
                  if (fileInputRef.current) {
                    (fileInputRef.current as any).click();
                  }
                }}
              >
                Choose Another Logo
              </Button>
            </div>
          </div>
        ) : (
          <Form.Item
            name="image"
            rules={[
              {
                required: true,
                message: "Please Upload Logo",
              },
            ]}
          >
            <PickImage
              onChange={(file: any) => {
                setImage(file);
                setCropModalOpen(true);
              }}
              fileURL={
                image?.url
                  ? image.url
                  : null
              }
            />
            
          </Form.Item>
        )}

        <div className="d-flex gap-2 mt-3 justify-content-end">
          <Button
            onClick={() => {
              props?.modalClose();
              setImage({});
            }}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={handler} disabled={btnACtive} loading={isLoading}>
            Add
          </Button>
        </div>
    </Modal>
  );
};

export default LogoPicker;

