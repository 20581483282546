import { useEffect, useState } from "react";
import "../styles.scss";
import { useSelector } from "react-redux";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import { Button } from "antd";
import { Container } from "react-bootstrap";
import { useNavigate, Outlet } from "react-router-dom";
import Table from "./component/table";
import moment from "moment";

const PurchaceInvoice = () => {
  const navigate = useNavigate();
  const Dtoday = moment().endOf('month');
  const DoneMonthAgo = moment(new Date().setDate(1));
  const { user } = useSelector((state: any) => state.User);
  const adminid: any = user.adminid;
  const [isLoading, seisLoading] = useState(true);
  const [list, setList] = useState([]);
  const [sdate, setSdate] = useState(Dtoday.format("YYYY-MM-DD"));
  const [ldate, setLdate] = useState(DoneMonthAgo.format("YYYY-MM-DD"));

  useEffect(() => {
    fetchSaleInvoiceList(
      DoneMonthAgo.format("YYYY-MM-DD"),
      Dtoday.format("YYYY-MM-DD")
    );
  }, []);

  const fetchSaleInvoiceList = async (sdate: any, ldate: any) => {
    try {
      seisLoading(true);
      let url =
        API.PURCHASE_INVOICELIST_BY_DATE +
        `${adminid}/purchase/${sdate}/${ldate}`;
      const response: any = await GET(url, "");
      if (response) {
        setList(response.res);
        setSdate(sdate);
        setLdate(ldate);
        seisLoading(false);
      }
    } catch (error) {
      console.log(error, "error-------------->");
      seisLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        title="Purchase Invoice"
        goBack={"/dashboard"}
        secondPathText="Purchase Invoice"
        secondPathLink={"/usr/purchace-invoice"}
      >
        <div>
          <Button
            type="primary"
            onClick={() => navigate(`/usr/purchace-invoice-form/create`)}
          >
            + Create Invoice
          </Button>
        </div>
      </PageHeader>
      <br />
      <Container>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Table
            List={list}
            fetchSaleInvoiceList={(sdate: any, ldate: any) =>
              fetchSaleInvoiceList(
                sdate.format("YYYY-MM-DD"),
                ldate.format("YYYY-MM-DD")
              )
            }
            ldate={ldate}
            sdate={sdate}
          />
        )}
      </Container>
      <Outlet />
    </div>
  );
};

export default PurchaceInvoice;
