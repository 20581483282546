import { TbLogout } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/userSlice";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ProfileBox() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.User);
  const { t } = useTranslation();

  return (
    <div style={{ width: 280 }} className="profileBox-Box1">
      <br />
      <div className="profileBox-Box2">
      { user?.companyInfo?.bimage == null || user?.companyInfo?.bimage == "" ?  <HiOutlineUserCircle size={70} color="#d9d9d9" />
        :   <div className="profile-picture">
       <img
         src={user?.companyInfo?.bimage }
         className="profile-img"
         style={{width:"50%"}}
         alt=""
       />
     </div>}
        <div className="profileBox-txt1">{t("home_page.homepage.Hi")}, {user?.firstname}</div>

        <br />
      </div>
      <div
        className="profileBox-Box3"
        onClick={() => navigate("/usr/profile/general")}
      >
        <div>{t("home_page.homepage.Manage_Account")}</div>

        <div className="profileBox-Box4">
      <FiUser size={25} color={"#ff9800"} />
        </div>
      </div>
      <div className="profileBox-Box3" onClick={() => dispatch(logout(null))}>
        <div className="profileBox-Txt3">{t("home_page.homepage.Logout")}</div>

        <div className="profileBox-Box4">
          <TbLogout size={25} color="red" />
        </div>
      </div>
    </div>
  );
}

export default ProfileBox;
