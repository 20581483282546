import React, { useEffect, useState } from "react";
import PageHeader from "../../../../../components/pageHeader";
import { useParams } from "react-router-dom";
import { Button, Card } from "antd";
import LoadingBox from "../../../../../components/loadingBox";
import { Col, Container, Row, Table } from "react-bootstrap";
import API from "../../../../../config/api";
import { GET } from "../../../../../utils/apiCalls";

function ViewBankTransfer() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const { id: transactionid } = useParams();

  const fetchBankDetails = async () => {
    try {
      setIsLoading(true);
      const url = API.VIEW_TRANSFER + transactionid;
      const { data }: any = await GET(url, null);
      setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchBankDetails();
  }, []);

  return (
    <div>
      <PageHeader
        firstPathLink={"/usr/cashBank"}
        firstPathText={"Bank"}
        secondPathLink={`/usr/cashBank/${transactionid}/details`}
        secondPathText={"Bank Details"}
        thirdPathLink={`/usr/cashBank/viewtransfer/${transactionid}`}
        thirdPathText={"View"}
        goback={-1}
        title="View Bank Transfer"

      />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Container>
          <br />
          <Card>
            <Table striped>
              <thead>
                <tr>
                  <th></th>
                  <th>TRANSACTION FROM DETAILS</th>
                  <th>RECEIVED BANK DETAILS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Bank Account</td>
                  <td>{data?.fromBank?.laccount}</td>
                  <td>{data?.toBank?.laccount}</td>
                </tr>

                {/* <tr>
                  <td>Account Number</td>
                  <td>{data?.fromBank?.accnum}</td>
                  <td>{data?.toBank?.accnum}</td>
                </tr> */}

                <tr>
                  <td>Nominal Code</td>
                  <td>{data?.fromBank?.nominalcode}</td>
                  <td>{data?.toBank?.nominalcode}</td>
                </tr>

                <tr>
                  <td>Current Balance</td>
                  <td>{data?.fromBank?.total}</td>
                  <td>{data?.toBank?.total}</td>
                </tr>

                <tr>
                  <td>Transfer/Received Amount</td>
                  <td>
                    {" "}
                    {Number(Math.abs(data?.transferDetails?.total)).toFixed(2)}
                  </td>
                  <td>
                    {" "}
                    {Number(Math.abs(data?.transferDetails?.total)).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Container>
      )}
    </div>
  );
}

export default ViewBankTransfer;
