import { Form, Input, Row } from 'antd'
import React from 'react'
import { Col } from 'react-bootstrap'

const TaxPercentageForm = () => {
  return (
    <div>
          <Row>
          <Col md={12}>
          <div className="formItem">
            <label className="formLabel">Type</label>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please tax type",
                },
              ]}
              initialValue={'VAT'}
            >
                <Input readOnly/>
            </Form.Item>
          </div>
        </Col>
        <Col md={12}>
          <div className="formItem">
            <label className="formLabel">Percentage</label>
            <Form.Item
              name="percentage"
              rules={[
                {
                  required: true,
                  message: "Please enter tax percentage",
                },
              ]}
            >
              <Input
              type="number"
              // onInput={(e) => {
              //   e.currentTarget.value = e.currentTarget.value.replace(
              //   /[^0-9]/g,
              //   ""
              //   );
              //   }}
              suffix='%'
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default TaxPercentageForm