import React, { useState } from "react";
import { Card, Modal, Pagination, Space, Table, TableProps } from "antd";
import { Container } from "react-bootstrap";
import { MdRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface DataType {
  employeeId: number;
  title: string;
  total_hours: string;
}

const DataTable = (props: any) => {
  const navigate = useNavigate();
  const columns: TableProps<DataType>["columns"] = [
    {
      title: "User Id",
      dataIndex: "userId",
      key: "userId",
      render: (_: any, record: any) => <span>{record?.user?.fullName}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: any) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Details",
      key: "id",
      render: (_: any, record: any) => (
        <Space size="middle">
          <MdRemoveRedEye
            color="blue"
            size={20}
            onClick={() => navigate(`/usr/hr-finance-details/${record?.id}`)}
          />
        </Space>
      ),
    },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCreate = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <br />

      <>
        <Card>
          <h4 style={{ textAlign: "center" }}> HrFinance History</h4>
          <br />
          <h6>Total {props?.meta?.total_count} FinanceHistory</h6>
          <br />

          <Table
            columns={columns}
            dataSource={props?.dataSource}
            pagination={false}
          />
          <Pagination
            total={props?.meta?.total_count}
            pageSize={props?.meta?.take}
            showTotal={(total) => `Total ${total} finance`}
            onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
            style={{
              display: props?.meta?.total_count > 10 ? "block" : "none",
              textAlign: "right",
              marginTop: "20px",
            }}
          />
        </Card>
      </>
      {/* <Modal
        title="payroll"
        visible={isModalOpen}
        onOk={() => {
          handleCreate();
        }}
        onCancel={handleCancel}
        width={1000}
      ></Modal> */}
    </Container>
  );
};

export default DataTable;
