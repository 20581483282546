
import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/pageHeader";
import { useNavigate } from "react-router-dom";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import { Card, Container } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";






interface Notification {
    title: string;
    description: string;
    createdAt: Date;

}

function Notifications() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<Notification[]>([]);
    const navigate = useNavigate();


    const fetchHrNotifications = async () => {
        try {
            setIsLoading(true);
            const url = API.GET_NOTIFICATIONS_REQUESTS + '?order=ASC&page=1&take=10';
            const { data }: any = await GET(url, null);
            setData(data);

            console.log(data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        fetchHrNotifications();
    }, []);

    return (
        <>
            <PageHeader
                firstPathText="Notification"
                title="Notification List"
            />
            <Container>
                <Card>

                    <div className="productAdd-Txt1">Notifications Details</div>
                    {data.map((notification, index) => (
                        <Card key={index} style={{ marginBottom: '20px' }}>
                            <Card.Body>
                                <Card.Title style={{ textAlign: 'left' }}>{notification.title}</Card.Title>
                                <Card.Text>{notification.description}</Card.Text>
                                <Card.Text>{moment(notification.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Card.Text>

                            </Card.Body>
                        </Card>
                    ))}
                </Card>
            </Container>
        </>
    );
}

export default Notifications;




