import { Button, Card, Tooltip, notification } from "antd";
import PageHeader from "../../../components/pageHeader";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Table } from "react-bootstrap";
import "../styles.scss";
import { BsCheckCircleFill } from "react-icons/bs";
import API from "../../../config/api";
import { MdFileDownload } from "react-icons/md";
import { MdAttachEmail } from "react-icons/md";
import { useEffect, useState } from "react";
import { GET } from "../../../utils/apiCalls";
import LoadingBox from "../../../components/loadingBox";
import dayjs from "dayjs";
import { IoShareSocial } from "react-icons/io5";
import { template1, template2, template3 } from "../components/templates";
import { useSelector } from "react-redux";
import moment from "moment";
import SendMailModal from "../../../components/sendMailModal";

function ViewCreditNote() {
  const { id }: any = useParams();
  const [isFullLoading, setIsFullLoading] = useState(false);
  const [details, setDetails] = useState<any>({});
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const User = useSelector((state: any) => state.User);

  const navigate = useNavigate();
  let _subTotal = 0;
  let _tatalVat = 0;
  let _overollDiscount = 0;
  let discountAmount: any = 0;
  let amountAdded = 0;

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  const getInvoiceDetails = async () => {
    setIsFullLoading(true);
    try {
      let url = API.VIEW_SALE_INVOICE + id + "/sales";
      const getInvDetails: any = await GET(url, null);
      if (getInvDetails.status) {
        setDetails(getInvDetails?.data);
        setIsFullLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsFullLoading(false);
    }
  };
  async function genrateTemplate(type: any, emaildata: any) {
    try {
      setDownloadLoading(true);
      let obj = {
        user: User.user,
        customer: details?.invoiceDetails?.customer,
        sale: details?.invoiceDetails,
        productlist: details?.invoiceItems,
        bankList: details?.banking,
        vatTotal: _tatalVat,
        netTotal: _subTotal,
        Discount: _overollDiscount,
        round: amountAdded,
        total: details?.invoiceDetails?.total,
        vatRate: _tatalVat,
        isPaymentInfo: false,
        pagetype: "SALES CREDIT NOTE",
        selectedBank: User.user.bankInfo,
      };
      let templates: any = null;
      if (!User.user.companyInfo.defaultinvoice) {
        notification.error({
          message: <div>Please select an email template</div>,
          description: (
            <Button
              type={"link"}
              onClick={() => navigate("/usr/settings/customize")}
            >
              Click to select
            </Button>
          ),
        });
        return;
      }
      if (!User.user.bankInfo) {
        notification.error({
          message: <div>Please select default Bank </div>,
          description: (
            <Button
              type={"link"}
              onClick={() => navigate("/usr/profile/business")}
            >
              Click to select
            </Button>
          ),
        });
        return;
      }
      if (User.user.companyInfo.defaultinvoice === "1") {
        templates = template1(obj);
      } else if (User.user.companyInfo.defaultinvoice === "2") {
        templates = template2(obj);
      } else if (User.user.companyInfo.defaultinvoice === "3") {
        templates = template3(obj);
      }
      if (type === "email") {
        sendMailPdf(templates, emaildata);
      } else {
        await downLoadPdf(templates);
      }

      setDownloadLoading(false);
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  }
  const downLoadPdf = async (templates: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    const encodedString = btoa(templateContent);
    const pdf_url = API.PDF_GENERATE_URL;
    const pdfData = {
      filename: "Sales Invoice",
      html: encodedString,
      isDownload: true,
      sendEmail: false,
      type: "",
      userid: "",
    };
    const token = User.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const pdfBlob = await response.arrayBuffer();
    const blob = new Blob([pdfBlob], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `CreditNote${details?.invoiceDetails?.customer?.bus_name}_${
      details?.invoiceDetails?.invoiceno
    }_${moment(new Date()).format("DD-MM-YYYY")}`;
    a.click();
    URL.revokeObjectURL(url);
  };
  const sendMailPdf = async (templates: any, email: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    const encodedString = btoa(templateContent);
    const pdf_url = API.PDF_GENERATE_URL;
    const pdfData = {
      email: email,
      filename: "Sales Invoice.pdf",
      html: encodedString,
      isDownload: false,
      sendEmail: true,
      type: "",
      userid: "",
    };
    const token = User.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (response.ok) {
      notification.success({ message: "Email Successfully Sent" });
      setEmailModal(false);
    }
    if (!response.ok) {
      notification.success({
        message:
          "Apologies, there was an error when attempting to send the email.",
      });
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  };


  return (
    <>
      <PageHeader
        title="Sales Credit View"
        goBack={"/dashboard"}
        secondPathText="Sales Credit View"
        secondPathLink={"/usr/sales-invoice"}
        children={
          <div>
            <Tooltip
              title="Download Invoice"
              mouseEnterDelay={0.5}
              arrow={false}
              color="white"
              overlayClassName="toolTip-Card"
              overlayInnerStyle={{
                color: "#000000",
                marginTop: 5,
                fontSize: "14px",
              }}
              placement={"bottom"}
            >
              <Button
                onClick={() => genrateTemplate("downLoad", {})}
                loading={downloadLoading}
              >
                <MdFileDownload size={20} />
              </Button>
            </Tooltip>
            &nbsp;
            <Tooltip
              title="Mail invoice"
              mouseEnterDelay={0.5}
              arrow={false}
              color="white"
              overlayClassName="toolTip-Card"
              overlayInnerStyle={{
                color: "#000000",
                marginTop: 5,
                fontSize: "14px",
              }}
              placement={"bottom"}
            >
              <Button onClick={() => setEmailModal(true)}>
                <MdAttachEmail size={20} />
              </Button>
            </Tooltip>
            &nbsp;
            <Tooltip
              title="Share Invoice"
              mouseEnterDelay={0.5}
              arrow={false}
              color="white"
              overlayClassName="toolTip-Card"
              overlayInnerStyle={{
                color: "#000000",
                marginTop: 5,
                fontSize: "14px",
              }}
              placement={"bottom"}
            >
              <Button onClick={() => setEmailModal(true)}>
                <IoShareSocial size={20} />
              </Button>
            </Tooltip>
          </div>
        }
      />
      <>
        <Container>
          <br />
          {isFullLoading ? (
            <LoadingBox />
          ) : (
            <Card>
              <Row>
                {details?.invoiceDetails?.salesType ===
                "WithoutStockReversal" ? (
                  <>
                    <Col md="12">
                      <div className="salesInvoice-Header">Credit Note</div>
                      <Table bordered>
                        <tbody>
                          <tr>
                            <td className="items-head">Invoice No</td>
                            <td>
                              <strong>
                                {details?.invoiceDetails?.invoiceno}
                              </strong>
                            </td>
                            <td className="items-head"> Invoice Date</td>
                            <td className="items-value">
                              {dayjs(details?.invoiceDetails?.sdate).format(
                                "DD-MM-YY"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="items-head"> Customer Address</td>
                            <td className="items-value">
                              {details?.invoiceDetails?.customer &&
                                `${details?.invoiceDetails?.customer?.name},
                                ${details?.invoiceDetails?.customer?.address},
                                ${details?.invoiceDetails?.customer?.city !== null ? details?.invoiceDetails?.customer?.city : ""},
                                ${details?.invoiceDetails?.customer?.postcode}`}
                            </td>
                            <td className="items-head">Description</td>
                            <td className="items-value">
                              {details?.invoiceDetails?.quotes &&
                                details?.invoiceDetails?.quotes}
                            </td>
                          </tr>
                          <tr>
                            <td className="items-head">Reference</td>
                            <td className="items-value">
                              {details?.invoiceDetails?.reference &&
                                details?.invoiceDetails?.reference}{" "}
                            </td>
                            <td className="items-head">Amount</td>
                            <td className="items-value">
                              {details?.invoiceDetails?.total &&
                                details?.invoiceDetails?.total}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col md="12">
                      <div className="salesInvoice-Header">Credit Note</div>
                      <Table bordered>
                        <tbody>
                          <tr>
                            <td className="items-head">Invoice No</td>
                            <td>
                              <strong>
                                {details?.invoiceDetails?.invoiceno}
                              </strong>
                            </td>
                            <td className="items-head"> Invoice Date</td>
                            <td className="items-value">
                              {dayjs(details?.invoiceDetails?.sdate).format(
                                "DD-MM-YY"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="items-head"> Invoice Address</td>
                            <td className="items-value">
                              {details?.invoiceDetails?.inaddress
                                ? details?.invoiceDetails?.inaddress
                                : "-"}
                            </td>
                            <td className="items-head"> Delivery Address</td>
                            <td className="items-value">
                              {details?.invoiceDetails?.deladdress
                                ? details?.invoiceDetails?.deladdress
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="items-head">
                              Against Sales Invoice
                            </td>
                            <td className="items-head">
                              {details?.invoiceDetails?.sales_ref}
                            </td>
                            <td className="items-head">Reference</td>
                            <td className="items-value">
                              {details?.invoiceDetails?.reference}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col>
                      {details.invoiceItems &&
                        details.invoiceItems.length > 0 && (
                          <>
                            <div className="salesInvoice-SubHeader ">
                              Invoice Items
                            </div>

                            <Table bordered>
                              <thead>
                                <tr>
                                  <th>PRODUCT</th>
                                  <th>QUANTITY</th>
                                  <th>PRICE</th>
                                  <th>TAX %</th>
                                  <th>TAX AMT</th>
                                  <th>INC TAX</th>
                                  <th>DISC %</th>
                                  <th>DISC AMT</th>
                                  <th>TOTAL</th>
                                </tr>
                              </thead>
                              {details?.invoiceItems?.map(
                                (item: any, index: number) => (
                                  <tbody>
                                    <tr key={index}>
                                      <td>{item?.description}</td>
                                      <td>{item?.quantity}</td>
                                      <td>{item?.costprice}</td>
                                      <td>{item?.vat}</td>
                                      <td>{item?.vatamt}</td>
                                      <td>{item?.incomeTax}</td>
                                      <td>{item?.percentage}</td>
                                      <td>{item?.description}</td>
                                      <td>{item?.total}</td>
                                    </tr>
                                  </tbody>
                                )
                              )}
                            </Table>
                          </>
                        )}
                    </Col>
                    <Col sm={8}></Col>
                    <Col sm={4}>
                      <Table bordered>
                        <tbody>
                          <tr>
                            <td>TAXABLE VALUE</td>
                            <td>{details?.invoiceDetails?.total}</td>
                          </tr>
                          <tr>
                            <td>TOTAL VAT</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>TOTAL DISCOUNT</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td className="items-head">TOTAL AMOUNT</td>
                            <td className="items-head">
                              {details?.invoiceDetails?.total}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>

                    <Col>
                      <Table bordered>
                        <tbody>
                          <tr>
                            <td className="items-head">Terms and Conditions</td>
                            <td className="items-value">
                              {details?.invoiceDetails?.terms}
                            </td>
                            <td className="items-head">Notes</td>
                            <td className="items-value">
                              {details?.invoiceDetails?.quotes}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </>
                )}
              </Row>
            </Card>
          )}
          {emailModal ? (
            <SendMailModal
              open={emailModal}
              close={() => setEmailModal(false)}
              onFinish={(val: any) => genrateTemplate("email", val)}
              ownMail={User.user.email}
              Attachment={`${details?.invoiceDetails?.customer?.bus_name}_${
                details?.invoiceDetails?.invoiceno
              }_${moment(new Date()).format("DD-MM-YYYY")}`}
              defaultValue={{
                to: details?.invoiceDetails.customer.email,
                subject: `Sales Credit Notes ${details?.invoiceDetails?.invoiceno}`,
                content: User.user.companyInfo.defaultmail,
              }}
            />
          ) : null}
        </Container>
      </>
    </>
  );
}

export default ViewCreditNote;
