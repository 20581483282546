// import React, { useRef, useState } from "react";

// import "devextreme/dist/css/dx.light.css";
// import moment from "moment";
// import { Card, Popover, Space, Table, TableProps, Tag } from "antd";
// import { useNavigate } from "react-router-dom";
// import { Container } from "react-bootstrap";

// const DataTable = (props: any) => {
//   console.log("PROPS ---> ", props);

//   const [selectedRows, setSelectedRows] = useState();

//   const navigate = useNavigate();

//   const onSelectionChanged = (e: any) => {
//     setSelectedRows(e.selectedRowsData.length);
//   };

//   const columns = [
//     { title: "Project", dataIndex: "projectId", key: "Project" },
//     { title: "Amount", dataIndex: "amount", key: "Amount" },
//     { title: "Currency", dataIndex: "currency", key: "Currency" },
//     {
//       title: "payment Method",
//       key: "payment Method",
//       dataIndex: "payment_method",
//     },
//     {
//       title: "Invoice Number",
//       dataIndex: "invoice_no",
//       key: "Invoice Number",
//     },
//     { title: "Document", dataIndex: "document", key: "Document" },
//   ];

//   return (
//     <Container>
//       <br />
//       <Card>
//         <Table columns={columns} dataSource={props?.dataSource} />
//       </Card>
//     </Container>
//   );
// };
// export default DataTable;
import React, { useState } from "react";
import { Card, Pagination, Table } from "antd";
import { Container } from "react-bootstrap";

const DataTable = (props: any) => {
  const [selectedRows, setSelectedRows] = useState();

  const onSelectionChanged = (e: any) => {
    setSelectedRows(e.selectedRowsData?.length);
  };

  const columns = [
    { title: "Project", dataIndex: "projectId", key: "Project" },
    { title: "Amount", dataIndex: "amount", key: "Amount" },
    { title: "Currency", dataIndex: "currency", key: "Currency" },
    {
      title: "Payment Method",
      key: "paymentMethod",
      dataIndex: "payment_method",
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_no",
      key: "InvoiceNumber",
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "Document",
      render: (text: any) =>
        text ? <a href={text}>View Document</a> : "No Document Uploaded",
    },
  ];

  return (
    <Container>
      <br />
      <Card>
        <h6>Total {props?.meta?.total_count} Project payments</h6>
        <br />
        <Table
          columns={columns}
          dataSource={props.dataSource}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selectedRows,
            onChange: onSelectionChanged,
          }}
          pagination={false}
        />
        <Pagination
          total={props?.meta?.total_count}
          pageSize={props?.meta?.take}
          onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
          style={{
            display: props?.meta?.total_count > 10 ? "block" : "none",
            textAlign: "right",
            marginTop: "20px",
          }}
        />
      </Card>
    </Container>
  );
};

export default DataTable;
