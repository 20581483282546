import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import API from "../../../config/api";
import { GET, PUT } from "../../../utils/apiCalls";
import { Card, Col, Form, Row, Spin, notification } from "antd";
import { Button } from "antd";
import { Container, Table } from "react-bootstrap";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

function EmployeeViews() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [Employeedata, setEmployeeData] = useState<any>();
  const [BlockEmployee, setBlockEmployee] = useState<any>();
  const { id } = useParams();
  const location = useLocation();
  const getAllEmployeeData = async () => {
    try {
      // setIsLoading(true);
      let api = API.GET_HR_EMPLOYEE_LIST + id;
      const Data: any = await GET(api, {});
      if (Data?.status) {
        console.log(Data);
        setEmployeeData(Data?.data);
        setIsLoading(false);
        setIsLoading2(false);
      } else {
        setEmployeeData({});
        setIsLoading2(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading2(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllEmployeeData();
  }, []);

  const blockUser = async () => {
    try {
      setIsLoading2(true);
      let api = API.BLOCK_EMPLOYEE + id;
      const updateData: any = await PUT(api, {});
      if (updateData?.status) {
        setBlockEmployee(updateData?.data);
        notification.success({ message: "User blocked successfully" });

        getAllEmployeeData();
        // setIsLoading2(false);
      } else {
        setBlockEmployee({});
        setIsLoading2(false);
        notification.error({ message: "Failed to block user" });
      }
    } catch (error) {
      console.log(error);
      setIsLoading2(false);
      notification.error({ message: "Something went wrong" });
    }
  };

  const formattedDob = Employeedata?.dob
    ? moment(Employeedata.dob).format("YYYY-MM-DD")
    : "N/A";
  const formattedHireDate = Employeedata?.hireDate
    ? moment(Employeedata.hireDate).format("YYYY-MM-DD")
    : "N/A";

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <PageHeader firstPathText="EmployeeData" title="Employee Details" />
          <br />
          <Container>
            <Card>
              <div className="productAdd-Txt1">Employee Details</div>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Title:</strong>
                </Col>
                <Col span={18}>
                  <span>{Employeedata?.title}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>role:</strong>
                </Col>
                <Col span={18}>
                  <span>{Employeedata?.role}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Name:</strong>
                </Col>
                <Col span={18}>
                  <span>{Employeedata?.fullName}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>email:</strong>
                </Col>
                <Col span={18}>
                  <span>{Employeedata?.email}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>officeEmail:</strong>
                </Col>
                <Col span={18}>
                  <span>{Employeedata?.officeEmail}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>phone:</strong>
                </Col>
                <Col span={18}>
                  <span>{Employeedata?.phone}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>altPhone:</strong>
                </Col>
                <Col span={18}>
                  <span>{Employeedata?.altPhone}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>gender:</strong>
                </Col>
                <Col span={18}>
                  <span>{Employeedata?.gender}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>department:</strong>
                </Col>
                <Col span={18}>
                  <span>{Employeedata?.department}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>wrokLocation:</strong>
                </Col>
                <Col span={18}>
                  <span>{Employeedata?.workLocation}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>dob:</strong>
                </Col>
                <Col span={18}>
                  <span>{formattedDob}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>hireDate:</strong>
                </Col>
                <Col span={18}>
                  <span>{formattedHireDate}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>address:</strong>
                </Col>
                <Col span={18}>
                  <span>{Employeedata?.address}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Status:</strong>
                </Col>
                <Col span={18}>
                  <span>{Employeedata?.status ? "Active" : "Blocked"}</span>
                </Col>
              </Row>
              <Row>
                <Col md="6"></Col>
                <Col md="6">
                  <br />
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={blockUser}
                    >
                      {isLoading2 ? (
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 24, color: "#fff" }}
                              spin
                            />
                          }
                        />
                      ) : Employeedata?.status ? (
                        "Block"
                      ) : (
                        "Unblock"
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Container>
        </>
      )}
    </>
  );
}

export default EmployeeViews;
