import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  notification,
  Checkbox,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/pageHeader";
import { GET, POST } from "../../../utils/apiCalls";
import Items from "./components/items";
import API from "../../../config/api";
import LoadingBox from "../../../components/loadingBox";
import { template1, template2, template3 } from "../components/templates";
import PrintModal from "../../../components/printModal/printModal";
function Create(props: any) {
  const [form] = Form.useForm();
  const { user } = useSelector((state: any) => state.User);
  const navigate = useNavigate();
  const adminid = user.adminid;
  const [isLoading, setIsLoading] = useState(true);
  const [subTotal, setSubTotal] = useState(0);
  const [tatalVat, setTatalVat] = useState(0);
  const [roundOff, setRoundOff] = useState(0);
  const [overollDiscount, setOverolDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isPaymentInfo, setIsPaymentInfo] = useState<any>(false);
  const [isCheck, setIsCheck] = useState<any>(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState<any>(null);
  const [customerSalesData, setCustomerSalesData] = useState<any>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<any>([]);
  const [genInvoiceNo, setGenInvoiceNo] = useState<any>(null);
  const [columnData, setColumnsData] = useState([]);
  const [products, setProducts] = useState([]);
  const [qSuffix, setqSuffix] = useState("");
  const [pStock, setPStock] = useState(0);
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [Includevats, setincludedvat] = useState<any>();
  const [stockReversalOptions, setStockReversalOptions] = useState({
    withStockReversal: false,
    withoutStockReversal: false,
  });
  const [selectBankBalance, setSelectBankBalance] = useState(0);
  const [template, setTemplate] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [saleInvoice, setSaleInvoice] = useState(false);

  const handleCheckboxChange = (e: any, option: any) => {
    setStockReversalOptions({
      ...stockReversalOptions,
      [option]: e.target.checked,
    });
  };

  useEffect(() => {}, [stockReversalOptions]);

  useEffect(() => {
    getInvoiceNumber();
  }, []);

  useEffect(() => {
    getCustomerInvoices(selectedCustomerId);
  }, [selectedCustomerId]);

  useEffect(() => {
    getInvoiceData(selectedInvoice.id).finally(() => {
      setLoadingInvoices(false);
    });
  }, [selectedInvoice]);

  //get the invoice number
  const getInvoiceNumber = async () => {
    try {
      const getinvoiceurl = API.GET_INVOICENO + `${adminid}/scredit`;
      const { data }: any = await GET(getinvoiceurl, null);
      setGenInvoiceNo(data);
      form.setFieldValue("invoiceNo", data);
      setIsLoading(false);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  //get selected customer invoices
  const getCustomerInvoices = async (customer_id: number) => {
    setLoadingInvoices(true);
    try {
      let customersalesapi =
        API.GET_CREDIT_NOTE_SALESINVOICE + `${adminid}/${customer_id}`;
      const data: any = await GET(customersalesapi, null);
      const filteredSalesInvoices = data?.filter(
        (invoice: any) => invoice.type === "sales"
      );
      setCustomerSalesData(filteredSalesInvoices);
      setLoadingInvoices(false);
    } catch (error: any) {
      console.log(error.message);
      setLoadingInvoices(false);
    }
  };

  //function to get selected invoice items
  const getInvoiceData = async (id: any) => {
    try {
      setSaleInvoice(id);
      const getinvoicedataapi = API.VIEW_SALE_INVOICE + `${id}` + `/sales`;
      const { data: invoiceData }: any = await GET(getinvoicedataapi, null);
      const selectedItems: any = getInitialValues(invoiceData);
      if (selectedItems) {
        form.setFieldsValue({
          terms: selectedItems?.terms,
          quotes: selectedItems?.quotes,
          reference: selectedItems.reference,
        });
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getInitialValues = (data: any) => {
    let productArray: any = [];
    try {
      let columns = data.invoiceItems?.map((item: any) => {
        productArray.push(item.product);
        setincludedvat(item.includevat == 1 ? true : false);
        return {
          id: item.product.id,
          quantity: Number(item.quantity),
          price: Number(item.costprice),
          vatamt: item.vatamt,
          description: item.description,
          vat: item.incomeTax,
          vatamount: item.incomeTaxAmount,
          discount: item.discount,
          discountAmt: item.discount_amount,
          total: item.total,
          includeVat: item.includevat == 1 ? true : false,
        };
      });
      setProducts(productArray);
      setColumnsData(columns);
      const initialValue = {
        terms: data?.invoiceDetails?.terms,
        quotes: data?.invoiceDetails?.quotes,
        reference: data?.invoiceDetails?.reference,
        inaddress: data?.invoiceDetails?.inaddress,
        deladdress: data?.invoiceDetails?.deladdress,
      };
      return initialValue;
    } catch (error) {
      console.log(error);
      return {};
    }
  };
  function containsNull(arr: any) {
    let isThereNull = false;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (element === undefined) {
        isThereNull = true;
      }
    }
    return isThereNull;
  }
  //form value change
  const formValue = form.getFieldsValue();
  const onValuesChange = (column: any, allarray: any) => {
    try {
      if (column?.columns?.length < 1) {
        setSubTotal(0);
        setTatalVat(0);
        setOverolDiscount(0);
        setRoundOff(0);
        setTotalAmount(0);
      }
      if (column.ledger) {
        props.getProduct(column.ledger === 2 ? "Service" : "Stock");
        setSubTotal(0);
        setTatalVat(0);
        setOverolDiscount(0);
        setRoundOff(0);
        setTotalAmount(0);
      }
      if (column.paymentBank) {
        let selectedBank = props?.banks?.find(
          (item: any) => item?.list?.id === column.paymentBank
        );
        let amoutToPaid = Number(allarray.amoutToPaid) || 0;
        setSelectBankBalance(selectedBank?.amount);
        form.setFieldsValue({
          bicnum: selectedBank?.list?.bicnum,
          ibanNumber: selectedBank?.list?.ibannum,
          accountNumber: selectedBank?.list?.accnum,
          holderName: selectedBank?.list?.laccount,
          availableBalance: selectedBank?.amount,
          outStanding: (Number(totalAmount) - amoutToPaid).toFixed(2),
          paymentMethod: selectedBank?.list?.laccount === "Cash" ? "cash" : "",
        });
      }
      if (column.amoutToPaid) {
        let outstanding = totalAmount - Number(column.amoutToPaid);
        form.setFieldsValue({ outStanding: outstanding });
        if (outstanding < 0) {
          form.setFieldsValue({ outStanding: 0, amoutToPaid: totalAmount - 0 });
          notification.error({
            message:
              "The amount cannot be greater than the outstanding balance.",
          });
        }
      } else if (
        allarray.amoutToPaid === null ||
        allarray.amoutToPaid === undefined ||
        allarray.amoutToPaid === ""
      ) {
        form.setFieldsValue({
          outStanding: totalAmount - 0,
        });
      }

      if (allarray?.columns && allarray?.columns?.length) {
        let _subTotal = 0;
        let _tatalVat = 0;
        let _overollDiscount = 0;
        const updatedColumns = allarray?.columns?.map(
          (item: any, index: any) => {
            if (column.columns.length > 1) {
              if (containsNull(column.columns)) {
                let productId = column.columns[index]?.id || null;
                if (productId) {
                  let array = allarray?.columns;
                  array[index].price = null;
                  array[index].vat = null;
                  array[index].discount = null;
                  array[index].discountamt = null;
                  array[index].quantity = null;
                  if (array?.length - 1 === index) {
                    array[index].includeVat = null;
                  }
                  form.setFieldsValue({ columns: array });
                }
              }
            } else {
              if (
                !column.columns[index]?.price ||
                !column.columns[index]?.quantity
              ) {
                if (
                  column.columns[index]?.discount ||
                  column.columns[index]?.discountamt
                ) {
                  console.log("");
                } else {
                  if (column.columns[index]?.id) {
                    let array = allarray?.columns;
                    array[index].price = null;
                    array[index].vat = null;
                    array[index].discount = null;
                    array[index].discountamt = null;
                    array[index].quantity = null;
                    if (array?.length - 1 === index) {
                      array[index].includeVat = null;
                    }
                    form.setFieldsValue({ columns: array });
                  }
                }
              }
            }
            if (item && item.id !== null) {
              let foundProduct = props.product.find(
                (product: any) => Number(product?.id) === Number(item.id)
              );
              let columnDiscountAmt = Number(
                column?.columns[index]?.discountamt
              );
              let quantity =
                column?.columns[index]?.quantity === undefined
                  ? item?.quantity || 1
                  : column?.columns[index]?.quantity;
              let price =
                item?.price === undefined || item?.price === null
                  ? Number(foundProduct?.rate)
                  : item?.price;
              let total = price * quantity;
              let itemDiscountAmt = Number(item?.discountamt);
              let discountAmount: any =
                Number.isNaN(columnDiscountAmt) && Number.isNaN(itemDiscountAmt)
                  ? 0
                  : columnDiscountAmt ||
                    item?.quantity === null ||
                    item?.quantity === 0 ||
                    item?.price === null ||
                    Number(item?.price) === 0
                  ? 0
                  : itemDiscountAmt || 0;

              let columnDiscount = Number(column?.columns[index]?.discount);
              let itemDiscount = Number(item?.discount);
              let discount: any =
                Number.isNaN(columnDiscount) && Number.isNaN(itemDiscount)
                  ? 0
                  : columnDiscount ||
                    item?.quantity === null ||
                    item?.price === null ||
                    item?.quantity === 0 ||
                    Number(item?.price) === 0
                  ? 0
                  : itemDiscount || 0;

              setqSuffix(foundProduct?.unit);
              const curentQuntityChangingIndex =
                column?.columns[index]?.quantity === undefined
                  ? -1
                  : column.columns.findIndex(
                      (item: any) =>
                        item?.quantity === column?.columns[index]?.quantity
                    );
              if (curentQuntityChangingIndex >= 0) {
                setPStock(Number(foundProduct.stock));
              }

              let vatPercent =
                item?.vat === undefined ||
                item?.vat === null ||
                item?.vat === ""
                  ? Number(foundProduct?.vat)
                  : Number(item?.vat);
              let vatAmount =
                formValue.column?.columns.length > 1
                  ? formValue?.columns[index]?.vatamount
                  : Number(foundProduct?.vatamt);
              if (
                column?.columns[index]?.id === undefined ||
                column?.columns[index]?.includeVat !== undefined ||
                column?.columns[index]?.vat !== undefined ||
                column?.columns[index]?.quantity !== undefined ||
                column?.columns[index]?.discount !== undefined ||
                column?.columns[index]?.discountamt !== undefined ||
                column?.columns[index]?.price !== undefined
              ) {
                vatAmount = (price * quantity * vatPercent) / 100;
              }
              ///////////////////////includeVat//////////////
              let includeVat;
              const selectedIncludeVat = column?.columns[index]?.includeVat;
              if (selectedIncludeVat === undefined) {
                if (foundProduct?.includevat === "1.00") {
                  includeVat =
                    item?.includeVat === false ? false : true || true;
                } else {
                  includeVat = item?.includeVat || false;
                }
              } else {
                includeVat = selectedIncludeVat;
              }
              if (includeVat) {
                let totalItemsRate = price * quantity;
                vatAmount = (totalItemsRate / (100 + vatPercent)) * 100;
                vatAmount = Number(price * quantity - vatAmount);
                total = price * quantity;
              } else {
                total = Number(price * quantity + vatAmount);
              }
              ///////////////////////includeVat//////////////

              if (
                column?.columns[index]?.discount > 0 &&
                item.quantity != null &&
                item.quantity !== 0 &&
                item.price != null &&
                item.price !== 0
              ) {
                const discountRate = Number(item.discount) / 100;
                discountAmount = total * discountRate;
                discount = Number(item.discount);
                if (column?.columns[index]?.discount > 100) {
                  let disRate = 100 / 100;
                  discountAmount = total * disRate;
                  discount = 100;
                  notification.error({
                    message:
                      "Discount cannot exceed the total amount of the invoice",
                  });
                }
              } else if (
                column?.columns[index]?.discount == null ||
                column?.columns[index]?.discount == 0 ||
                column?.columns[index]?.discount == "" ||
                column?.columns[index]?.discount === undefined
              ) {
                if (
                  item?.discountamt > 0 &&
                  item.quantity != null &&
                  item.quantity !== 0 &&
                  item.price != null &&
                  item.price !== 0
                ) {
                  const discountpecentage =
                    (Number(item?.discountamt) / total) * 100;
                  discountAmount = Number(item?.discountamt);
                  discount = Number(discountpecentage);
                } else {
                  discountAmount = 0;
                }
              }
              if (0 >= column?.columns[index]?.discountamt) {
                discount = 0;
              }
              if (
                column?.columns[index]?.discountamt > 0 &&
                item.quantity != null &&
                item.quantity !== 0 &&
                item.price !== 0 &&
                item.price !== null
              ) {
                const discountpecentage =
                  (Number(item?.discountamt) / total) * 100;
                discountAmount = Number(item?.discountamt);
                discount = Number(discountpecentage);
                if (column?.columns[index]?.discountamt >= total) {
                  let disRate = 100 / 100;
                  discountAmount = total * disRate;
                  discount = 100;
                  notification.error({
                    message:
                      "Discount cannot exceed the total amount of the invoice.",
                  });
                }
              } else if (column?.columns[index]?.discountamt === "") {
                discount = "";
              }
              if (
                column.columns[index]?.price ||
                column.columns[index]?.quantity
              ) {
                const discountRate = Number(item.discount) / 100;
                discountAmount = total * discountRate;
                discount = Number(item.discount);
              }
              //here total calculation
              if (includeVat) {
                _subTotal = price * quantity - vatAmount + _subTotal;
              } else {
                _subTotal = price * quantity + _subTotal;
              }
              _tatalVat = _tatalVat + vatAmount;
              _overollDiscount = _overollDiscount + discountAmount;

              const updatedColumn = {
                id: item.id,
                quantity:
                  column?.columns[index]?.quantity === undefined
                    ? quantity
                    : column?.columns[index]?.quantity.length == "0"
                    ? null
                    : item.quantity,
                price:
                  column?.columns[index]?.price === undefined
                    ? price
                    : column?.columns[index]?.price.length == "0"
                    ? null
                    : item.price,
                incomeTaxAmount: Number(vatAmount).toFixed(2),
                vatamt: Number(vatAmount).toFixed(2),
                description: foundProduct?.idescription,
                vat:
                  item?.vat === undefined || item?.vat === null
                    ? foundProduct?.vat
                    : item?.vat,
                vatamount: Number(vatAmount).toFixed(2),
                discountamt: Number(discountAmount).toFixed(2),
                discount: Number(discount).toFixed(2),
                total: Number(total - discountAmount).toFixed(2),
                includeVat,
              };
              return updatedColumn;
            } else {
              let newColumn = {
                id: null,
                quantity: null,
                price: null,
                incomeTaxAmount: null,
                vatamt: null,
                description: null,
                vat: null,
                vatamount: null,
                discountamt: null,
                discount: null,
                total: null,
                includeVat: null,
              };
              return newColumn;
            }
          }
        );

        if (updatedColumns.length) {
          form.setFieldsValue({ columns: updatedColumns });
          setSubTotal(_subTotal);
          setTatalVat(_tatalVat);
          setOverolDiscount(_overollDiscount);
          let _totalAmount = _subTotal + _tatalVat - _overollDiscount;
          let roundedNumber = Math.round(_totalAmount);
          let amountAdded = roundedNumber - _totalAmount;
          setRoundOff(Number(amountAdded.toFixed(2)));
          //setTotalAmount(roundedNumber);
          setTotalAmount(_totalAmount);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //form submit
  const onFinish = async (val: any) => {
    setLoadingForm(true);
    if (!val?.columns || !val?.columns?.length) {
      notification.error({ message: <h6>Add items to invoice</h6> });
      return;
    }
    let amountPaid = Number(val?.amoutToPaid) || 0;
    let totalPayable = Number(val?.availableBalance) || totalAmount;
    let outstanding = totalPayable - amountPaid;
    let status = "0";
    if (outstanding <= 0) {
      status = "2"; //paid
    } else if (outstanding < totalPayable) {
      status = "1"; //part Paid
    } else if (outstanding >= totalPayable) {
      status = "0"; //unpaid
    }

    let paymentInfo = isPaymentInfo;
    if (isPaymentInfo) {
      paymentInfo = {
        id: val?.paymentBank,
        bankid: val?.paymentBank,
        outstanding: val?.outStanding,
        amount: val?.availableBalance,
        date: val?.paymentDate,
        type: val?.paymentMethod,
        paidmethod: val?.paymentMethod,
      };
    }

    try {
      let selectedCustomer =
        props?.customers &&
        props?.customers?.length &&
        props?.customers.find((item: any) => item.id === val.customerid);
      let ledger =
        props?.ledgers &&
        props?.ledgers.length &&
        props?.ledgers?.find((item: any) => item.id === val.ledger);
      let column = val?.columns?.map((item: any) => {
        let foundedProduct = props?.product?.find(
          (product: any) => product?.id === item.id
        );
        let productLedger = {};
        if (foundedProduct?.itemtype === "Stock") {
          productLedger = {
            category: "13",
            id: 1,
            laccount: "Sales-Products",
            nominalcode: "4000",
          };
        } else if (foundedProduct?.itemtype === " Service") {
          productLedger = {
            category: "24",
            id: 2,
            laccount: "Sales-Services",
            nominalcode: "4001",
          };
        }
        return {
          id: item?.id,
          discount: item?.discount,
          discountamt: item?.discountamt,
          productId: item?.id,
          product: foundedProduct,
          idescription: foundedProduct?.idescription,
          description: foundedProduct?.idescription,
          vat: item?.vat,
          includevat: item?.includeVat,
          incomeTax: item?.vat,
          percentage: item?.vat,
          costprice: item?.price,
          ledgerDetails: productLedger,
          ledger: productLedger,
          quantity: item?.quantity,
          total: item?.total,
          vatamt: item?.vat,
          vatamount: item?.vatamt,
          incomeTaxAmount: item?.vatamt,
          itemorder: 1,
        };
      });

      let payload = {
        cname: selectedCustomer?.bus_name,
        customerid: val?.customerid,
        columns: column,
        sales_ref: val?.invoice,
        invoiceno: genInvoiceNo,
        sdate: val?.sdate,
        ldate: val?.ldate,
        inaddress: val?.inaddress,
        deladdress: val?.deladdress,
        terms: val?.terms,
        quotes: val?.quotes,
        status: status,
        issued: "yes",
        type: "scredit",
        pagetype: "1",
        total: Number(totalAmount),
        userid: adminid,
        adminid: adminid,
        userdate: new Date(),
        paymentInfo: paymentInfo,
        reference: val?.reference,
        salesType: "",
        ledger: ledger,
        email: user.email,
        // roundOff: roundOff + "",
        total_vat: tatalVat,
        overall_discount: overollDiscount,
        taxable_value: subTotal,
        invoiceid: saleInvoice,
      };
      let obj = {
        user: user,
        customer: selectedCustomer,
        sale: {
          inaddress: val?.inaddress,
          deladdress: val?.deladdress,
          invoiceno: val.invoiceno,
          quotes: val?.quotes,
          terms: val?.terms,
          reference: val?.reference,
          userdate: new Date(),
          sdate: dayjs(val?.sdate).format("YYYY-MM-DD"),
          ldate: dayjs(val?.ldate).format("YYYY-MM-DD"),
          total: totalAmount,
          outstanding: isPaymentInfo ? paymentInfo.outstanding : totalAmount,
          status: 0,
          adminid: 2856200,
        },
        productlist: column,
        bankList: {},
        vatTotal: tatalVat,
        netTotal: subTotal,
        Discount: overollDiscount,
        round: roundOff,
        total: totalAmount,
        vatRate: tatalVat,
        isPaymentInfo: false,
        pagetype: "Credit Note",
        selectedBank: user?.bankInfo,
      };
      let templates: any = null;
      if (user.companyInfo.defaultinvoice === "1") {
        templates = template1(obj);
      } else if (user.companyInfo.defaultinvoice === "2") {
        templates = template2(obj);
      } else if (user.companyInfo.defaultinvoice === "3") {
        templates = template3(obj);
      }
      setTemplate(templates);
      let salesUrl = "SaleInvoice/add";
      const response: any = await POST(salesUrl, payload);
      if (response.status) {
        // setIsLoading(false);
        setLoadingForm(false);
        setModalOpen(true);
        notification.success({ message: "credit note created successfully" });
        // navigate(-1);
      } else {
        notification.error({ message: "oops.., something went wrong" });
        setLoadingForm(false);
        // setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      notification.error({ message: "Oops .. something went wrong" });
      setLoadingForm(false);
      setIsLoading(false);
    }
  };

  //form submit
  const onFinishWithoutReversal = async (val: any) => {
    setLoadingForm(true);
    let amountPaid = Number(val?.amoutToPaid) || 0;
    let totalPayable = Number(val?.availableBalance) || totalAmount;
    let outstanding = totalPayable - amountPaid;
    let status = "0";
    let paymentInfo = isPaymentInfo;
    if (isPaymentInfo) {
      paymentInfo = {
        id: val.paymentBank,
        bankid: val.paymentBank,
        outstanding: val.outStanding,
        amount: val?.availableBalance,
        date: val?.paymentDate,
        type: val.paymentMethod,
        paidmethod: val.paymentMethod,
      };
    }

    try {
      let selectedCustomer =
        props?.customers &&
        props?.customers?.length &&
        props?.customers.find((item: any) => item.id === val.customerid);

      let ledger =
        props?.ledgers &&
        props?.ledgers?.length &&
        props?.ledgers?.find((item: any) => item.id === val.ledger);
      let payload = {
        cname: selectedCustomer?.bus_name,
        customerid: val?.customerid,
        columns: [
          {
            quantity: 0,
            id: "",
          },
        ],
        invoiceno: genInvoiceNo,
        sdate: val?.sdate,
        ldate: val?.ldate,
        inaddress: val?.inaddress,
        deladdress: val?.deladdress,
        terms: val?.terms,
        quotes: val?.description,
        status: status,
        issued: "yes",
        type: "scredit",
        pagetype: "1",
        total: Number(val.amount),
        userid: adminid,
        adminid: adminid,
        userdate: new Date(),
        paymentInfo: paymentInfo,
        reference: val?.reference,
        salesType: "WithoutStockReversal",
        ledger: ledger,
        email: user.email,
      };
      let salesUrl;
      if (stockReversalOptions?.withoutStockReversal) {
        salesUrl = "Saleinvoice/addCreditNew";
      } else {
        salesUrl = "SaleInvoice/add";
      }
      const response: any = await POST(salesUrl, payload);
      if (response.status) {
        setLoadingForm(false);
        notification.success({
          message: "Success",
          description: "Credit note created successfully",
        });
        navigate(-1);
      } else {
        notification.error({
          message: "Failed",
          description: "Failed to create credit note",
        });
        setLoadingForm(false);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Server Error",
        description: "Faied to create credit note",
      });
      setLoadingForm(false);
    }
  };

  return (
    <div>
      <PageHeader
        title="Create Credit Notes"
        goBack={"/dashboard"}
        secondPathText="Credit Notes"
        secondPathLink={"/usr/salesCredit"}
        thirdPathText="Create Credit Notes"
        thirdPathLink={"/usr/salesCredit/screditform/0"}
      >
        <div>
          <Checkbox
            onChange={(e) => handleCheckboxChange(e, "withoutStockReversal")}
          >
            Without Stock Reversal
          </Checkbox>
        </div>
      </PageHeader>
      <br />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Container>
          <br />

          <Card>
            {stockReversalOptions.withoutStockReversal ? (
              <Form
                form={form}
                onValuesChange={onValuesChange}
                onFinish={onFinishWithoutReversal}
              >
                <Row>
                  <Col sm={3}>
                    <div className="formLabel">Credit Invoice No.</div>
                    <Form.Item name={"invoiceNo"} rules={[{ required: true }]}>
                      <Input size="large" readOnly />
                    </Form.Item>
                  </Col>
                  <Col sm={3}>
                    <div className="formLabel">Customers Name</div>
                    <Form.Item
                      name={"customerid"}
                      rules={[{ required: true, message: "choose customer" }]}
                    >
                      <Select
                        size="large"
                        showSearch
                        onSearch={(val: any) => props.customerName(val)}
                        onChange={(val: any) => setSelectedCustomerId(val)}
                      >
                        {props.customers &&
                          props.customers.length &&
                          props?.customers?.map((item: any) => {
                            return (
                              <Select.Option key={item.id} value={item.id}>
                                {item.bus_name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={3}>
                    <div className="formLabel">Sales Ledger</div>
                    <Form.Item
                      name={"ledger"}
                      rules={[{ required: true, message: "choose ledger" }]}
                      // initialValue={}
                    >
                      <Select size="large">
                        {props.ledgers &&
                          props.ledgers.length &&
                          props?.ledgers
                            .filter(
                              (item: any) => item.id === 20704 || item.id === 2
                            )
                            .map((item: any) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.nominalcode + "-" + item.laccount}
                              </Select.Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={3}>
                    <div className="formLabel">Invoice Date</div>
                    <Form.Item
                      name={"sdate"}
                      initialValue={dayjs(selectedInvoice.data)}
                    >
                      <DatePicker style={{ width: "100%" }} size="large" />
                    </Form.Item>
                  </Col>
                  <Col sm={3}>
                    <div className="formLabel">Description</div>
                    <Form.Item name={"description"}>
                      <Input
                        type="text"
                        size="large"
                        placeholder="description"
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col sm={2}></Col> */}
                  <Col sm={3}>
                    <div className="formLabel">Reference</div>
                    <Form.Item name={"reference"}>
                      <Input type="text" size="large" placeholder="reference" />
                    </Form.Item>
                  </Col>
                  <Col sm={3}>
                    <div className="formLabel">Amount</div>
                    <Form.Item
                      name={"amount"}
                      rules={[
                        {
                          type: "number",
                          required: true,
                          message: "Please enter a valid number",
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} size="large" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}></Col>
                  <Col sm={6}>
                    <Row>
                      <Col sm={6}>
                        <Button size="large" block>
                          Close
                        </Button>
                      </Col>
                      <Col sm={6}>
                        <Button
                          size="large"
                          block
                          type="primary"
                          htmlType="submit"
                          loading={loadingForm}
                        >
                          Create
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            ) : (
              <Form
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                scrollToFirstError
              >
                <Row>
                  <Col sm={2}>
                    <div className="formLabel">Credit Invoice No.</div>
                    <Form.Item name={"invoiceNo"} rules={[{ required: true }]}>
                      <Input size="large" readOnly />
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Customer Name</div>
                    <Form.Item
                      name={"customerid"}
                      rules={[{ required: true, message: "choose customer" }]}
                    >
                      <Select
                        size="large"
                        showSearch
                        onSearch={(val: any) => props.customerName(val)}
                        onChange={(val: any) => setSelectedCustomerId(val)}
                      >
                        {props.customers &&
                          props?.customers?.length &&
                          props?.customers?.map((item: any) => {
                            return (
                              <Select.Option key={item.id} value={item.id}>
                                {item.bus_name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Sales Invoice</div>
                    <Form.Item
                      name={"invoice"}
                      rules={[
                        { required: true, message: "choose sales invoice" },
                      ]}
                    >
                      <Select
                        size="large"
                        onChange={(val: any) => {
                          let selectedInvoice = customerSalesData.find(
                            (item: any) => item.invoiceno === val
                          );
                          setSelectedInvoice(selectedInvoice);
                        }}
                        loading={loadingInvoices}
                      >
                        {customerSalesData &&
                          customerSalesData?.length &&
                          customerSalesData?.map((item: any) => {
                            return (
                              <Select.Option
                                key={item.id}
                                value={item.invoiceno}
                              >
                                {item.invoiceno}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Sales Ledger</div>
                    <Form.Item
                      name={"ledger"}
                      rules={[{ required: true, message: "choose ledger" }]}
                      // initialValue={}
                    >
                      <Select size="large">
                        {props.ledgers &&
                          props.ledgers.length &&
                          props?.ledgers
                            .filter((item: any) => item.id === 20704)
                            .map((item: any) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.nominalcode + "-" + item.laccount}
                              </Select.Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Invoice Date</div>
                    <Form.Item
                      name={"sdate"}
                      initialValue={dayjs(selectedInvoice.data)}
                    >
                      <DatePicker style={{ width: "100%" }} size="large" />
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Reference</div>
                    <Form.Item name={"reference"}>
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                </Row>

                {columnData.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "100px",
                      backgroundColor: "lightgrey",
                      width: "100%",
                      marginBottom: "30px",
                    }}
                  >
                    <h6 style={{ width: "100%", textAlign: "center" }}>
                      Choose customer sales invoice
                    </h6>
                  </div>
                ) : (
                  <div style={{ marginBottom: "50px" }}>
                    <Table bordered style={{ backgroundColor: "lightgrey" }}>
                      <thead>
                        <div style={{ textAlign: "center", width: "100%" }}>
                          Sale Invoice Items
                        </div>
                        <tr>
                          <th>PRODUCT</th>
                          <th>QTY</th>
                          <th>PRICE</th>
                          <th>TAX %</th>
                          <th>TAX AMT</th>
                          <th>INC TAX</th>
                          <th>DISC %</th>
                          <th>DISC AMT</th>
                          <th>TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {columnData?.map(
                          (field: any, index: number, val: any) => {
                            return (
                              <>
                                <tr>
                                  <td>{field.description}</td>
                                  <td>{field.quantity}</td>
                                  <td>{field.price}</td>
                                  <td>{field.vat} %</td>
                                  <td>{field.vatamount}</td>
                                  <td className="d-flex justify-content-center">
                                    <Checkbox
                                      checked={
                                        field.includeVat === true ? true : false
                                      }
                                    />
                                  </td>
                                  <td>{field.discount} %</td>
                                  <td>{field.discountAmt}</td>
                                  <td>{field.total}</td>
                                </tr>
                              </>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}

                {products && (
                  <Items
                    form={form}
                    products={products}
                    taxLists={props.taxList}
                    isCheckChange={(val: any) => setIsCheck(val)}
                  />
                )}
                <Row>
                  <Col sm={6}>
                    <div className="formLabel">Terms and Conditions</div>
                    <Form.Item
                      name={"terms"}
                      rules={[{ message: "enter terms" }]}
                    >
                      <Input.TextArea rows={4} size="large" />
                    </Form.Item>
                  </Col>
                  <Col sm={6}>
                    <div className="formLabel">Note</div>
                    <Form.Item
                      name={"quotes"}
                      rules={[{ message: "enter note" }]}
                    >
                      <Input.TextArea rows={4} size="large" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}></Col>
                  <Col sm={6}>
                    <Table bordered>
                      <tbody>
                        <tr>
                          <td>TAXABLE VALUE</td>
                          <td>{subTotal.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>TOTAL VAT</td>
                          <td>{tatalVat.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>OVERALL DISCOUNT</td>
                          <td>{overollDiscount.toFixed(2)}</td>
                        </tr>
                        {/* <tr>
                          <td>ROUND OFF</td>
                          <td className="p-1">
                            <Input
                              className="p-1"
                              type="number"
                              bordered={false}
                              value={roundOff}
                              onChange={(e: any) => {
                                setRoundOff(e.target.value);
                                let round = Number(e.target.value);
                                let _totalAmount =
                                  subTotal + tatalVat - overollDiscount;
                                _totalAmount = _totalAmount - round;
                                setTotalAmount(_totalAmount);
                              }}
                            />
                          </td>
                        </tr> */}
                        <tr>
                          <td>TOTAL AMOUNT</td>
                          <td>{totalAmount.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <Row>
                      <Col sm={6}>
                        <Button
                          size="large"
                          block
                          onClick={() => navigate("/usr/sales-invoice")}
                        >
                          Close
                        </Button>
                      </Col>
                      <Col sm={6}>
                        <Button
                          size="large"
                          block
                          type="primary"
                          htmlType="submit"
                          loading={loadingForm}
                        >
                          Create
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </Card>
        </Container>
      )}
      {modalOpen ? (
        <PrintModal
          open={modalOpen}
          modalClose={(val: any) => setModalOpen(val)}
          template={template}
        />
      ) : null}
    </div>
  );
}
export default Create;
