import React, { useEffect, useState } from "react";
import PageHeader from "../../../../components/pageHeader";
import API from "../../../../config/api";
import { GET, POST } from "../../../../utils/apiCalls";
import DataTable from "./Table";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { Card, notification } from "antd";

function Projectreport() {
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(12);
  const [meta, setMeta] = useState<any>({});
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = useForm();
  console.log("reportData----->", reportData);
  const getAllReportData = async () => {
    try {
      let api = API.POST_HR_REPORT;
      const response: any = await POST(api, {});
      console.log("Data history", response);

      if (response) {
        setReportData(response?.data);
      } else {
        console.error("Failed to fetch data from the API.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllReportData();
  }, []);
  const fetchHrProject = async () => {
    try {
      setIsLoading(true);
      let obj = {
        page: page,
        take: take,
      };
      const url = API.GET_PROJECT_PAGE;
      const response: any = await POST(url, obj);
      if (response?.status) {
        setData(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notification.error({
          message: "Oops !",
          description: "Project data not found",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      notification.error({
        message: "Oops !",
        description: "Project data not found",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageHeader title="Project Report" /> <br />
      <div>
        <DataTable dataSource={reportData} />
      </div>
    </>
  );
}

export default Projectreport;
