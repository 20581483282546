import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  message,
  notification,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingBox from "../../../../../../components/loadingBox";
import API from "../../../../../../config/api";
import { GET, POST, PUT } from "../../../../../../utils/apiCalls";
import RecieptTable from "../component/recieptTable";
import PageHeader from "../../../../../../components/pageHeader";
import moment from "moment";

function SupplierRefund(props: any) {
  const { user } = useSelector((state: any) => state.User);
  const [form] = Form.useForm();
  const { id, update } = useParams();

  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [customer, setCustomer] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [load, setLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [supplierData, setSupplierData] = useState<any>();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [initialData, setInitialData] = useState<any>();
  const [value, setValue] = useState("");
  const [saleId, setSaleId] = useState("");
  const [routAmount, setRoutAmount] = useState<any>();

  useEffect(() => {
    supplierList();
  }, [value]);
  useEffect(() => {
    update && legderDetails();
  }, []);
  const legderDetails = async () => {
    let url = API.LEDGER_DEATAILS + Number(update);

    try {
      const data: any = await GET(url, null);
      let edit = data?.data;
      setSaleId(edit?.purchaseid);
      setInitialData(data?.data);
      handleSelect(edit?.cname);
      form.setFieldsValue({
        account_name: Number(edit?.cname),
        payment_mode: edit?.paidmethod,
        reciept_date: dayjs(edit?.sdate),
        reference: edit?.reference,
        amount_paid: edit?.debit,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const supplierList = async () => {
    try {
      let url = API.CONTACT_MASTER_SEARCHLIST + user.adminid + `?name=${value}`;
      const data: any = await GET(url, null);
      setSupplierData(data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const screditColumns = [
    {
      name: "date",
      title: "Date",
      dataType: "date",
      alignment: "center",
      format: "dd-MM-yyyy",
    },
    {
      name: "invoiceno",
      title: "Invoiceno",
      dataType: "string",
      alignment: "center",
      cellRender: "Invoiceno",
    },
    {
      name: "type",
      title: "Type",
      dataType: "string",
      alignment: "center",
      cellRender: "Type",
    },
    {
      name: "rout",
      title: "Outstanding",
      dataType: "number",
      alignment: "center",
      cellRender: "Outstanding",
    },
    {
      name: "total",
      title: "Total",
      dataType: "number",
      alignment: "center",
    },
  ];
  let paymentMode = [
    { id: 1, value: "Cheque", name: "Cheque" },
    { id: 2, value: "other", name: "Electronic" },
    { id: 3, value: "card", name: "Credit Card" },
    { id: 4, value: "card", name: "Debit Card" },
    { id: 5, value: "loan", name: "PayPal" },
  ];
  const handleSelect = async (val: any) => {
    setCustomer(val);
    try {
      let url = API.SUPPLIER_PAY_LIST + `${val}/` + user?.adminid;
      const data: any = await GET(url, null);
      setData(data?.data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSelectRow = (val: any) => {
    setLoad(true);

    if (val) {
      setSelectedRow(val);
      setLoad(false);
      const totalSum = val
        ?.map((item: any) => parseFloat(item?.rout))
        .filter((value: number) => !isNaN(value))
        .reduce((sum: number, value: number) => sum + value, 0);
      setRoutAmount(totalSum);

      form.setFieldsValue({
        amount_paid: totalSum,
      });
    }
  };
  const onFinish = async (val: any) => {
    setIsLoading(true);
    try {
      let url = update
        ? API.UPDATE_BANK_DETAILS + Number(update)
        : API.CREATE_SUPPLIER_REFUND;

      let item = [
        {
          amountpaid: val?.amount_paid,
          sname: val?.sname,
          total: selectedRow[0]?.total,
          duplicateout: selectedRow[0]?.duplicateout,
          id: selectedRow[0]?.id,
          checked: 1,
          type: selectedRow[0]?.type,
          date: selectedRow[0]?.date,
          remainout:
            Number(selectedRow[0]?.duplicateout) - Number(val?.amount_paid),
          invoiceno: selectedRow[0]?.invoiceno,
          ledgercategory: selectedRow[0]?.ledgercategory,
          ledgerid: selectedRow[0]?.ledgerid,
          rout: Number(selectedRow[0]?.duplicateout) - Number(val?.amount_paid),
          outstanding: Number(selectedRow[0]?.rout) - Number(val?.amount_paid),
        },
      ];

      let body1 = {
        userid: user.id,
        adminid: user.adminid,
        cname: customer,
        customer_name: data[0]?.sname || data[0]?.cname,
        item: item,
        amount: update ? parseInt(val?.amount_paid) : val?.amount_paid,
        reference: val?.reference,
        paidmethod: val?.payment_mode,
        paidto: id,
        logintype: "user",
        sdate: val.reciept_date,
        receipttype: "Supplier Refund",
        outstanding: Number(selectedRow[0]?.rout) - Number(val?.amount_paid),
      };

      const amt = Number(initialData?.debit) - Number(val?.amount_paid);
      const out = Number(initialData?.outstanding) + Number(amt);
      const running = Number(initialData?.running_total) - amt;

      let body2 = {
        cname: customer,
        reference: val?.reference,
        debit: Number(val.amount_paid),
        receipttype: "Supplier Refund",
        userid: user.id,
        adminid: user.adminid,
        outstanding: out,
        purchaseid: selectedRow[0]?.id,
        rout: selectedRow ? selectedRow[0]?.rout : null,
        customer_name: data[0]?.sname || data[0]?.cname,
        item: {
          amountpaid: val?.amount_paid,
          sname: val?.sname,
          total: selectedRow[0]?.total,
          duplicateout: selectedRow[0]?.duplicateout,
          id: selectedRow[0]?.id,
          checked: 1,
          type: selectedRow[0]?.type,
          date: selectedRow[0]?.date,
          remainout: out,
          invoiceno: selectedRow[0]?.invoiceno,
          ledgercategory: selectedRow[0]?.ledgercategory,
          ledgerid: selectedRow[0]?.ledgerid,
          rout: out,
          outstanding: out,
        },
        paidmethod: val?.payment_mode,
        paidto: id,
        logintype: "user",
        sdate: val.reciept_date,
        type: "Supplier Refund",
        running_total: running,
      };

      const res: any = update ? await PUT(url, body2) : await POST(url, body1);

      if (res?.status) {
        notification.success({
          message:"Success" ,description: update  ? "Supplier refund updated successfully" : "Supplier refund created successfully"
        });
        navigate(`/usr/cashBank/${id}/details/transaction`);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notification.error({
          message:"Failed" ,description: update ? "Failed to update supplier refund" : "Failed to create supplier refund"
        });
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      notification.error({
        message:"Server Error" ,description: update ? "Failed to update supplier refund!! Please try again later " : "Failed to create supplier refund!! Please try again later"
      });
    }
  };

  const onValuesChange = (val: any) => {
    if (Number(val.amount_paid) > Number(routAmount)) {
      notification.error({
        message: "You cannot pay more than the outstanding amount.",
      });
      form.setFieldsValue({
        amount: Number(routAmount),
      });
    }
  };

  return (
    <>
      {update && (
        <>
          <PageHeader
            title="Payment"
            firstPathLink={"/usr/cashBank"}
            firstPathText={"Bank"}
            secondPathLink={`/usr/cashBank/${id}/details`}
            secondPathText={"Bank Details"}
            thirdPathLink={`/usr/cashBank/${id}/details/reciept/customer`}
            thirdPathText={"payment"}
            // goback={() => navigate(`/usr/cashBank/${id}/details/transaction`)}
          />
          <br />
        </>
      )}
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Container>
          <Form onFinish={onFinish} form={form} onValuesChange={onValuesChange}>
            <Card>
              <Col className="Table-Txt" md={12}>
                Add/Update Supplier Refund
              </Col>
              <Col md={12}>
                Manage your non invoice payments to suppliers - payment with
                ledgers
              </Col>
              <br />
              <br />
              <hr />
              <Row>
                <Col md={4}>
                  <div>
                    <label className="formLabel">Account Name</label>
                    <Form.Item
                      name={"account_name"}
                      rules={[{ required: true }]}
                    >
                      <Select
                        onChange={handleSelect}
                        showSearch
                        placeholder="choose a supplier"
                        onSearch={(val) => setValue(val)}
                        allowClear
                        filterOption={false}
                      >
                        {supplierData?.map((item: any) => (
                          <Select.Option value={item?.id}>
                            {item?.bus_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label className="formLabel">Payment Method</label>
                    <Form.Item
                      name={"payment_mode"}
                      rules={[{ required: true }]}
                    >
                      <Select>
                        {paymentMode?.map((item: any) => (
                          <Select.Option key={item?.value}>
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label className="formLabel">Reciept Date</label>
                    <Form.Item
                      name={"reciept_date"}
                      initialValue={dayjs(new Date())}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format={"YYYY-MM-DD"}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={4}>
                  <div>
                    <label className="formLabel">Reference</label>
                    <Form.Item name={"reference"}>
                      <Input />
                    </Form.Item>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label className="formLabel">Amount Paid</label>
                    <Form.Item
                      name={"amount_paid"}
                      rules={[{ required: true }]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </div>
                </Col>
                {data?.length > 0 && (
                  <Col md={{ span: 3, offset: 9 }}>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                )}
              </Row>
              <br />
              {/* {selectedRow && selectedRow?.length > 0 && (
                <>
                  {load ? (
                    <LoadingBox />
                  ) : (
                    <>
                      <RecieptTable
                        products={selectedRow}
                        columns={screditColumns}
                        saleId={saleId}
                      />
                      <br />
                    </>
                  )}
                </>
              )} */}
              {/* <br /> */}
              <br />
              {data?.length > 0 && (
                <RecieptTable
                  products={data}
                  columns={screditColumns}
                  saleId={saleId}
                  tableHead={"Select Supplier Refund and Invoices."}
                  type={"supplier-refund"}
                  onSelectedData={(data: any) => handleSelectRow(data)}
                />
              )}
            </Card>
          </Form>
        </Container>
      )}
    </>
  );
}

export default SupplierRefund;
