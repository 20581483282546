import { useEffect, useState } from "react";
import PageHeader from "../../../components/pageHeader";
import { useNavigate } from "react-router-dom";
import API from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";
import DataTable from "./dataTable";
import { Button, notification, Pagination, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import ExcelImport from "./components/excelImport";
import { SiMicrosoftexcel } from "react-icons/si";
import { useTranslation } from "react-i18next";

function HrProjects() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(12);
  const [meta, setMeta] = useState<any>({});
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = useForm();
  const [excelModal, setExcelModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchHrProject();
  }, [page]);

  const columns = [
    {
      dataField: "projectId",
      title: "Project Id",
    },
    {
      dataField: "title",
      title: "Title",
    },
    {
      dataField: "priority",
      title: "Priority",
    },
    {
      dataField: "description",
      title: "Description",
    },
    {
      dataField: "startDate",
      title: "Start Date",
    },
    {
      dataField: "endDate",
      title: "End Date",
    },
  ];

  const fetchHrProject = async () => {
    try {
      setIsLoading(true);
      let obj = {
        query: form.getFieldValue("query"),
        page: page,
        take: take,
      };
      const url = API.GET_PROJECT_PAGE;
      const response: any = await POST(url, obj);
      if (response?.status) {
        setData(response.data);
        setMeta(response?.meta);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notification.error({
          message: "Oops !",
          description: "Project data not found",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      notification.error({
        message: "Oops !",
        description: "Project data not found",
      });
    } finally {
      setIsLoading(false);
    }
  };

  console.log("meta ---> ", meta?.total_count);

  return (
    <>
      <PageHeader
        firstPathText="project"
        buttonTxt={"CREATE"}
        onSubmit={() => navigate("/usr/hr-projects/create/null")}
        title="Projects"
      >
        <Tooltip title={t("home_page.homepage.Import_from_Excel")}>
          <Button onClick={() => setExcelModal(true)}>
            <SiMicrosoftexcel size={20} />
          </Button>
        </Tooltip>

        <ExcelImport
          visible={excelModal}
          onCancel={() => setExcelModal(false)}
          onSucess={() => fetchHrProject()}
          URL={API.PROJECT_EXCEL}
        />
      </PageHeader>

      <div>
        <DataTable
          columns={columns}
          list={data}
          isLoading={isLoading}
          meta={meta}
          onPageChange={(page: number, pageSize: number) => {
            setIsLoading(true);
            setPage(page);
            setTake(pageSize);
          }}
        />
      </div>
      <div style={{ textAlign: "center", marginTop: "10px" }}></div>
    </>
  );
}

export default HrProjects;
