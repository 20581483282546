import WebsiteHeader from "../../components/websiteHeader";
import WebsiteFooter from "../../components/websiteFooter";
import { Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Input, Form, Button, notification, InputNumber } from "antd";
import { IoCallOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { CiGlobe } from "react-icons/ci";
import "./styles.scss";
import { POST } from "../../utils/apiCalls";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
function Contact(props:any) {
  const { t } = props;
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const SendEmail = async (index: any) => {
    setIsLoading(true);
    let mailBody = {
      name: index.fullname,
      email: index.email,
      phone: index.phone,
      message: index.message,
    };
    try {
      const add_supplier_url = "contactus/add";
      const { data, message }: any = await POST(add_supplier_url, mailBody);
      if (data) {
        setIsLoading(false);
        notification.success({
          message: "Success",
          description: message,
        });
        form.resetFields();
      } else {
        setIsLoading(false);

        notification.error({
          message: "Error",
          description: "Your request failed..!",
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      notification.error({
        message: "Error",
        description: "Your request failed..!",
      });
    }
  };

  return (
    <div className="website-screens">
      <WebsiteHeader />
      <br />
      <Container>
        <Form onFinish={SendEmail} form={form}>
          <Row className="g-0">
            <Col md={6}>
              <div className="contact-box1">
                <p className="contactBox-tex1">
                  {t("home_page.homepage.Let_s")}
                </p>
                {t("home_page.homepage.Got_Any_body")}
                <br />
                <br />
                <div className="contact-box2">
                  <CiLocationOn size={27} color="#ff9800 " />
                  <div style={{ paddingLeft: "10px", color: "black" }}>
                    <a
                      style={{ color: "black", textDecoration: "none" }}
                      href="https://www.google.com/maps/place/4+Damastown+Way,+Macetown+South,+Dublin,+Ireland/@53.412584,-6.4087859,17z/data=!3m1!4b1!4m5!3m4!1s0x48676d49afa44f5d:0xb3654f1e161deda3!8m2!3d53.4125808!4d-6.406211?entry=ttu"
                    >
                      {t("home_page.homepage.address")}
                    </a>
                  </div>
                </div>
                <br />
                <div className="contact-box2">
                  <IoCallOutline size={23} color="#ff9800 " />

                  <div style={{ paddingLeft: "10px", color: "black" }}>
                    {" "}
                    <a
                      style={{ color: "black", textDecoration: "none" }}
                      href="tel:+35315549629"
                    >
                      +35315549629
                    </a>
                  </div>
                </div>
                <br />
                <div className="contact-box2">
                  <CiMail size={23} color="#ff9800 " />

                  <div style={{ paddingLeft: "10px", color: "black" }}>
                    <a
                      style={{ color: "black", textDecoration: "none" }}
                      href="mailto:info@taxgoglobal.com"
                    >
                      info@taxgoglobal.com
                    </a>
                  </div>
                </div>
                <br />
                <div className="contact-box2">
                  <CiGlobe size={26} color="#ff9800 " />

                  <div style={{ paddingLeft: "10px", color: "black" }}>
                    www.taxgoglobal.com
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="contact-box3">
                <label className="formLabel">
                  {t("home_page.homepage.name")}
                </label>
                <Form.Item name="fullname">
                  <Input type="text" size="large" />
                </Form.Item>
                <label className="formLabel">
                  {t("home_page.homepage.Email")}
                </label>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input size="large"></Input>
                </Form.Item>
                <label className="formLabel">
                  {t("home_page.homepage.mobile")}
                </label>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your number",
                    },
                  ]}
                >
                  <InputNumber
                    type="number"
                    size="large"
                    controls={false}
                    style={{ width: '100%' }}
                  ></InputNumber>
                </Form.Item>
                <label className="formLabel">
                  {t("home_page.homepage.message")}
                </label>
                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a message!",
                    },
                    {
                      min: 5,
                      message: "Message must be at least 5 characters!",
                    },
                  ]}
                >
                  <Input.TextArea
                    style={{ width: "100%" }}
                    rows={3}
                  ></Input.TextArea>
                </Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="contactBox-btn"
                  loading={isLoading}
                >
                  {t("home_page.homepage.send_message")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
      <br />
      <WebsiteFooter />
    </div>
  );
}

export default withTranslation()(Contact);
