import React, { useEffect, useState } from "react";
import PageHeader from "../../../../../components/pageHeader";
import { Container, Table } from "react-bootstrap";
import { Card } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { GET } from "../../../../../utils/apiCalls";
import API from "../../../../../config/api";
import moment from "moment";
import LoadingBox from "../../../../../components/loadingBox";

function ViewPurchasePayment() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState<any>([]);
  const { id: transactionid, purchaseType: type } = useParams();

  const fetchDetails = async () => {
    try {
      setIsLoading(true);
      const url = API.LEDGER_DEATAILS + `${transactionid}`;
      const { data }: any = await GET(url, null);
      let datas: any = [data];
      setDetails(datas);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);
  return (
    <div>
      <PageHeader
        firstPathLink={"/usr/cashBank"}
        firstPathText={"Bank"}
        //    secondPathLink={`/usr/cashBank/${transactionid}/details`}
        secondPathText={"Bank Details"}
        thirdPathLink={location.pathname}
        thirdPathText={"View"}
        goback={-1}
        title={
          type == "supplier-payment"
            ? "View Supplier Payment"
            : type == "other-payment"
            ? "Other Payment"
            : type == "customer-refund"
            ? "Customer Refund"
            : type == "other-receipt"
            ? "View Other Reciept"
            : type == "customer-receipt"
            ? "View Customer Reciept"
            : type == "supplier-refund"
            ? "Supplier Refund"
            : ""
        }
      />
      <Container>
        <br />
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Card>
            {type == "other-payment" || type == "supplier-payment" || type == "customer-refund" ? (
              <Table>
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>Account Name</th>
                    <th>PAID METHOD</th>
                    <th>REFERENCE</th>
                    <th>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {details?.map((item: any) => (
                    <tr>
                      <td>{moment(item?.userdate).format("DD-MM-YYYY")}</td>
                      <td>{item?.name}</td>
                      <td>
                        {item?.paidmethod || item?.ledgerDetails?.paidmethod}
                      </td>
                      <td>{item?.reference === "" || item?.reference === null ? "-" : item?.reference }</td>
                      <td>
                        {item?.credit }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : type == "customer-receipt" || type == "other-receipt" ||  type == "supplier-refund" ? (
              <Table>
                <thead>
                  <tr>
                    <th>TRANSACTION DATE</th>
                    <th>ACCOUNT NAME</th>
                    <th>PAID TO/FROM</th>
                    <th>PAID METHOD</th>
                    <th>REFERENCE</th>
                    <th>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {details?.map((item: any) => (
                    <tr>
                      <td>{moment(item?.userdate).format("DD-MM-YYYY")}</td>
                      <td>{item?.name}</td>
                      <td>{item?.ledgername}</td>
                      <td>{item?.paidmethod}</td>
                      <td>{item?.reference === "" || item?.reference === null ? "-" : item?.reference }</td>
                      <td>{item?.debit}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              ""
            )}
          </Card>
        )}
      </Container>
    </div>
  );
}

export default ViewPurchasePayment;
