import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  TimePicker,
  notification,
  Pagination,
} from "antd";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import API from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import moment from "moment";
import exportToExcel from "../../../utils/exportexcel";
import PageHeader from "../../../components/pageHeader";
import { Container } from "react-bootstrap";

const { Option } = Select;

const HrTimesheet: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [timesheetData, setTimesheetData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.User.user);

  const fetchEmployees = async () => {
    try {
      setIsLoading(true);
      const { data }: any = await GET(API.GET_HR_EMPLOYEE_LISTING, {});
      setEmployeeData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchHrTimesheet = async () => {
    try {
      setIsLoading(true);
      const api = `${API.GET_TIMESHEET_FILTER}?order=ASC&page=${currentPage}&take=${pageSize}`;
      const obj = {
        employeeId: selectedEmployee,
        date: selectedDate,
      };
      const { data, meta }: any = await POST(api, obj);
      setTimesheetData(data);
      setTotalItems(meta.itemCount);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    fetchHrTimesheet();
  }, [currentPage, pageSize, selectedEmployee, selectedDate]);

  const handleEmployeeChange = (value: string) => {
    setSelectedEmployee(value);
    setCurrentPage(1);
  };

  const handleDateChange = (date: any, dateString: string) => {
    setSelectedDate(dateString);
    setCurrentPage(1);
  };

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize || 6);
  };
  const handleExportToExcel = () => {
    const data = timesheetData?.map((item: any) => {
      return {
        EmployeeName: item.employee.fullName,
        Project: item.project.project.title,
        Task: item.project?.title,
        Description: item.description,
        Remark: item.remark,
        Hours: item.hours,
        Minutes: item.minutes,
        Date: moment(item.Date).format("DD-MM-YYYY"),
        CreatedAt: moment(item.createdAt).format("DD-MM-YYYY"),
        UpdatedAt: moment(item.updatedAt).format("DD-MM-YYYY"),
      };
    });

    exportToExcel(data, "exported_data.xlsx");
  };

  const columns = [
    { title: "Employee ID", dataIndex: "employeeId" },
    {
      title: "Project",
      dataIndex: "title",
      key: "title",
      render: (_: any, record: any) => <span>{record?.project?.title}</span>,
    },

    {
      title: "Task",
      dataIndex: "title",
      key: "title",
      render: (_: any, record: any) => <span>{record?.task?.title}</span>,
    },
    { title: "Description", dataIndex: "description" },
    { title: "Remark", dataIndex: "remark" },
    { title: "Hours", dataIndex: "hours" },
    { title: "Minutes", dataIndex: "minutes" },
  ];

  return (
    <>
      <PageHeader title="Timesheet Data" />
      <br />
      <div>
        <Container>
          <Card>
            <br />
            <Row gutter={16} style={{ marginBottom: "16px" }}>
              <Col span={8}>
                <Form.Item name="employeeName">
                  <Select
                    style={{ width: "200px" }}
                    placeholder="Select Employee"
                    onChange={handleEmployeeChange}
                    value={selectedEmployee}
                    allowClear
                  >
                    {employeeData.map((employee: any) => (
                      <Option key={employee.id} value={employee.id}>
                        {employee.fullName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="selectedDate">
                  <DatePicker
                    style={{ width: "200px" }}
                    onChange={handleDateChange}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Button type="primary" onClick={handleExportToExcel}>
                  Export to Excel
                </Button>
              </Col>
            </Row>
          </Card>
          <br />
          <Card>
            {isLoading ? (
              <div className="">Loading...</div>
            ) : (
              <div>
                <h4 style={{ textAlign: "center" }}>Timesheet details</h4>
                <br />
                <h6>Total {totalItems} Timesheet Records</h6>
                <br />
                <Table
                  columns={columns}
                  dataSource={timesheetData}
                  loading={isLoading}
                  pagination={false}
                />
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalItems}
                    onChange={handlePaginationChange}
                    style={{
                      display: totalItems > 10 ? "block" : "none",
                      textAlign: "right",
                      marginTop: "20px",
                    }}
                  />
                </div>
              </div>
            )}
          </Card>
        </Container>
      </div>
    </>
  );
};
export default HrTimesheet;
