import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import image1 from "../../assets/images/clockimg.png";
import image2 from "../../assets/images/sunimg.png";
import image3 from "../../assets/images/bookimg.png";
import { withTranslation } from "react-i18next";
function DriveUs(props: any) {
  const { t } = props;
  const data = [
    {
      icon: image1,
      text: `${t("home_page.homepage.Long_head")}`,
      description: `${t("home_page.homepage.Long_body")}`,
    },
    {
      icon: image2,
      text: `${t("home_page.homepage.Research_head")}`,
      description: `${t("home_page.homepage.Research_body")}`,
      backgroundcolour: "#F4F6F8",
    },
    {
      icon: image3,
      text: `${t("home_page.homepage.Customer_head")}`,
      description: `${t("home_page.homepage.Customer_body")}`,
    },
  ];
  return (
    <div>
      <Container>
        <div className="driveUs-Box1">
          <div className="driveUs-Box2">
            {t("home_page.homepage.The_Core_Value")}
          </div>
          <Row className="g-3">
            {data.map((item, index) => (
              <Col md={4} key={index}>
                <div className="DriveUs-Box4">
                  <div
                    className="driveUs-Box5"
                    style={{ backgroundColor: `${item.backgroundcolour}` }}
                  >
                    <div>
                      <div
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        <div className="driveUs-heading">
                          <img className="driveUs-img" src={item.icon} />
                          {item.text}
                        </div>
                      </div>
                    </div>
                    <div className="driveUs-text">{item.description}</div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default withTranslation()(DriveUs);
