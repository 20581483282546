import { useState } from "react";
import "./styles.scss";
import SideItem from "./components/sideItem";
// import Data from "./privilages.json";
import { RiFlashlightLine } from "react-icons/ri";
import { BsPrinter } from "react-icons/bs";
import { Popover } from "antd";
import Subscription from "./components/subscription";
import ProfileSider from "./components/ProfileSider";
import { useNavigate } from "react-router";
import logo from "../../src/assets/images/logo.png";
import logo2 from "../../src/assets/images/logo2.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function SiderBar(props: any) {
  const { t } = useTranslation();
  const [expand, setexpand] = useState(0);
  const navigate = useNavigate();
  const { user }: any = useSelector((state: any) => state.User);

  const Data = [
    {
      id: 1,
      name: `${t("sidebar.title.dashboard")}`,
      route: "/usr/dashboard",
      icon: "IoGridOutline",
      submenu: [],
    },
    {
      id: 2,
      name: `${t("home_page.homepage.Product_Service")}`,
      route: "/usr/product",
      icon: "RiShoppingBagLine",
      submenu: [
        {
          id: 1,
          name: `${t("home_page.homepage.Stock")}`,
          route: "/usr/productStock",
          icon: "PiChartLineUpBold",
        },
        {
          id: 2,
          name: `${t("home_page.homepage.Non_Stock")}`,
          route: "/usr/productNonStock",
          icon: "IoBan",
        },
        {
          id: 3,
          name: `${t("home_page.homepage.service")}`,
          route: "/usr/productService",
          icon: "RiSignalTowerLine",
        },
      ],
    },
    {
      id: 3,
      name: `${t("sidebar.title.sale")}`,
      route: "/usr/sale",
      icon: "FiTag",
      submenu: [
        {
          id: 1,
          name: `${t("home_page.homepage.Sales_Invoice")}`,
          route: "/usr/sales-invoice",
          icon: "LiaFileInvoiceSolid",
        },
        {
          id: 2,
          name: `${t("sidebar.title.proformaInvoice")}`,
          route: "/usr/sales-proforma-invoice",
          icon: "GoChecklist",
        },
        {
          id: 3,
          name: `${t("sidebar.title.cnote")}`,
          route: "/usr/salesCredit",
          icon: "HiOutlineClipboardDocumentList",
        },
        {
          id: 5,
          name: `${t("home_page.homepage.Reccuring_Notification")}`,
          route: "/usr/sales-reccuring-notification",
          icon: "TbChecklist",
        },
      ],
    },
    {
      id: 4,
      name: `${t("sidebar.title.purchase")}`,
      route: "/usr/Purchase",
      icon: "FiShoppingCart",
      submenu: [
        {
          id: 1,
          name: `${t("sidebar.title.purchace_stock")}`,
          route: "/usr/purchace-invoice",
          icon: "MdShoppingCartCheckout",
        },
        {
          id: 2,
          name: `${t("home_page.homepage.Debit_Notes")}`,
          route: "/usr/purchase-debit-note",
          icon: "CgNotes",
        },
        {
          id: 3,
          name: `${t("sidebar.title.purchace_assets")}`,
          route: "/usr/purchase-fore-assets",
          icon: "FiShoppingBag",
        },
      ],
    },
    {
      id: 5,
      name: `${t("sidebar.title.contact")}`,
      route: "/usr/contacts",
      icon: "FaRegUser",
      submenu: [
        {
          id: 1,
          name: `${t("sidebar.title.customer")}`,
          route: "/usr/contactCustomers",
          icon: "LuUser2",
        },
        {
          id: 1,
          name: `${t("sidebar.title.supplier")}`,
          route: "/usr/contactSuppliers",
          icon: "LiaLuggageCartSolid",
        },
      ],
    },
    {
      id: 6,
      name: `${t("sidebar.title.journal")}`,
      route: "/usr/journal",
      icon: "FiBook",
      submenu: [],
    },
    {
      id: 7,
      name: `${t("sidebar.title.bank")}`,
      route: "/usr/cashBank",
      icon: "TbBuildingBank",
      submenu: [],
    },
    {
      id: 8,
      name: `${t("sidebar.title.cash")}`,
      route: "/usr/cash",
      icon: "TbMoneybag",
      submenu: [],
    },
    {
      id: 9,
      name: `${t("home_page.homepage.Report")}`,
      route: "/usr/report",
      icon: "IoBarChartOutline",
      submenu: [],
    },
    {
      id: 10,
      name: `${t("home_page.homepage.Ledger")}`,
      route: "/usr/ledgers",
      icon: "LuClipboardList",
      submenu: [
        {
          id: 1,
          name: `${t("sidebar.title.myledger")}`,
          route: "/usr/ledgerMyLedger",
          icon: "CiViewList",
        },
        {
          id: 2,
          name: `${t("sidebar.title.defaultledger")}`,
          route: "/usr/ledgerDefaultLedger",
          icon: "SlNotebook",
        },
        {
          id: 3,
          name: `${t("sidebar.title.mycategory")}`,
          route: "/usr/ledgerMyCategory",
          icon: "SlNote",
        },
        {
          id: 4,
          name: `${t("home_page.homepage.Defualt_Category")}`,
          route: "/usr/ledgerDefaultCategory",
          icon: "LuBookOpen",
        },
        {
          id: 5,
          name: `${t("home_page.homepage.Fixed_Asset")}`,
          route: "/usr/purchaseFixedAsset",
          icon: "RiSignalTowerLine",
        },
        // {
        //   id: 6,
        //   name: `${t("home_page.homepage.Pay_Heads")}`,
        //   route: "/usr/payHead",
        //   icon: "CiViewList",
        // },
      ],
    },
    // {
    //   id: 13,
    //   name: `${t("home_page.homepage.payroll")}`,
    //   route: "/usr/payroll/paysheet",
    //   icon: "MdAttachMoney",
    //   submenu: [
    //     {
    //       id: 1,
    //       name: `${t("home_page.homepage.Pay_Sheet")}`,
    //       route: "/usr/payroll/paysheet",
    //       icon: "CgNotes",
    //     },
    //     {
    //       id: 2,
    //       name: `${t("home_page.homepage.Employees")}`,
    //       route: "/usr/payroll/employees",
    //       icon: "MdOutlineGroup",
    //     },
    //   ],
    // },
    {
      id: 11,
      name: `${t("home_page.homepage.Proposal")}`,
      route: "/usr/proposal",
      icon: "MdOutlineStickyNote2",
      submenu: [],
    },
    {
      id: 13,
      name: "HR",
      route: "/usr/hr",
      icon: "BsPeopleFill",
      submenu: [
        {
          id: 1,
          name: `HR DASHBOARD`,
          route: "/usr/hr-dashboard",
          icon: "IoGridOutline",
        },
        {
          id: 2,
          name: `Employeee`,
          route: "/usr/hr-employees",
          icon: "LuUser2",
        },
        {
          id: 3,
          name: `Timesheet`,
          route: "/usr/hr-timesheet",
          icon: "SlNotebook",
        },
        {
          id: 4,
          name: `Projects`,
          route: "/usr/hr/hr-projects",
          icon: "SlNote",
        },
        {
          id: 5,
          name: `Leaves`,
          route: "/usr/hr-leave",
          icon: "LuBookOpen",
        },
        {
          id: 6,
          name: `Project Payments`,
          route: "/usr/projectpayments",
          icon: "TbMoneybag",
        },
        {
          id: 7,
          name: `Client`,
          route: "/usr/hr-client",
          icon: "FaRegUser",
        },
        {
          id: 8,
          name: `Payroll`,
          route: "/usr/hr-payroll",
          icon: "MdAttachMoney",
        },
        {
          id: 9,
          name: `Report`,
          route: "/usr/hr-report",
          icon: "IoBarChartOutline",
        },
      ],
    },
    {
      id: 12,
      name: `${t("home_page.homepage.Settings")}`,
      route: "/usr/settings",
      icon: "CiSettings",
      submenu: [],
    },
  ];

  return (
    <div className={props.collapsed ? "" : "Navigation-SiderBar scroll"}>
      <div onClick={() => navigate("/usr/dashboard")}>
        {props.collapsed === true ? (
          <img style={{ width: 70, height: 60 }} src={logo} alt="logo" />
        ) : (
          <img
            style={{ width: 200, height: 60, objectFit: "cover" }}
            src={logo2}
            alt="logo2"
          />
        )}
      </div>
      <div>
        <ProfileSider collapsed={props.collapsed} />
      </div>
      {props.collapsed ? (
        <div style={{ margin: 10 }} />
      ) : (
        <div
          className="SiderBar-txt1"
          onClick={() => navigate("profile/subscription")}
        >
          {t("home_page.homepage.SUBSCRIPTION")}
        </div>
      )}
      <Popover
        content={<Subscription />}
        title={t("home_page.homepage.CURRENT_SUBSCRIPTION")}
        placement="right"
        arrow={false}
      >
        <div style={{ padding: 14 }}>
          <div className="SiderBar-card">
            {props.collapsed ? (
              <span className="SiderBar-cardtxt1">
                <RiFlashlightLine />
              </span>
            ) : (
              <div style={{ flex: 1, margin: 10 }}>
                {t("home_page.homepage.Standard")} -{" "}
                <span className="SiderBar-cardtxt">
                  {t("home_page.homepage.Free")}
                </span>
              </div>
            )}
          </div>
        </div>
      </Popover>
      <div
        style={{ padding: 14, marginTop: 0, paddingBottom: 0 }}
        onClick={() => navigate("/retail-express")}
      >
        <div className="SiderBar-card">
          {props.collapsed ? (
            <span className="SiderBar-cardtxt1">
              <BsPrinter />
            </span>
          ) : (
            <div className="SiderBar-txt3">
              <div>{t("home_page.homepage.Retail_Express")}</div>
              <BsPrinter size={20} />
            </div>
          )}
        </div>
      </div>
      {props.collapsed ? null : (
        <div className="SiderBar-txt1">{t("home_page.homepage.MAIN_MENU")}</div>
      )}
      <div className="SiderBar-scroll">
        {Data?.map((item: any) => {
          return (
            <SideItem
              key={item.id}
              id={item.id}
              name={item.name}
              item={item}
              Icon={item.icon}
              collapsed={props.collapsed}
              expand={expand}
              clickExpand={(val: any) => setexpand(val)}
            />
          );
        })}
      </div>
      <br /> <br />
    </div>
  );
}

export default SiderBar;
