import React from "react";
import PageHeader from "../../../components/pageHeader";
import StocksummaryTable from "../StockSummary/stocksummarytable";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const StockSummary = () => {
  const location = useLocation();

  return (
    <>
      <PageHeader firstPathText="Report" title="STOCK SUMMARY"
      firstPathLink="/usr/report"
      secondPathText="stocksummary"
      secondPathLink={location.pathname} 
      />

      <>
        <Container>
          <StocksummaryTable />
        </Container>
      </>
    </>
  );
};

export default StockSummary;
