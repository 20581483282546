import {
  Button,
  Card,
  ColorPicker,
  DatePicker,
  Form,
  Image,
  Input,
  Radio,
  notification,
} from "antd";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageHeader from "../../../components/pageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import temp1 from "./templates/images/sample_temp.png";
import temp2 from "./templates/images/sample_temp2.png";
import API from "../../../config/api";
import { POST } from "../../../utils/apiCalls";
import { useSelector } from "react-redux";
import Billings from "./billings";
import { MdCloudUpload } from "react-icons/md";
import LogoPicker from "./LogoPicker";
import { generatePDF } from "./templates";
import ProjectPlan from "./project_plan";

function ProposalForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [toggle, toggleModal] = useState(false);
  const [img, setImg] = useState(null);
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const [form] = Form.useForm();
  const location = useLocation();

  const navigate = useNavigate();

  const submitHandler = async (values: any) => {
    try {
      setIsLoading(true);
      let url = API.CREATE_PROPOSAL;
      const { data, status, message }: any = await POST(url, {
        adminid,
        template:selectedTemplate,
        ...values,
      });
      if (status) {
        generatePDF(data, selectedTemplate);
        notification.success({ message: message });
        navigate("/usr/proposal");
      } else {
        notification.error({ message: message });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "something went wrong!! , Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {

  
  };
  return (
    <>
      <PageHeader
        firstPathText="Proposals"
        firstPathLink={location.pathname.replace("/create", "")}
        secondPathText="Create"
        secondPathLink={location.pathname}
        title="Create Proposal"
      />
      <Container>
        <Form form={form} onFinish={submitHandler} onValuesChange={onValuesChange}>
          <br />
          <Card>
            <div className="productAdd-Txt1">Choose a template</div>
            <Form.Item
              name="selectedTemplate"
              rules={[
                {
                  required: true,
                  message: "Please select a template!",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => setSelectedTemplate(e.target.value)}
                value={selectedTemplate}
              >
                <Row gutter={[16, 16]}>
                  <Col md={4}>
                    <Radio value="template1">
                      <Card>
                        <Image src={temp1} alt="template-1" />
                      </Card>
                    </Radio>
                  </Col>
                  <Col md={4}>
                    <Radio value="template2">
                      <Card>
                        <Image src={temp2} alt="template-2" />
                      </Card>
                    </Radio>
                  </Col>

                  <Col md={4}>
                    <Radio value="template3">
                      <Card>
                        <Image src={temp2} alt="template-3" />
                      </Card>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
            <Row>
              <Col md={6}>
                <div className="productAdd-Txt1">Choose Color</div>
                <Row>
                  <Col md={6} style={{ display: "flex", alignItems: "center" }}>
                    <strong>Primary</strong>
                    <Form.Item name="primary_color" style={{ marginBottom: 0 }}
                     rules={[
                      {
                        required: true,
                        message: "Please select a primary color!",
                      },
                    ]}
                    >
                      <ColorPicker
                        defaultValue="#fd7e14"
                        format={"hex"}
                        style={{ marginLeft: 10 }}
                        onChange={(val: any, data: any) => {
                          form.setFieldsValue({ primary_color: data });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={6} style={{ display: "flex", alignItems: "center" }}>
                    <strong>Secondary</strong>
                    <Form.Item
                      name="secondary_color"
                      style={{ marginBottom: 0 }}
                      rules={[
                        {
                          required: true,
                          message: "Please select a secondary color!",
                        },
                      ]}
                    >
                      <ColorPicker
                        defaultValue="#efbc91"
                        format={"hex"}
                        style={{ marginLeft: 10 }}
                        onChange={(val: any, data: any) => {
                          form.setFieldsValue({ secondary_color: data });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <div className="productAdd-Txt1">Choose Logo</div>
                <Form.Item
                  name="logo"
                  rules={[{ required: true, message: "Please select a logo" }]}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ marginTop: 20 }}>
                      {img ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={img}
                              alt="Uploaded Logo"
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                          <Button
                            style={{ marginTop: 10 }}
                            onClick={() => toggleModal(true)}
                          >
                            Change Logo
                          </Button>
                        </>
                      ) : (
                        <MdCloudUpload
                          className="profile-edit"
                          color="#fff"
                          size={25}
                          onClick={() => toggleModal(true)}
                        />
                      )}
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <br />

          <Card>
            <div className="productAdd-Txt1">Basic Details</div>

            <Row>
              <Col md={4}>
                <label className="formLabel">Company Name</label>
                <Form.Item
                  name="from_company_name"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="" />
                </Form.Item>

                <label className="formLabel">Date</label>
                <Form.Item name="proposal_date" style={{ marginBottom: 10 }}>
                  <DatePicker
                    placeholder=""
                    size="large"
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
                <label className="formLabel">Email</label>
                <Form.Item
                  name="from_email"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="" />
                </Form.Item>
              </Col>
              <Col md={4}>
                <label className="formLabel">Mobile</label>
                <Form.Item name="from_mobile" style={{ marginBottom: 10 }}  rules={[{ required: true }]}>
                  <Input type="number" size="large" placeholder="" />
                </Form.Item>
                <label className="formLabel">Website</label>
                <Form.Item name="from_website" style={{ marginBottom: 10 }}  rules={[{ required: true }]}>
                  <Input size="large" placeholder="" />
                </Form.Item>
              </Col>
              <Col md={4}>
                <label className="formLabel">Address</label>
                <Form.Item name="from_address"  rules={[{ required: true }]}>
                  <Input.TextArea size="large" placeholder="" rows={4} />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <div className="productAdd-Txt1">Proposal To Details</div>
            <Row>
              <Col md={4}>
                <label className="formLabel">Company Name</label>
                <Form.Item name="to_company_name" style={{ marginBottom: 10 }}  rules={[{ required: true }]}>
                  <Input size="large" placeholder="" />
                </Form.Item>
                <label className="formLabel">Website</label>
                <Form.Item name="to_website" style={{ marginBottom: 10 }}  rules={[{ required: true }]}>
                  <Input size="large" placeholder="" />
                </Form.Item>
              </Col>

              <Col md={4}>
                <label className="formLabel">Email</label>
                <Form.Item name="to_email" style={{ marginBottom: 10 }}  rules={[{ required: true }]}>
                  <Input size="large" placeholder="" />
                </Form.Item>
                <label className="formLabel">Mobile</label>
                <Form.Item name="to_mobile" style={{ marginBottom: 10 }}  rules={[{ required: true }]}>
                  <Input size="large" placeholder="" />
                </Form.Item>
              </Col>

              <Col md={4}>
                <label className="formLabel">Address</label>
                <Form.Item name="to_address"  rules={[{ required: true }]}>
                  <Input.TextArea size="large" placeholder="" rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <br />
          <Card>
            <div className="productAdd-Txt1">About the Company</div>
            <Form.Item name="about__from_company"  rules={[{ required: true }]}>
              <Input.TextArea size="large" placeholder="" rows={8} />
            </Form.Item>
            <label className="formLabel">About Company Tagline</label>
            <Form.Item name="about_from_company_tag"  rules={[{ required: false }]}>
              <Input.TextArea size="large" placeholder="" rows={1} />
            </Form.Item>
            <label className="formLabel">Services</label>
            <Form.Item name="about_from_services"  rules={[{ required: false }]}>
              <Input.TextArea size="large" placeholder="" rows={3} />
            </Form.Item>
            <label className="formLabel">Technologies</label>
            <Form.Item name="about_from_technologies"  rules={[{ required: false }]}>
              <Input.TextArea size="large" placeholder="" rows={3} />
            </Form.Item>
          </Card>
          <br />

          <Card>
            <div className="productAdd-Txt1">About The Proposal</div>
            <Row>
              <Col md={4}>
                <label className="formLabel">Proposal Title</label>
                <Form.Item name="proposal_title" style={{ marginBottom: 10 }}  rules={[{ required: true }]}>
                  <Input size="large" placeholder="" />
                </Form.Item>
              </Col>
              <Col md={4}>
                <label className="formLabel">Sub Title</label>
                <Form.Item
                  name="proposal_subtitle"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="" />
                </Form.Item>
              </Col>
            </Row>
            <label className="formLabel">Details</label>
            <Form.Item name="proposal_details"  rules={[{ required: true }]}>
              <Input.TextArea size="large" placeholder="" rows={8} />
            </Form.Item>

            <Billings form={form} />
            <ProjectPlan form={form}/>

            <label className="formLabel">Terms & Conditions</label>
            <Form.Item name="proposal_terms"  rules={[{ required: true }]}>
              <Input.TextArea size="large" placeholder="" rows={8} />
            </Form.Item>
            <label className="formLabel">Conclusion</label>
            <Form.Item name="conclusion"  rules={[{ required: true }]}>
              <Input.TextArea size="large" placeholder="" rows={8} />
            </Form.Item>

            <Row>
              <Col md={6} />
              <Col md={3}>
                <Button
                  block
                  style={{ height: 45 }}
                  onClick={() => navigate("/usr/proposal")}
                >
                  Cancel
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  style={{ height: 45 }}
                >
                  Save & Download
                </Button>
              </Col>
            </Row>
          </Card>
          <br />
          <LogoPicker
            open={toggle}
            modalClose={() => toggleModal(false)}
            form={form}
            setImg={setImg}
          />
        </Form>
      </Container>
    </>
  );
}

export default ProposalForm;
