import React, { useEffect, useState } from "react";
import LoadingBox from "../../../components/loadingBox";
import { Container } from "react-bootstrap";
import { Card, Col, Form, Row } from "antd";
import PageHeader from "../../../components/pageHeader";
import moment from "moment";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import { useParams } from "react-router-dom";

const Financeview = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [hrfinanceData, setHrfinanceData] = useState<any[]>([]);
  const { id } = useParams();

  useEffect(() => {
    viewHrfinanceData();
  }, []);

  const viewHrfinanceData = async () => {
    try {
      setIsLoading(true);
      const api = API.GET_HRFINANCE + id;
      const response: any = await GET(api, {});

      if (response?.status) {
        setHrfinanceData(response?.data);
      } else {
        throw new Error("HR finance data not found");
      }
    } catch (error) {
      console.log("Error fetching hrfinance data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <PageHeader firstPathText="HrFinance" title="HrFinance Details" />
          <br />
          <Container>
            <Form form={form}>
              {hrfinanceData?.map((finance, index) => (
                <Card key={index} style={{ marginBottom: "16px" }}>
                  <div className="Hrfinance">HrFinance Details</div>
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <strong>Employee Name:</strong>
                    </Col>
                    <Col span={18}>
                      <span>{finance?.user?.fullName}</span>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <strong>Type:</strong>
                    </Col>
                    <Col span={18}>
                      <span>{finance?.type}</span>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <strong>Amount:</strong>
                    </Col>
                    <Col span={18}>
                      <span>{finance?.amount}</span>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <strong>Category:</strong>
                    </Col>
                    <Col span={18}>
                      <span>{finance?.category}</span>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <strong>Date:</strong>
                    </Col>
                    <Col span={18}>
                      <span>{moment(finance?.date).format("DD-MM-YYYY")}</span>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Form>
          </Container>
        </>
      )}
    </>
  );
};

export default Financeview;
