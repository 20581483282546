import React, { useEffect, useState } from "react";
import API from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import DataTable from "../hr-employees/Table";
import "./style.scss";
import { Pagination, Table } from "antd";
import form from "antd/es/form";
import { Container } from "react-bootstrap";

interface CardProps {
  title: string;
  count: number;
  onClick: () => void;
}

const Card: React.FC<CardProps> = ({ title, count, onClick }) => (
  <div className="card" onClick={onClick}>
    <div className="card-title">{title}</div>
    <div className="card-count">{count}</div>
  </div>
);

const HrDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [dataCount, setDataCount] = useState<any>({});
  const [meta, setMeta] = useState<any>({});
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);

  useEffect(() => {
    getAllData();
    getAllEmployees(1, 10);
  }, [page, take]);

  console.log(dataCount);

  const getAllData = async () => {
    try {
      let api = API.HR_DASHBOARD;
      const count: any = await GET(api, {});
      if (count?.status) {
        setDataCount(count?.data);
      } else {
        console.error("Failed to fetch data from the API.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllEmployees = async (page: any, take: any) => {
    try {
      setIsLoading(true);
      let obj = {
        page: page,
        take: 10,
      };
      const url = API.POST_PAGE_DATA;
      const response: any = await POST(url, obj);
      setData(response.data);
      setMeta(response?.meta);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchHrEmployee(1, 10);
  // }, []);
  const handleProjectsonClick = () => {
    navigate("/usr/hr-projects");
  };

  const handleEmployeesonClick = () => {
    navigate("/usr/hr-employees");
  };

  const handleLeaveRequestsonClick = () => {
    navigate("/usr/hr-leave");
  };

  const handleClick = () => {
    navigate("/usr/hr-timesheet");
  };

  const columns = [
    { dataIndex: "employeeId", title: "employeeId" },
    { dataIndex: "fullName", title: "name" },
    { dataIndex: "role", title: "role" },
    { dataIndex: "officeEmail", title: "officeEmail" },
    { dataIndex: "department", title: "department" },
  ];

  return (
    <div className="dashboard-container">
      <div className="card-container">
        <Card
          title="Employees"
          count={dataCount?.employees}
          onClick={handleEmployeesonClick}
        />
        <Card
          title="Leave Requests"
          count={dataCount?.leave_requests}
          onClick={handleLeaveRequestsonClick}
        />
        <Card
          title="Projects"
          count={dataCount?.projects}
          onClick={handleProjectsonClick}
        />
      </div>
      <div>
        {/* <h6>Total {meta?.total_count} employees</h6> */}
        <br />
        <Table columns={columns} dataSource={data} pagination={false} />

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Pagination
            defaultCurrent={meta?.page}
            total={meta?.total_count}
            onChange={(page, take) => {
              getAllEmployees(page, take);
            }}
            style={{
              display: meta?.total_count > 10 ? "block" : "none",
              textAlign: "right",
              marginTop: "20px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HrDashboard;
