import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import UpdateStock from "./updateStock";
import { useState } from "react";
import { GET } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { Popconfirm, message } from "antd";
import { MdEditDocument, MdPreview } from "react-icons/md";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

function ActionPopover(props: any) {
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const data = props.data.data;
  const { t } = useTranslation();
  const onView = () => {
    navigate(`/usr/product-view/${data.itemtype}/${data.id}`);
  };

  const onEdit = () => {
    data?.itemtype == "Asset" ? props?.handleEditAsset(data.id) :
    navigate(`/usr/create-product/${data.itemtype}/${data.id}`);
  };
  const showModal = () => {
    setModalVisible(true);
  };

  const Delete = async () => {
    let url = API.DELETE_PRODUCT + data.id;
    const res: any = await GET(url, null);
    if (res.status) {
      message.success("Successfully deleted");
      props.onSuccess();
    }
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };
  return (
    <>
      <div className="table-actionBox">
        { 
          data?.itemtype == "Asset" ? null : 
          <div
          className="table-actionBoxItem"
          onClick={onView}
        >
          <div>{t("home_page.homepage.View")}</div>
          <MdPreview size={18} color="grey" />
        </div>
        }
        <div
            className="table-actionBoxItem"
            onClick={onEdit}
          >
            <div>{t("home_page.homepage.Edit")}</div>
            <MdEditDocument size={18} color="grey" />
          </div>
        {/* <div className="viewpopover-Box2" onClick={showModal}>
          <RxUpdate size={20} color="#14839c" />
          <div className="viewpopover-Txt1">UPDATE STOCK</div>
        </div> */}
        <div>
          <Popconfirm
            title="Delete"
            description="Are you sure to delete ?"
            onConfirm={() => Delete()}
            icon={<AiOutlineQuestionCircle style={{ color: "red" }} />}
            placement="topRight"
          >
            <div className="table-actionBoxItem">
              <div>{t("home_page.homepage.Delete")}</div>
              <RiDeleteBinLine size={18} color="grey" />
            </div>
          </Popconfirm>
        </div>
      </div>
      <UpdateStock
        modalVisible={modalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        stock={data}
        onSuccess={props.onSuccess}
      />
    </>
  );
}

export default ActionPopover;
