import { Col, Container, Row } from "react-bootstrap";
import image from "../../assets/images/device mockup 1.png";
import image1 from "../../assets/images/appstore.png";
import image2 from "../../assets/images/playstore (1).png";
import { withTranslation } from "react-i18next";

function StayConnected(props:any) {
  const { t } = props;
  return (
    <div style={{ backgroundColor: "#f4f6f8" }}>
      <Container>
        <div className="stayConnected-Box1">
          <br />
          <div className="stayConnected-Box2">
          {t("home_page.homepage.Stay_head")}
          </div>
          <br />
          <div className="stayConnected-text1">
          {t("home_page.homepage.Stay_body1")}
          <br />
          {t("home_page.homepage.Stay_body2")}
          <br />
          {t("home_page.homepage.Stay_body3")}
          </div>
          <br />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              padding: "15px",
            }}
          >
            <div style={{ marginRight: "15px" }}>
              <img src={image1} />
            </div>
            <div>
              <img src={image2} />
            </div>
            <br />
            <br />
          </div>
          <div className="stayConnected-Box3">
            <Row>
            <div className="d-flex justify-content-center">
              <Col md={8} sm={12}>
                <div>
                  <img src={image} style={{ width: "100%" }} />
                </div>
              </Col>
              </div>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default withTranslation()(StayConnected);
