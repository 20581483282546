import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BiSolidUserRectangle } from "react-icons/bi";
import { IoMdBusiness } from "react-icons/io";
import { LuCalendarCheck } from "react-icons/lu";
import {
  MdMenuBook,
  MdVpnKey,
  MdEmail,
  MdOutlineCardMembership,
} from "react-icons/md";
import { FaRegFloppyDisk } from "react-icons/fa6";
import EmailMobile from "./components/EmailMobile";
import { update } from "../../redux/slices/userSlice";
import { Tabs } from "antd";
import LoadingBox from "../../components/loadingBox";
import General from "./components/General";
import Business from "./components/Business";
import Accounting from "./components/Accounting";
import Security from "./components/Security";
import Subscription from "./components/Subscription";
import AccountData from "./components/AccountData";
import { useTranslation } from "react-i18next";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import PageHeader from "../../components/pageHeader";
import EndOfYear from "./components/EndOfYear";


function Profile() {
  const { t } = useTranslation();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const { user } = useSelector((state: any) => state.User);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      const userData = API.GET_USER_DETAILS + user.id;
      const response: any = await GET(userData, null);
      if (response.status) {
        dispatch(update(response.data));
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const refreshData = () => {
    getDetails();
  };

  const CustomTab = ({ elements }: any) => {
    const navigate = useNavigate();
    const { source } = useParams();
    return (
      <>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <>
            <PageHeader
              firstPathLink={location?.pathname}
              title={t("home_page.homepage.Profile")}
              firstPathText ={t("home_page.homepage.Profile")}
              goBack={"/dashboard"}
            />

            <Tabs
              activeKey={source}
              onChange={(path) => navigate(`../${path}`)}
              tabBarStyle={{ backgroundColor: "white", paddingLeft: 10 }}
            >
              {elements.map((element: any) => (
                <Tabs.TabPane
                  key={element.path}
                  tab={element.tabTitle}
                  className="TabsBody"
                >
                  {element.tabBody}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </>
        )}
      </>
    );
  };

  return (
    <div>
      <div className="profile-tab-container">
        <CustomTab
          elements={[
            {
              tabTitle: (
                <div className="profile-tab-title">
                  <span>
                    <BiSolidUserRectangle size={22} />{" "}
                  </span>
                  <span className="profile-tab-title-text">{t("home_page.homepage.GENERAL")}</span>
                </div>
              ),
              tabBody: (
                <General isLoading={isLoading} onChange={() => refreshData()} />
              ),

              path: "general",
            },
            {
              tabTitle: (
                <div className="profile-tab-title">
                  <span>
                    <MdEmail size={22} />{" "}
                  </span>
                  <span className="profile-tab-title-text">{t("home_page.homepage.EMAIL_MOBILE")}</span>
                </div>
              ),
              tabBody: <EmailMobile onChange={() => refreshData()}/>,
              path: "email-mobile",
            },
            {
              tabTitle: (
                <div className="profile-tab-title">
                  <span>
                    <IoMdBusiness size={22} />{" "}
                  </span>
                  <span className="profile-tab-title-text">{t("home_page.homepage.business")}</span>
                </div>
              ),
              tabBody: <Business onChange={() => refreshData()}/>,
              path: "business",
            },
            {
              tabTitle: (
                <div className="profile-tab-title">
                  <span>
                    <MdMenuBook size={22} />{" "}
                  </span>
                  <span className="profile-tab-title-text">{t("home_page.homepage.ACCOUNTING")}</span>
                </div>
              ),
              tabBody: <Accounting onChange={() => refreshData()}/>,
              path: "accounting",
            },
            {
              tabTitle: (
                <div className="profile-tab-title">
                  <span>
                    <MdVpnKey size={22} />{" "}
                  </span>
                  <span className="profile-tab-title-text">{t("home_page.homepage.SECURITY")}</span>
                </div>
              ),
              tabBody: <Security onChange={() => refreshData()}/>,
              path: "security",
            },

            {
              tabTitle: (
                <div className="profile-tab-title">
                  <span>
                    <MdOutlineCardMembership size={22} />{" "}
                  </span>
                  <span className="profile-tab-title-text">{t("home_page.homepage.SUBSCRIPTION")}</span>
                </div>
              ),
              tabBody: <Subscription />,
              path: "subscription",
            },
            {
              tabTitle: (
                <div className="profile-tab-title">
                  <span>
                    <LuCalendarCheck size={22} />{" "}
                  </span>
                  <span className="profile-tab-title-text">End of Year</span>
                </div>
              ),
              tabBody: <EndOfYear />,
              path: "end-of-year",
            },
            {
              tabTitle: (
                <div className="profile-tab-title">
                  <span>
                    <FaRegFloppyDisk size={22} />{" "}
                  </span>
                  <span className="profile-tab-title-text">{t("home_page.homepage.Account_Data")}</span>
                </div>
              ),
              tabBody: <AccountData />,
              path: "account",
            },
          
            
          ]}
        />
      </div>
    </div>
  );
}
export default Profile;
