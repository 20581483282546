import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageHeader from "../../../components/pageHeader";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../config/api";
import { GET, POST, PUT } from "../../../utils/apiCalls";
import { IoRemoveCircleOutline } from "react-icons/io5";

function FormHrTask() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [data, setData] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [taskdata, setTask] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(2);
  const [meta, setMeta] = useState<any>({});
  const { type, proId, id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [projectId, setProjectId] = useState<any>(Number());

  const [taskId, setTaskId] = useState<number | null>(null);

  const [getdatas, setGetData] = useState<any>([]);

  console.log("data", taskdata);
  useEffect(() => {
    setisUpdate(true);
    getData(id);

    getallmemberslist();
  }, [type]);

  useEffect(() => {
    // fetchHrEmployee();
    getallmemberslist();
  }, []);

  const getData = async (id: any) => {
    try {
      setIsLoading(true);
      const url = API.GET_ONE_TASK + id;
      const { data }: any = await GET(url, {});
      setData(data);
      form.setFieldsValue({
        title: data.title,
        description: data.description,
        startDate: data?.startDate ? dayjs(data.startDate, "YYYY/MM/DD") : null,
        endDate: data?.endDate ? dayjs(data.endDate, "YYYY/MM/DD") : null,
        projectId: data.projectId,
        priority: data.priority,
        assignedTo: data.assignedTo,
      });

      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getallmemberslist = async () => {
    try {
      let api = API.GET_PROJECT_MEMBERS + proId + "?order=ASC&page=1&take=20";
      const Data: any = await GET(api, null);
      if (Data?.status) {
        console.log(Data);
        setGetData(Data?.data);
      } else {
        setGetData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHrTak = async () => {
    try {
      setIsLoading(true);

      let obj = {
        query: form.getFieldValue("query"),
        page: page,
        take: 2,
        projectId: Number(id),
      };

      const url = API.POST_PAGE_TASK;
      const tasks: any = await POST(url, obj);
      if (tasks?.status) {
        setData(tasks.data);
        console.log(tasks.data);
        setMeta(tasks?.meta);
      } else {
        setData([]);
        setMeta({});
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHrTak();
  }, [projectId, taskId, page, take]);

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      let tasks = {
        tasks: val?.tasks,
        projectId: proId,
      };
      let url = API.BULK_CREATE_TASK;
      const data: any = await POST(url, tasks);
      if (data?.status) {
        notification.success({
          message: `Success`,
          description: `Tasks Successfully Created"`,
        });
        setIsLoading(false);
        navigate(-1);
      } else {
        notification.error({
          message: "Faild",
          description: "Failed to create tasks",
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      notification.error({
        message: `Opps!`,
        description: `Server error"`,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageHeader
        firstPathText="Project"
        // firstPathLink={location.pathname.replace("/update", "")}
        secondPathText="Update"
        // secondPathLink={location.pathname}
        title={"create"}
      />
      <Container>
        <Form
          onFinish={onFinish}
          form={form}
          // onFieldsChange={(value: any, allFields: any) => TaskChanges(value)}
        >
          <br />
          <Card>
            <div className="productAdd-Txt1"> Task Details</div>

            <br />
            <br />

            <Form.List name="tasks">
              {(fields, { add, remove }) => (
                <>
                  {fields?.length ? (
                    <Row>
                      <Col sm={3}>
                        <div className="form-label">Title</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">Priority</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">Start Date</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">End Date</div>
                      </Col>
                    </Row>
                  ) : null}
                  {fields.map((field) => (
                    <Row>
                      <Col sm={3}>
                        <Form.Item
                          rules={[{ required: true, message: "Enter title" }]}
                          name={[field.name, "title"]}
                        >
                          <Input size="large" placeholder="title" type="text" />
                        </Form.Item>
                      </Col>
                      <Col sm={2}>
                        <Form.Item
                          name={[field.name, "priority"]}
                          rules={[{ required: true }]}
                        >
                          <Select placeholder="Select a Priority" size="large">
                            <Select.Option value="low">Low</Select.Option>
                            <Select.Option value="medium">Medium</Select.Option>
                            <Select.Option value="high">High</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={3}>
                        <Form.Item
                          rules={[
                            { required: true, message: "Select Start Date" },
                          ]}
                          name={[field.name, "startDate"]}
                        >
                          <DatePicker
                            placeholder=""
                            size="large"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={3}>
                        <Form.Item
                          rules={[
                            { required: true, message: "Select End Date" },
                          ]}
                          name={[field.name, "endDate"]}
                        >
                          <DatePicker
                            placeholder=""
                            size="large"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={1}>
                        <Form.Item>
                          <Button
                            size="large"
                            onClick={() => remove(field.name)}
                            block
                          >
                            <IoRemoveCircleOutline size={20} color="red" />
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Row>
                    <Col sm={8}></Col>
                    <Col sm={4}>
                      <Button size="large" block onClick={() => add()}>
                        Add Task +
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>

            <br />
            <hr />
            <br />

            <Row>
              <Col sm={4} />
              <Col sm={4}>
                <Button
                  size="large"
                  danger
                  block
                  onClick={() => console.log("")}
                >
                  Close
                </Button>
              </Col>
              <Col sm={4}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  {"Create"}
                </Button>
              </Col>
            </Row>
          </Card>
          <br />
        </Form>
      </Container>
    </>
  );
}

export default FormHrTask;
function onSucess() {
  throw new Error("Function not implemented.");
}
