import { useEffect, useState } from "react";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import LedgerTable from "../component/table";
import { useLocation } from "react-router-dom";

const LedgerDefaultCategory = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    fetchDefaultCategoryList();
  }, []);

  const fetchDefaultCategoryList = async () => {
    try {
      setIsLoading(true);
      const default_category_url =
        API.GET_LEDGER_CATEGORY + `defaultLedgerCategory`;
      const { data }: any = await GET(default_category_url, null);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const Columns = [
    {
      name: "category",
      title: "Category Name",
      dataType: "string",
      alignment: "center",
      allowEditing: false,
    },
    {
      name: "categorygroup",
      title: "Category Group",
      dataType: "string",
      alignment: "center",
      allowEditing: false,
    },
  ];
  return (
    <>
      <PageHeader title={"Default Category"} 
      secondPathLink = {location.pathname}
      secondPathText='Default Category'
      />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <LedgerTable
          products={data}
          columns={Columns}
          onItemSelect={() => {}}
          onPageChange={(p: any, t: any) => {}}
          title={"Default Category"}
        />
      )}
    </>
  );
};

export default LedgerDefaultCategory;
