import { useEffect, useState } from "react";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import LedgerTable from "../component/table";
import { useLocation } from "react-router-dom";

const LedgerDefaultLedger = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchDefaultLedger = async () => {
    try {
      setIsLoading(true);
      const get_default_ledger_url = API.GET_ACCOUNT_MASTER_LIST;
      const { data }: any = await GET(get_default_ledger_url, null);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDefaultLedger();
  }, []);

  const Columns = [
    {
      name: "nominalcode",
      title: "Nominal Code",
      dataType: "string",
      alignment: "center",
      allowEditing: false,
    },
    {
      name: "laccount",
      title: "Ledger Account",
      alignment: "left",
      cellRender: "LedgerAccount",
      allowEditing: false,
    },
    {
      name: "categoryDetails.category",
      title: "Category",
      dataType: "string",
      alignment: "center",
      allowEditing: false,
    },
    {
      name: "groupDetails.categorygroup",
      title: "Category Group",
      dataType: "string",
      alignment: "center",
      allowEditing: false,
    },
  ];
   const location = useLocation();
  return (
    <>
      <PageHeader title={"Default Ledger"}
      secondPathLink ={location.pathname}
      secondPathText ='Default Ledger
      ' />
  
      {isLoading ? (
        <LoadingBox />
      ) : (
        <LedgerTable
          products={data}
          columns={Columns}
          onItemSelect={() => {}}
          onPageChange={(p: any, t: any) => {}}
          title={"Default Ledger"}
        />
      )}
    </>
  );
};

export default LedgerDefaultLedger;
