import { Col, Container, Row, Table } from "react-bootstrap";
import PageHeader from "../../../components/pageHeader";
import {
  Card,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  notification,
} from "antd";
import Items from "../components/items";
import { GET, POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import CreateCutomerModal from "../../../components/contactCreateModal";

function Create(props: any) {
  const { taxList } = props;
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.User);
  const [form] = Form.useForm();
  const { customers, products } = props;
  const adminid = user.adminid;
  const [subTotal, setSubTotal] = useState(0);
  const [totalVat, setTatalVat] = useState(0);
  const [roundOff, setRoundOff] = useState(0);
  const [qSuffix, setqSuffix] = useState("");
  const [overollDiscount, setOverolDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [isCheck, setIsCheck] = useState<any>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerCreate, setCustomerCreate] = useState(false);

  useEffect(() => {
    if (invoiceNumber === null) {
      getInvoiceNo();
    }
    form.setFieldsValue({
      terms: user?.companyInfo?.defaultTerms,
      quotes: user?.companyInfo?.cusNotes,
    });
  }, []);

  const getInvoiceNo = async () => {
    try {
      let invoiceurl = API.GET_INVOICENO + adminid + "/proforma";
      const { data: invnumber }: any = await GET(invoiceurl, null);
      setInvoiceNumber(invnumber);
      form.setFieldsValue({
        invoiceno: invnumber,
      });
    } catch (error) {}
  };

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      let selectedCustomer =
        customers &&
        customers?.length &&
        customers?.find((item: any) => item.id === val.customerid);
      let column = val.columns.map((item: any) => {
        let foundedProduct = products.find(
          (product: any) => product.id === item.id
        );
        return {
          id: item.id,
          discount: item.discount,
          discountamt: item.discountamt,
          productId: item.id,
          product: foundedProduct,
          idescription: foundedProduct.idescription,
          description: foundedProduct.idescription,
          vat: item.vat,
          includevat: item.includeVat,
          incomeTax: item.vat,
          percentage: item.vat,
          costprice: item.price,
          // ledgerDetails: productLedger,
          // ledger: productLedger,
          quantity: item.quantity,
          total: item.total,
          vatamt: item.vat,
          vatamount: item.vatamount,
          incomeTaxAmount: item.vatamount,
          itemorder: 1,
        };
      });
      let payload = {
        cname: selectedCustomer?.bus_name,
        customerid: val?.customerid,
        columns: column,
        invoiceno: val?.invoiceno,
        sdate: val?.sdate,
        // ldate: val?.ldate,
        inaddress: val?.inaddress,
        deladdress: val?.deladdress,
        terms: val?.terms,
        quotes: val?.quotes,
        status: "0",
        issued: "yes",
        type: "proforma",
        pagetype: "1",
        total: Number(Number(totalAmount).toFixed(2)),
        userid: adminid,
        adminid: adminid,
        userdate: new Date(),
        paymentInfo: false,
        reference: val?.reference,
        salesType: "",
        email: user.email,
        // roundOff: roundOff + "",
      };
      let salesUrl = "SaleInvoice/add";
      const response: any = await POST(salesUrl, payload);
      if (response.status) {
        notification.success({ message:"Success",description:"Proforma invoice created successfully" });
        form.resetFields();
        navigate(-1);
        setIsLoading(false);
      } else {
        notification.error({ message:"Failed",description:"Failed to create proforma invoice" });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      notification.error({ message:"Server Error",description:"Failed to create proforma invoice!! Please try again later" });
    }
  };

  function containsNull(arr: any) {
    let isThereNull = false;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (element === undefined) {
        isThereNull = true;
      }
    }
    return isThereNull;
  }
  const formValue = form.getFieldsValue();
  const onValuesChange = (column: any, allarray: any) => {
    try {
      if (column?.columns?.length < 1) {
        setSubTotal(0);
        setTatalVat(0);
        setOverolDiscount(0);
        setRoundOff(0);
        setTotalAmount(0);
      }
      //

      if (column.amoutToPaid) {
        let outstanding = totalAmount - Number(column.amoutToPaid);
        form.setFieldsValue({ outStanding: outstanding });
        if (outstanding < 0) {
          form.setFieldsValue({ outStanding: 0, amoutToPaid: totalAmount - 0 });
          notification.error({
            message:
              "The amount cannot be greater than the outstanding balance.",
          });
        }
      } else if (
        allarray.amoutToPaid === null ||
        allarray.amoutToPaid === undefined ||
        allarray.amoutToPaid === ""
      ) {
        form.setFieldsValue({
          outStanding: totalAmount - 0,
        });
      }

      //
      if (allarray?.columns && allarray?.columns?.length) {
        let _subTotal = 0;
        let _tatalVat = 0;
        let _overollDiscount = 0;
        const updatedColumns = allarray?.columns?.map(
          (item: any, index: any) => {
            if (column.columns.length > 1) {
              if (containsNull(column.columns)) {
                let productId = column.columns[index]?.id || null;
                if (productId) {
                  let array = allarray?.columns;
                  array[index].price = null;
                  array[index].vat = null;
                  array[index].discount = null;
                  array[index].discountamt = null;
                  array[index].quantity = 1;
                  if (array?.length - 1 === index) {
                    array[index].includeVat = null;
                  }
                  form.setFieldsValue({ columns: array });
                }
              }
            } else {
              if (
                !column.columns[index]?.price ||
                !column.columns[index]?.quantity
              ) {
                if (
                  column.columns[index]?.discount ||
                  column.columns[index]?.discountamt
                ) {
                  console.log("");
                } else {
                  if (column.columns[index]?.id) {
                    let array = allarray?.columns;
                    array[index].price = null;
                    array[index].vat = null;
                    array[index].discount = null;
                    array[index].discountamt = null;
                    array[index].quantity = null;
                    if (array?.length - 1 === index) {
                      array[index].includeVat = null;
                    }
                    form.setFieldsValue({ columns: array });
                  }
                }
              }
            }

            if (item && item.id !== null) {
              let foundProduct = products?.find(
                (product: any) => Number(product?.id) === Number(item?.id)
              );
              let columnDiscountAmt = Number(
                column?.columns[index]?.discountamt
              );
              let itemDiscountAmt = Number(item?.discountamt);
              let discountAmount: any =
                Number.isNaN(columnDiscountAmt) && Number.isNaN(itemDiscountAmt)
                  ? 0
                  : columnDiscountAmt || itemDiscountAmt || 0;

              let columnDiscount = Number(column?.columns[index]?.discount);
              let itemDiscount = Number(item?.discount);
              let discount: any =
                Number.isNaN(columnDiscount) && Number.isNaN(itemDiscount)
                  ? 0
                  : columnDiscount || itemDiscount || 0;

              setqSuffix(foundProduct?.unit);
              let quantity =
                column?.columns[index]?.quantity === undefined
                  ? item?.quantity || 1
                  : column?.columns[index]?.quantity;
              let price =
                item?.price === undefined || item?.price === null
                  ? Number(foundProduct?.rate)
                  : item?.price;
              let total = price * quantity;

              let vatPercent =
                item?.vat === undefined ||
                item?.vat === null ||
                item?.vat === ""
                  ? Number(foundProduct?.vat)
                  : Number(item?.vat);
              let vatAmount =
                formValue?.column?.columns?.length > 1
                  ? formValue?.columns[index]?.vatamount
                  : Number(foundProduct?.vatamt);

              if (
                column?.columns[index]?.id === undefined ||
                column?.columns[index]?.includeVat !== undefined ||
                column?.columns[index]?.vat !== undefined ||
                column?.columns[index]?.quantity !== undefined ||
                column?.columns[index]?.discount !== undefined ||
                column?.columns[index]?.discountamt !== undefined ||
                column?.columns[index]?.price !== undefined
              ) {
                vatAmount = (price * quantity * vatPercent) / 100;
              }
              ///////////////////////includeVat//////////////
              let includeVat;
              const selectedIncludeVat = column?.columns[index]?.includeVat;
              if (selectedIncludeVat === undefined) {
                if (foundProduct?.includevat === "1.00") {
                  includeVat =
                    item?.includeVat === false ? false : true || true;
                } else {
                  includeVat = item?.includeVat || false;
                }
              } else {
                includeVat = selectedIncludeVat;
              }
              if (includeVat) {
                let totalItemsRate = price * quantity;
                vatAmount = (totalItemsRate / (100 + vatPercent)) * 100;
                vatAmount = Number((price * quantity - vatAmount).toFixed(2));
                total = price * quantity;
              } else {
                total = Number((price * quantity + vatAmount).toFixed(2));
              }
              ///////////////////////includeVat//////////////

              if (column?.columns[index]?.discount > 0) {
                const discountRate = Number(item.discount) / 100;
                discountAmount = total * discountRate;
                discount = Number(item?.discount);
              } else if (
                column?.columns[index]?.discount == null ||
                column?.columns[index]?.discount == 0 ||
                column?.columns[index]?.discount == "" ||
                column?.columns[index]?.discount === undefined
              ) {
                if (item?.discountamt > 0) {
                  const discountpecentage =
                    (Number(item?.discountamt) / total) * 100;
                  discountAmount = Number(item?.discountamt);
                  discount = Number(discountpecentage);
                } else {
                  discountAmount = 0;
                }
              }
              if (0 >= column?.columns[index]?.discountamt) {
                discount = 0;
              }
              if (column?.columns[index]?.discountamt > 0) {
                const discountpecentage =
                  (Number(item?.discountamt) / total) * 100;
                discountAmount = Number(item?.discountamt);
                discount = Number(discountpecentage.toFixed(2));
              }
              //here total calculation
              if (includeVat) {
                _subTotal = price * quantity - vatAmount + _subTotal;
              } else {
                _subTotal = price * quantity + _subTotal;
              }
              _tatalVat = _tatalVat + vatAmount;
              _overollDiscount = _overollDiscount + discountAmount;

              const updatedColumn = {
                id: item.id,
                quantity:
                  column?.columns[index]?.quantity === undefined
                    ? quantity
                    : column?.columns[index]?.quantity?.length == "0"
                    ? null
                    : item?.quantity,
                price:
                  column?.columns[index]?.price === undefined
                    ? price
                    : column?.columns[index]?.price?.length == "0"
                    ? null
                    : item?.price,
                incomeTaxAmount: vatAmount?.toFixed(2),
                vatamt: vatAmount?.toFixed(2),
                description: foundProduct?.idescription,
                vat:
                  item?.vat === undefined || item?.vat === null
                    ? foundProduct?.vat
                    : item?.vat,
                vatamount: vatAmount.toFixed(2),
                discountamt: discountAmount,
                discount: discount,
                total: total - discountAmount,
                includeVat,
              };
              return updatedColumn;
            } else {
              let newColumn = {
                id: null,
                quantity: null,
                price: null,
                incomeTaxAmount: null,
                vatamt: null,
                description: null,
                vat: null,
                vatamount: null,
                discountamt: null,
                discount: null,
                total: null,
                includeVat: null,
              };
              return newColumn;
            }
          }
        );
        if (updatedColumns?.length) {
          form.setFieldsValue({ columns: updatedColumns });
          setSubTotal(_subTotal);
          setTatalVat(_tatalVat);
          setOverolDiscount(_overollDiscount);
          let _totalAmount = _subTotal + _tatalVat - _overollDiscount;
          let roundedNumber = Math.round(_totalAmount);
          let amountAdded = roundedNumber - _totalAmount;
          setRoundOff(Number(amountAdded.toFixed(2)));
          //setTotalAmount(roundedNumber);
          setTotalAmount(_totalAmount);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <PageHeader
        title="Create Proforma Invoice"
        goBack={"/dashboard"}
        secondPathText="Proforma Invoice"
        secondPathLink={"/usr/sales-proforma-invoice"}
        thirdPathText={"Create Proforma Invoice"}
        thirdPathLink={`/usr/proforma-invoice-form/${0}`}
      />
      <br />
      <Container>
        <Card>
          <Form form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
            <Row>
              <Col sm={3}>
                <div className="formLabel">Invoice No.</div>
                <Form.Item name={"invoiceno"}>
                  <Input readOnly size="large" />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <div className="formLabel">Customer Name</div>
                <Form.Item
                  name={"customerid"}
                  rules={[{ required: true, message: "choose customer" }]}
                >
                  <Select
                    size="large"
                    showSearch
                    onSearch={(val: any) => props?.customerName(val)}
                    onChange={(val: any) => {
                      let selectCustomers = customers?.find(
                        (item: any) => item.id === val
                      );
                      form.setFieldsValue({
                        inaddress: selectCustomers?.address,
                        deladdress: selectCustomers?.address,
                      });
                    }}
                  >
                    {customers &&
                      customers?.length &&
                      customers?.map((item: any) => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {item.bus_name}
                          </Select.Option>
                        );
                      })}
                    <Select.Option key="addButton" value="addButton">
                      <Button
                        type="primary"
                        block
                        onClick={() => setCustomerCreate(true)}
                      >
                        <GoPlus /> Add New
                      </Button>
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={3}>
                <div className="formLabel">Invoice Date</div>
                <Form.Item name={"sdate"} initialValue={dayjs(new Date())}>
                  <DatePicker style={{ width: "100%" }} size="large" />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <div className="formLabel">Reference</div>
                <Form.Item name={"reference"}>
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Items
              form={form}
              taxLists={taxList}
              products={products}
              isCheckChange={(val: any) => setIsCheck(val)}
              qSuffix={qSuffix}
            />
            <br />
            <Row>
              <Col sm={3}>
                <div className="formLabel">Invoice Address</div>
                <Form.Item name={"inaddress"}>
                  <Input.TextArea rows={4} size="large" />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <div className="formLabel">Delivery Address</div>
                <Form.Item name={"deladdress"}>
                  <Input.TextArea rows={4} size="large" />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <div className="formLabel">Terms and Conditions</div>
                <Form.Item name={"terms"}>
                  <Input.TextArea rows={4} size="large" />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <div className="formLabel">Note</div>
                <Form.Item name={"quotes"}>
                  <Input.TextArea rows={4} size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col sm={6}></Col>
              <Col sm={6}>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>TAXABLE VALUE</td>
                      <td>{Number(subTotal).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>TOTAL VAT</td>
                      <td>{Number(totalVat).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>OVERALL DISCOUNT</td>
                      <td>{Number(overollDiscount).toFixed(2)}</td>
                    </tr>
                    {/* <tr>
                      <td>ROUND OFF</td>
                      <td className="p-1">
                        <Input
                          className="p-1"
                          type="number"
                          bordered={false}
                          value={roundOff}
                          onChange={(e: any) => {
                            setRoundOff(e.target.value);
                            let round = Number(e.target.value);
                            let _totalAmount =
                              subTotal + totalVat - overollDiscount;
                            _totalAmount = _totalAmount + round;
                            setTotalAmount(_totalAmount);
                          }}
                        />
                      </td>
                    </tr> */}
                    <tr>
                      <td>TOTAL AMOUNT</td>
                      <td>{Number(totalAmount).toFixed(2)}</td>
                    </tr>
                  </tbody>
                </Table>
                <Row>
                  <Col sm={6}>
                    <Button size="large" block onClick={() => navigate(-1)}>
                      Close
                    </Button>
                  </Col>
                  <Col sm={6}>
                    <Button
                      size="large"
                      block
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Create
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>

      {customerCreate ? (
        <CreateCutomerModal
          open={customerCreate}
          onCancel={() => setCustomerCreate(false)}
          customerSearch={props.customerName}
          type="customer"
          customer={(val: any) =>
            form.setFieldsValue({ customerid: Number(val) })
          }
        />
      ) : null}
    </div>
  );
}
export default Create;
