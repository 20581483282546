import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Pagination,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import PageHeader from "../../../components/pageHeader";
import { GET, POST, PUT } from "../../../utils/apiCalls";
import API from "../../../config/api";
import DataTable from "./Table";
import { useNavigate, useParams } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";
import search from "antd/es/transfer/search";
import * as XLSX from "xlsx";
import { Component } from "devextreme-react/cjs/core/component";
import { SiMicrosoftexcel } from "react-icons/si";
import { useTranslation } from "react-i18next";

import ExcelImport from "./componet/excelImport";

import { IoRemoveCircleOutline } from "react-icons/io5";
import departments from "./helpers/department.json";
import { MdRemoveRedEye } from "react-icons/md";
import designation from "./helpers/designation.json";

function HrEmployees() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [employee, setEmployees] = useState<any>([]);
  const [take, setTake] = useState(10);
  const [excelModal, setExcelModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const { id } = useParams();
  console.log("data==============", data);
  const columns = [
    {
      dataIndex: "employeeId",
      title: "employeeId",
    },
    {
      dataIndex: "fullName",
      title: "name",
    },
    {
      dataIndex: "email",
      title: "email",
    },
    {
      dataIndex: "gender",
      title: "gender",
    },
    { dataIndex: "designation", title: "designation" },
    { dataIndex: "department", title: "department" },
    {
      title: "Details",
      key: "id",
      render: (_: any, record: any) => (
        <Space size="middle">
          <MdRemoveRedEye
            color="blue"
            size={20}
            onClick={() => navigate(`/usr/hr-employees/details/${record?.id}`)}
          />
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record: any) => (
        <div className="table-Action">
          {record ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Blocked</Tag>
          )}
        </div>
      ),
    },
    {
      title: "edit",
      key: "id",
      render: (_: any, record: any) =>
        record.status ? (
          <Space size="middle">
            <EditOutlined
              color="blue"
              size={20}
              onClick={() => navigate(`/usr/hr-employees/edit/${record?.id}`)}
            />
          </Space>
        ) : null,
    },
  ];

  const fetchHrEmployee = async (page: any, take: any) => {
    try {
      setIsLoading(true);
      let obj = {
        query: form.getFieldValue("query"),
        page: page,
        take: 10,
      };
      const url = API.POST_PAGE_DATA;
      const response: any = await POST(url, obj);
      setData(response?.data);
      setMeta(response?.meta);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHrEmployee(1, 10);
    // submitHandler({});
  }, []);
  useEffect(() => {
    searchEmployees({}, 1, 10);
  }, [page]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const submitHandler = async (values: any) => {
    try {
      let employee = {
        employee: values?.employee,
        role: "user",
      };
      setIsLoading(true);
      let api = API.HR_EMPLOYEE_CREATE;
      let postRequest: any = await POST(api, employee);
      if (postRequest.status) {
        notification.success({ message: "Employeee create" });
        setIsLoading(false);
        setIsModalOpen(false);
        fetchHrEmployee(1, 10);
        form.resetFields();
      } else {
        notification.error({ message: "something went wrong" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const searchEmployees = async (values: any, page: any, take: any) => {
    console.log("values --> ", values);
    try {
      setIsLoading(true);
      let url = `${API.SEARCH_EMPLOYEE_DATA}?order=DESC&page=${page}&take=${take}`;
      let obj = {
        ...(values &&
          values.designation && { designation: values.designation }),
        ...(values && values.department && { department: values.department }),
        ...(values && values.query && { query: values.query }),
      };
      console.log("obj-----", obj);
      var employees: any = await POST(url, obj);

      if (employees.data) {
        setData(employees?.data);
        setMeta(employees.meta);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const uploadfile = async (fileObj: any) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", fileObj);
      formData.append("reqObj", JSON.stringify(employee));

      let URL = API.HR_EMPLOYEE_CREATE;
      console.log("formData ---> ", formData);

      const response: any = await POST(URL, employee);
      if (response.ok) {
        notification.success({
          message: "Success",
          description: "Uploaded Successfully.!",
        });
        onSucess();

        setIsLoading(false);
      } else {
        setIsLoading(false);
        notification.error({
          message: "Failed",
          description: "Failed to upload ..!",
        });
      }
    } catch (error) {
      notification.error({
        message: "Server Error",
        description: "Failed to upload ..!",
      });
      console.log(error);
    }
  };

  return (
    <>
      <PageHeader title="Employees List">
        <Tooltip title={t("home_page.homepage.Import_from_Excel")}>
          <Button
            onClick={() => setExcelModal(true)}
            style={{ marginRight: "10px" }}
          >
            <SiMicrosoftexcel size={20} />
          </Button>
        </Tooltip>
        <Button
          type="primary"
          onClick={showModal}
          style={{ marginRight: "10px" }}
        >
          Create Employee
        </Button>

        <ExcelImport
          visible={excelModal}
          onCancel={() => setExcelModal(false)}
          onSucess={() => fetchHrEmployee(1, 10)}
          URL={API.HR_EMPLOYEE_EXCEL}
        />
        <Modal
          title="Create Employee"
          visible={isModalOpen}
          onCancel={handleCancel}
          width={1000}
          footer={false}
        >
          <Form form={form} onFinish={submitHandler} layout="vertical">
            <Form.List name="employee">
              {(fields, { add, remove }) => (
                <>
                  {/* {fields?.length ? (
                    <Row>
                      <Col sm={3}>
                        <div className="form-label">Role</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">Full Name</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">Designation</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">Gender</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">Office Email</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">Phone</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">Salary</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">Department</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">Date of Birth</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">Hire Date</div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-label">Actions</div>
                      </Col>
                    </Row>
                  ) : null} */}
                  {fields?.map((field) => (
                    <Row key={field.key}>
                      <Col sm={3}>
                        <Form.Item
                          name={[field.name, "fullName"]}
                          rules={[{ required: true, message: "Enter name" }]}
                        >
                          <Input placeholder="Enter name" />
                        </Form.Item>
                      </Col>
                      <Col sm={3}>
                        <Form.Item
                          name={[field.name, "designation"]}
                          style={{ marginBottom: 10 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select desiganation",
                            },
                          ]}
                        >
                          <Select placeholder="Select desiganation">
                            {designation?.map((des) => (
                              <Select.Option key={des.id} value={des.value}>
                                {des.title}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={3}>
                        <Form.Item
                          name={[field.name, "gender"]}
                          rules={[{ required: true, message: "Select gender" }]}
                        >
                          <Select placeholder="Select gender">
                            <Select.Option value="male">Male</Select.Option>
                            <Select.Option value="female">Female</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={3}>
                        <Form.Item
                          name={[field.name, "email"]}
                          rules={[
                            { required: true, message: "Enter  email" },
                            {
                              message: "Enter a address",
                            },
                          ]}
                        >
                          <Input placeholder="Enter  email" />
                        </Form.Item>
                      </Col>
                      <Col sm={3}>
                        <Form.Item
                          name={[field.name, "phone"]}
                          rules={[
                            { required: true, message: "Enter phone number" },
                          ]}
                        >
                          <Input placeholder="Enter phone number" />
                        </Form.Item>
                      </Col>
                      <Col sm={3}>
                        <Form.Item
                          name={[field.name, "salary"]}
                          rules={[{ required: true, message: "Enter salary" }]}
                        >
                          <Input placeholder="Enter salary" />
                        </Form.Item>
                      </Col>
                      <Col sm={3}>
                        <Form.Item
                          name={[field.name, "department"]}
                          rules={[
                            { required: true, message: "Select department" },
                          ]}
                        >
                          <Select placeholder="Select department">
                            {departments.map((dep: any) => (
                              <Select.Option key={dep.id} value={dep.value}>
                                {dep.title}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={3}>
                        <Form.Item
                          name={[field.name, "dob"]}
                          rules={[
                            { required: true, message: "Select date of birth" },
                          ]}
                        >
                          <DatePicker placeholder="Select date of birth" />
                        </Form.Item>
                      </Col>
                      <Col sm={3}>
                        <Form.Item
                          name={[field.name, "hireDate"]}
                          rules={[
                            { required: true, message: "Select hire date" },
                          ]}
                        >
                          <DatePicker placeholder="Select hire date" />
                        </Form.Item>
                      </Col>
                      <Col sm={3}>
                        {" "}
                        <Form.Item
                          name={[field.name, "workLocation"]}
                          rules={[
                            { required: true, message: " Enter work location" },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter work location"
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={1}>
                        <Form.Item>
                          <Button
                            size="large"
                            onClick={() => remove(field.name)}
                            block
                          >
                            <IoRemoveCircleOutline size={20} color="red" />
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Row>
                    <Col sm={8}></Col>
                    <Col sm={4}>
                      <Button size="large" block onClick={() => add()}>
                        Add Employee +
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={isLoading}>
                {isLoading ? <Spin size="small" /> : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </PageHeader>
      <br />
      <div>
        <br />
        <div className="">
          <Container>
            <Card>
              <Form
                form={form2}
                layout="vertical"
                onFinish={(values: any) => searchEmployees(values, page, take)}
              >
                <Row style={{ marginTop: "20px" }}>
                  <Col md="3">
                    <Form.Item name="designation">
                      <Select
                        placeholder="Select designation"
                        onChange={(values: any) =>
                          searchEmployees({ designation: values }, page, take)
                        }
                        allowClear
                      >
                        {designation?.map((des: any) => (
                          <Select.Option key={des.id} value={des.value}>
                            {des.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md="3">
                    <Form.Item name="department">
                      <Select
                        placeholder="Select Department"
                        onChange={(values: any) =>
                          searchEmployees({ department: values }, page, take)
                        }
                        allowClear
                      >
                        {departments.map((dep: any) => (
                          <Select.Option key={dep.id} value={dep.value}>
                            {dep.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md="3">
                    <div>
                      <div></div>
                      <Form.Item name={"query"}>
                        <Input bordered={true} placeholder="Search here" />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md="3">
                    <Form.Item>
                      <Button htmlType="submit">Search</Button>
                      <Input bordered={false} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Container>
          <br />
        </div>
        <div>
          <Container>
            {" "}
            <Card>
              {" "}
              <br />
              <h4 style={{ textAlign: "center" }}>Employee Details</h4>
              <br />
              <h6>Total {meta?.total_count} employees</h6>
              <br />
              <Table columns={columns} dataSource={data} pagination={false} />
            </Card>
          </Container>
        </div>
      </div>
      <Pagination
        defaultCurrent={meta?.page}
        total={meta?.total_count}
        onChange={(page, take) => {
          fetchHrEmployee(page, take);
        }}
        style={{
          display: meta?.total_count > 10 ? "block" : "none",
          textAlign: "right",
          marginTop: "20px",
        }}
      />

      <div style={{ textAlign: "right", marginTop: "10px" }}></div>
    </>
  );
}

export default HrEmployees;
function onSucess() {
  throw new Error("Function not implemented.");
}
