import {
  Button,
  Card,
  Checkbox,
  Modal,
  Timeline,
  Tooltip,
  notification,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoMdAlarm } from "react-icons/io";
import { IoShareSocial } from "react-icons/io5";
import { SiGridsome } from "react-icons/si";
import { useNavigate, useParams } from "react-router-dom";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import API from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";
import "../styles.scss";
import {
  ClockCircleOutlined,
  DollarCircleOutlined,
  EditOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";
import { template1, template2, template3 } from "../components/templates";
import { MdFileDownload } from "react-icons/md";
import { MdAttachEmail } from "react-icons/md";
import SendMailModal from "../../../components/sendMailModal";
import Paymentmodal from "../../../components/paymentmodal/paymentmodal";
import { numberToWords } from "../../../utils/helpers";

function SaleInvoiceView(props: any) {
  const { id }: any = useParams();
  const User = useSelector((state: any) => state.User);
  const [isFullLoading, setIsFullLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [details, setDetails] = useState<any>({});
  const [selectBank, setSlectedBank] = useState<any>({});
  const [paymentModal, setPaymentModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const navigate = useNavigate();
  let _subTotal = 0;
  let _tatalVat = 0;
  let _overollDiscount = 0;
  let discountAmount: any = 0;
  let amountAdded = 0;
  useEffect(() => {
    getInvoiceDetails();
    getBankList();
  }, []);

  const getInvoiceDetails = async () => {
    setIsFullLoading(true);
    try {
      let url = API.VIEW_SALE_INVOICE + id + "/sales";
      const getInvDetails: any = await GET(url, null);
      if (getInvDetails?.status) {
        setDetails(getInvDetails?.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsFullLoading(false);
    }
  };

  async function genrateTemplate(type: any, emaildata: any) {
    try {
      setDownloadLoading(true);
      let obj = {
        user: User.user,
        customer: details?.invoiceDetails?.customer,
        sale: details?.invoiceDetails,
        productlist: details?.invoiceItems,
        bankList: details?.banking,
        vatTotal: _tatalVat,
        netTotal: _subTotal,
        Discount: _overollDiscount,
        round: amountAdded,
        total: details?.invoiceDetails?.total,
        vatRate: _tatalVat,
        isPaymentInfo: false,
        pagetype: "Sales Invoice",
        selectedBank: User.user.bankInfo,
      };
      let templates: any = null;
      if (!User.user.companyInfo.defaultinvoice) {
        notification.error({
          message: <div>Please select an email template</div>,
          description: (
            <Button
              type={"link"}
              onClick={() => navigate("/usr/settings/customize")}
            >
              Click to select
            </Button>
          ),
        });
        setDownloadLoading(false);
        return;
      }
      if (!User?.user?.bankInfo?.id) {
        notification.error({
          message: <div>Please select default Bank </div>,
          description: (
            <Button
              type={"link"}
              onClick={() => navigate("/usr/profile/business")}
            >
              Click to select
            </Button>
          ),
        });
        setDownloadLoading(false);
        return;
      }
      if (User.user.companyInfo.defaultinvoice === "1") {
        templates = template1(obj);
      } else if (User.user.companyInfo.defaultinvoice === "2") {
        templates = template2(obj);
      } else if (User.user.companyInfo.defaultinvoice === "3") {
        templates = template3(obj);
      }
      if (type === "email") {
        sendMailPdf(templates, emaildata);
      } else {
        await downLoadPdf(templates);
      }

      setDownloadLoading(false);
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  }
  const downLoadPdf = async (templates: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    const encodedString = btoa(templateContent);
    const pdf_url = API.PDF_GENERATE_URL;
    const pdfData = {
      filename: "Sales Invoice",
      html: encodedString,
      isDownload: true,
      sendEmail: false,
      type: "",
      userid: "",
    };
    const token = User.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const pdfBlob = await response.arrayBuffer();
    const blob = new Blob([pdfBlob], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `sales${details?.invoiceDetails?.customer?.bus_name}_${
      details?.invoiceDetails?.invoiceno
    }_${moment(new Date()).format("DD-MM-YYYY")}`;
    a.click();
    URL.revokeObjectURL(url);
  };
  const sendMailPdf = async (templates: any, email: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    const encodedString = btoa(templateContent);
    const pdf_url = API.PDF_GENERATE_URL;
    const pdfData = {
      email: email,
      filename: "Sales Invoice.pdf",
      html: encodedString,
      isDownload: false,
      sendEmail: true,
      type: "",
      userid: "",
    };
    const token = User.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (response.ok) {
      notification.success({ message: "Email Successfully Sent" });
      setEmailModal(false);
    }
    if (!response.ok) {
      notification.success({
        message:
          "Apologies, there was an error when attempting to send the email.",
      });
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  };

  async function paymentFinish(val: any) {
    try {
      let status = null;
      if (val.outStanding <= 0) {
        status = "2"; //paid
      } else if (val.outStanding < details?.invoiceDetails?.total) {
        status = "1"; //part Paid
      } else if (val.outStanding >= details?.invoiceDetails?.total) {
        status = "0"; //unpaid
      }

      let payload = {
        userid: User.user.adminid,
        adminid: User.user.adminid,
        status: status,
        customerid: details?.invoiceDetails?.customer?.id,
        outstanding: val?.outStanding,
        bankid: val?.paymentBank,
        sinvoice: id,
        logintype: "usertype",
        type: "Customer Payment",
        date: val?.paymentDate,
        paidmethod: val?.paymentMethod,
        amount: val?.amoutToPaid,
        userdate: new Date(),
      };
      let url = API.SALES_PAYMENT;
      let response: any = await POST(url, payload);
      if (response.status) {
        notification.success({ message: response.message });
        setPaymentModal(false);
        await getInvoiceDetails();
      }
    } catch (error) {
      console.log("error", error);
      notification.error({ message: "Something went wrong to your payment." });
      setPaymentModal(false);
    }
  }

  const getBankList = async () => {
    try {
      let url = "account_master/getBankList/" + User.user.adminid;
      const { data }: any = await GET(url, null);

      setSlectedBank(data.bankList);
    } catch (error) {}
  };

  return (
    <>
      <PageHeader
        title="Sales Invoice View"
        goBack={"/dashboard"}
        secondPathText="Sales Invoice View"
        secondPathLink={"/usr/sales-invoice"}
        children={
          <div>
            <Tooltip
              title="Download Invoice"
              mouseEnterDelay={0.5}
              arrow={false}
              color="white"
              overlayClassName="toolTip-Card"
              overlayInnerStyle={{
                color: "#000000",
                marginTop: 5,
                fontSize: "14px",
              }}
              placement={"bottom"}
            >
              <Button
                onClick={() => genrateTemplate("downLoad", {})}
                loading={downloadLoading}
              >
                <MdFileDownload size={20} />
              </Button>
            </Tooltip>
            &nbsp;
            <Tooltip
              title="Mail invoice"
              mouseEnterDelay={0.5}
              arrow={false}
              color="white"
              overlayClassName="toolTip-Card"
              overlayInnerStyle={{
                color: "#000000",
                marginTop: 5,
                fontSize: "14px",
              }}
              placement={"bottom"}
            >
              <Button onClick={() => setEmailModal(true)}>
                <MdAttachEmail size={20} />
              </Button>
            </Tooltip>
            {/* &nbsp;
            <Tooltip
              title="Share Invoice"
              mouseEnterDelay={0.5}
              arrow={false}
              color="white"
              overlayClassName="toolTip-Card"
              overlayInnerStyle={{
                color: "#000000",
                marginTop: 5,
                fontSize: "14px",
              }}
              placement={"bottom"}
            >
              <Button onClick={() => setEmailModal(true)}>
                <IoShareSocial size={20} />
              </Button>
            </Tooltip> */}
          </div>
        }
      />
      <Container>
        <br />
        {isFullLoading ? (
          <LoadingBox />
        ) : (
          <Card>
            <Row>
              <Col md="12">
                <div className="salesInvoice-Header">Sales Invoice</div>

                <Table bordered>
                  <tbody>
                    <tr>
                      <td className="items-head">Invoice No</td>
                      <td>
                        <strong>{details?.invoiceDetails?.invoiceno}</strong>
                      </td>
                      <td className="items-head">Reference</td>
                      <td className="items-value">
                        {details?.invoiceDetails?.reference}
                      </td>
                    </tr>
                    <tr>
                      <td className="items-head"> Invoice Date</td>
                      <td className="items-value">
                        {dayjs(details?.invoiceDetails?.sdate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td className="items-head">Due Date</td>
                      <td className="items-value">
                        {dayjs(details?.invoiceDetails?.ldate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="items-head"> Invoice Address</td>
                      <td className="items-value">
                        {details?.invoiceDetails?.inaddress}
                      </td>
                      <td className="items-head"> Delivery Address</td>
                      <td className="items-value">
                        {details?.invoiceDetails?.cname},
                        {details?.invoiceDetails?.deladdress}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col>
                <div className="salesInvoice-SubHeader ">Invoice Items</div>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>PRODUCT</th>
                      <th>QUANTITY</th>
                      <th>UNIT</th>
                      <th>PRICE</th>
                      <th>TAX %</th>
                      <th>TAX AMT</th>
                      <th>INC TAX</th>
                      <th>DISC %</th>
                      <th>DISC AMT</th>
                      <th>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {details?.invoiceItems?.map((item: any) => {
                      let vatPercent = Number(item.vat);
                      discountAmount = 0;
                      let vatAmount = Number(item.vatamt);

                      if (item.includevat == 1) {
                        _subTotal =
                          Number(item.costprice) * Number(item.quantity) -
                          vatAmount +
                          _subTotal;
                      } else {
                        _subTotal =
                          Number(item.costprice) * Number(item.quantity) +
                          _subTotal;
                      }
                      if (item.discount > 0) {
                        const discountRate = item.discount / 100;
                        discountAmount =
                          item.includevat == 1
                            ? Number(item.costprice) *
                              Number(item.quantity) *
                              discountRate
                            : (Number(item.costprice) * Number(item.quantity) +
                                vatAmount) *
                              discountRate;
                      }

                      _tatalVat = _tatalVat + vatAmount;
                      _overollDiscount = _overollDiscount + discountAmount;
                      let _totalAmount =
                        _subTotal + _tatalVat - _overollDiscount;
                      let roundedNumber = Math.round(_totalAmount);
                      amountAdded = roundedNumber - _totalAmount;

                      return (
                        <tr>
                          <td>{item?.description}</td>
                          <td>{item?.quantity}</td>
                          <td>{item.product.unit}</td>
                          <td>{item?.costprice}</td>
                          <td>{item?.vat}%</td>
                          <td>{item?.vatamt}</td>
                          <td style={{ textAlign: "center" }}>
                            <Checkbox
                              checked={item.includevat == 1 ? true : false}
                            />
                          </td>
                          <td>{item?.discount}</td>
                          <td>{(discountAmount).toFixed(2)}</td>
                          <td>{item?.total}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col sm={8}></Col>
              <Col sm={4}>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>TAXABLE VALUE</td>
                      <td>{_subTotal?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>TOTAL VAT</td>
                      <td>{_tatalVat?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>OVERALL DISCOUNT</td>
                      <td>{_overollDiscount?.toFixed(2)}</td>
                    </tr>
                    {/* <tr>
                      <td>ROUND OFF</td>
                      <td>{amountAdded?.toFixed(2)}</td>
                    </tr> */}
                    <tr>
                      <td className="items-head">TOTAL AMOUNT</td>
                      <td className="items-head">
                        {details?.invoiceDetails?.total}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Table bordered>
              <tbody>
                <tr>
                  <td>
                    <strong>Terms and Conditions</strong>
                    <hr />
                    <div>{details?.invoiceDetails?.terms}</div>
                  </td>
                  <td>
                    <strong>Notes</strong>
                    <hr />
                    <div>{details?.invoiceDetails?.quotes}</div>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table bordered>
              <tbody>
                <tr>
                  <td>
                    Payment Status: &nbsp;
                    {details?.invoiceDetails?.status === 0 ? (
                      <>
                        <IoMdAlarm size={25} color="red" />
                        &nbsp; Awaiting Payment
                      </>
                    ) : details?.invoiceDetails?.status === 1 ? (
                      <>
                        <SiGridsome size={25} color="orange" />
                        &nbsp; Payment Pending
                      </>
                    ) : (
                      <>
                        <BsCheckCircleFill size={25} color="green" />
                        &nbsp; Paid
                      </>
                    )}
                  </td>
                  <td>
                    Outstanding:&nbsp;
                    <strong>
                      {details?.invoiceDetails?.outstanding || 0.0}
                    </strong>
                  </td>
                  {details?.invoiceDetails?.status === 2 ? null : (
                    <td>
                      <Button
                        block
                        type="primary"
                        size="large"
                        onClick={() => setPaymentModal(true)}
                      >
                        Add Payment
                      </Button>
                    </td>
                  )}
                </tr>
              </tbody>
            </Table>

            <Modal
              open={paymentModal}
              onCancel={() => setPaymentModal(false)}
              width={800}
              maskClosable={false}
              footer={false}
              title="Add Payment"
            >
              <Paymentmodal
                onCancel={() => setPaymentModal(false)}
                onFinish={(val: any) => paymentFinish(val)}
                outstanding={details?.invoiceDetails?.outstanding}
                bankList={selectBank}
              />
            </Modal>
            <div className="salesInvoice-SubHeader ">Invoice TimeLine</div>
            <br />
            <Timeline mode="alternate">
              {details?.invoiceDetails?.status === 2 && (
                <Timeline.Item
                  dot={
                    <DollarCircleOutlined
                      style={{ color: "green", fontSize: 20 }}
                    />
                  }
                  color="green"
                >
                  <div className="heading-txt3">Paid</div>
                  <br />
                  Payment Recorded on{" "}
                  {details?.invoiceDetails.paymentdate &&
                    moment(details?.invoiceDetails.paymentdate).format(
                      "MMMM Do YYYY @ h:mm a"
                    )}
                </Timeline.Item>
              )}

              {details?.invoiceDetails?.status === 0 && (
                <Timeline.Item
                  dot={<FileDoneOutlined style={{ fontSize: 20 }} />}
                  color="gray"
                >
                  <div className="heading-txt3">Pending Payment</div>
                  <br />
                  No payment yet
                </Timeline.Item>
              )}

              {details.banking?.map((item: any, index: any) => {
                return (
                  <Timeline.Item
                    key={index}
                    dot={<DollarCircleOutlined style={{ fontSize: 20 }} />}
                    color="blue"
                  >
                    <div className="heading-txt3">
                      {details?.invoiceDetails?.status === 2
                        ? "Invoice Paid "
                        : "Part of Invoice Paid"}
                    </div>
                    <br />
                    Made a payment on{" "}
                    {moment(new Date(item.date)).format("DD-MMM-YYYY")} of{" "}
                    <strong>{parseInt(item.amount)}</strong> to{" "}
                    {item?.bankInf?.laccount}
                  </Timeline.Item>
                );
              })}

              {details.banking?.map((item: any, index: any) => {
                if (index > 0) {
                  return null;
                }
                return (
                  <>
                    {item.type === "Live Payment" ? null : (
                      <Timeline.Item
                        // key={index}
                        dot={<ClockCircleOutlined style={{ fontSize: 20 }} />}
                        color="red"
                      >
                        <div className="heading-txt3">Due</div>
                        <br />
                        Invoice Due on{" "}
                        {moment(details?.invoiceDetails?.ldate).format(
                          "DD-MMM-yyyy"
                        )}
                      </Timeline.Item>
                    )}
                  </>
                );
              })}

              <Timeline.Item
                dot={<EditOutlined style={{ fontSize: 20 }} />}
                color="gray"
                className="heading-txt"
              >
                <div className="heading-txt3">Created</div>
                <br />
                Invoice placed on{" "}
                {moment(new Date(details?.invoiceDetails?.created_at)).format(
                  "DD-MMM-yyyy @ h:mm a"
                ) || moment(new Date()).format("DD-MMM-yyyy @ h:mm a")}
              </Timeline.Item>
            </Timeline>
          </Card>
        )}
        {emailModal ? (
          <SendMailModal
            open={emailModal}
            close={() => setEmailModal(false)}
            onFinish={(val: any) => genrateTemplate("email", val)}
            ownMail={User.user.email}
            Attachment={`${details?.invoiceDetails?.customer?.bus_name}_${
              details?.invoiceDetails?.invoiceno
            }_${moment(new Date()).format("DD-MM-YYYY")}`}
            defaultValue={{
              to: details?.invoiceDetails.customer.email,
              subject: `Performa Invoice ${details?.invoiceDetails?.invoiceno}`,
              content: User.user.companyInfo.defaultmail,
            }}
            fileName={`SalesInvoice${new Date()}.pdf`}
          />
        ) : null}
      </Container>
      <br />
    </>
  );
}

export default SaleInvoiceView;
