import { Button, Form, Input, InputNumber, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GET, PUT } from "../../../../utils/apiCalls";
import API from "../../../../config/api";
import { useNavigate, useParams } from "react-router";
import { Col, Row } from "react-bootstrap";
import LoadingBox from "../../../../components/loadingBox";
import PageHeader from "../../../../components/pageHeader";
import { useLocation } from "react-router-dom";
import PrefixSelector from "../../../../components/prefixSelector";

function EditCustomerDetails() {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchCustomerDetails = async () => {
    try {
      setIsLoading(true);
      const customer_details_url = API.CONTACT_MASTER + `details/${id}`;
      const { data }: any = await GET(customer_details_url, null);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const submitHandler = async (values: any) => {
    try {
      const update_customer_url = API.CONTACT_MASTER + `update/${id}`;
      setIsLoading(true);
      let mobile = `${values.code} ${values.mobileNumber}`;
      const { status,message }: any = await PUT(update_customer_url, {
        ...values,
        mobile: values.mobileNumber ? mobile : "",
        adminid,
        type: "customer",
      });
      setIsLoading(false);
      navigate("/usr/contactCustomers");
      if (status) {
        notification.success({
          message: "Success",
          description:"Customer details updated successfully"
        });
      }else{
        notification.success({
          message: "Failed",
          description:`Failed to update customer details(${message})`
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      notification.error({
        message: "Server Error",
       description: "Something went wrong in server!! Please try again later",
      });
    }
  };

  const initialValues = {
    adminid,
    name: data?.name,
    reference: data?.reference,
    bus_name: data?.bus_name,
    code: data?.mobile
      ? data?.mobile.split(" ")[0]
      : user?.countryInfo?.phoneCode,
    mobileNumber: data?.mobile && data?.mobile.split(" ")[1],
    vat_number: data?.vat_number,
    email: data?.email,
    telephone: data?.telephone,
    city: data?.city,
    address: data?.address,
    postcode: data?.postcode,
    notes: data?.notes,
    ledger_category: data?.ledger_category,
    opening_balance: Number(data?.opening_balance),
  };

  const isVatExists = async(value:any) =>{
    console.log(value)
    try {
      let url = API.VAT_EXISTS + adminid + `/${value}`;
      const response :any = await GET(url,null)
      if(response.status){
        notification.error({
          message: "Duplicate VAT Number",
          description: "The VAT number you entered already exists. Please choose a unique VAT number.",
        });
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCustomerDetails();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <PageHeader
            firstPathLink={location.pathname.replace(`/edit/${id}`, "")}
            firstPathText="Customers List"
            secondPathLink={location?.pathname}
            secondPathText="Update Customer"
            goback="/usr/contactCustomers"
            title="Update Customer"
          />
          <div className="adminTable-Box1 ">
            <div className="create-customer-container">
              <Form
                onFinish={submitHandler}
                initialValues={initialValues}
                layout="vertical"
              >
                <p>The informations can be edited</p>
                <Row>
                  <Col md={4}>
                    <label className="formLabel">Name</label>
                    <Form.Item
                      name="name"
                      style={{ marginBottom: 10 }}
                      rules={[{ required: true }]}
                    >
                      <Input
                        placeholder="Customer Name"
                        size="large"
                        className="input-field"
                      />
                    </Form.Item>
                    <label className="formLabel">Reference Code</label>
                    <Form.Item
                      name="reference"
                      style={{ marginBottom: 10 }}
                      rules={[{ required: true }]}
                    >
                      <Input
                        placeholder="Reference Code"
                        size="large"
                        className="input-field"
                      />
                    </Form.Item>
                    <label className="formLabel">Business Name</label>
                    <Form.Item
                      name="bus_name"
                      rules={[{ required: true }]}
                      style={{ marginBottom: 10 }}
                    >
                      <Input
                        placeholder="Business Name"
                        size="large"
                        className="input-field"
                      />
                    </Form.Item>
                    <label className="formLabel">VAT Number</label>
                    <Form.Item
                      name="vat_number"
                      style={{ marginBottom: 10 }}
                      rules={[
                        {
                          pattern: new RegExp("^[A-Z0-9]+$"),
                          message:
                            "Please enter only capital letters and numbers",
                        },
                      ]}
                    >
                      <Input
                        placeholder="VAT Number"
                        size="large"
                        className="input-field"
                        onChange={(e) => {
                          const filteredValue = e.target.value.replace(/[^A-Z0-9]/g,"");
                          e.target.value = filteredValue;
                          isVatExists(e.target.value)
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <label className="formLabel">Email Address</label>
                    <Form.Item name="email" style={{ marginBottom: 10 }}
                     rules={[
                     {
                       pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                       message: 'Please enter a valid email address',
                     },
                   ]}
                    >
                      <Input
                        placeholder="Email"
                        size="large"
                        className="input-field"
                      />
                    </Form.Item>
                    <label className="formLabel">Mobile Number</label>
                    <Form.Item name="mobileNumber" style={{ marginBottom: 10 }}>
                      <Input
                        placeholder="Mobile Number"
                        size="large"
                        className="input-field"
                        addonBefore={<PrefixSelector />}
                        type="text"
                                    onInput={(e) => {
                                    e.currentTarget.value = e.currentTarget.value.replace(
                                    /[^0-9]/g,
                                    ""
                                    );
                                }}      
                      />
                    </Form.Item>
                    <label className="formLabel">Telephone Number</label>
                    <Form.Item name="telephone" style={{ marginBottom: 10 }}>
                      <Input
                        placeholder="Telephone Number"
                        size="large"
                        className="input-field"
                        type="text"
                      onInput={(e) => {
                      e.currentTarget.value = e.currentTarget.value.replace(
                      /[^0-9]/g,
                      ""
                      );
                  }}      
                      />
                    </Form.Item>
                    <label className="formLabel">Town/city</label>
                    <Form.Item name="city" style={{ marginBottom: 10 }}>
                      <Input
                        placeholder="Town/city"
                        size="large"
                        className="input-field"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <label className="formLabel">Address</label>
                    <Form.Item name="address" style={{ marginBottom: 10 }}>
                      <Input.TextArea
                        placeholder="Address"
                        size="large"
                        className="input-field"
                        rows={3}
                      />
                    </Form.Item>
                    <label className="formLabel">Postal Code</label>
                    <Form.Item name="postcode" style={{ marginBottom: 10 }}>
                      <Input
                        placeholder="Postal Code"
                        size="large"
                        className="input-field"
                      />
                    </Form.Item>
                    <label className="formLabel">Notes</label>
                    <Form.Item name="notes" style={{ marginBottom: 10 }}>
                      <Input.TextArea
                        placeholder="Notes"
                        size="large"
                        className="input-field"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={4}>
                    <label className="formLabel">Opening Balance(As on Business starting Date)</label>
                    <Form.Item
                      name="opening_balance"
                      style={{ marginBottom: 10 }}
                    >
                      <InputNumber
                        placeholder="Enter Amount"
                        size="large"
                        className="input-field"
                        style={{ width: "100%" }}
                        controls={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4} />
                  <Col md={2}>
                    <br />
                    <Button
                      size="large"
                      type="default"
                      onClick={()=>navigate('/usr/contactCustomers')}
                      block
                    >
                      Close
                    </Button>
                  </Col>
                  <Col md={2}>
                    <br />
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                      disabled={isLoading}
                      block
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default EditCustomerDetails;
