import { useEffect, useState } from "react";
import { useParams } from "react-router";
import "../styles.scss";
import { useSelector } from "react-redux";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import Edit from "./Edit";
import Create from "./create";

function CreditNotesForm(props: any) {
  const { id }: any = useParams();
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const [customers, setCustomers] = useState([]);
  const [ledgers, setLedgers] = useState([]);
  const [product, setProduct] = useState([]);
  const [banks, setBanks] = useState([]);
  const [taxList, setTaxlist] = useState([]);
  const [customerName, setCustomerName] = useState("");
  
  useEffect(() => {
    getCustomers();
    getProduct();
    loadTaxList();
    // getBanks();
    getLedgers();
  }, []);

  useEffect(() => {
    getCustomers();
  }, [customerName]);

  //get ledgers
  const getLedgers = async () => {
    try {
      let url = "account_master/defualt-ledger/sales/" + adminid;
      const { data: bank }: any = await GET(url, null);
      setLedgers(bank);
    } catch (error) {
      console.log(error)
    }
  };

  const getProduct = async () => {
    try {
      let productuul = "ProductMaster/user/Stock/" + adminid;
      const { data: products }: any = await GET(productuul, null);
      setProduct(products);
    } catch (error) {
      console.log(error)
    }
  };

  const loadTaxList = async () => {
    try {
      let URL = API.TAX_MASTER + adminid +'/all';
      const data :any = await GET(URL, null);
      setTaxlist(data);
    } catch (error) {
      console.log(error)
    }
  };


  const getCustomers = async () => {
    try {
      let customerapi =
        "contactMaster/searchList/both/" +
        adminid +
        `?name=${customerName}`;
      const { data: customer }: any = await GET(customerapi, null);
      setCustomers(customer);
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <div>
      {id === "0" ? (
        <Create
          customers={customers}
          banks={banks}
          adminid={adminid}
          ledgers={ledgers}
          product={product}
          getProduct={getProduct}
          taxList={taxList}
          customerName={(val: any) => setCustomerName(val)}
        />
      ) : (
        <Edit     
          customers={customers}
          banks={banks}
          adminid={adminid}
          ledgers={ledgers}
          product={product}
          getProduct={getProduct}
          taxList={taxList}
          customerName={(val: any) => setCustomerName(val)}
          id={id}
        />
      )}
      <br />
    </div>
  );
}
export default CreditNotesForm;
