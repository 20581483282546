import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import PageHeader from "../../../components/pageHeader";
import { GET, POST } from "../../../utils/apiCalls";
import Items from "../components/items";
import { GoPlus } from "react-icons/go";
import PrintModal from "../../../components/printModal/printModal";
import { template1, template2, template3 } from "../components/templates";
import ProductAddModal from "../../../components/productCreateModal";
import CreateCutomerModal from "../../../components/contactCreateModal";
import { useNavigate } from "react-router-dom";

function Create(props: any) {
  const [form] = Form.useForm();
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [tatalVat, setTatalVat] = useState(0);
  const [roundOff, setRoundOff] = useState(0);
  const [qSuffix, setqSuffix] = useState("");
  const [pStock, setPStock] = useState(0);
  const [overollDiscount, setOverolDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isPaymentInfo, setIsPaymentInfo] = useState<any>(false);
  const [selectBank, setSlectedBank] = useState<any>({});
  const [isReccNotification, setIsReccNotification] = useState<any>(false);
  const [selectBankBalance, setSelectBankBalance] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [template, setTemplate] = useState();

  const [coustomerCreate, setCoustomerCreate] = useState<any>(false);
  const [productCreate, setProductCreate] = useState<any>(false);
  const [ledgerId, setLedgerId] = useState<any>();

  const { Option } = Select;
  const navigate = useNavigate();

  const onFinish = async (val: any) => {
    try {
      const values = await form.validateFields();
      if (!val?.columns || !val?.columns?.length) {
        notification.error({ message: <h6>Add items to invoice</h6> });
        return;
      }
      let amountPaid = Number(val.amoutToPaid) || 0;
      let totalPayable = totalAmount;
      let outstanding = totalPayable - amountPaid;
      let status = "0";
      let reccObj = {};
      if (outstanding <= 0) {
        status = "2"; //paid
      } else if (outstanding < totalPayable) {
        status = "1"; //part Paid
      } else if (outstanding >= totalPayable) {
        status = "0"; //unpaid
      }
      setIsLoading(true);
      let paymentInfo = isPaymentInfo;
      if (isPaymentInfo) {
        paymentInfo = {
          id: val.paymentBank,
          bankid: val.paymentBank,
          outstanding: val.outStanding,
          amount: val.amoutToPaid,
          date: val?.paymentDate,
          type: val.paymentMethod,
          paidmethod: val.paymentMethod,
          running_total: Number(selectBankBalance) + Number(val?.amoutToPaid),
        };
      }

      let selectedCustomer =
        props.customers &&
        props.customers.length &&
        props?.customers.find((item: any) => item.id === val.customerid);

      let ledger =
        props.ledgers &&
        props.ledgers.length &&
        props?.ledgers?.find((item: any) => item.id === val.ledger);

      let column = val.columns.map((item: any) => {
        let foundedProduct = props.product.find(
          (product: any) => product.id === item.id
        );
        let productLedger = {};
        if (foundedProduct.itemtype === "Stock") {
          productLedger = {
            category: "13",
            id: 1,
            laccount: "Sales-Products",
            nominalcode: "4000",
          };
        } else if (foundedProduct.itemtype === " Service") {
          productLedger = {
            category: "24", //"13"
            id: 2,
            laccount: "Sales-Services",
            nominalcode: "4001",
          };
        }
        if (isReccNotification) {
          reccObj = {
            invoiceNo: val.invoiceno,
            period: val.period,
            date: val.date,
            daybefore: val?.daybefore,
          };
        }
        return {
          id: item.id,
          discount: item.discount,
          discountamt: item.discountamt,
          productId: item.id,
          product: foundedProduct,
          idescription: foundedProduct.idescription,
          description: foundedProduct.idescription,
          vat: item.vat,
          includevat: item.includeVat,
          incomeTax: item.vat,
          percentage: item.vat,
          costprice: item.price,
          ledgerDetails: productLedger,
          ledger: productLedger,
          quantity: item.quantity,
          total: item.total,
          vatamt: item.vat,
          vatamount: item.vatamount,
          incomeTaxAmount: item.vatamount,
          itemorder: 1,
        };
      });
      let payload = {
        cname: selectedCustomer.bus_name,
        customerid: val.customerid,
        columns: column,
        invoiceno: val.invoiceno,
        sdate: dayjs(val?.sdate).format("YYYY-MM-DD"),
        ldate: dayjs(val?.ldate).format("YYYY-MM-DD"),
        inaddress: val?.inaddress,
        deladdress: val?.deladdress,
        terms: val?.terms,
        quotes: val?.quotes,
        status: status,
        issued: "yes",
        type: "sales",
        pagetype: "1",
        total: Number(totalAmount),
        userid: adminid,
        adminid: adminid,
        userdate: new Date(),
        paymentInfo: paymentInfo,
        reference: val?.reference,
        salesType: "",
        ledger: ledger,
        email: user.email,
        reccObj: reccObj,
        //roundOff: roundOff + "",
        total_vat: tatalVat,
        overall_discount: overollDiscount,
        taxable_value: subTotal,
      };
      let obj = {
        user: user,
        customer: selectedCustomer,
        sale: {
          inaddress: val?.inaddress,
          deladdress: val?.deladdress,
          invoiceno: val.invoiceno,
          quotes: val?.quotes,
          terms: val?.terms,
          reference: val?.reference,
          userdate: new Date(),
          sdate: dayjs(val?.sdate).format("YYYY-MM-DD"),
          ldate: dayjs(val?.ldate).format("YYYY-MM-DD"),
          total: totalAmount,
          outstanding: isPaymentInfo ? paymentInfo.outstanding : totalAmount,
          status: 0,
          adminid: 2856200,
        },
        productlist: column,
        bankList: {},
        vatTotal: tatalVat,
        netTotal: subTotal,
        Discount: overollDiscount,
        // round: roundOff,
        total: totalAmount,
        vatRate: tatalVat,
        isPaymentInfo: false,
        pagetype: "Sales Invoice",
        selectedBank: user.bankInfo,
      };
      let templates: any = null;
      if (user.companyInfo.defaultinvoice === "1") {
        templates = template1(obj);
      } else if (user.companyInfo.defaultinvoice === "2") {
        templates = template2(obj);
      } else if (user.companyInfo.defaultinvoice === "3") {
        templates = template3(obj);
      }
      setTemplate(templates);
      let salesUrl = "SaleInvoice/add";
      const response: any = await POST(salesUrl, payload);

      // let response={status:false}
      if (response.status) {
        setIsLoading(false);
        setModalOpen(true);
        notification.success({
          message: "Success",
          description: "Sales invoice created successfully",
        });
        // navigate(-1);
      } else {
        notification.error({
          message: "Failed",
          description: "Failed to create sales invoice",
        });
        setIsLoading(false);
      }
    } catch (error: any) {
      const firstErrorField = Object.keys(error.errorFields[0])[0];
      // Scroll to the first error field
      const errorFieldElement = document.getElementsByName(firstErrorField)[0];
      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({ behavior: "smooth" });
      }

      // Display error message
      notification.error({ message: "Please fill in all required fields" });
      console.log(error);
      notification.error({ message: "Oops .. something went wrong" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (invoiceNumber === null) {
      getInvoiceNo();
    }
    form.setFieldsValue({
      terms: user?.companyInfo?.defaultTerms,
      quotes: user?.companyInfo?.cusNotes,
      sdate: dayjs(new Date()),
    });
  }, []);

  const getInvoiceNo = async () => {
    try {
      let invoiceurl = "user_settings/getInvoiceNo/" + props.adminid + "/sales";
      const { data: invnumber }: any = await GET(invoiceurl, null);
      setInvoiceNumber(invnumber);
      form.setFieldsValue({
        invoiceno: invnumber,
      });
    } catch (error) {}
  };

  function containsNull(arr: any) {
    let isThereNull = false;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (element === undefined) {
        isThereNull = true;
      }
    }
    return isThereNull;
  }
  const formValue = form.getFieldsValue();
  const onValuesChange = (column: any, allarray: any) => {
    try {
      if (column?.columns?.length < 1) {
        setSubTotal(0);
        setTatalVat(0);
        setOverolDiscount(0);
        setRoundOff(0);
        setTotalAmount(0);
      }
      if (column.ledger) {
        props.getProduct(column.ledger === 2 ? "Service" : "Stock");
        setLedgerId(column.ledger);
        setSubTotal(0);
        setTatalVat(0);
        setOverolDiscount(0);
        setRoundOff(0);
        setTotalAmount(0);
      }
      if (column.paymentBank) {
        let selectedBank = props?.banks?.find(
          (item: any) => item?.list?.id === column.paymentBank
        );
        let amoutToPaid = Number(allarray.amoutToPaid) || 0;
        setSelectBankBalance(selectedBank?.amount);
        form.setFieldsValue({
          bicnum: selectedBank?.list?.bicnum,
          ibanNumber: selectedBank?.list?.ibannum,
          accountNumber: selectedBank?.list?.accnum,
          holderName: selectedBank?.list?.laccount,
          availableBalance: selectedBank?.amount,
          outStanding: (Number(totalAmount) - amoutToPaid).toFixed(2),
          paymentMethod: selectedBank?.list?.laccount === "Cash" ? "cash" : "",
        });
      }
      if (column.amoutToPaid) {
        let outstanding = totalAmount - Number(column.amoutToPaid);
        form.setFieldsValue({ outStanding: outstanding });
        if (outstanding < 0) {
          form.setFieldsValue({ outStanding: 0, amoutToPaid: totalAmount - 0 });
          notification.error({
            message:
              "The amount cannot be greater than the outstanding balance.",
          });
        }
      } else if (
        allarray.amoutToPaid === null ||
        allarray.amoutToPaid === undefined ||
        allarray.amoutToPaid === ""
      ) {
        form.setFieldsValue({
          outStanding: totalAmount - 0,
        });
      }

      if (allarray?.columns && allarray?.columns?.length) {
        let _subTotal = 0;
        let _tatalVat = 0;
        let _overollDiscount = 0;
        const updatedColumns = allarray?.columns?.map(
          (item: any, index: any) => {
            if (column?.columns?.length > 1) {
              if (containsNull(column.columns)) {
                let productId = column.columns[index]?.id || null;
                if (productId) {
                  let array = allarray?.columns;
                  array[index].price = null;
                  array[index].vat = null;
                  array[index].discount = null;
                  array[index].discountamt = null;
                  array[index].quantity = null;
                  if (array?.length - 1 === index) {
                    array[index].includeVat = null;
                  }
                  form.setFieldsValue({ columns: array });
                }
              }
            } else {
              if (
                !column.columns[index]?.price ||
                !column.columns[index]?.quantity
              ) {
                if (
                  column.columns[index]?.discount ||
                  column.columns[index]?.discountamt
                ) {
                  console.log("");
                } else {
                  if (column.columns[index]?.id) {
                    let array = allarray?.columns;
                    array[index].price = null;
                    array[index].vat = null;
                    array[index].discount = null;
                    array[index].discountamt = null;
                    array[index].quantity = null;
                    if (array?.length - 1 === index) {
                      array[index].includeVat = null;
                    }
                    form.setFieldsValue({ columns: array });
                  }
                }
              }
            }
            if (item && item.id !== null) {
              let foundProduct = props.product.find(
                (product: any) => Number(product?.id) === Number(item.id)
              );
              let quantity =
                column?.columns[index]?.quantity === undefined
                  ? item?.quantity || 1
                  : column?.columns[index]?.quantity;
              let price =
                item?.price === undefined || item?.price === null
                  ? Number(foundProduct?.rate)
                  : item?.price;
              let total = price * quantity;
              let columnDiscountAmt = Number(
                column?.columns[index]?.discountamt
              );
              let itemDiscountAmt = Number(item?.discountamt);
              let discountAmount: any =
                Number.isNaN(columnDiscountAmt) && Number.isNaN(itemDiscountAmt)
                  ? 0
                  : columnDiscountAmt ||
                    item?.quantity === null ||
                    item?.quantity === 0 ||
                    item?.price === null ||
                    Number(item?.price) === 0
                  ? 0
                  : itemDiscountAmt || 0;
              let columnDiscount = Number(column?.columns[index]?.discount);
              let itemDiscount = Number(item?.discount);
              let discount: any =
                Number.isNaN(columnDiscount) && Number.isNaN(itemDiscount)
                  ? 0
                  : columnDiscount ||
                    item?.quantity === null ||
                    item?.price === null ||
                    item?.quantity === 0 ||
                    Number(item?.price) === 0
                  ? 0
                  : itemDiscount || 0;
              setqSuffix(foundProduct.unit);
              const curentQuntityChangingIndex =
                column?.columns[index]?.quantity === undefined
                  ? -1
                  : column.columns.findIndex(
                      (item: any) =>
                        item?.quantity === column?.columns[index]?.quantity
                    );
              if (curentQuntityChangingIndex >= 0) {
                setPStock(Number(foundProduct.stock));
              }

              let vatPercent =
                item?.vat === undefined ||
                item?.vat === null ||
                item?.vat === ""
                  ? Number(foundProduct.vat)
                  : Number(item.vat);
              let vatAmount =
                formValue.column?.columns.length > 1
                  ? formValue?.columns[index].vatamount
                  : Number(foundProduct.vatamt);
              if (
                column?.columns[index]?.id === undefined ||
                column?.columns[index]?.includeVat !== undefined ||
                column?.columns[index]?.vat !== undefined ||
                column?.columns[index]?.quantity !== undefined ||
                column?.columns[index]?.discount !== undefined ||
                column?.columns[index]?.discountamt !== undefined ||
                column?.columns[index]?.price !== undefined
              ) {
                vatAmount = (price * quantity * vatPercent) / 100;
              }
              ///////////////////////includeVat//////////////
              let includeVat;
              const selectedIncludeVat = column?.columns[index]?.includeVat;
              if (
                foundProduct.includevat === "1.00" &&
                selectedIncludeVat === false
              ) {
                notification.error({
                  message: "VAT Inclusion Warning",
                  description: "This product is priced inclusive of VAT",
                });
              }
              if (selectedIncludeVat === undefined) {
                if (foundProduct.includevat === "1.00") {
                  includeVat = item.includeVat === false ? false : true || true;
                } else {
                  includeVat = item.includeVat || false;
                }
              } else {
                includeVat = selectedIncludeVat;
              }
              if (includeVat) {
                let totalItemsRate = price * quantity;
                vatAmount = (totalItemsRate / (100 + vatPercent)) * 100;
                vatAmount = Number(price * quantity - vatAmount);
                total = price * quantity;
              } else {
                total = Number(price * quantity + vatAmount);
              }
              ///////////////////////includeVat//////////////

              if (
                column?.columns[index]?.discount > 0 &&
                item.quantity != null &&
                item.quantity !== 0 &&
                item.price != null &&
                item.price !== 0
              ) {
                const discountRate = Number(item.discount) / 100;
                discountAmount = total * discountRate;
                discount = Number(item.discount);
                if (column?.columns[index]?.discount > 100) {
                  let disRate = 100 / 100;
                  discountAmount = total * disRate;
                  discount = 100;
                  notification.error({
                    message:
                      "Discount cannot exceed the total amount of the invoice",
                  });
                }
              } else if (
                column?.columns[index]?.discount == null ||
                column?.columns[index]?.discount == 0 ||
                column?.columns[index]?.discount == "" ||
                column?.columns[index]?.discount === undefined
              ) {
                if (
                  item?.discountamt > 0 &&
                  item.quantity != null &&
                  item.quantity !== 0 &&
                  item.price != null &&
                  item.price !== 0
                ) {
                  const discountpecentage =
                    (Number(item?.discountamt) / total) * 100;
                  discountAmount = Number(item?.discountamt);
                  discount = Number(discountpecentage);
                } else {
                  discountAmount = 0;
                }
              }
              if (0 >= column?.columns[index]?.discountamt) {
                discount = 0;
              }
              if (
                column?.columns[index]?.discountamt > 0 &&
                item.quantity != null &&
                item.quantity !== 0 &&
                item.price !== 0 &&
                item.price !== null
              ) {
                const discountpecentage =
                  (Number(item?.discountamt) / total) * 100;
                discountAmount = Number(item?.discountamt);
                discount = Number(discountpecentage);
                if (column?.columns[index]?.discountamt >= total) {
                  let disRate = 100 / 100;
                  discountAmount = total * disRate;
                  discount = 100;
                  notification.error({
                    message:
                      "Discount cannot exceed the total amount of the invoice.",
                  });
                }
              } else if (column?.columns[index]?.discountamt === "") {
                discount = "";
              }
              if (
                column.columns[index]?.price ||
                column.columns[index]?.quantity
              ) {
                const discountRate = Number(item.discount) / 100;
                discountAmount = total * discountRate;
                discount = Number(item.discount);
              }
              //here total calculation
              if (includeVat) {
                _subTotal = price * quantity - vatAmount + _subTotal;
              } else {
                _subTotal = price * quantity + _subTotal;
              }
              _tatalVat = _tatalVat + vatAmount;
              _overollDiscount = _overollDiscount + discountAmount;

              const updatedColumn = {
                id: item.id,
                quantity:
                  column?.columns[index]?.quantity === undefined
                    ? quantity
                    : column?.columns[index]?.quantity.length == "0"
                    ? null
                    : item.quantity,
                price:
                  column?.columns[index]?.price === undefined
                    ? Number(price).toFixed(2)
                    : column?.columns[index]?.price.length == "0"
                    ? null
                    : Number(item.price).toFixed(2),
                incomeTaxAmount: vatAmount.toFixed(2),
                vatamt: vatAmount.toFixed(2),
                description: foundProduct?.idescription,
                vat:
                  item?.vat === undefined || item?.vat === null
                    ? foundProduct?.vat
                    : item?.vat,
                vatamount: vatAmount.toFixed(2),
                discountamt: Number(discountAmount).toFixed(2),
                discount: Number(discount).toFixed(2),
                total: Number(total - discountAmount).toFixed(2),
                includeVat,
              };
              return updatedColumn;
            } else {
              let newColumn = {
                id: null,
                quantity: null,
                price: null,
                incomeTaxAmount: null,
                vatamt: null,
                description: null,
                vat: null,
                vatamount: null,
                discountamt: null,
                discount: null,
                total: null,
                includeVat: null,
              };
              return newColumn;
            }
          }
        );

        if (updatedColumns.length) {
          form.setFieldsValue({ columns: updatedColumns });
          setSubTotal(_subTotal);
          setTatalVat(_tatalVat);
          setOverolDiscount(_overollDiscount);
          let _totalAmount = _subTotal + _tatalVat - _overollDiscount;
          let roundedNumber = Math.round(_totalAmount);
          let amountAdded = roundedNumber - _totalAmount;
          setRoundOff(Number(amountAdded.toFixed(2)));
          //setTotalAmount(roundedNumber);
          setTotalAmount(_totalAmount);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <PageHeader
        title="Create Sales Invoice"
        goBack={"/dashboard"}
        secondPathText="Create Invoice"
        secondPathLink={"/usr/sales-invoice"}
      ></PageHeader>
      <br />
      <Container>
        <Card>
          <Form
            form={form}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            scrollToFirstError
          >
            <Row>
              <Col sm={2}>
                <div className="formLabel">Invoice No.</div>
                <Form.Item name={"invoiceno"} rules={[{ required: true }]}>
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col sm={2}>
                <div className="formLabel">Customer Name</div>
                <Form.Item
                  name={"customerid"}
                  rules={[{ required: true, message: "choose customer" }]}
                >
                  <Select
                    size="large"
                    showSearch
                    onSearch={(val: any) => props.customerName(val)}
                    onChange={(val: any) => {
                      let selectCustomers = props?.customers.find(
                        (item: any) => item.id === val
                      );
                      form.setFieldsValue({
                        inaddress: selectCustomers?.address,
                        deladdress: selectCustomers?.address,
                      });
                    }}
                  >
                    {props.customers &&
                      props.customers.length &&
                      props?.customers?.map((item: any) => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {item.bus_name}
                          </Select.Option>
                        );
                      })}
                    <Select.Option key="addButton" value="addButton">
                      <Button
                        type="primary"
                        block
                        onClick={() => setCoustomerCreate(true)}
                      >
                        <GoPlus /> Add New
                      </Button>
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={2}>
                <div className="formLabel">Sales Ledger</div>
                <Form.Item
                  name={"ledger"}
                  rules={[{ required: true, message: "choose ledger" }]}
                >
                  <Select size="large">
                    {props.ledgers &&
                      props.ledgers.length &&
                      props?.ledgers?.map((item: any) => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {item.nominalcode + "-" + item.laccount}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={2}>
                <div className="formLabel">Invoice Date</div>
                <Form.Item name={"sdate"}>
                  <DatePicker style={{ width: "100%" }} size="large" />
                </Form.Item>
              </Col>
              <Col sm={2}>
                <div className="formLabel">Due Date</div>
                <Form.Item name={"ldate"}>
                  <DatePicker style={{ width: "100%" }} size="large" />
                </Form.Item>
              </Col>
              <Col sm={2}>
                <div className="formLabel">Reference</div>
                <Form.Item name={"reference"}>
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Items
              form={form}
              products={props.product}
              taxLists={props.taxList}
              qSuffix={qSuffix}
              stock={pStock}
              productModal={(val: any) => setProductCreate(val)}
            />
            <br />
            <Row>
              <Col sm={3}>
                <div className="formLabel">Invoice Address</div>
                <Form.Item
                  name={"inaddress"}
                  rules={[{ required: true, message: "enter invoice address" }]}
                >
                  <Input.TextArea rows={4} size="large" />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <div className="formLabel">Delivery Address</div>
                <Form.Item
                  name={"deladdress"}
                  rules={[{ message: "enter delivery address" }]}
                >
                  <Input.TextArea rows={4} size="large" />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <div className="formLabel">Terms and Conditions</div>
                <Form.Item
                  name={"terms"}
                  // rules={[{ required: true, message: "enter terms" }]}
                >
                  <Input.TextArea rows={4} size="large" />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <div className="formLabel">Note</div>
                <Form.Item
                  name={"quotes"}
                  // rules={[{ required: true, message: "enter note" }]}
                >
                  <Input.TextArea rows={4} size="large" />
                </Form.Item>
              </Col>
            </Row>
            <div className="salesInvoice-SubHeader">
              <div>Record Payment</div>
              <div>
                <Button
                  type="primary"
                  onClick={() => setIsPaymentInfo(!isPaymentInfo)}
                >
                  {isPaymentInfo ? "Close" : "+ Add"} Payment
                </Button>
              </div>
            </div>
            {isPaymentInfo ? (
              <Row>
                <Col sm={4}>
                  <div className="formLabel" style={{ marginTop: 10 }}>
                    Choose Payment Bank :
                  </div>
                  <Form.Item
                    noStyle
                    name="paymentBank"
                    rules={[{ required: true, message: "" }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="slecet payment bank"
                      onChange={(val: any) => {
                        let bank = props?.banks.find(
                          (item: any) => item?.list?.id === val
                        );
                        setSlectedBank(bank);
                      }}
                    >
                      {props?.banks?.length &&
                        props?.banks?.map((item: any) => {
                          return (
                            <Select.Option
                              key={item?.list?.id}
                              value={item?.list?.id}
                            >
                              {item?.list?.laccount}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={4}>
                  <div className="formLabel" style={{ marginTop: 10 }}>
                    Acc.Holder Name
                  </div>
                  <Form.Item
                    noStyle
                    name="holderName"
                    rules={[{ required: true, message: "" }]}
                  >
                    <Input placeholder="Acc.holder name" readOnly />
                  </Form.Item>
                </Col>
                {selectBank?.list?.laccount?.toLowerCase() ===
                "Cash".toLowerCase() ? null : (
                  <Col sm={4}>
                    <div className="formLabel" style={{ marginTop: 10 }}>
                      Bank Info/ Acc. No. :
                    </div>
                    <Form.Item noStyle name="accountNumber">
                      <Input placeholder="account number" readOnly />
                    </Form.Item>
                  </Col>
                )}
                {selectBank?.list?.laccount?.toLowerCase() ===
                "Cash".toLowerCase() ? null : (
                  <Col sm={4}>
                    <div className="formLabel" style={{ marginTop: 10 }}>
                      BIC/Swift
                    </div>
                    <Form.Item noStyle name="bicnum">
                      <Input placeholder="BIC/Swift" readOnly />
                    </Form.Item>
                  </Col>
                )}
                {selectBank?.list?.laccount?.toLowerCase() ===
                "Cash".toLowerCase() ? null : (
                  <Col sm={4}>
                    <div className="formLabel" style={{ marginTop: 10 }}>
                      IBAN Number :
                    </div>
                    <Form.Item noStyle name="ibanNumber">
                      <Input placeholder=" IBAN number" readOnly />
                    </Form.Item>
                  </Col>
                )}
                <Col sm={4}>
                  <div className="formLabel" style={{ marginTop: 10 }}>
                    Payment Date :
                  </div>
                  <Form.Item
                    noStyle
                    name="paymentDate"
                    initialValue={dayjs(new Date())}
                  >
                    <DatePicker
                      defaultValue={dayjs(new Date(), "YYYY-MM-DD")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col sm={4}>
                  <div className="formLabel" style={{ marginTop: 10 }}>
                    Available Balance :
                  </div>
                  <Form.Item name={"availableBalance"} noStyle>
                    <Input placeholder="available balance" readOnly />
                  </Form.Item>
                </Col>
                <Col sm={4}>
                  <div className="formLabel" style={{ marginTop: 10 }}>
                    Outstanding :
                  </div>
                  <Form.Item name={"outStanding"} noStyle>
                    <Input placeholder="out standing amount" readOnly />
                  </Form.Item>
                </Col>
                <Col sm={4}>
                  <div className="formLabel" style={{ marginTop: 10 }}>
                    Amount To Be Paid :
                  </div>
                  <Form.Item
                    noStyle
                    name="amoutToPaid"
                    rules={[
                      { required: true, message: "enter amount to be paid" },
                    ]}
                  >
                    <Input type="number" placeholder="amount to be paid" />
                  </Form.Item>
                </Col>
                <Col sm={8}></Col>
                <Col sm={4}>
                  <div className="formLabel" style={{ marginTop: 10 }}>
                    Paid Method:
                  </div>
                  <Form.Item
                    noStyle
                    name="paymentMethod"
                    rules={[
                      { required: true, message: "choose payment method" },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      allowClear
                      placeholder="choose payment method"
                      options={[
                        { label: "Cash", value: "cash" },
                        { label: "Cheque", value: "cheque" },
                        { label: "Electronic", value: "other" },
                        { label: "Credit/Debit Card", value: "card" },
                        { label: "PayPal", value: "loan" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
            <br />
            {/* reccurring notification  */}
            <div className="salesInvoice-SubHeader">
              <div>Reccuring Notification</div>
              <div>
                <Button
                  type="primary"
                  onClick={() => setIsReccNotification(!isReccNotification)}
                >
                  {isReccNotification ? "Close" : "Set"} Notification
                </Button>
              </div>
            </div>
            {isReccNotification ? (
              <>
                <Row>
                  <Col sm={4}>
                    <div className="formLabel" style={{ marginTop: 10 }}>
                      Start Date :
                    </div>
                    <Form.Item
                      noStyle
                      name="date"
                      initialValue={dayjs(new Date())}
                    >
                      <DatePicker
                        defaultValue={dayjs(new Date(), "YYYY-MM-DD")}
                        style={{ width: "100%" }}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={4}>
                    <div className="formLabel" style={{ marginTop: 10 }}>
                      Period:
                    </div>
                    <Form.Item
                      noStyle
                      name="period"
                      rules={[{ required: true, message: "select period" }]}
                    >
                      <Select
                        placeholder="Select period"
                        size="large"
                        style={{ width: "100%" }}
                      >
                        <Option value="daily">Daily</Option>
                        <Option value="weekly">Weekly</Option>
                        <Option value="monthly">Monthly</Option>
                        <Option value="yearly">Yearly</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* <Col sm={2}>
                    <div className="formLabel" style={{ marginTop: 10 }}>
                      Days Before :
                    </div>
                    <Form.Item
                      noStyle
                      name="daybefore"
                      rules={[{ message: "Enter days before" }]}
                    >
                      <Input
                        placeholder="days before"
                        size="large"
                        type="number"
                      />
                    </Form.Item>
                  </Col> */}
                  <Col sm={4}>
                    <div className="formLabel" style={{ marginTop: 10 }}>
                      Send Via
                    </div>
                    <Form.Item
                      name="mailto"
                      rules={[{ message: "Select Notification" }]}
                    >
                      <Select size="large">
                        <Select.Option>Mail</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <br />
                <hr />
              </>
            ) : null}
            <br />
            <Row>
              <Col sm={6}></Col>
              <Col sm={6}>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>TAXABLE VALUE</td>
                      <td>{subTotal?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>TOTAL VAT</td>
                      <td>{tatalVat?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>OVERALL DISCOUNT</td>
                      <td>{overollDiscount?.toFixed(2)}</td>
                    </tr>
                    {/* <tr>
                      <td>ROUND OFF</td>
                      <td className="p-1">
                        <Input
                          className="p-1"
                          type="number"
                          bordered={false}
                          value={roundOff}
                          onChange={(e: any) => {
                            setRoundOff(e.target.value);
                            let round = Number(e.target.value);
                            let _totalAmount =
                              subTotal + tatalVat - overollDiscount;
                            _totalAmount = _totalAmount + round;
                            setTotalAmount(_totalAmount);
                          }}
                          // suffix={false}
                        />
                      </td>
                    </tr> */}
                    <tr>
                      <td>TOTAL AMOUNT</td>
                      <td>{totalAmount?.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </Table>
                <Row>
                  <Col sm={6}>
                    <Button
                      size="large"
                      block
                      onClick={() => navigate("/usr/sales-invoice")}
                    >
                      Close
                    </Button>
                  </Col>
                  <Col sm={6}>
                    <Button
                      size="large"
                      block
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Create
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
      {modalOpen ? (
        <PrintModal
          open={modalOpen}
          modalClose={(val: any) => setModalOpen(val)}
          template={template}
        />
      ) : null}
      {coustomerCreate ? (
        <CreateCutomerModal
          open={coustomerCreate}
          onCancel={() => setCoustomerCreate(false)}
          customerSearch={props.customerName}
          type="customer"
          customer={(val: any) =>
            form.setFieldsValue({ customerid: Number(val) })
          }
        />
      ) : null}
      {productCreate ? (
        <ProductAddModal
          open={productCreate}
          onCancel={() => setProductCreate(false)}
          productRefrush={() =>
            props.getProduct(ledgerId === 2 ? "Service" : "Stock")
          }
          type={ledgerId === 2 ? "Service" : "Stock"}
        />
      ) : null}
    </div>
  );
}
export default Create;
