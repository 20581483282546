import { useEffect, useState } from "react";
import { GET } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { useSelector } from "react-redux";
import PageHeader from "../../../components/pageHeader";
import Table from "../components/table";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingBox from "../../../components/loadingBox";
import ExcelImport from "../../../components/ExcelImport";
import { Button, Tooltip } from "antd";
import { SiMicrosoftexcel } from "react-icons/si";
import { useTranslation } from "react-i18next";

const ProductNonStock = () => {
  const { t } = useTranslation();
  const gdpFormat = {
    type: "percent",
    precision: 1,
  };
  const columns = [
    {
      name: "icode",
      title: "Code",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "idescription",
      title: "Item Name",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "itemtype",
      title: "Type",
      alignment: "center",
    },
    {
      name: "vat",
      title: "VAT(%)",
      dataType: "string",
      alignment: "center",
      format: { gdpFormat },
    },
  ];
  let template =
    "https://taxgo.s3.eu-west-1.amazonaws.com/excelTemplates/Product-StockSampleTemplate.xlsx";
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [excelModal, setExcelModal] = useState(false);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const { user } = useSelector((state: any) => state.User);
  const navigate = useNavigate();
  const location = useLocation();

  const loadData = async (page: any, take: any) => {
    setIsLoading(true);
    let product_url =
      API.PRODUCT_MASTER_USER +
      `Nonstock/${user.adminid}?order=DESC&page=${page}&take=${take}`;
    const { data }: any = await GET(product_url, null);
    setData(data);
    setIsLoading(false);
  };
  const onPageChange = (page: any, take: any) => {
    setPage(page);
    setTake(take);
  };
  useEffect(() => {
    loadData(page, take);
  }, [page, take]);
  return (
    <div>
      <PageHeader
        goBack={"/usr/productStock"}
        secondPathLink={location?.pathname}
        secondPathText="Product Non Stock List"
        title="Non stock Items"
      >
        <div>
        <Tooltip title={t("home_page.homepage.Import_from_Excel")}>
          <Button onClick={() => setExcelModal(true)}>
            <SiMicrosoftexcel size={20} />
          </Button>
          </Tooltip>
          &nbsp;
          <Button
          type="primary"
            onClick={() => navigate("/usr/create-product/Nonstock/create")}
          >
            + Add Non stock
          </Button>
        </div>
      </PageHeader>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Table
          products={data}
          columns={columns}
          take={take}
          title={"Nonstock"}
          onItemSelect={() => {}}
          onPageChange={(p: any, t: any) => onPageChange(p, t)}
          onSuccess={() => loadData(page, take)}
        />
      )}
      {excelModal ? (
        <ExcelImport
          visible={excelModal}
          onCancel={() => setExcelModal(false)}
          onSucess={() => loadData(page, take)}
          URL={API.ADD_PRODUCT_VIAEXCEL}
          template={template}
          type={"Stock"}
        />
      ) : null}
    </div>
  );
};

export default ProductNonStock;
