import { Button, Modal, message, notification } from "antd";
import EXCEL from "../../../../assets/images/excel.png";
import "../../../../components/ExcelImport/styles.scss";
import { useState } from "react";
// import { UPLOADEXCEL } from "../../utils/apiCalls";
import { Row, Col } from "react-bootstrap";
import * as xlsx from "xlsx";
// import Table from "./table";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { UPLOADEXCEL } from "../../../../utils/apiCalls";
import Table from "../excelTable";

function ExcelImport(props: any) {
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.User);

  const adminid = user.adminid;
  const userid = user.id;

  const [isloading, setIsLoading] = useState(false);
  const [file, setFile] = useState();
  const [filedata, setFiledata] = useState([]);
  const [fileheaders, setFileheaders] = useState([]);
  const [excelData, setexcelData] = useState([]);

  const uploadfile = async (fileObj: any) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      let reqObj = {
        itemtype: props.type,
      };
      formData.append("file", fileObj);
      formData.append("reqObj", JSON.stringify(filedata));
      let URL = props.URL;
      console.log("formData ---> ", formData);

      const response: any = await UPLOADEXCEL(URL, formData);
      if (response.ok) {
        notification.success({
          message: "Success",
          description: "Uploaded Successfully.!",
        });
        props.onSucess();
        props.onCancel();
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notification.error({
          message: "required",
          description: "Select a file to upload ..!",
        });
      }
    } catch (error) {
      notification.error({
        message: "Server Error",
        description: "Failed to upload ..!",
      });
      console.log(error);
    }
  };

  const readExcel = (event: any) => {
    const fileObj = event[0];
    setFile(fileObj);
    var reader = new FileReader();
    reader.onload = function (e: any) {
      var data = e.target.result;
      let readedData = xlsx.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse: any = xlsx.utils.sheet_to_json(ws);
      const headers: any = [];
      for (let key in ws) {
        if (key.match(/^[A-Z]+1$/) && ws[key].t === "s") {
          headers.push(ws[key].v);
        }
      }
      setFiledata(dataParse);
      console.log("dataParse ---> ", dataParse);
      setFileheaders(headers);
    };
    reader.readAsBinaryString(fileObj);
  };

  // const readExcel = (event: any) => {
  //   const fileObj = event[0];
  //   setFile(fileObj);
  //   var reader = new FileReader();
  //   reader.onload = function (e: any) {
  //     var data = e.target.result;
  //     let readedData = xlsx.read(data, { type: "binary" });
  //     const wsname = readedData.SheetNames[0];
  //     const ws = readedData.Sheets[wsname];
  //     const dataParse: any = xlsx.utils.sheet_to_json(ws);

  //     // Extract headers from the first row
  //     const headers: any = [];
  //     for (let key in dataParse[0]) {
  //       headers.push(dataParse[0][key]);
  //     }

  //     // Map the data to objects using the extracted headers
  //     const mappedData = dataParse.slice(1).map((row: any) => {
  //       const obj: any = {};
  //       headers.forEach((header: any, index: number) => {
  //         obj[header] = row[`__EMPTY_${index + 9}`];
  //       });
  //       return obj;
  //     });

  //     setFiledata(mappedData);
  //     console.log("mappedData ---> ", mappedData);
  //     setexcelData(mappedData);
  //     setFileheaders(headers);
  //   };
  //   reader.readAsBinaryString(fileObj);
  // };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onCancel}
      footer={false}
      width={1000}
    >
      <h2 className="ExcelImport-txt1">
        {t("home_page.homepage.Import_from_Excel")}
      </h2>
      <br />
      <Dropzone onDrop={readExcel}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: "ExcelImport-box1" })}>
              <input {...getInputProps()} />
              <div className="ExcelImport-box2">
                <img src={EXCEL} width={"30%"} />
                {t("home_page.homepage.Click_here_to_Upload_Excel")}
              </div>
              <div className="ExcelImport-box3">
                <ul>
                  <li>{t("home_page.homepage.Fill_All_Required_Details")}</li>
                  <li>
                    {t("home_page.homepage.Upload_xlsx_format_files_only")}
                  </li>
                  <li>{t("home_page.homepage.Any_Issues_Contact_Support")}</li>
                </ul>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
      <br />
      {filedata.length ? <Table data={filedata} columns={fileheaders} /> : null}
      <br />
      <Row>
        <Col sm="6" md="6"></Col>
        <Col sm="3" md="3">
          <Button block onClick={() => props.onCancel()}>
            {t("home_page.homepage.Cancel")}
          </Button>
        </Col>
        <Col sm="3" md="3">
          <Button
            type="primary"
            block
            loading={isloading}
            onClick={() => uploadfile(file)}
            // onClick={() => console.log(file)}
          >
            {t("home_page.homepage.Upload")}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default ExcelImport;
