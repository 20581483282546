import { useEffect, useState } from "react";
import PageHeader from "../../../components/pageHeader";
import { useNavigate } from "react-router-dom";
import DataTable from "./Table";
import API from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";
import { Card, DatePicker, Form, Pagination, Select } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";

function Leave() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});
  const [leaveData, setLeaveData] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<string>("");

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const columns = [
    {
      dataField: "createdAt",
      title: "Requested Date",
    },
    {
      dataField: "fullName",
      title: "Name",
    },

    {
      dataField: "from",
      title: "From",
    },
    {
      dataField: "to",
      title: "To",
    },

    {
      dataField: "type",
      title: "Type",
    },
    {
      dataField: "status",
      title: "Status",
    },
  ];

  const fetchHrLeave = async () => {
    try {
      setIsLoading(true);
      let obj = {
        query: form.getFieldValue("query"),
        page: Number(page),
        take: 10,
      };
      const url = API.POST_PAGE_LEAVE;
      const leaves: any = await POST(url, obj);
      setLeaveData(leaves);
      setMeta(leaves?.meta);
      console.log("Date ---> ", leaves.data);

      console.log(leaves);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getLeaveType = async (values: any) => {
    try {
      setIsLoading(true);
      const api = API.POST_FILTER_LEAVE;
      let obj: any = {};

      if (values.type) {
        obj.type = values.type;
      }

      if (values.status) {
        obj.status = values.status;
      }
      if (values.createdAt) {
        obj.createdAt = moment(values.createdAt).format("YYYY-MM-DD");
      }

      const data: any = await POST(api, obj);
      setLeaveData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHrLeave();
  }, [page, take]);

  const handleDateChange = (date: any, dateString: string) => {
    const formattedDate = moment(dateString).format("DD-MM-YYYY");
    setSelectedDate(formattedDate);
  };

  return (
    <>
      <PageHeader
        firstPathText="Leave"
        buttonTxt={"CREATE"}
        onSubmit={() => navigate("/usr/hr-applyleave")}
        title="Leave Request List"
      />
      <br />
      <Container>
        <Card>
          <br />
          <br />
          <Form form={form} onChange={getLeaveType}>
            <Row>
              <Col md="3">
                <Form.Item name="type">
                  <Select
                    style={{ marginBottom: 10 }}
                    placeholder="Select Leave Type"
                    allowClear
                    size="large"
                    onChange={(value) =>
                      getLeaveType({ ...form.getFieldsValue(), type: value })
                    }
                  >
                    <Select.Option value="Sick leave">Sick leave</Select.Option>
                    <Select.Option value="Casual leave">
                      Casual leave
                    </Select.Option>
                    <Select.Option value="Maternity leave">
                      Maternity leave
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md="3">
                <Form.Item name="status">
                  <Select
                    style={{ marginBottom: 10 }}
                    placeholder="Select Leave status"
                    allowClear
                    size="large"
                    onChange={(value) =>
                      getLeaveType({ ...form.getFieldsValue(), status: value })
                    }
                  >
                    <Select.Option value="approved">approved</Select.Option>
                    <Select.Option value="rejected">rejected</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md="3">
                <Form.Item name="createdAt" style={{ marginBottom: 10 }}>
                  <DatePicker
                    placeholder="select Requested date"
                    allowClear
                    size="large"
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      handleDateChange(date, dateString);
                      getLeaveType({
                        ...form.getFieldsValue(),
                        createdAt: dateString,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <br />

        <div>
          <Card>
            <DataTable
              columns={columns}
              list={leaveData}
              isLoading={isLoading}
              meta={meta}
              onPageChange={(page: number, pageSize: number) => {
                setIsLoading(true);
                setPage(page);
                setTake(pageSize);
              }}
            />
          </Card>
          <br />
        </div>
        <div style={{ textAlign: "center", marginTop: "10px" }}></div>
      </Container>
    </>
  );
}

export default Leave;
