import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Checkbox,
  notification,
  Popconfirm,
  InputNumber,
} from "antd";
import { Col, Row, Table } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";
function Items(props: any) {
  const [active, setActive] = useState<any>(-1);
  const [isDisabled, setIsDisabled] = useState(false);
  const allFieldValues = props.form.getFieldsValue();
  const navigate = useNavigate();
  function disableDuplicates(arr1: any, arr2: any) {
    const uniqueIds = arr2.map((item: any) => item.id);
    const result = arr1.map((item: any) => {
      if (uniqueIds.includes(item.id)) {
        return { ...item, disable: true };
      }
      return { ...item, disable: false };
    });
    return result;
  }

  const validateQuantity = (_: any, value: any) => {
    if (Number(value) < 1) {
      notification.error({
        message: "Error",
        description: "Quantity should be atleast 1",
      });
      setIsDisabled(true);
      return Promise.reject(new Error("Quantity should be atleast 1"));
    }
    if (value && value > props?.stock) {
      notification.error({
        message: "Error",
        description:
          "Quantity cannot be more than stock ," +
          "\n stock is " +
          props?.stock,
      });
      setIsDisabled(true);
      return Promise.reject(new Error("Quantity cannot be more than stock"));
    }
    setIsDisabled(false);
    return Promise.resolve();
  };


  return (
    <Form.List name={"columns"}>
      {(fields, { add, remove }, { errors }) => (
        <div>
          <div className="salesInvoice-SubHeader">
            <div>Invoice Items</div>
          </div>
          <Table bordered>
            <thead style={{ backgroundColor: "rgb(247, 247, 247)" }}>
              <tr>
                <th>PRODUCT</th>
                <th>QTY</th>
                <th>PRICE</th>
                <th>TAX %</th>
                <th>TAX AMT</th>
                <th>INC TAX</th>
                <th>DISC %</th>
                <th>DISC AMT</th>
                <th>TOTAL</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {fields?.map((field: any, index: number, val: any) => {
                return (
                  <React.Fragment key={index.toString()}>
                    {index === active ? null : (
                      <tr>
                        <td>
                          {(allFieldValues &&
                            allFieldValues?.columns?.length &&
                            allFieldValues?.columns[index]?.description) ||
                            "n/a"}
                        </td>
                        <td>
                          {(allFieldValues &&
                            allFieldValues?.columns?.length &&
                            allFieldValues?.columns[index]?.quantity) ||
                            "0"}
                        </td>
                        <td>
                          {(allFieldValues &&
                            allFieldValues?.columns?.length &&
                            Number(
                              allFieldValues?.columns[index]?.price
                            ).toFixed(2)) ||
                            "0"}
                        </td>
                        <td>
                          {(allFieldValues &&
                            allFieldValues?.columns?.length &&
                            Number(allFieldValues?.columns[index]?.vat).toFixed(
                              2
                            )) ||
                            "0"}
                        </td>
                        <td>
                          {(allFieldValues &&
                            allFieldValues?.columns?.length &&
                            Number(
                              allFieldValues?.columns[index]?.vatamount
                            ).toFixed(2)) ||
                            "0"}
                        </td>
                        <td className="d-flex justify-content-center">
                          <Checkbox
                            // disabled
                            checked={
                              (allFieldValues &&
                                allFieldValues?.columns?.length &&
                                allFieldValues?.columns[index]?.includeVat) ||
                              false
                            }
                          />
                        </td>
                        <td>
                          {(allFieldValues &&
                            allFieldValues?.columns?.length &&
                            Number(
                              allFieldValues?.columns[index]?.discount
                            ).toFixed(2)) ||
                            "0"}
                        </td>
                        <td>
                          {(allFieldValues &&
                            allFieldValues?.columns?.length &&
                            Number(
                              allFieldValues?.columns[index]?.discountamt
                            ).toFixed(2)) ||
                            "0"}
                        </td>
                        <td>
                          {(allFieldValues &&
                            allFieldValues?.columns?.length &&
                            Number(
                              allFieldValues?.columns[index]?.total
                            ).toFixed(2)) ||
                            "0"}
                        </td>
                        <td style={{ width: 70 }}>
                          <div className="salesInvoice-action">
                            <div
                              onClick={() =>
                                active == -1
                                  ? setActive(index)
                                  : notification.error({
                                      message:
                                        "Kindly save the currently active form.",
                                    })
                              }
                            >
                              <MdEdit size={20} />
                            </div>
                            <Popconfirm
                              title="Remove Row"
                              description="Are you sure you want to remove the row ?"
                              icon={
                                <AiOutlineQuestionCircle
                                  style={{ color: "red" }}
                                />
                              }
                              onConfirm={() => {
                                if (active == -1) {
                                  remove(field.name);
                                } else {
                                  notification.error({
                                    message:
                                      "Kindly save the currently active form.",
                                  });
                                }
                              }}
                              placement="topRight"
                            >
                              <div>
                                <IoClose size={23} color="red" />
                              </div>
                            </Popconfirm>
                          </div>
                        </td>
                      </tr>
                    )}

                    {index === active ? (
                      <tr>
                        <td
                          colSpan={10}
                          style={{ backgroundColor: "rgb(247, 247, 247)" }}
                        >
                          <Row>
                            <Col sm={4}>
                              <div
                                className="formLabel"
                                style={{ marginTop: 10 }}
                              >
                                Product
                              </div>
                              <Form.Item
                                {...field}
                                name={[field.name, "id"]}
                                noStyle
                                required
                                rules={[{ required: true, message: "" }]}
                              >
                                <Select
                                  allowClear
                                  placeholder="choose product"
                                  style={{ width: "100%" }}
                                  showSearch
                                  filterOption={(input: any, option: any) =>
                                    option?.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {disableDuplicates(
                                    props.products,
                                    props.form.getFieldsValue().columns
                                  )?.map((item: any) => (
                                    <Select.Option
                                      key={item.id}
                                      value={item.id}
                                      disabled={item?.disable}
                                    >
                                      {item.idescription}
                                    </Select.Option>
                                  ))}
                                  <Select.Option
                                    key="addButton"
                                    value="addButton"
                                  >
                                    <Button
                                      type="primary"
                                      block
                                      onClick={() =>
                                        navigate(
                                          "/usr/create-product/Stock/create"
                                        )
                                      }
                                    >
                                      <GoPlus /> Add New
                                    </Button>
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col sm={4}>
                              <div
                                className="formLabel"
                                style={{ marginTop: 10 }}
                              >
                                Description{" "}
                              </div>
                              <Form.Item
                                {...field}
                                name={[field.name, "description"]}
                                noStyle
                              >
                                <Input placeholder="Input" />
                              </Form.Item>
                            </Col>
                            <Col sm={2}>
                              <div
                                className="formLabel"
                                style={{ marginTop: 10 }}
                              >
                                Qantity
                              </div>
                              <Form.Item
                                {...field}
                                name={[field.name, "quantity"]}
                                noStyle
                                rules={[{ validator: validateQuantity }]}
                              >
                                <Input
                                  placeholder="quantity"
                                  defaultValue={1}
                                  suffix={props?.qSuffix}
                                  type="number"
                                />
                              </Form.Item>
                            </Col>
                            <Col sm={2}>
                              <div
                                className="formLabel"
                                style={{ marginTop: 10 }}
                              >
                                Price
                              </div>
                              <Form.Item
                                {...field}
                                name={[field.name, "price"]}
                                noStyle
                              >
                                <Input placeholder="price" />
                              </Form.Item>
                            </Col>

                            <Col sm={2}>
                              <div
                                className="formLabel"
                                style={{ marginTop: 10 }}
                              >
                                Tax: *
                              </div>
                              <Form.Item
                                {...field}
                                name={[field.name, "vat"]}
                                noStyle
                              >
                                <Select style={{ width: "100%" }}>
                                  {props?.taxLists?.length &&
                                    props?.taxLists?.map((item: any) => {
                                      return (
                                        <Select.Option
                                          key={item.id}
                                          value={item.percentage}
                                        >
                                          {`${item.percentage} %`}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col sm={2}>
                              <div
                                className="formLabel"
                                style={{ marginTop: 10 }}
                              >
                                Tax Amt:
                              </div>
                              <Form.Item
                                {...field}
                                name={[field.name, "vatamount"]}
                                noStyle
                              >
                                <Input placeholder="Input" readOnly />
                              </Form.Item>
                            </Col>

                            <Col sm={2}>
                              <div
                                className="formLabel"
                                style={{ marginTop: 10 }}
                              >
                                Dis %:
                              </div>
                              <Form.Item
                                {...field}
                                name={[field.name, "discount"]}
                                noStyle
                              >
                                <InputNumber
                                  placeholder="discount"
                                  type="number"
                                  defaultValue={0}
                                  suffix={"%"}
                                />
                              </Form.Item>
                            </Col>
                            <Col sm={2}>
                              <div
                                className="formLabel"
                                style={{ marginTop: 10 }}
                              >
                                Dis amt:
                              </div>
                              <Form.Item
                                {...field}
                                name={[field.name, "discountamt"]}
                                noStyle
                              >
                                <InputNumber
                                  placeholder="Enter amount"
                                  type="number"
                                  defaultValue={0}
                                />
                              </Form.Item>
                            </Col>

                            <Col sm={2}>
                              <div
                                className="formLabel"
                                style={{ marginTop: 10 }}
                              >
                                Iclude Tax
                              </div>
                              <Form.Item
                                {...field}
                                name={[field.name, "includeVat"]}
                                noStyle
                                valuePropName="checked"
                              >
                                <Checkbox
                                  onChange={(e) => {
                                    props.form.setFieldsValue({
                                      includeVat: e.target.checked,
                                    });
                                  }}
                                  checked={
                                    allFieldValues?.columns[index]?.includeVat
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col sm={2}>
                              <div
                                className="formLabel"
                                style={{ marginTop: 10 }}
                              >
                                <strong>Total :</strong>
                              </div>
                              <Form.Item
                                {...field}
                                name={[field.name, "total"]}
                                noStyle
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col sm={8}></Col>
                            <Col sm={2}>
                              <div style={{ marginTop: 28 }}></div>
                              <Popconfirm
                                title="Remove Row"
                                description="Are you sure you want to remove the row?"
                                icon={
                                  <AiOutlineQuestionCircle
                                    style={{ color: "red" }}
                                  />
                                }
                                onConfirm={() => {
                                  remove(field.name);
                                  setActive(-1);
                                }}
                                placement="topRight"
                              >
                                <Button danger block>
                                  Remove
                                </Button>
                              </Popconfirm>
                            </Col>
                            <Col sm={2}>
                              <div style={{ marginTop: 28 }}></div>
                              <Button
                                type="primary"
                                block
                                onClick={() => setActive(-1)}
                                disabled={
                                  !allFieldValues?.columns[index]
                                    ?.description || isDisabled 
                                }
                              >
                                Save
                              </Button>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </Form.List>
  );
}
export default Items;
