import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function QuickMenu() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div style={{ width: 450 }}>
      <div className="heading-txt2">{t("home_page.homepage.Quick_Access")}</div>
      <hr />
      <Container fluid>
        <Row>
          <Col
            sm={6}
            xs={12}
            style={{
              margin: 0,
              padding: 0,
              borderRight: "1px solid lightgray",
            }}
          >
            {/* <div
              onClick={() => navigate("/usr/create-product/Stock/create")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.Add_Stock")}{" "}
            </div> */}
            <div
              onClick={() => navigate("/usr/create-product/Nonstock/create")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.Add_non_stock")}{" "}
            </div>
            <div
              onClick={() => navigate("/usr/create-product/Service/create")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.Add_service")}{" "}
            </div>
            <div
              onClick={() => navigate("/usr/sale-invoice-form/0")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.create_sales_invoice")}{" "}
            </div>
            <div
              onClick={() => navigate("/usr/proforma-invoice-form/create")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.create_proforma_invoice")}{" "}
            </div>
            <div className="QuickMenu-item"
            onClick={()=>navigate('/usr/salesCredit/screditform/0')}
            >{t("home_page.homepage.Add_credit_note")} </div>
            {/* <div className="QuickMenu-item">{t("home_page.homepage.Add_credit_noteNew")}</div> */}
            <div
              onClick={() => "/usr/purchace-invoice-form/create"}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.Add_cost_of_good_sold")}{" "}
            </div>
            <div
              onClick={() => navigate("/usr/purchace-debitnote-form/create")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.Add_Debit_note")}
            </div>
          </Col>
          <Col sm={6} xs={12}>
            <div
              onClick={() => navigate("/usr/purchase-asset-form/Create/0")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.Add_purchase_for_asset")}{" "}
            </div>
            <div
              onClick={() => navigate("/usr/contactCustomers/create")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.Add_customers")}
            </div>
            <div
              onClick={() => navigate("/usr/contactSuppliers/create")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.Add_suppliers")}
            </div>
            <div
              onClick={() => navigate("/usr/CreateJournal")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.Add_journal")}
            </div>
            <div
              onClick={() => navigate("/usr/cashBank/addbank/create")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.Add_bank")}
            </div>
            <div
              onClick={() => navigate("/usr/ledgerMyCategory")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.Add_My_category")}
            </div>
            <div
              onClick={() => navigate("/usr/ledgerMyLedger")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.Add_my_ledger")}
            </div>

            {/* <div
              onClick={() => navigate("/usr/sales-reccuring-notification/add")}
              className="QuickMenu-item"
            >
              {t("home_page.homepage.create_Reccuring_Notification")}{" "}
            </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default QuickMenu;
