import { useEffect, useState } from "react";
import "./styles.scss";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  message,
  notification,
} from "antd";
import { InputOTP } from "antd-input-otp";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Col, Container, Row } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo2 from "../../assets/images/logo2.png";
import API from "../../config/api";
import countryCode from "../../config/countryCode.json";
import { Auth } from "../../config/firebase";
import { GET, POST } from "../../utils/apiCalls";
import { withTranslation } from "react-i18next";
import { storeBusinessCategory } from "../../redux/slices/businessCategorySlice";
import { storeCountries } from "../../redux/slices/countriesSlice";
import { useForm } from "antd/es/form/Form";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

function Signup(props: any) {
  const { t } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Option } = Select;
  const [isLoading, setIsLoading] = useState(false);
  const [autho, setautho] = useState<any>(null);
  const [data, setdata] = useState<any>({});
  const [verification, setverification] = useState(false);
  const [error, setError] = useState<any>(null);
  const [businessCategories, setbBusinessCategories] = useState<any>(null);
  const [countries, setCountries] = useState<any>(null);
  const [passwordValidation, setPasswordValidation] = useState<any>({
    status: "",
    help: "",
  });
  const [isSamePass, setIsSamePass] = useState<any>(false);

  const [form] = useForm();
  const LoginPhone = async (values: any) => {
    console.log("this i s login pageee");
    setdata(values);
    try {
      await form.validateFields();
      let recaptchas = new RecaptchaVerifier(Auth, "recaptcha", {
        size: "invisible",
      });
      let phone = `${values.code}${values.phonenumber}`;
      let checkPhone: any = await signInWithPhoneNumber(
        Auth,
        phone,
        recaptchas
      );
      if (checkPhone?.verificationId) {
        setautho(checkPhone);
        setverification(true);
      } else {
        setError("Something went wrong");
      }
    } catch (error: any) {
      console.error("Error during OTP sign up", error);
      let errorcode = await error.code;
      switch (errorcode) {
        case "auth/invalid-phone-number":
          notification.error({
            message: "Invalid Number",
            description: "Please input a valid number.",
          });
          break;
        case "auth/captcha-check-failed":
          notification.error({
            message: "reCAPTCHA Verification Failed",
            description: "The verification process failed. Please try again.",
          });
          break;
        case "auth/too-many-requests":
          notification.error({
            message: "Login Attempts Exceeded",
            description:
              "You have made several attempts. Please try again later.",
          });
          break;
        case "auth/user-disabled":
          notification.error({
            message: "Account Disabled",
            description:
              "Your account has been disabled. Please contact support.",
          });
          break;
        case "auth/invalid-verification-code":
          notification.error({
            message: "Verification Code Error",
            description:
              "Invalid verification code. Please enter a valid code and try again.",
          });
          break;
        case "auth/session-expired":
          notification.error({
            message: "Phone Authentication Error",
            description:
              "Phone authentication session expired. Please initiate the process again.",
          });
          break;
        case "auth/app-not-authorized":
          notification.error({
            message: "Error: Authorized failed",
            description:
              "App not authorized for phone authentication. Please report.",
          });
          break;
        case "auth/operation-not-allowed":
          notification.error({
            message: "Authentication Error",
            description:
              "Phone number authentication is not enabled. Please report.",
          });
          break;
        case "auth/quota-exceeded":
          notification.error({
            message: "Authentication Error",
            description:
              "Authentication failed due to quota exceeded. Please contact the technical support team for assistance.",
          });
          break;
        case "auth/network-request-failed":
          notification.error({
            message: "Error: reCAPTCHA failed",
            description: "Please refresh the page to load a new reCAPTCHA.",
          });
          break;
        default:
          notification.error({
            message: "Error: Operation failed",
            description: "Please refresh the page and form and try again.",
          });
      }
      setIsLoading(false);
    }
  };
  const verifyOtp = async (values: any) => {
    console.log("this i s verifyOtp verifyOtp");
    try {
      let otp = "";
      setIsLoading(true);
      otp = await values?.otp?.join("");
      let verify = await autho.confirm(otp);
      if (verify?.user?.phoneNumber) {
        signUp();
      }
    } catch (err: any) {
      console.log("Error during OTP verification:", err);
      if (err.code === "auth/invalid-verification-code") {
        notification.error({
          message: "Invalid Verification Code",
          description: "Please enter a valid verification code.",
        });
        setIsLoading(false);
        return;
      } else if (err.code === "auth/code-expired") {
        notification.error({
          message: "Invalid Verification Code",
          description: "Please enter a valid verification code.",
        });
        setIsLoading(false);
        return;
      } else {
        notification.error({
          message: "Error: Operation failed",
          description: "Please refresh the page and form and try again.",
        });
        setIsLoading(false);
        return;
      }
    }
  };
  const signUp = async () => {
    let obj = {
      firstname: data.firstname,
      lastname: data.lastname,
      password: data.password,
      country_code: data.code,
      bcategory: data?.bcategory,
      bname: data.bname,
      countryid: data.countryid,
      remember: data.remember,
      email: data.email,
      phonenumber: data?.phonenumber,
      mobileverified: 1,
      status: 1,
    };
    let url = API.LOGIN_REGISTRATION;
    try {
      const data: any = await POST(url, obj);
      if (data.status) {
        notification.success({ message: data.message });
        navigate("/login");
      } else {
        setverification(false);
        notification.error({ message: data.message });
      }
    } catch (err) {
      console.log(err);
      message.error("something went wrong");
    }
  };
  const validatePassword = (value: any) => {
    // Your password validation logic
    const minLength = 8;
    const specialCharacterRegex = /[.*@!#%&()^~]/;
    const digitRegex = /\d/;

    if (value.length < minLength) {
      setPasswordValidation({
        status: "error",
        help: "Password must be at least 8 characters long.",
      });
    } else if (!specialCharacterRegex.test(value)) {
      setPasswordValidation({
        status: "error",
        help: "Password must contain at least one special character.",
      });
    } else if (!digitRegex.test(value)) {
      setPasswordValidation({
        status: "error",
        help: "Password must contain at least one digit.",
      });
    } else {
      setPasswordValidation({
        status: "success",
        help: "",
      });
    }
  };
  const compareToFirstPassword = (item: any, value: any) => {
    if (item.field === "password") {
      const firstPassword = form.getFieldValue("confirmPassword");
      if (firstPassword.length && value && value !== firstPassword) {
        setIsSamePass(false);
        return Promise.reject("The two passwords do not match!");
      } else {
        setIsSamePass(true);
        if (value === "") {
          setIsSamePass(false);
        }
        return Promise.resolve();
      }
    } else {
      const firstPassword = form.getFieldValue("password");
      if (value && value !== firstPassword) {
        setIsSamePass(false);
        return Promise.reject("The two passwords do not match!");
      } else {
        setIsSamePass(true);
        if (value === "") {
          setIsSamePass(false);
        }
        return Promise.resolve();
      }
    }
  };

  const Selector = (
    <Form.Item name="code" noStyle>
      <Select style={{ width: 85 }} showSearch={true}>
        {countryCode.map((item: any) => (
          <Option key={item} value={item.dial_code}>
            {item.dial_code}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const fetch_business_categories = async () => {
    try {
      let business_category_url = API.BUSINESS_CATEGORY;
      setIsLoading(true);
      const { data }: any = await GET(business_category_url, null);
      dispatch(storeBusinessCategory(data));
      setbBusinessCategories(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetch_countries = async () => {
    try {
      let countries_url = API.ALL_COUNTRIES;
      setIsLoading(true);
      const { data }: any = await GET(countries_url, null);
      setCountries(data);
      dispatch(storeCountries(data));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch_business_categories();
    fetch_countries();
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col sm={8} style={{ margin: 0, padding: 0 }}>
            <div className="website-SignupBox1">
              <img src={Logo2} style={{ width: 300 }} alt="taxgo" />
            </div>
          </Col>
          <Col sm={4} style={{ margin: 0, padding: 0 }}>
            <div className="website-SignupBack" onClick={() => navigate(-1)}>
              <BiArrowBack />
            </div>
            <div className="website-SignupBox2">
              <div className="website-Signuptxt1">
                {t("home_page.homepage.Sign_Up")}
              </div>
              <br />

              <Form
                form={form}
                onFinish={verification ? verifyOtp : LoginPhone}
                initialValues={{ code: "+91" }}
              >
                {verification ? (
                  <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "Input 6 digit verification code !",
                      },
                    ]}
                  >
                    <InputOTP autoFocus inputType="numeric" length={6} />
                  </Form.Item>
                ) : (
                  <>
                    <Row>
                      <Col sm={6} xs={6}>
                        <Form.Item
                          name="firstname"
                          style={{ marginBottom: 10 }}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your first name!",
                            },
                          ]}
                        >
                          <Input
                            style={{ width: "100%" }}
                            placeholder={t("home_page.homepage.first")}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={6} xs={6}>
                        <Form.Item
                          name="lastname"
                          style={{ marginBottom: 10 }}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your last name!",
                            },
                          ]}
                        >
                          <Input
                            style={{ width: "100%" }}
                            placeholder={t("home_page.homepage.Last")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      name="email"
                      style={{ marginBottom: 10 }}
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please enter your email!",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder={t("home_page.homepage.Email_Address")}
                      />
                    </Form.Item>

                    <Form.Item
                      name="phonenumber"
                      style={{ marginBottom: 10 }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone number!",
                        },
                      ]}
                    >
                      <Input
                        addonBefore={Selector}
                        placeholder={t("home_page.homepage.Enter_Phone_Number")}
                        type="number"
                      />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      style={{ marginBottom: 10 }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password!",
                        },
                        {
                          validator: compareToFirstPassword,
                        },
                      ]}
                      validateStatus={passwordValidation.status}
                      help={passwordValidation.help}
                    >
                      <Input.Password
                        onChange={(e) => validatePassword(e.target.value)}
                        placeholder={t("home_page.homepage.enter_password")}
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        {
                          validator: compareToFirstPassword,
                        },
                      ]}
                    >
                      <Input.Password
                        iconRender={(visible) =>
                          isSamePass ? (
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                          ) : (
                            <CloseCircleTwoTone twoToneColor="#eb2f96" />
                          )
                        }
                        placeholder={t("home_page.homepage.Confirm_Password")}
                      />
                    </Form.Item>
                    <div className="website-Signuptxt2">
                      {t("home_page.homepage.Setup_your_Business")}
                    </div>
                    <Form.Item
                      name="bname"
                      style={{ marginBottom: 10 }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your business name!",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder={t(
                          "home_page.homepage.Business_Company_Name"
                        )}
                      />
                    </Form.Item>
                    <Form.Item
                      name="bcategory"
                      style={{ marginBottom: 10 }}
                      rules={[
                        {
                          required: true,
                          message: "Please select your category!",
                        },
                      ]}
                    >
                      <Select
                        placeholder={t("home_page.homepage.choose")}
                        showSearch={true}
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {businessCategories &&
                          businessCategories?.map((category: any) => (
                            <Select.Option
                              key={category.id}
                              value={category?.btitle}
                            >
                              {category?.btitle}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="countryid"
                      style={{ marginBottom: 10 }}
                      rules={[
                        {
                          required: true,
                          message: "Please select your country!",
                        },
                      ]}
                    >
                      <Select
                        placeholder={t("home_page.homepage.country_choose")}
                        showSearch={true}
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {countries &&
                          countries?.map((country: any) => (
                            <Select.Option key={country.id} value={country.id}>
                              {country?.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="remember" valuePropName="checked">
                      <Checkbox>
                        {t("home_page.homepage.privacy_policy")}
                      </Checkbox>
                    </Form.Item>
                  </>
                )}
                {!verification && <div id="recaptcha"></div>}

                <Form.Item>
                  <Button
                    block
                    size="large"
                    type="primary"
                    style={{ height: 45, marginTop: 5 }}
                    htmlType="submit"
                    loading={isLoading}
                    onClick={verification ? verifyOtp : LoginPhone}
                  >
                    {verification
                      ? "Verify"
                      : `${t("home_page.homepage.Sign_up")}`}
                  </Button>
                </Form.Item>
              </Form>
              <div className="website-Signuptxt4">
                {t("home_page.homepage.Already")}{" "}
                <span
                  className="website-Signuptxt3"
                  onClick={() => navigate("/login")}
                >
                  {t("home_page.homepage.login")}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withTranslation()(Signup);
