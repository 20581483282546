import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  notification,
} from "antd";
import { Col, Container, Row } from "react-bootstrap";
import API from "../../../config/api";
import { GET, PUT } from "../../../utils/apiCalls";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../../redux/slices/userSlice";
import dayjs from "dayjs";
import { FaExclamationCircle } from "react-icons/fa";

function DataModal(props: any) {
  const [businessCategory, setBusinessCategory] = useState([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.User);

  const GetBusinessCategory = async () => {
    let URL = API.BUSINESS_CATEGORY;
    const { data }: any = await GET(URL, null);
    if (data) {
      setBusinessCategory(data);
    }
  };

  useEffect(() => {
    GetBusinessCategory();
  }, []);

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      let obj = {
        id: user?.id,
        userid: user?.userid,
        bname: val?.bname,
        bcategory: val?.bcategory,
        cemail: val?.cemail,
        fulladdress: val?.fulladdress,
        taxno: val?.taxno,
        defaultBank: val?.defaultBank,
        financial_year_start: val?.financial_year_start,
        books_begining_from: val?.books_begining_from,
      };
      let url = API.UPDATE_PROFILE + user?.id;
      const response: any = await PUT(url, obj);
      if (response.status) {
        notification.success({
          message: `${t("home_page.homepage.success")}`,
          description: `${t("home_page.homepage.updated_successfully")}`,
        });
        response.data["bankInfo"] = response.bankInfo;
        dispatch(update(response.data));
        props.setOpenModal(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const initialValues = {
    bname: user?.companyInfo?.bname,
    bcategory: user?.companyInfo?.bcategory,
    cemail: user?.companyInfo?.cemail,
    cperson: user?.companyInfo?.cperson,
    fulladdress: user?.companyInfo?.fulladdress,
    registerno: user?.companyInfo?.registerno,
    taxno: user?.companyInfo?.taxno,
    defaultBank: user?.bankInfo?.id,

    financial_year_start: user?.companyInfo?.financial_year_start
      ? dayjs(user?.companyInfo?.financial_year_start)
      : "",
    books_begining_from: user?.companyInfo?.books_begining_from
      ? dayjs(user?.companyInfo?.books_begining_from)
      : "",
    defaultmail: user?.companyInfo?.defaultmail,
    cusNotes: user?.companyInfo?.cusNotes,
    reporttype: user?.companyInfo?.reporttype,
    defaultTerms: user?.companyInfo?.defaultTerms,
  };

  return (
    <Container>
      <Modal
        title={
          <div className="dashboard-info-modal">
            <span>
              Basic Informations: Please fill below details to continue
            </span>
            <FaExclamationCircle size={24} color="red" />
          </div>
        }
        open={props?.openModal}
        closable={false}
        width={800}
        footer={false}
        maskClosable={false}
      >
        <Form onFinish={onFinish} initialValues={initialValues}>
          <div className="productAdd-Txt1">Business Details</div>
          <Row>
            <Col md={6}>
              <label className="formLabel">
                {t("home_page.homepage.Business_Name")}
              </label>
              <Form.Item
                name="bname"
                style={{ marginBottom: 10 }}
                rules={[
                  { required: true, message: "Business name is required" },
                ]}
              >
                <Input
                  placeholder={t("home_page.homepage.Business_Name")}
                  size="large"
                />
              </Form.Item>

              <label className="formLabel">
                {t("home_page.homepage.Business_Category")}
              </label>
              <Form.Item
                name="bcategory"
                style={{ marginBottom: 10 }}
                rules={[
                  { required: true, message: "Business Category is required" },
                ]}
              >
                <Select placeholder={t("home_page.homepage.choose")}>
                  {businessCategory.length &&
                    businessCategory?.map((category: any) => (
                      <Select.Option
                        key={category?.id}
                        value={category?.bcategory}
                      >
                        {category?.btitle}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <label className="formLabel">
                {t("home_page.homepage.Business_Email")}
              </label>
              <Form.Item
                name="cemail"
                style={{ marginBottom: 10 }}
                rules={[
                  { required: true, message: "Business Email is required" },
                  {
                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input
                  placeholder={t("home_page.homepage.Business_Email")}
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col md={6}>
              <label className="formLabel">
                {t("home_page.homepage.Business_Address")}
              </label>
              <Form.Item
                name="fulladdress"
                style={{ marginBottom: 10 }}
                rules={[
                  { required: true, message: "Business address is required" },
                ]}
              >
                <Input
                  placeholder={t("home_page.homepage.Business_Address")}
                  size="large"
                />
              </Form.Item>

              <label className="formLabel">
                {t("home_page.homepage.Tax_VAT_Number")}
              </label>
              <Form.Item
                name="taxno"
                style={{ marginBottom: 10 }}
                rules={[
                  { required: true, message: "tax/vat number is required" },
                ]}
              >
                <Input
                  placeholder={t("home_page.homepage.Tax_VAT_Number")}
                  size="large"
                />
              </Form.Item>

              <label className="formLabel">
                {t("home_page.homepage.Bank_For_Sales_Invoice")}
              </label>
              <Form.Item
                name="defaultBank"
                style={{ marginBottom: 10 }}
                rules={[{ required: true, message: "Bank is required" }]}
              >
                <Select placeholder={t("home_page.homepage.Add_New_Bank")}>
                  {props?.bankList.length &&
                    props?.bankList.map((item: any) => (
                      <Select.Option key={item.list.id} value={item.list.id}>
                        {item.list.laccount}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <br />

          <div className="productAdd-Txt1">Accounting Details</div>
          <Row>
            <Col md={6}>
              <label className="formLabel">
                {t("home_page.homepage.Financial_Year_Starting_from")}
              </label>
              <Form.Item
                name="financial_year_start"
                style={{ marginBottom: 10 }}
                rules={[
                  {
                    required: true,
                    message: "Financial year starting date is required",
                  },
                ]}
              >
                <DatePicker
                  placeholder={t(
                    "home_page.homepage.Financial_Year_Starting_from"
                  )}
                  size="large"
                  style={{ width: "100%" }}
                  format="DD-MM-YYYY"
                  inputReadOnly={true}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <label className="formLabel">
                {t("home_page.homepage.Books_Begining_from")}
              </label>
              <Form.Item
                name="books_begining_from"
                style={{ marginBottom: 10 }}
                rules={[
                  {
                    required: true,
                    message: "Books beginning from date is required",
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("home_page.homepage.Books_Begining_from")}
                  size="large"
                  style={{ width: "100%" }}
                  format="DD-MM-YYYY"
                  inputReadOnly={true}
                />
              </Form.Item>
              <Row>
                <Col md={6} />
                <Col md={6}>
                  <Button
                    size="large"
                    type="primary"
                    loading={isLoading}
                    style={{
                      height: 45,
                      fontWeight: "600",
                      width: "100%",
                      display: "block",
                      marginTop: 18,
                    }}
                    htmlType="submit"
                  >
                    {t("home_page.homepage.submit")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Container>
  );
}

export default DataModal;
