import "../styles.scss";
import { useState, useEffect, useRef } from "react";
import DataGrid, {
  Column,
  SearchPanel,
  Export,
  Paging,
  Pager,
  HeaderFilter,
  Selection,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { Card, Popover, notification } from "antd";
import ViewPopover from "../../../components/viewPopover";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { AnyARecord } from "dns";
import { Container } from "react-bootstrap";
import { EXPORT } from "../../../utils/exportData";
import { DELETE } from "../../../utils/apiCalls";
import API from "../../../config/api";

const ReccuringNotificationTable = (props: any) => {
  const dataGridRef: any = useRef(null);
  const [data, setData] = useState(props.List);

  

  useEffect(() => {
    const sortedData: AnyARecord =
      data && data.length && data?.sort((a: any, b: any) => b.id - a.id);
    setData(sortedData);
  }, [data,props.List]);

  const navigate = useNavigate();

  //function to delete a record using id
  const OnDelete = async (id: number) => {
    try {
      const deleteUrl = API.DELETE_RECCURING + id
    const deletedData:any = await DELETE(deleteUrl)
    if (deletedData.status === true) {
      notification.success({
        message: "Deleted",
        description: "Credit Note Deleted Successfully",
      });
      props.refreshData()
    } else if (deletedData.status === false) {
      notification.error({
        message: "Not Deleted",
        description: "Credit Note Deletion Failed",
      });
    }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong xx!!",
        description: "Something went wrong!! Please try again later",
      });
    }
  };

  //function to edit a row using id
  const OnEdit = async (invoice_id: number,id:number) => {
    navigate(`edit/${invoice_id}`,{state:{id}});
  };
  const onSendNow = async (data: any) => {

  }

  const columns = [
    {
      dataField: "invoice_number",
      caption: "Reference Invoice",
    },
    {
      dataField: "period",
      caption: "Period",
    },
    {
      dataField: "date",
      caption: "Start Date",
      cellRender: (data: any) => moment(data.value).format("DD-MM-YY"),
    },
    {
      dataField: "nextdate",
      caption: "Next Date",
      cellRender: (data: any) => moment(data.value).format("DD-MM-YY"),
    },
    {
      caption: "Action",
      cellRender: (data: any) => {
        return (
          <div className="table-title">
            <Popover
              content={
                <ViewPopover
                  OnDelete={() => OnDelete(data.data.id)}
                  OnEdit={() => OnEdit(data.data.invoice_id,data.data.id)}
                />
              }
              placement="bottom"
              trigger={"click"}
            >
              <BsThreeDotsVertical size={16} cursor={"pointer"} />
            </Popover>
          </div>
        );
      },
    },
  ];

  const exportFormats = ["pdf", "xlsx"];
  return (
    <Container>
      <br />
      <Card>
        <DataGrid
          ref={dataGridRef}
          dataSource={data}
          columnAutoWidth={true}
          showBorders={true}
          onExporting={(e) => EXPORT(e, dataGridRef, "recurring", () => {})}
        >
          <Selection
            mode="multiple"
            selectAllMode="allPages"
            showCheckBoxesMode="always"
          />
          <SearchPanel visible={true} width={240} />
          <HeaderFilter visible={true} />
          {columns.map((column, index) => (
            <Column
              key={index}
              dataField={column.dataField}
              caption={column.caption}
              alignment="center"
              cellRender={column?.cellRender}
            />
          ))}
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, "all"]}
            showInfo={true}
          />
          <Export
            enabled={true}
            allowExportSelectedData={true}
            formats={exportFormats}
          />
        </DataGrid>
      </Card>
    </Container>
  );
};
export default ReccuringNotificationTable;
