import React, { useEffect, useState } from "react";
import Table from "../components/table";
import PageHeader from "../../../components/pageHeader";
import API from "../../../config/api";
import { DELETE, GET } from "../../../utils/apiCalls";
import { useSelector } from "react-redux";
import LoadingBox from "../../../components/loadingBox";
import { useNavigate } from "react-router-dom";
import CreateSettings from "../components/form";
import { notification } from "antd";

function ProductCategory() {
  const { user } = useSelector((state: any) => state.User);
  const [isLoading, setIsLoading] = useState(true);
  const [isForm, setIsForm] = useState(false);
  const [id, setId] = useState("create");

  const [initalValue, setInitalValue] = useState({});

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const adminid = user?.adminid;

  const columns = [
    {
      name: "category",
      title: "Category",
      dataType: "string",
      alignment: "center",
      cellRender: "Reference",
    },
  ];
  useEffect(() => {
    fetchCategory();
  }, []);
  const fetchCategory = async () => {
    try {
      setIsLoading(true);
      let unit_url = API.PRODUCTCATEGORY_LIST_USER + `${adminid}`;
      const data: any = await GET(unit_url, null);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error)
    }
  };
  const deleteCategory = async (id: any) => {
    try {
      setIsLoading(true);
      let unit_url = API.PRODUCTCATEGORY_DELETE + id;
      const data: any = await DELETE(unit_url);
      if (data) {
        notification.success({
          message: "Deleted Succesfully",
          description: "Your product catogory has been deleted successfully.",
        });
        fetchCategory();
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const productCategoryGet = async (id: any) => {
    try {
      let url = API.PRODUCTCATEGORY_GETBY_ID + id;
      let data: any = await GET(url, {});
      let inital = {
        category: data?.category,
      };

      setInitalValue(inital);
      setId(id);
      setTimeout(() => {
        setIsForm(true);
      }, 100);
    } catch (error) {}
  };
  return (
    <div>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Table
          data={data}
          columns={columns}
          title={"Product Category"}
          handleDeleteClick={deleteCategory}
          handleEditClick={productCategoryGet}
          onBtnClick={() => {
            setIsForm(true);
            setId("create");
          }}
        />
      )}
      {isForm ? (
        <CreateSettings
          open={isForm}
          close={() => {
            setIsForm(false);
            setInitalValue({});
          }}
          source={"productCategory"}
          id={id}
          reload={() => fetchCategory()}
          initalValue={initalValue}
        />
      ) : null}
    </div>
  );
}

export default ProductCategory;
