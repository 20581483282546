import { Button, Checkbox, Form, Input, message, notification } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../config/api";
import { login, setToken } from "../../redux/slices/userSlice";
import { POST } from "../../utils/apiCalls";
import { withTranslation } from "react-i18next";

function EmailLogin(props: any) {
  const { t } = props;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submit = async (values: any) => {
    try {
      setIsLoading(true);
      let url = API.LOGIN_EMAIL;
      var loginRes: any = await POST(url, { ...values });
      if (loginRes.status) {
        dispatch(setToken(loginRes?.data?.token));
        dispatch(login(loginRes?.data));
        notification.success({
          message: "Success",
          description: "Logged in successfully",
        });

        let isOpen = false;
        const data = loginRes?.data?.companyInfo;

        if (
          !data?.bname ||
          !data?.cemail ||
          !data?.taxno ||
          !data?.bcategory ||
          !data?.financial_year_start ||
          !data?.books_begining_from ||
          !data?.fulladdress ||
          !data?.defaultBank
        ) {
          isOpen = true;
        }
        navigate("/usr/dashboard", { state: { isOpen } });
        setIsLoading(false);
      } else {
        notification.error({
          message: "Failed",
          description:loginRes?.message ,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Server Error",
        description: 'Failed to login,Please try again later',
      });
      navigate("/login");
      setIsLoading(false);
    }
  };
  return (
    <>
      <Form onFinish={submit}>
        <Form.Item
          name="email"
          style={{ marginBottom: 10 }}
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input
            style={{ width: "100%" }}
            size="large"
            placeholder={t("home_page.homepage.Enter_email")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          style={{ marginBottom: 10 }}
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            style={{ width: "100%" }}
            size="large"
            placeholder={t("home_page.homepage.Enter_password")}
          />
        </Form.Item>
        <Row>
          <Col>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>{t("home_page.homepage.Remember_me")}</Checkbox>
            </Form.Item>
          </Col>
          <Col>
            <div
              className="website-Logintxt3"
              onClick={() => navigate("/forgot")}
            >
              {t("home_page.homepage.Forgot_Password")}
            </div>
          </Col>
        </Row>
        <Form.Item>
          <Button
            block
            size="large"
            type="primary"
            style={{ height: 45 }}
            htmlType="submit"
            loading={isLoading}
          >
            {t("home_page.homepage.Sign_In")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default withTranslation()(EmailLogin);
