import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  notification,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../components/pageHeader";
import { GET, PUT } from "../../../utils/apiCalls";
import Items from "./components/items";
import API from "../../../config/api";
import LoadingBox from "../../../components/loadingBox";
function Edit(props: any) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { user } = useSelector((state: any) => state.User);
  const navigate = useNavigate();
  const adminid = user.adminid;
  const [isLoading, setIsLoading] = useState(true);
  const [subTotal, setSubTotal] = useState(0);
  const [tatalVat, setTatalVat] = useState(0);
  const [roundOff, setRoundOff] = useState(0);
  const [overollDiscount, setOverolDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isPaymentInfo, setIsPaymentInfo] = useState<any>(false);
  const [isCheck, setIsCheck] = useState<any>(false);
  const [defaltOutStanding, setDefaltOutStanding] = useState(0);
  const [qSuffix, setqSuffix] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<any>(null);
  const [customerSalesData, setCustomerSalesData] = useState<any>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<any>([]);
  const [columnData, setColumnsData] = useState([]);
  const [products, setProducts] = useState([]);
  const [isFullLoading, setIsFullLoading] = useState(false);
  const [details, setDetails] = useState<any>([]);
  const [customers, setCustomers] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [ledgers, setLedgers] = useState([]);
  const [taxList, setTaxlist] = useState([]);
  const [isSmallForm, setIsSmallForm] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [paidedValue, setPaidedValue] = useState<any>({});

  useEffect(() => {
    getInvoiceDetails();
    getCustomers();
    getLedgers();
    loadTaxList();
    form.setFieldsValue({
      terms: user?.companyInfo?.defaultTerms,
      quotes: user?.companyInfo?.cusNotes,
    });
  }, []);

  const getCustomers = async () => {
    try {
      let customerapi =
        "contactMaster/searchList/customer/" +
        adminid +
        `?name=${customerName}`;
      const { data: customer }: any = await GET(customerapi, null);
      setCustomers(customer);
    } catch (error) {}
  };

  const getLedgers = async () => {
    try {
      let url = "account_master/defualt-ledger/sales/" + adminid;
      const { data: bank }: any = await GET(url, null);
      setLedgers(bank);
    } catch (error) {}
  };

  const loadTaxList = async () => {
    try {
      let URL = API.TAX_MASTER + adminid +'/all';
      const data :any = await GET(URL, null);
      setTaxlist(data);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    getCustomerInvoices(selectedCustomerId);
  }, [selectedCustomerId]);

  useEffect(() => {
    setIsLoading(true);
    getInvoiceData(selectedInvoice.id).finally(() => {});
  }, [selectedInvoice]);

  //get selected invoice details
  const getInvoiceDetails = async () => {
    try {
      const getinvoicedataapi = API.VIEW_SALE_INVOICE + `${id}` + `/sales`;
      const getInvDetails: any = await GET(getinvoicedataapi, null);
      if (getInvDetails.status) {
        setDetails(getInvDetails?.data);
        let initialValus = getInitialValues(getInvDetails?.data);
        form.setFieldsValue(initialValus);
        setIsLoading(false);
      }
      if (getInvDetails.message === "Invoice Details Not Found") {
        setIsSmallForm(true);
        const intialValuesSmallForm = {
          invoiceNo: getInvDetails?.data?.invoiceDetails?.invoiceno,
          cname: getInvDetails?.data?.invoiceDetails?.cname,
          ledger: getInvDetails?.data?.invoiceDetails?.ledger,
          sdate: dayjs(getInvDetails?.data?.invoiceDetails?.sdate),
          amount: Number(getInvDetails?.data?.invoiceDetails?.total),
        };
        form.setFieldsValue(intialValuesSmallForm);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsFullLoading(false);
    }
  };

  //get selected customer invoices
  const getCustomerInvoices = async (customer_id: number) => {
    try {
      let customersalesapi =
        API.GET_SALE_INVOICE_BY_ID + customer_id + `/${adminid}`;
      const { data: customerSalesData }: any = await GET(
        customersalesapi,
        null
      );

      setCustomerSalesData(customerSalesData);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  //function to get selected invoice items
  const getInvoiceData = async (id: any) => {
    try {
      const getinvoicedataapi = API.VIEW_SALE_INVOICE + `${id}` + `/sales`;
      const { data: invoiceData }: any = await GET(getinvoicedataapi, null);
      const selectedItems: any = getInitialValues(invoiceData);
      if (selectedItems?.columns) {
        form.setFieldsValue(selectedItems);
      }
      // setIsLoading(false);
    } catch (error: any) {
      console.log(error.message);
    }
  };
  //get initial values for forms
  const getInitialValues = (data: any) => {
    let productArray: any = [];
    try {
      let _subTotal = 0;
      let _tatalVat = 0;
      let _overollDiscount = 0;
      let discountAmount: any = 0;
      let columns = data.invoiceItems.map((item: any) => {
        let vatPercent = Number(item.vat);
        discountAmount = 0;
        let vatAmount = Number(item.vatamt);

        if (item.includevat == 1) {
          _subTotal =
            Number(item.costprice) * Number(item.quantity) -
            vatAmount +
            _subTotal;
        } else {
          _subTotal =
            Number(item.costprice) * Number(item.quantity) + _subTotal;
        }
        if (item.discount > 0) {
          const discountRate = item.discount / 100;
          discountAmount =
            item.includevat == 1
              ? Number(item.costprice) * Number(item.quantity) * discountRate
              : (Number(item.costprice) * Number(item.quantity) + vatAmount) *
                discountRate;
        }
        productArray.push(item.product);
        _tatalVat = _tatalVat + vatAmount;
        _overollDiscount = _overollDiscount + discountAmount;

        return {
          id: item.product.id,
          quantity: Number(item.quantity),
          price: Number(item.costprice),
          vatamt: item.vatamt,
          description: item.description,
          vat: item.incomeTax,
          vatamount: item.incomeTaxAmount,
          discount: item.percentage,
          discountamt: item.discount_amount,
          total: item.total,
          includeVat: item.includevat == 1 ? true : false,
        };
      });
      setProducts(productArray);
      setColumnsData(columns);
      setSubTotal(_subTotal);
      setTatalVat(_tatalVat);
      setOverolDiscount(_overollDiscount);
      let _totalAmount = _subTotal + _tatalVat - _overollDiscount;
      setTotalAmount(_totalAmount);
      setDefaltOutStanding(
        _totalAmount -
          (_totalAmount - Number(data?.invoiceDetails?.outstanding))
      );
      setPaidedValue(_totalAmount - Number(data?.invoiceDetails?.outstanding));
      const initialValue = {
        columns: columns,
        invoiceNo: data?.invoiceDetails?.invoiceno,
        customerid: data?.invoiceDetails?.cname,
        sales_ref: data?.invoiceDetails?.sales_ref,
        terms: data?.invoiceDetails?.terms,
        quotes: data?.invoiceDetails?.quotes,
        reference: data?.invoiceDetails?.reference,
        inaddress: data?.invoiceDetails?.inaddress,
        deladdress: data?.invoiceDetails?.deladdress,
        ledger: data?.invoiceItems[0].ledger,
        sdate: dayjs(data?.invoiceDetails?.sdate),
      };
      return initialValue;
    } catch (error) {
      return {};
    }
  };

  //form value change
  const onValuesChange = (column: any, allarray: any) => {
    try {
      if (column.paymentBank) {
        let selectedBank = props?.banks?.find(
          (item: any) => item.id === column.paymentBank
        );
        form.setFieldsValue({
          bicnum: selectedBank.bicnum,
          ibanNumber: selectedBank.ibannum,
          accountNumber: selectedBank.accnum,
          holderName: selectedBank.laccount,
          availableBalance: selectedBank.total,
          outStanding: Number(defaltOutStanding),
          paymentMethod:
            selectedBank.acctype === "savings" ? "cash" : selectedBank.acctype,
        });
      }
      if (column.amoutToPaid) {
        let outstanding =
          Number(defaltOutStanding) - Number(column.amoutToPaid);
        form.setFieldsValue({ outStanding: outstanding });
      } else if (
        allarray.amoutToPaid === null ||
        allarray.amoutToPaid === undefined ||
        allarray.amoutToPaid === ""
      ) {
        form.setFieldsValue({
          outStanding: Number(defaltOutStanding) - 0,
        });
      }
      if (allarray.columns && allarray.columns.length) {
        let _subTotal = 0;
        let _tatalVat = 0;
        let _overollDiscount = 0;
        const updatedColumns = allarray?.columns?.map(
          (item: any, index: any) => {
            let productId = column.columns[index]?.id || null;
            if (productId) {
              let array = allarray?.columns;
              array[index].price = null;
              array[index].vat = null;
              array[index].discount = null;
              array[index].discountamt = null;
              array[index].includeVat = null;
              form.setFieldsValue({ columns: array });
            }

            if (item && item.id) {
              // let foundProduct = props.product.find(
              let foundProduct: any = products.find(
                (product: any) => Number(product?.id) === Number(item.id)
              );
              let columnDiscountAmt = Number(
                column?.columns[index]?.discountamt
              );
              let itemDiscountAmt = Number(item?.discountamt);
              let discountAmount: any =
                Number.isNaN(columnDiscountAmt) && Number.isNaN(itemDiscountAmt)
                  ? 0
                  : columnDiscountAmt || itemDiscountAmt || 0;

              let columnDiscount = Number(column?.columns[index]?.discount);
              let itemDiscount = Number(item?.discount);
              let discount: any =
                Number.isNaN(columnDiscount) && Number.isNaN(itemDiscount)
                  ? 0
                  : columnDiscount || itemDiscount || 0;

              setqSuffix(foundProduct.unit);
              let quantity =
                column?.columns[index]?.quantity === undefined
                  ? item.quantity || 1
                  : column?.columns[index].quantity;
              let price =
                item?.price === undefined || item?.price === null
                  ? Number(foundProduct?.rate)
                  : item?.price;
              let total = price * quantity;

              let vatPercent =
                item?.vat === undefined ||
                item?.vat === null ||
                item?.vat === ""
                  ? Number(foundProduct.vat)
                  : Number(item.vat);
              let vatAmount = Number(foundProduct.vatamt);

              if (
                column?.columns[index]?.id === undefined ||
                column?.columns[index]?.includeVat !== undefined ||
                column?.columns[index]?.vat !== undefined ||
                column?.columns[index]?.quantity !== undefined ||
                column?.columns[index]?.discount !== undefined ||
                column?.columns[index]?.discountamt !== undefined ||
                column?.columns[index]?.price !== undefined
              ) {
                vatAmount = (price * quantity * vatPercent) / 100;
              }

              if (column?.columns[index]?.includeVat) {
                let totalItemsRate = price * quantity;
                vatAmount = (totalItemsRate / (100 + vatPercent)) * 100;
                vatAmount = Number(price * quantity - vatAmount);
                total = price * quantity;
              } else if (item.includeVat) {
                let totalItemsRate = price * quantity;
                vatAmount = (totalItemsRate / (100 + vatPercent)) * 100;
                vatAmount = Number(price * quantity - vatAmount);
                total = price * quantity;
              } else {
                total = Number(price * quantity + vatAmount);
              }

              if (column?.columns[index]?.discount > 0) {
                const discountRate = Number(item.discount) / 100;
                discountAmount = total * discountRate;
                discount = Number(item.discount);
              } else if (
                column?.columns[index]?.discount == null ||
                column?.columns[index]?.discount == 0 ||
                column?.columns[index]?.discount == "" ||
                column?.columns[index]?.discount === undefined
              ) {
                if (item?.discountamt > 0) {
                  const discountpecentage =
                    (Number(item?.discountamt) / total) * 100;
                  discountAmount = Number(item?.discountamt);
                  discount = Number(discountpecentage);
                } else {
                  discountAmount = 0;
                }
              }
              if (0 >= column?.columns[index]?.discountamt) {
                discount = 0;
              }
              if (column?.columns[index]?.discountamt > 0) {
                const discountpecentage =
                  (Number(item?.discountamt) / total) * 100;
                discountAmount = Number(item?.discountamt);
                discount = Number(discountpecentage);
              } else if (column?.columns[index]?.discountamt === "") {
                discount = "";
              }
              if (
                column.columns[index]?.price ||
                column.columns[index]?.quantity
              ) {
                const discountRate = Number(item.discount) / 100;
                discountAmount = total * discountRate;
                discount = Number(item.discount);
              }
              //here total calculation
              if (item.includeVat) {
                _subTotal = price * quantity - vatAmount + _subTotal;
              } else {
                _subTotal = price * quantity + _subTotal;
              }
              _tatalVat = _tatalVat + vatAmount;
              _overollDiscount = _overollDiscount + discountAmount;

              const selectedIncludeVat = column?.columns[index]?.includeVat;
              let includeVat;

              if (selectedIncludeVat === undefined) {
                //here we check if they not select incule vat now
                if (foundProduct.includevat === "1.00") {
                  includeVat = item.includeVat === false ? false : true || true;
                } else {
                  includeVat = item.includeVat || false;
                }
              } else {
                includeVat = selectedIncludeVat;
              }

              // Use 'includeVat' as needed in your code

              const updatedColumn = {
                id: item.id,
                quantity:
                  column?.columns[index]?.quantity === undefined
                    ? quantity
                    : column?.columns[index]?.quantity.length == "0"
                    ? null
                    : item.quantity,
                price:
                  column?.columns[index]?.price === undefined
                    ? price
                    : column?.columns[index]?.price.length == "0"
                    ? null
                    : column?.columns[index]?.price,
                incomeTaxAmount: foundProduct?.vatamt,
                vatamt: foundProduct?.vatamt,
                description: foundProduct?.idescription,
                vat:
                  item?.vat === undefined || item?.vat === null
                    ? foundProduct?.vat
                    : item?.vat,
                vatamount: Number(vatAmount).toFixed(2),
                discountamt: Number(discountAmount).toFixed(2),
                discount: Number(discount).toFixed(2),
                total: Number(total - discountAmount).toFixed(2),
                includeVat,
              };
              return updatedColumn;
            } else {
              let newColumn = {
                id: null,
                quantity: null,
                price: null,
                incomeTaxAmount: null,
                vatamt: null,
                description: null,
                vat: null,
                vatamount: null,
                discountamt: null,
                discount: null,
                total: null,
                includeVat: null,
              };
              return newColumn;
            }
          }
        );
        if (updatedColumns.length) {
          form.setFieldsValue({ columns: updatedColumns });
        }
        setSubTotal(_subTotal);
        setTatalVat(_tatalVat);
        setOverolDiscount(_overollDiscount);
        let _totalAmount = _subTotal + _tatalVat - _overollDiscount;
        let roundedNumber = Math.round(_totalAmount);
        let amountAdded = roundedNumber - _totalAmount;
        setRoundOff(Number(amountAdded.toFixed(2)));
        // setTotalAmount(roundedNumber);
        setTotalAmount(_totalAmount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //form submit
  const onFinish = async (val: any) => {
    setLoadingForm(true);
    let amountPaid = Number(val.amoutToPaid) || 0;
    let totalPayable = Number(val.availableBalance) || totalAmount;
    let outstanding = totalPayable - amountPaid;
    let status = "0";
    if (outstanding <= 0) {
      status = "2"; //paid
    } else if (outstanding < totalPayable) {
      status = "1"; //part Paid
    } else if (outstanding >= totalPayable) {
      status = "0"; //unpaid
    }
    // setIsLoading(true);
    let paymentInfo = isPaymentInfo;
    if (isPaymentInfo) {
      paymentInfo = {
        id: val.paymentBank,
        bankid: val.paymentBank,
        outstanding: val.outStanding,
        amount: val?.availableBalance,
        date: val?.paymentDate,
        type: val.paymentMethod,
        paidmethod: val.paymentMethod,
      };
    }

    try {
      let selectedCustomer: any =
        customers &&
        customers.length &&
        customers.find((item: any) => item.id === val.customerid);

      let ledger =
        ledgers &&
        ledgers.length &&
        ledgers?.find((item: any) => item.id === val.ledger);

      let column = val.columns.map((item: any) => {
        // let foundedProduct = props.product.find(
        let foundedProduct: any = products.find(
          (product: any) => product.id === item.id
        );
        let productLedger = {};
        if (foundedProduct.itemtype === "Stock") {
          productLedger = {
            category: "13",
            id: 1,
            laccount: "Sales-Products",
            nominalcode: "4000",
          };
        } else if (foundedProduct.itemtype === " Service") {
          productLedger = {
            category: "24",
            id: 2,
            laccount: "Sales-Services",
            nominalcode: "4001",
          };
        }
        return {
          id: item.id,
          discount: item.discount,
          discountamt: item.discountamt,
          productId: item.id,
          product: foundedProduct,
          idescription: foundedProduct.idescription,
          description: foundedProduct.idescription,
          vat: item.vat,
          includevat: item.includeVat,
          incomeTax: item.vat,
          percentage: item.vat,
          costprice: item.price,
          ledgerDetails: productLedger,
          ledger: productLedger,
          quantity: item.quantity,
          total: item.total,
          vatamt: item.vat,
          vatamount: item.vatamount,
          incomeTaxAmount: item.vatamount,
          itemorder: 1,
        };
      });
      let payload = {
        cname: selectedCustomer?.bus_name,
        // cname: "sam",
        // customerid: val.customerid,
        columns: column,
        invoiceno: val.invoiceNo,
        sdate: val?.sdate,
        ldate: val?.ldate,
        inaddress: val?.inaddress,
        deladdress: val?.deladdress,
        terms: val?.terms,
        quotes: val?.quotes,
        outstanding,
        // status: status,
        issued: "yes",
        type: "scredit",
        pagetype: "1",
        total: Number(totalAmount),
        userid: adminid,
        adminid: adminid,
        userdate: new Date(),
        paymentInfo: paymentInfo,
        reference: val?.reference,
        salesType: "",
        ledger: ledger,
        email: user.email,
        //roundOff: roundOff + "",
        total_vat: tatalVat,
        overall_discount: overollDiscount,
        taxable_value: subTotal,
      };

      let salesUrl = `SaleInvoice/update/${id}`;
      const response: any = await PUT(salesUrl, payload);
      if (response.status) {
        // setIsLoading(false);
        setLoadingForm(false);
        notification.success({ message: "sales credit updated successfully" });
        navigate(-1);
      } else {
        notification.error({ message: "oops.., something went wrong" });
        // setIsLoading(false);
        setLoadingForm(false);
      }
    } catch (error) {
      console.log(error);
      notification.error({ message: "Oops .. something went wrong" });
      setIsLoading(false);
      setLoadingForm(false);
    }
  };

  //form submit without reversal
  const onFinishWithoutReversal = async (val: any) => {
    setLoadingForm(true);
    let amountPaid = Number(val.amoutToPaid) || 0;
    let totalPayable = Number(val.availableBalance) || totalAmount;
    let outstanding = totalPayable - amountPaid;
    let status = "0";
    if (outstanding <= 0) {
      status = "2"; //paid
    } else if (outstanding < totalPayable) {
      status = "1"; //part Paid
    } else if (outstanding >= totalPayable) {
      status = "0"; //unpaid
    }
    // setIsLoading(true);
    let paymentInfo = isPaymentInfo;
    if (isPaymentInfo) {
      paymentInfo = {
        id: val.paymentBank,
        bankid: val.paymentBank,
        outstanding: val.outStanding,
        amount: val?.availableBalance,
        date: val?.paymentDate,
        type: val.paymentMethod,
        paidmethod: val.paymentMethod,
      };
    }

    const updatedValues = {
      ...val,
      columns: [],
    };

    try {
      let totalDifference = Number(val.amount) -  Number(details?.invoiceDetails?.total)
      let newOutstanding = Number(details?.invoiceDetails?.outstanding) + Number(totalDifference)

      let selectedCustomer =
        customers &&
        customers.length &&
        customers.find((item: any) => item.id === val.customerid);

      let ledger =
        ledgers &&
        ledgers.length &&
        ledgers?.find((item: any) => item.id === val.ledger);
      let payload = {
        cname: val.cname,
        columns: [
          {
            quantity: 0,
            id: "",
          },
        ],
        invoiceno: val?.invoiceNo,
        sdate: val?.sdate,
        ldate: val?.ldate,
        inaddress: val?.inaddress,
        deladdress: val?.deladdress,
        terms: val?.terms,
        quotes: val?.quotes,
        issued: "yes",
        type: "scredit",
        pagetype: "1",
        total: Number(val.amount),
        userid: adminid,
        adminid: adminid,
        userdate: new Date(),
        paymentInfo: paymentInfo,
        reference: val?.reference,
        outstanding:newOutstanding,
        ledger: ledger,
        email: user.email,
        status: details?.invoiceDetails?.status,
        salesType: "WithoutStockReversal",
      };
      let salesUrl = `SaleInvoice/updateCreditnoteWithoutReversal/${id}`;
     
      const response: any = await PUT(salesUrl, payload);
      if (response.status) {
        setLoadingForm(false);
        notification.success({ message: "Success",description:"Credit note updated successfully"});
        navigate(-1);
      } else {
        notification.error({ message: "Failed",description:"Failed to update credit note"});;
        setLoadingForm(false);
      }
    } catch (error) {
      console.log(error);
      notification.error({ message: "Server Error",description:"Failed to update credit note"});;
      setLoadingForm(false);
    }
  };

  return (
    <div>
      <PageHeader
        title="Edit Credit Notes"
        goBack={"/dashboard"}
        secondPathText="Credit Notes"
        secondPathLink={"/usr/salesCredit"}
        thirdPathText="Edit Credit Notes"
        thirdPathLink={`/usr/salesCredit/edit/${id}`}
      >
        <div></div>
      </PageHeader>
      <br />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Container>
          <Card>
            {isSmallForm ? (
              <Form
                form={form}
                onValuesChange={onValuesChange}
                onFinish={onFinishWithoutReversal}
              >
                <Row>
                  <Col sm={2}>
                    <div className="formLabel">Credit Invoice No.</div>
                    <Form.Item name={"invoiceNo"} rules={[{ required: true }]}>
                      <Input size="large" readOnly />
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Customer Name</div>
                    <Form.Item
                      name={"cname"}
                      rules={[{ required: true, message: "choose customer" }]}
                    >
                      <Select
                        size="large"
                        showSearch
                        onSearch={(val: any) => props.customerName(val)}
                        onChange={(val: any) => setSelectedCustomerId(val)}
                      >
                        {customers &&
                          customers.length &&
                          customers?.map((item: any) => {
                            return (
                              <Select.Option key={item.id} value={item.name}>
                                {item.bus_name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Sales Ledger</div>
                    <Form.Item
                      name={"ledger"}
                      rules={[{ required: true, message: "choose ledger" }]}
                    >
                      <Select size="large">
                        {ledgers &&
                          ledgers.length &&
                          ledgers?.map((item: any) => {
                            return (
                              <Select.Option key={item.id} value={item.id}>
                                {item.nominalcode + "-" + item.laccount}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Invoice Date</div>
                    <Form.Item
                      name={"sdate"}
                      initialValue={dayjs(selectedInvoice.data)}
                    >
                      <DatePicker style={{ width: "100%" }} size="large" />
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Amount</div>
                    <Form.Item
                      name={"amount"}
                      rules={[
                        {
                          type: "number",
                          required: true,
                          message: "Please enter a valid number",
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} size="large" />
                    </Form.Item>
                  </Col>
                </Row>

                <br />
                <br />
                <Row>
                  <Col sm={6}></Col>
                  <Col sm={6}>
                    <Row>
                      <Col sm={6}>
                        <Button size="large" block>
                          Close
                        </Button>
                      </Col>
                      <Col sm={6}>
                        <Button
                          size="large"
                          block
                          type="primary"
                          htmlType="submit"
                          loading={loadingForm}
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            ) : (
              <Form
                form={form}
                onValuesChange={onValuesChange}
                onFinish={onFinish}
              >
                <Row>
                  <Col sm={2}>
                    <div className="formLabel">Credit Invoice No</div>
                    <Form.Item name={"invoiceNo"} rules={[{ required: true }]}>
                      <Input size="large" readOnly />
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Customer Name</div>
                    <Form.Item
                      name={"customerid"}
                      rules={[{ required: true, message: "choose customer" }]}
                    >
                      <Select
                        size="large"
                        showSearch
                        onSearch={(val: any) => props.customerName(val)}
                        onChange={(val: any) => setSelectedCustomerId(val)}
                      >
                        {customers &&
                          customers.length &&
                          customers?.map((item: any) => {
                            return (
                              <Select.Option key={item.id} value={item.id}>
                                {item.bus_name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Sales Invoice</div>
                    <Form.Item
                      name={"sales_ref"}
                      rules={[
                        { required: true, message: "choose Sales Invoice" },
                      ]}
                    >
                      <Select size="large" showSearch>
                        {customerSalesData &&
                          customerSalesData.length &&
                          customerSalesData?.map((item: any) => {
                            return (
                              <Select.Option
                                key={item.id}
                                value={item.invoiceno}
                              >
                                {item.invoiceno}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Sales Ledger</div>
                    <Form.Item
                      name={"ledger"}
                      rules={[{ required: true, message: "choose ledger" }]}
                    >
                      <Select size="large">
                        {ledgers &&
                          ledgers.length &&
                          ledgers?.map((item: any) => {
                            return (
                              <Select.Option key={item.id} value={item.id}>
                                {item.nominalcode + "-" + item.laccount}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Invoice Date</div>
                    <Form.Item
                      name={"sdate"}
                      initialValue={dayjs(selectedInvoice.data)}
                    >
                      <DatePicker style={{ width: "100%" }} size="large" />
                    </Form.Item>
                  </Col>
                  <Col sm={2}>
                    <div className="formLabel">Reference</div>
                    <Form.Item name={"reference"}>
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                </Row>

                {products && (
                  <Items
                    form={form}
                    products={products}
                    taxLists={taxList}
                    isCheckChange={(val: any) => setIsCheck(val)}
                  />
                )}

                <br />
                <Row>
                  <Col sm={6}>
                    <div className="formLabel">Terms and Conditions</div>
                    <Form.Item
                      name={"terms"}
                      rules={[{ message: "enter terms" }]}
                    >
                      <Input.TextArea rows={4} size="large" />
                    </Form.Item>
                  </Col>
                  <Col sm={6}>
                    <div className="formLabel">Note</div>
                    <Form.Item
                      name={"quotes"}
                      rules={[{ message: "enter note" }]}
                    >
                      <Input.TextArea rows={4} size="large" />
                    </Form.Item>
                  </Col>
                </Row>

                <br />
                <br />
                <Row>
                  <Col sm={6}></Col>
                  <Col sm={6}>
                    <Table bordered>
                      <tbody>
                        <tr>
                          <td>TAXABLE VALUE </td>
                          <td>{subTotal.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>TOTAL VAT</td>
                          <td>{tatalVat.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>OVERALL DISCOUNT</td>
                          <td>{overollDiscount.toFixed(2)}</td>
                        </tr>
                        {/* <tr>
                          <td>ROUND OFF</td>
                          <td className="p-1">
                            <Input
                              className="p-1"
                              type="number"
                              bordered={false}
                              value={roundOff}
                              onChange={(e: any) => {
                                setRoundOff(e.target.value);
                                let round = Number(e.target.value);
                                let _totalAmount =
                                  subTotal + tatalVat - overollDiscount;
                                _totalAmount = _totalAmount - round;
                                setTotalAmount(_totalAmount);
                              }}
                            // suffix={false}
                            />
                          </td>
                        </tr> */}
                        <tr>
                          <td>TOTAL AMOUNT</td>
                          <td>{totalAmount.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <Row>
                      <Col sm={6}>
                        <Button size="large" block onClick={()=>navigate('/usr/sales-invoice')}>
                          Close
                        </Button>
                      </Col>
                      <Col sm={6}>
                        <Button
                          size="large"
                          block
                          type="primary"
                          htmlType="submit"
                          loading={loadingForm}
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </Card>
        </Container>
      )}
    </div>
  );
}
export default Edit;
