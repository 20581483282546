import { useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Card,
  Input,
  Select,
  notification,
} from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import API from "../../../config/api";
import { PUT } from "../../../utils/apiCalls";
import ImagePicker from "../../../components/imagePicker";
import Avatar from "../../../assets/images/user.png";
import { IoIosCamera } from "react-icons/io";
import { update } from "../../../redux/slices/userSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";

function General(props: any) {
  const { user } = useSelector((state: any) => state.User);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { countries } = useSelector((state: any) => state.Country);
  const [isLoading, setIsLoading] = useState(false);
  const [toggle, toggleModal] = useState(false);

  const initialValues = {
    firstname: user?.firstname,
    lastname: user?.lastname,
    dob: user?.dob ? dayjs(user?.dob) : null,
    address1: user?.companyInfo?.address1,
    address2: user?.companyInfo?.address2,
    city: user?.companyInfo?.city,
    countryid: user?.countryid,
    bname: user?.companyInfo?.bname,
  };

  const onFinish = async (data: any) => {
    try {
      setIsLoading(true);
      let obj = {
        id: user?.id,
        userid: user?.userid,
        firstname: data?.firstname,
        lastname: data?.lastname,
        dob: data?.dob,
        address1: data?.address1,
        address2: data?.address2,
        city: data?.city,
        countryid: data?.countryid,
        bname: data?.bname,
        email: user?.email,
        phonenumber: user?.phonenumber,
        status: user?.status,
        usertype: user?.usertype,
        expiredate: user?.companyInfo?.expiredate,
        btype: user?.companyInfo?.btype,
        rtype: user?.companyInfo?.rtype,
        registerno: user?.companyInfo?.registerno,
        rdate: user?.companyInfo?.rdate,
        plan: user?.companyInfo?.plan,
        company: user?.companyInfo?.company,
        cemail: user?.companyInfo?.cemail,
        cphoneno: user?.companyInfo?.cphoneno,
        cperson: user?.companyInfo?.cperson,
        taxregno: user?.companyInfo?.taxregno,
        tax: user?.companyInfo?.tax,
        taxno: user?.companyInfo?.taxno,
        logo: user?.companyInfo?.logo,
        adminid: user?.companyInfo?.adminid,
        bimage: user?.companyInfo?.bimage,
        bcategory: user?.companyInfo?.bcategory,
        accounttype: user?.companyInfo?.accounttype,
        accplan: user?.companyInfo?.accplan,
        cusNotes: user?.companyInfo?.cusNotes,
        website: user?.companyInfo?.website,
        endYear: user?.companyInfo?.endYear,
        defaultmail: user?.companyInfo?.defaultmail,
        defaultTerms: user?.companyInfo?.defaultTerms,
      };
      let url = API.UPDATE_PROFILE + user?.id;
      const response: any = await PUT(url, obj);
      setIsLoading(false);
      if (response.status) {
        notification.success({
          message: `${t("home_page.homepage.success")}`,
          description:`${t("home_page.homepage.Profile_Updated_Succesfully")}`
        });
        response.data["bankInfo"] = response.bankInfo;
        dispatch(update(response.data));
        props.onChange();
      } else {
        notification.error({
          message: `${t("home_page.homepage.failed")}`,
          description:`${t("home_page.homepage.profile_update_failed")}`
        });
      }
    } catch (err) {
      notification.error({
        message: `${t("home_page.homepage.server_error")}`,
        description:`${t("home_page.homepage.profile_update_failed")}`
      });
      setIsLoading(false);
    }
  };

  function disabledDate(current: any) {
    return current && current > moment().endOf("day");
  }

  return (
    <Container>
      <Row>
        <Col md="2">
          <div className="profile-picture-container">
            <div className="profile-picture">
              <img
                src={
                  user?.companyInfo?.bimage == null ||
                  user?.companyInfo?.bimage == ""
                    ? Avatar
                    : user?.companyInfo?.bimage
                }
                className="profile-img"
                onClick={() => toggleModal(true)}
                alt=""
              />
              <IoIosCamera
                className="profile-edit"
                color="#fff"
                size={25}
                onClick={() => toggleModal(true)}
              />
            </div>
            <br />
            <div className="profile-text-box">
              <div className="profile-txt1">
                {user?.firstname} {user?.lastname}
              </div>
              <div className="profile-txt2">{user?.companyInfo?.address1}</div>
            </div>
          </div>
        </Col>

        <Col md="10">
          <Card>
            <Form
              layout="vertical"
              onFinish={onFinish}
              initialValues={initialValues}
            >
              <Row>
                <Col md="6">
                  <Row>
                    <Col md={6}>
                      <label className="formLabel">{t("home_page.homepage.first")}</label>
                      <Form.Item name="firstname" style={{ marginBottom: 10 }}
                      rules={[{ required: true ,message:'First name is required'}]}
                      >
                        <Input placeholder={t("home_page.homepage.first")} size="large" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <label className="formLabel">{t("home_page.homepage.Last")}</label>
                      <Form.Item name="lastname" style={{ marginBottom: 10 }}
                      rules={[{ required: true ,message:'Last name is required'}]}
                      >
                        <Input placeholder={t("home_page.homepage.Last")} size="large" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <label className="formLabel">{t("home_page.homepage.Address_Line_1")}</label>
                  <Form.Item
                    name="address1"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true ,message:'Address line 1 is required'}]}
                  >
                    <Input
                      placeholder={t("home_page.homepage.Address_Line_1")}
                      size="large"
                      type="text"
                    />
                  </Form.Item>

                  <label className="formLabel">{t("home_page.homepage.City")}</label>
                  <Form.Item
                    name="city"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true ,message:'City is required'}]}
                  >
                    <Input placeholder="City" size="large" />
                  </Form.Item>
                  <label className="formLabel">{t("home_page.homepage.Business_Name")}</label>
                  <Form.Item name="bname" style={{ marginBottom: 10 }}
                  rules={[{ required: true ,message:'Business name is required'}]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md="6">
                  <label className="formLabel">{t("home_page.homepage.Date_of_birth")}</label>
                  <Form.Item name="dob" style={{ marginBottom: 10 }}>
                    <DatePicker
                      disabledDate={disabledDate}
                      placeholder={t("home_page.homepage.DOB")}
                      size="large"
                      style={{ width: "100%" }}
                      format="DD-MM-YYYY"
                      inputReadOnly={true}
                    />
                  </Form.Item>

                  <label className="formLabel">{t("home_page.homepage.Address_Line_2")}</label>
                  <Form.Item
                    name="address2"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true ,message:'Address line 2 is required'}]}
                  >
                    <Input placeholder={t("home_page.homepage.Address_Line_2")} size="large" />
                  </Form.Item>
                  <label className="formLabel">{t("home_page.homepage.Country")}</label>
                  <Form.Item name="countryid" style={{ marginBottom: 10 }}
                  rules={[{ required: true ,message:'Country is required'}]}
                  >
                    <Select placeholder={t("home_page.homepage.Select_Country")} size="large">
                      {countries?.map((country: any) => (
                        <Select.Option key={country.id} value={country.id}>
                          {country.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Row>
                    <Col md={6} />
                    <Col md={6}>
                      <br />
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={isLoading}
                        block
                      >
                        {t("home_page.homepage.Update")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
            <ImagePicker
              open={toggle}
              modalClose={() => toggleModal(false)}
              data={user}
              refreshData={props.onChange}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default General;
