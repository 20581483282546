import React from "react";
import { Card } from "antd";
import PageHeader from "../../../components/pageHeader";
import { Row, Col } from "react-bootstrap";
import "./style.scss";
import { useNavigate } from "react-router-dom";

function HrReport() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader title="Report "></PageHeader>
      <br />
      <br />

      <div>
        <Row>
          <Col md="1"></Col>
          <Col md={2}>
            <Card
              title="Project Report"
              className="cards"
              style={{
                height: "100%",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/usr/hr-report/projectreport`)}
            ></Card>
          </Col>

          <Col md={2}>
            <Card
              title="Salary Report"
              className="cards"
              style={{
                marginBottom: "16px",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/usr/hr-report/salaryreport`)}
            ></Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default HrReport;
