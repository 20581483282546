import { ConfigProvider, message } from "antd";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import ProtectedRoute from "./utils/protectedRoute";

//screens
import { useCallback, useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DigitalInvoice from "./app/invoice";
import i18n from "./i18n";
import Navigation from "./navigation";
import Accounting from "./website/accounting";
import Consulting from "./website/consulting";
import Contact from "./website/contact";
import Forgott from "./website/forgott";
import ResetPassword from "./website/forgott/resetPassword";
import Home from "./website/home";
import Login from "./website/login";
import UpdateEmail from "./website/login/emailUpdate";
import Payroll from "./website/payroll";
import Plans from "./website/plans";
import Privacy from "./website/privacy";
import News from "./website/news";
import Retail from "./website/retail";
import Services from "./website/services";
import Signup from "./website/signup";
import Support from "./website/support/index";
import TaxForm from "./website/taxCalculator/TaxForm";
import Terms from "./website/terms";
import RetailExpress from "./retailExpress";
import HrProjects from "./app/hr/hr-projects";
import HrTimesheet from "./app/hr/hr-timesheet";

function App(props: any) {
  const navigate = useNavigate();
  const handleKeyPress = useCallback((e: any) => {
    if (e.key === "Escape") {
      navigate("/usr/dashboard");
    } else if (e.key === "s" && e.altKey) {
      navigate("/usr/sale-invoice-form/0");
    } else if (e.key?.toUpperCase() === "w"?.toUpperCase() && e.altKey) {
      navigate("/usr/create-product/Nonstock/create");
    } else if (e.key?.toUpperCase() === "m"?.toUpperCase() && e.altKey) {
      navigate("/usr/create-product/Service/create");
    } else if (e.key?.toUpperCase() === "r"?.toUpperCase() && e.altKey) {
      navigate("/usr/proforma-invoice-form/create");
    } else if (e.key?.toUpperCase() === "t"?.toUpperCase() && e.altKey) {
      navigate("/usr/purchace-invoice-form/create");
    } else if (e.key?.toUpperCase() === "y"?.toUpperCase() && e.altKey) {
      navigate("/usr/purchace-debitnote-form/create");
    } else if (e.key?.toUpperCase() === "u"?.toUpperCase() && e.altKey) {
      navigate("/usr/purchase-asset-form/Create/0");
    } else if (e.key?.toUpperCase() === "i"?.toUpperCase() && e.altKey) {
      navigate("/usr/contactCustomers/create");
    } else if (e.key?.toUpperCase() === "o"?.toUpperCase() && e.altKey) {
      navigate("/usr/contactSuppliers/create");
    } else if (e.key?.toUpperCase() === "p"?.toUpperCase() && e.altKey) {
      navigate("/usr/CreateJournal");
    } else if (e.key?.toUpperCase() === "a"?.toUpperCase() && e.altKey) {
      navigate("/usr/cashBank");
    } else if (
      e.key?.toUpperCase() === "s"?.toUpperCase() &&
      (e.altKey || e.metaKey)
    ) {
      navigate("/usr/ledgerMyCategory");
    } else if (e.key?.toUpperCase() === "d"?.toUpperCase() && e.altKey) {
      navigate("/usr/ledgerMyLedger");
    } else if (e.key?.toUpperCase() === "g"?.toUpperCase() && e.altKey) {
      navigate("/usr/purchaseFixedAsset");
    } else if (e.key?.toUpperCase() === "h"?.toUpperCase() && e.altKey) {
      navigate("/usr/cash");
    } else if (e.key?.toUpperCase() === "j"?.toUpperCase() && e.altKey) {
      navigate("/usr/ledgerMyCategory");
    } else if (e.key?.toUpperCase() === "k"?.toUpperCase() && e.altKey) {
      navigate("/usr/ledgerMyLedger");
    } else if (e.key?.toUpperCase() === "l"?.toUpperCase() && e.altKey) {
      navigate("/usr/salesCredit/screditform/0");
    } else if (e.key?.toUpperCase() === "v"?.toUpperCase() && e.altKey) {
      navigate("/usr/proposal/create");
    }
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const direction: any = localStorage.getItem("direction");
  const User = useSelector((state: any) => state.User);
  message.config({ top: 100 });
  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#18a762",
            fontFamily: "Poppins-Regular",
            borderRadius: 4,
            colorTextPlaceholder: "#888c99",
          },
        }}
      >
        <div dir={direction}>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/" element={<Home />} />;
            <Route path="/login" element={<Login />} />;
            <Route path="/signup" element={<Signup />} />;
            <Route path="/forgot" element={<Forgott />} />;
            <Route path="/contact" element={<Contact />} />;
            <Route path="/services" element={<Services />} />;
            <Route path="/plans" element={<Plans />} />;
            <Route path="/support" element={<Support />} />
            {/* <Route path="/taxCalculator" element={<TaxCalculator />} />; */}
            <Route path="/taxCalculator" element={<TaxForm />} />;
            {/* <Route path="/taxCalculator/:id" element={<TaxForm />} />; */}
            <Route path="/accounting" element={<Accounting />} />;
            <Route path="/retail" element={<Retail />} />;
            <Route path="/consulting" element={<Consulting />} />;
            <Route path="/payroll" element={<Payroll />} />;
            <Route path="/terms" element={<Terms />} />;
            <Route path="/privacy" element={<Privacy />} />;
            <Route path="/news" element={<News />} />;
            <Route path="/invoice/:type/:id" element={<DigitalInvoice />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />;
            <Route path="/hr-projects" element={<HrProjects />} />;
            <Route path="/hr-timesheet" element={<HrTimesheet />} />;
            <Route
              path="/emailUpdated/:userId/:token"
              element={<UpdateEmail />}
            />
            ;
            <Route
              path="/usr/*"
              element={
                <ProtectedRoute isSignedIn={User.auth}>
                  <Navigation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/retail-express"
              element={
                <ProtectedRoute isSignedIn={User.auth}>
                  <RetailExpress />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
        </div>
      </ConfigProvider>
    </I18nextProvider>
  );
}

export default App;
