const API = {
  // BASE_URL: "https://taxgov2-server.taxgoglobal.com/taxgov2/",
  // BASE_URL: "http://localhost:8082/taxgov2/",
  // BASE_URL: "https://taxgonew-dev.herokuapp.com/taxgo/",
  //  BASE_URL: "https://taxgov2-fee2c8be4992.herokuapp.com/taxgo/",
  // BASE_URL: "http://localhost:8024/taxgov2/",
  BASE_URL: "https://server.bairuhatech.com/employee-server/taxgov2/",

  TAXGO_V0: "https://main.dd5bgcyjfqt12.amplifyapp.com/login",
  TAXGO_V1: "https://master.dd5bgcyjfqt12.amplifyapp.com/login",

  RESETPASSWORD: "auth/reset-password",
  FORGOTPASSWORD: "auth/forgot-password",
  FILE_PATH: "https://taxgo-v2.s3.eu-west-1.amazonaws.com/",
  LOGIN: "user/login", //done
  ALL_COUNTRIES: "countries/all", //GET
  BUSINESS_CATEGORY: "business_category/", //GET
  PUBLIC_REGISTER: "user/publicregister", //POST
  LOGIN_GMAIL: "auth/google_login",
  LOGIN_EMAIL: "auth/email-login",
  LOGIN_PHONE: "auth/Phone-login",

  LOGIN_REGISTRATION: "auth/register",

  //Dashboard
  CUSTOM_DATA: "dashboard/customData",
  GRAPH_DATA: "dashboard/graphData", //POST
  PROFORMA_INVOICE_LIST: "SaleInvoice/list/", //GET
  CUSTOMER_LIST: "contactMaster/searchList/customer/", //GET
  ADD_CUSTOMER: "customer/add",
  CUSTOMER: "customer/",
  CONTACT_MASTER: "contactMaster/",
  CONTACT_MASTER_LIST: "contactMaster/list/",
  CONTACTS_LIST_TYPE: "contactMaster/lists/",
  SUPPLIER: "contactMaster/",

  // profile --------------------
  UPDATE_PASSWORD: "user/updatePassword",
  UPDATE_PROFILE_PICTURE: "user/updateProfilePicture",
  GET_PROFILE_DETAILS: "user/viewProfile/",
  UPDATE_PHONENUMBER: "auth/updatePhonenumber/",
  UPDATE_EMAIL: "auth/updateEmail/",
  VERIFY_EMAIL: "auth/verifyEmail/",

  // profile --------------------
  GET_USER_DETAILS: "user/viewProfile/",
  UPDATE_PICTURE: "user/updateProfilePicture/",
  UPDATE_LOGO: "user/updateLogoPicture",
  CUSTOMISE_DATA: "user_settings/invoiceNoConfig/",
  UPADATE_TEMPLATE: "user/updateInvoiceTemplate/",
  UPDATE_PROFILE: "user/updateProfile/",
  CLEAR_USER_DATA: "user/clear-data/",
  USER_SETTING_GETINVOICENO: "user_settings/getInvoiceNo/",

  // profile ---------------------
  LOCATION_GET: "location",
  LOCATION_DELETE: "location/delete/",
  LOCATION_GETBY_ID: "location/byId/",
  LOCATION_POST: "location/add",
  LOCATION_PUT: "location/update/",
  LOCATION_GET_BY_USER: "location/user/",
  DELETE_BANK_TRANSACTION: "ledger_details/delateTransaction/",

  CREATE_TAX_MASTER: "tax_master/add",
  TAX_MASTER: "tax_master/",

  // purchase table--------------------->
  PURCHASE_INVOICE_LIST: "purchaseinvoice/list/",
  PURCHASE_SUPPLIER_LIST: "purchaseinvoice/viewInvoice/",
  PURCHASE_INVOICE_ADD: "purchaseinvoice/add",
  PURCHASE_INVOICE_UPDATE: "purchaseinvoice/update/",
  PURCHASE_PRODUCT_LIST: "ProductMaster/user/Stocks/",
  PURCHASE_TAXGO_SUPPLIER_LIST: "contactMaster/allList/supplier/",
  PURCHASE_DEFUALT_LEDGER_LIST: "account_master/defualt-ledger/purchase/",
  PURCHASE_ID_BY_LIST: "purchaseinvoice/listSupplierPay/",
  GET_PURCHASE_ID_BY_LIST: "purchaseinvoice/addSupOtherPaymentCash",
  GET_FIXED_ASSET_LEDJERS: "account_master/fixed-assets/",
  PURCHASE_SUPPKIER_LIST: "supplier/list/",
  PURCHASE_INVOICELIST_BY_DATE: "Purchaseinvoice/listByDateFilter/",
  PURCHASE_PAYMENT: "purchaseinvoice/supplierpayment",
  VIEW_PURCHASE_INVOICE: "purchaseinvoice/viewInvoice/",

  //Product Master
  PRODUCT_MASTER_USER: "ProductMaster/user/list/",
  ADD_PRODUCT: "ProductMaster/add",
  ADD_PRODUCT_VIAEXCEL: "ProductMaster/addFromExcel",
  GET_PRODUCT_CATEGORY: "ProductCategory/category/",
  GET_PRODUCT_MASTER_BY_ID: "ProductMaster/getProductById/",
  GET_PRODUCT_UPDATE: "ProductMaster/update/",
  PRODUCT_STOCK_UPDATE: "ProductMaster/adjustStockLevel/",
  PRODUCT_UNIT_BY_USER: "unit/user/",
  DELETE_PRODUCT: "ProductMaster/delete/",
  CHECK_IF_EXIST: "ProductMaster/checkifExist/",
  PRODUCTMASTER_IMAGE_UPLOADER: "ProductMaster/updateProductImage",
  GET_AACCOUNT_BYID: "ProductMaster/saccount/",
  GET_PRODUCT_TYPELIST: "ProductMaster/user/list/type/",
  GET_PRODUCT_TYPE: "ProductMaster/user/type/",

  //credit note
  ALL_CREDIT_NOTES: "CreditNote/all/",
  CREDIT_INVOICE_LIST: "SaleInvoice/list/", //GET
  CREATE_CREDIT_NOTE: "SaleInvoice/add",
  CREATE_CREDIT_NOTE_NEW: "SaleInvoice/addCreditNew",
  DELETE_SALES_INVOICE: "SaleInvoice/deleteInvoice",

  //suppliers-list
  SUPPLIERS_LIST: "contactMaster/list/supplier/",
  SUPPLIERS_LIST_ID: "contactMaster/",
  VAT_EXISTS: "contactMaster/checkifVatNumberExist/",

  SUPPLIERS_SEARCH_LIST: "contactMaster/searchList/supplier/",
  SUPPLIER_PAY_LIST: "purchaseinvoice/supplierPayidList/",
  PURCHASE_ALL_PAY_LISTS: "purchaseinvoice/listAllSupplierPay/",
  GET_ALL_LISTS_CONTACTMASTER: "contactMaster/allList/",
  DELETE_CONTACT: "contactMaster/delete/",

  //unit
  UNIT_LIST_USER: "unit/user/",
  UNIT_CREATE: "unit/add",
  UNIT_UPDATE: "unit/update/",
  UNIT_DELETE: "unit/delete/",
  UNIT_GETBY_ID: "unit/byId/",

  PRODUCTCATEGORY_LIST_USER: "ProductCategory/user/",
  PRODUCTCATEGORY_CREATE: "ProductCategory/add",
  PRODUCTCATEGORY_UPDATE: "ProductCategory/update/",
  PRODUCTCATEGORY_DELETE: "ProductCategory/delete/",
  PRODUCTCATEGORY_GETBY_ID: "ProductCategory/byId/",
  //EmployeeCategory

  EMPLOYEECATEGORY_LIST_USER: "employeeCategory/user/",
  EMPLOYEECATEGORY_GET_BYID: "employeeCategory/byId/",
  EMPLOYEECATEGORY_CREATE: "employeeCategory/add",
  EMPLOYEECATEGORY_UPDATE: "employeeCategory/update/",
  EMPLOYEECAREGORY_DELETE: "employeeCategory/delete/",

  //payrollPayHead
  PAYROLLPAYHEAD_LIST_USER: "account_master/getPayHead/",
  PAYROLLPAYHEAD_CREATE: "payrollPayHead/add",
  PAYROLLPAYHEAD_UPDATE: "payrollPayHead/update/",
  PAYROLLPAYMENT_DELETE: "payrollPayHead/delete/",
  PAYROLLPAYMENT_GET_BYID: "payrollPayHead/byId/",

  //payrollPayHead
  EMPLOYEES_LIST_USER: "employees/user/",
  EMPLOYEES_CREATE: "employees/add",
  EMPLOYEES_UPDATE: "employees/update/",

  //payrollPayHead
  GET_PAYSHEET: "paySheet/",
  PAYSHEET_LIST_USER: "paySheet/user/",
  PAYSHEET_CREATE: "paySheet/add",
  PAYSHEET_UPDATE: "paySheet/update/",
  PAYSHEET_PAYMENT: "paySheet/sendPayment/",
  UPDATE_PAYSHEET: "paySheet/update/",

  // employee
  DELETE_EMPLOYEE: "employees/delete/",

  //Journallist
  JOURNAL: "Journal/",
  JOURNAL_LIST: "Journal/list/",
  ADD_JOURNAL: "Journal/add",
  GET_ALL_ENTRIES: "contactMaster/all/list/",
  DELETE_JOURNAL: "Journal/delete/",

  //Bank
  ADD_BANK: "account_master/addBank",
  GET_BANK_LIST: "account_master/getBankList/",
  GET_ACCOUNT_MASTER_LIST: "account_master/list",
  GET_BANK_DETAILS: "bank/bankDetails/",
  UPDATE_BANK: "account_master/updateBank",
  UPDATE_BANK_DETAILS: "ledger_details/updateCashDeatails/",
  LIST_BANK_ACTIVITY: "bank/listBankActivity/",
  LIST_BANK_ACTIVITY_NEW: "bank/listBankActivityNew/",
  BANK_TRANSFER: "bank/bankTransfer",
  GET_BANK_TRANSACTION_DETAILS: "bank/getBankingTransactionDetail/",
  VIEW_TRANSFER: "bank/viewtransfer/",
  UPDATE_RECONCILE: "ledger_details/update/",
  LIST_LEDGER: "account_master/list",
  GET_MY_LEDGERS: "account_master/getMyLedgers/",
  GET_LEDGER_CATEGORY: "ledgercategory/",
  GET_LEDGER_CAT: "searchList/:type/:id",
  GET_CATEGORY_LIST: "ledgercategory/all",
  GET_CATEGORY_GROUP_LIST: "ledgercategorygroup/all",
  LEDGER_CREATE: "account_master/add",
  CREATE_LEDGER_CATEGORY: "Ledgercategory",
  UPDATE_LEDGER_CATEGORY: "Ledgercategory/",
  UPDATE_LEDGER: "account_master/update/",
  CHANGE_LEDGER_VISIBILITY: "account_master/update-visibility",
  LEDGER_DEATAILS: "ledger_details/",
  LEDGER_DEATAILS_UPDATE: "ledger_details/update_ledger/",
  LEDGER_ACCOUNT_DETAILS: "account_master/defualt-ledger/journals/",
  CREATE_SUPPLIER_REFUND: "purchaseinvoice/addSuppRefundCash",
  CREATE_PURCHASE_PAYMENT: "purchaseinvoice/addSuppReceiptCash",
  GET_CONTACT_MASTER_LIST: "contactMaster/getalldata/",
  S_DELETE_LEDGER: "ledger_details/delete/",
  GET_CREDIT_NOTE_SALESINVOICE: "SaleInvoice/getListByCustmer/",
  CUSTOMER_SUPPLIER_LIST: "contactMaster/allList/",
  GET_SCREDITLIST_SUPPLIER_REFUND: "SaleInvoice/scredit_invoice/", //GET
  CONTACT_MASTER_SEARCHLIST: "contactMaster/searchList/both/", //GET
  GET_PURCHASE_INVOICE: "purchaseinvoice/list/",
  UPDATE_SUPPLIER_REFUND: "purchaseinvoice/update/",
  // GET_SCREDITLIST_SUPPLIER_REFUND: "SaleInvoice/scredit_list/", //GET
  ///////////
  LEDGER_UPDATE: "ledger_details/update_ledger/",

  //LEDGER
  UPDATE_MY_LEDGER: "account_master/update/",
  GET_LEDGERBY_ID: "Ledgercategory/getLedgerCategoryById/",
  //LEDGERCATEGORY GROUP
  GET_LEDGERCATE_GROUP: "ledgercategorygroup/all",

  GET_ALL_LEDGER_DETAILS: "report/ledger-details/",
  GET_LEDGER_DETAILS: "account_master/getLedgerById/",

  //Tax
  TAXLIST: "tax_master/list/",

  //Report VatGst
  VAT_RETURN: "report/overallVatReport/",

  //enquiry
  GET_ENURIES_LIST: "contactus",
  VAT_RETURN_NOMINAL: "report/getVatNominalList/",
  VAT_RETURN_VIEW: "report/getNominalVat/",

  //Report Profit/Loss
  PROFITLOSS: "report/profitLoss/",
  GET_SALES_LIST: "SaleInvoice/list/",

  //trial balance
  TRIAL_BALANCE: "report/trialbalance/",

  //stocksummary
  GET_PURCHASE_MONTH: "purchaseinvoice/month/",
  GET_SALEINVOICE_STOCK: "SaleInvoice/stock/",
  GET_SALESMONTH_DAILY: "purchaseinvoice/month/",
  GET_STOCK_DETAILS: "report/user/list/type/",
  GET_PRODUCT_DETAILS: "report/product/",
  INWARD_DATA: "report/inward/",
  OUTWARD_DATA: "report/outward/",

  //Cash
  GET_CASH_LIST: "account_master/getCashList/",
  GET_CASH_LIST_ACTIVITY_BY_ID: "bank/listBankActivity/",

  GET_CASH_LIST_PAGE: "bank/list/",
  //Sale
  GET_SALE_INVOICE_BY_ID: "SaleInvoice/listCustomerPay/",
  SALE_ALL_CUSTOMER_PAY: "SaleInvoice/listallCustomerPay/",
  UPDATE_SALES: "SaleInvoice/update/",
  SALES_PAYMENT: "SaleInvoice/customerPayment",
  // proforma
  GET_INVOICENO: "user_settings/getInvoiceNo/",
  VIEW_SALE_INVOICE: "SaleInvoice/viewInvoice/",
  GET_CUSTOMERS: "customer/list/",
  GET_PRODUCT_LIST: "ProductMaster/user/Stock/",

  //Receipt
  ADD_CUSTOMER_RECCEIPT: "SaleInvoice/addCustReceipt",
  ADD_OTHER_RECEIPT: "SaleInvoice/addOtherReceipt",
  ADD_SUPPLIRE_REFUND: "purchaseinvoice/addSuppRefund",
  GET_SALE_ID_BY_PAY_LIST: "SaleInvoice/customerPayidList/",
  // GET_CUS_REFUND_INVOICE_LIST: "SaleInvoice/customerPayidList/",
  EDIT_SUPPLIER_REFUND: "purchaseinvoice/update/",

  //Payment
  ADD_CUSTOMER_REFUND: "SaleInvoice/addCustRefundCash",
  ADD_SUPPLIRE_OTHER_PAYMENT: "purchaseinvoice/addSupOtherPayment/",
  ADD_SUPPLIRE_PAYMENT: "purchaseinvoice/addSuppPayment/",

  //reccuring notification
  GET_RECCURING_LIST: "reccuringnotification/all/",
  GET_RECCURING: "reccuringnotification/get/",
  EDIT_RECCURING: "reccuringnotification/update/",
  DELETE_RECCURING: "reccuringnotification/delete/",

  //Report Aged Creditors
  REPORT_CREDITORS: "report/agedcreditors2/",

  //Report Aged Creditors
  REPORT_DEBTORS: "report/agedebtors2/",

  SHARE_INVOICE: "share/invoice/",

  //tax calculator
  GET_COUNTRIES_DETAILS:
    "https://taxgocalculator-default-rtdb.firebaseio.com/taxCountry.json",
  GET_COUNTRY_FORM:
    "https://taxgocalculator-default-rtdb.firebaseio.com/taxForms/",

  // account master --------->
  // account_master/getBankListById
  GETBANK_LIST_BYID: "account_master/getBankListById/",
  //Report Aged Creditors
  // REPORT_CREDITORS: "report/agedcreditors2/",

  //Report Aged Creditors
  // REPORT_DEBTORS: "report/agedebtors2/",

  // USER_SETTING_GETINVOICENO: "user_settings/getInvoiceNo/",

  //
  PDF_GENERATE_URL: `https://pdf.taxgoglobal.com/getPdf`,

  //proposals
  GET_PROPOSAL_LIST: "proposal/list/",
  CREATE_PROPOSAL: "proposal/add",
  DELETE_PROPOSAL: "proposal/delete/",
  GET_PROPOSAL: "proposal/",
  UPDATE_PROPOSAL: "proposal/update/",
  UPLOAD_PROPOSAL_LOGO: "proposal/uploadLogo",

  IMAGE_COMPRESS: "img_compress/compress",
  FILE_UPLOAD: "img_compress/file",

  //REPORT APIS
  BALANCESHEET: "report/balancesheet", //POST

  GET_CASH_DETAILS: "account_master/getLedgerById/",

  UPDATE_INVOICE_DETAILS: "user_settings/updateInvoiceConfig/",

  //RETAIL EXPRESS
  LIST_PRODUCTS: "retail/productlist/",

  // END OF YEAR
  END_OF_YEAR: "account_master/setEndOfYear/",

  //HR DASHBOARD
  HR_DASHBOARD: "employee/HrDashboard",

  //LEAVE
  GET_LEAVE_REQUESTS: "leaves/",
  POST_LEAVE_REQUEST: "leaves",
  POST_LEAVE_REQUESTS: "leaves/approveLeave/",
  UPDATE_LEAVE_REQUEST: "leaves/approveLeave",
  // GET_LEAVE_REQUESTS: "leaves",
  POST_FILTER_LEAVE: "leaves/filter",
  POST_PAGE_LEAVE: "leaves/page",

  //NOTIFICATION
  GET_NOTIFICATIONS_REQUESTS: "notification/",

  // HR_EMPLOYEE
  GET_HR_EMPLOYEE_LIST: "employee/",
  // GET_EMPLOYEE_DETAILS: "employee/",
  EDIT_EMPLOYEE_DETAILS: "employee/update/",
  POST_HR_EMPLOYEE: "employee/createEmployee",

  HR_EMPLOYEES: "employee",
  GET_HR_EMPLOYEE_LISTING: "employee/listing",
  BLOCK_EMPLOYEE: "employee/block/",

  SEARCH_EMPLOYEE_DATA: "employee/filter",
  HR_EMPLOYEE_EXCEL: "employee/addFromExcel",
  HR_EMPLOYEE_CREATE: "employee/bulkCreate",
  POST_PAGE_DATA: "employee/page",

  //MEMBERS
  GET_PROJECT_MEMBERS: "members/project/",
  POST_MEMBERS: "members",
  DELETE_MEMBERS: "members/",

  //PROJECT
  GET_PROJECT: "project",
  GET_PROJECT_PAGE: "project/list",
  VIEW_PROJECT: "project/",
  EDIT_PROJECT: "project/",
  CREATE_PROJECT: "project",
  GET_ONE_PROJECT: "project/",
  PROJECT_EXCEL: "project/addFromExcel",
  DELETE_PROJECT: "project/",

  //TIMESHEET
  GET_TIMESHEET_FILTER: "timeSheet/filter",
  GET_USER_TIMESHEET: "timeSheet/",

  //PROJECT_PAYMENTS
  POST_PROJECTPAYMENTS: "projectpay",
  GET_PROJECTPAYMENTS: "projectpay",
  GET_PROJECTPAYMENTS_FILTER: "projectpay/filter",
  POST_PAGE_PAYMENTS: "projectpay/page",

  //TASK
  GET_ONE_TASK: "task/",
  CREATE_TASK: "task/create",
  CREATE_TASKS: "task/creates",
  EDIT_TASK: "task/update/",
  GET_TASK: "task",
  GET_FILTER_TASK: "task/filter",
  POST_PAGE_TASK: "task/list",
  TASK_EXCEL: "task/addFromExcel",
  BULK_CREATE_TASK: "task/bulkCreate",
  GET_TASK_HISTORY: "task_logs/task/",
  //members
  GET_USERID_FILTER: "members/byUser/",
  DELETE_TASK: "task/",

  //Comments
  COMMENTS: "comments",
  PAGE_COMMENTS: "comments/page",

  //CLIENT
  HR_CLIENTS: "client",
  POST_PAGE_CLIENTS: "client/page",
  LIST_CLIENTS: "client/listing",

  //PAYROLL
  POST_HR_PAYROLL: "Hr_Payroll",

  //REPORT
  POST_HR_REPORT: "hr_reports",
  POST_HR_REPORT_SALARY: "hr_reports/salary",
  POST_HR_REPORT_PAY: "hr_reports/pay",

  //HR FINANCE
  POST_PAGE_HRFINANCE: "HrFinance/page",
  GET_HEFINANCE: "HrFinanc",

  POST_HRFINANCE: "HrFinance",
  GET_HRFINANCE: "HrFinance/byUser/",
};
export default API;
