import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  notification,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageHeader from "../../../components/pageHeader";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import API from "../../../config/api";
import { GET, POST, PUT } from "../../../utils/apiCalls";
import { useSelector } from "react-redux";
import { generatePDF } from "../../proposal/components/templates";
import form from "antd/es/form";

function FormHrProject() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [data, setData] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const { user } = useSelector((state: any) => state.User);
  const { id, type } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataCount, setDataCount] = useState<any>([]);
  console.log("id", id);
  useEffect(() => {
    if (type === "edit") {
      setisUpdate(true);
      //   submitHandler(id);
      getData(id);
    }
  }, [type]);

  const getAllData = async () => {
    try {
      let api = API.LIST_CLIENTS;
      const count: any = await GET(api, {});
      console.log("count", count);
      if (count?.status) {
        setDataCount(count?.data);
      } else {
        console.error("Failed to fetch data from the API.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  const getData = async (id: any) => {
    try {
      setIsLoading(true);
      const url = API.GET_ONE_PROJECT + id;
      const { data }: any = await GET(url, {});
      setData(data);
      form.setFieldsValue({
        title: data.title,
        description: data.description,
        startDate: data?.startDate ? dayjs(data.startDate, "YYYY/MM/DD") : null,
        endDate: data?.endDate ? dayjs(data.endDate, "YYYY/MM/DD") : null,
        priority: data.priority,
        client: data.client,
      });

      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const submitHandler = async (values: any) => {
    try {
      setIsLoading(true);
      console.log("values --> ", values);
      let createUrl = API.CREATE_PROJECT;
      let updateUrl = API.EDIT_PROJECT + id;

      let obj = {
        key: "key",
        projectId: 15,
        title: values.title,
        description: values.description,
        client: Number(values.client),
        sprint: 6,
        startDate: values.startDate,
        endDate: values.endDate,
        createdBy: 168,
        updatedBy: 168,
        priority: values.priority,
        totalCost: 222,
        status: true,
      };

      console.log(obj);
      setIsLoading2(true);
      let result: any = isUpdate
        ? await PUT(updateUrl, obj)
        : await POST(createUrl, obj);

      if (result?.status) {
        getData(id);
        notification.success({
          message: `Item ${isUpdate ? "Updated" : "Added"} Successfully`,
        });
        navigate("/usr/hr-projects");
      } else {
        notification.error({ message: "something went wrong" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    } finally {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  return (
    <>
      <PageHeader
        firstPathText="Project"
        // firstPathLink={location.pathname.replace("/update", "")}
        secondPathText="Update"
        // secondPathLink={location.pathname}
        title={isUpdate ? "update" : "create"}
      />
      <Container>
        <Form form={form} onFinish={submitHandler}>
          <Card>
            <div className="productAdd-Txt1"> Project Details</div>

            <Row>
              <Col md={6}>
                <label className="formLabel">Title</label>
                <Form.Item
                  name="title"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="" />
                </Form.Item>

                <label className="formLabel">Start Date</label>
                <Form.Item
                  name="startDate"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    placeholder=""
                    size="large"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <label className="formLabel">End Date</label>
                <Form.Item
                  name="endDate"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    placeholder=""
                    size="large"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col md={6}>
                <label className="formLabel">Priority</label>
                <Form.Item
                  name="priority"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select a Priority" size="large">
                    <Select.Option value="low">Low</Select.Option>
                    <Select.Option value="medium">Medium</Select.Option>
                    <Select.Option value="high">High</Select.Option>
                  </Select>
                </Form.Item>

                <label className="formLabel">Description</label>
                <Form.Item name="description" rules={[{ required: true }]}>
                  <Input.TextArea size="large" placeholder="" rows={4} />
                </Form.Item>
              </Col>

              <Form.Item
                label="client Name"
                name="client"
                rules={[{ required: true }]}
              >
                <Select style={{ width: "200px" }}>
                  {dataCount.map((client: any) => (
                    <Select.Option key={client?.id} value={client?.id}>
                      {client?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>
            <br />
            <hr />
            <br />

            <Row>
              <Col md={3}>
                <Button
                  block
                  style={{ height: 45 }}
                  onClick={() => navigate("/usr/hr-projects")}
                >
                  Cancel
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isLoading}
                  style={{ height: 45 }}
                  onClick={() => console.log("")}
                >
                  {isUpdate ? "Update" : "Save"}
                </Button>
              </Col>
              {/* <Col md={3}>
                <Button
                  type="primary"
                  block
                  style={{ height: 45 }}
                  onClick={() => setisUpdate(!isUpdate)}
                >
                  edit
                </Button>
              </Col> */}
            </Row>
          </Card>
          <br />
        </Form>
      </Container>
    </>
  );
}

export default FormHrProject;
