import { Drawer, Layout } from "antd";
import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CashBank from "../app/bank/cash-bank";
import BankDetails from "../app/bank/cash-bank/components/Bankdetails";
import ReconcilePage from "../app/bank/cash-bank/components/ReconcilePage";
import Cash from "../app/cash";
import ContactCustomers from "../app/contact/contact-customers";
import ContactSuppliers from "../app/contact/contact-suppliers";
import Product from "../app/product";
import ProductNonStock from "../app/product/product-nonstock";
import ProductService from "../app/product/product-service";
import ProductStock from "../app/product/product-stock";
import PurchaseDebitNote from "../app/purchase/purchase-debitnotes";
import PurchaseInvoice from "../app/purchase/purchase-invoice";
import CreditNotes from "../app/sales/sales-credit";
import Header from "./Header";
import SiderBar from "./SiderBar";
import HelpBox from "./components/helpBox";
import NotificatonBox from "./components/notificatonBox";
import "./styles.scss";
import Contacts from "../app/contact";
import Dashboard from "../app/dashboard";
import Journal from "../app/journals";

//Ledger screens
import LedgerDefaultCategory from "../app/ledger/ledger-DefaultCategory";
import LedgerDefaultLedger from "../app/ledger/ledger-DefaultLedger";
import LedgerMyCategory from "../app/ledger/ledger-MyCategory";
import LedgerMyLedger from "../app/ledger/ledger-MyLedger";

import Merchant from "../app/merchant";
import RecoveryNotification from "../app/sales/reccuring-notification";
import SaleNewCreditNotes from "../app/sales/sales-newCreditNotes";
import CreateCutomer from "../app/contact/contact-customers/components/CreateCutomer";
import CustomerDetails from "../app/contact/contact-customers/components/CustomerDetails";
import EditCustomerDetails from "../app/contact/contact-customers/components/EditCustomerDetails";
import CreateSupplier from "../app/contact/contact-suppliers/components/CreateSupplier";
import EditSupplier from "../app/contact/contact-suppliers/components/EditSupplier";
import SupplierDetails from "../app/contact/contact-suppliers/components/SupplierDetails";

import ViewBankTransfer from "../app/bank/cash-bank/components/TransactionView/ViewBankTransfer";
import CashTable from "../app/cash/component/cashTable";
import PurchasePaymentForm from "../app/cash/component/form/purchasePayment";
import CustumerReceiptForm from "../app/cash/component/form/salesReceipt";
import CashView from "../app/cash/component/view";
import Enquiry from "../app/enquiry";
import CreateJournal from "../app/journals/component/create-journal";
import PayRoll from "../app/payroll";
import PayRollForm from "../app/payroll/component/form";
import PaySheet from "../app/payroll/paySheet";
import Employees from "../app/payroll/payrollEmployees";
import ProductAdd from "../app/product/components/form";
import ProductView from "../app/product/components/view";
import Profile from "../app/profile";
import Report from "../app/report";
import BalanceSheet from "../app/report/BalanceSheet";
import ProfitnLoss from "../app/report/ProfitnLoss";
import StockSummary from "../app/report/StockSummary";
import VatNominalView from "../app/report/VatGst/VatNominalView";
import VatReturnView from "../app/report/VatGst/VatReturnView";
import VatReturns from "../app/report/VatGst/VatReturns";
import Settings from "../app/settings";

import AddBankDetails from "../app/bank/cash-bank/components/addBank";
import EditJournal from "../app/journals/component/EditJournal";
import JournalDetails from "../app/journals/component/JournalDetails";
import TrialBalance from "../app/report/TrialBalance";

//sales invoice screens
import SalesInvoice from "../app/sales/sales-invoice";
import SaleInvoiceForm from "../app/sales/sales-invoice/invoiceForm";
import SaleInvoiceView from "../app/sales/sales-invoice/view";
//perfoma invoice screens
import SaleInvoiceProforma from "../app/sales/sales-proforma";
import ProformaInvoiceForm from "../app/sales/sales-proforma/invoiceForm";

import AgedCreditors from "../app/report/AgedCreditors";
import AgedDebtors from "../app/report/AgedDebtors";

import PurchaceInvoice from "../app/purchase/purchase-invoice";
import PurchaceInvoiceForm from "../app/purchase/purchase-invoice/component/invoiceForm";

import BankTransfer from "../app/bank/cash-bank/components/MoreOptions/bank-Transfer/BankTransfer";
import BankPurchasePayment from "../app/bank/cash-bank/components/MoreOptions/purchase-payment";
import BankSalesReciept from "../app/bank/cash-bank/components/MoreOptions/sales-reciept";
import PurchaseFixedAsset from "../app/product/product-asset";
import Proposal from "../app/proposal";
import ProposalForm from "../app/proposal/components/Form";
import PurchaseAsset from "../app/purchase/purchase-assets";
import PurchaseAssetForm from "../app/purchase/purchase-assets/purchaseAssetForm";
import PurchaseAssetViewPage from "../app/purchase/purchase-assets/viewPage";
import PurchaceDebitnotesForm from "../app/purchase/purchase-debitnotes/component/invoiceForm";
import StockMonth from "../app/report/StockSummary/stockmonth";
import CreditNotesForm from "../app/sales/sales-credit/CreditNotesForm";
import Edit from "../app/sales/sales-credit/Edit";
import ViewCreditNote from "../app/sales/sales-credit/ViewCreditNote";

import Reconcile from "../app/bank/cash-bank/components/Bankdetails/Reconcile";
import CustomerRefund from "../app/bank/cash-bank/components/MoreOptions/purchase-payment/customerRefund";
import OtherPayment from "../app/bank/cash-bank/components/MoreOptions/purchase-payment/otherPayment";
import SupplierPayment from "../app/bank/cash-bank/components/MoreOptions/purchase-payment/supplierPayment";
import CustomerReciept from "../app/bank/cash-bank/components/MoreOptions/sales-reciept/customerReciept";
import OtherReciept from "../app/bank/cash-bank/components/MoreOptions/sales-reciept/otherReciept";
import SupplierRefund from "../app/bank/cash-bank/components/MoreOptions/sales-reciept/supplierRefund";
import ViewPurchasePaymentScreens from "../app/bank/cash-bank/components/TransactionView/ViewPurchasePayment";
import AddCashDetails from "../app/cash/component/addCash";
import PayHead from "../app/ledger/ledger-PayHead";
import EditProposal from "../app/proposal/components/EditProposal";
import ProposalView from "../app/proposal/components/View";
import DebitNotInvoiceView from "../app/purchase/purchase-debitnotes/component/view";
import PurchaceInvoiceView from "../app/purchase/purchase-invoice/component/view";
import Stockbase from "../app/report/StockSummary/stockbase";
import PerfomaView from "../app/sales/sales-proforma/view";
import UserManual from "../app/user-manual/userManual";
import PaySheetView from "../app/payroll/paySheet/view";
import PayrollEmployeesViewPage from "../app/payroll/payrollEmployees/viewPage";
import ReccuringEdit from "../app/sales/reccuring-notification/edit";
import { useSelector } from "react-redux";
import AddPayHeadLedger from "../app/ledger/ledger-PayHead/components/addLedger";
import ViewLedger from "../app/ledger/view";

import Leaves from "../app/hr/leave";
import LeaveRequestView from "../app/hr/leave/view";
import Notifications from "../app/hr/notification";
// import Employee from "../app/hr";
// import Hr from "../app/hr";
// import HrEmployees from "../app/hr/hr-employees";
// import Employee from "../app/hr";
// import Hr from "../app/hr";

import HrEmployees from "../app/hr/hr-employees";
// import CreateHrEmployee from "../app/hr/hr-employees/create";
import HrDashboard from "../app/hr/hr-dashboard";

import HrProjects from "../app/hr/hr-projects";
// import HrTimesheet from "../app/hr/hr-timesheet";
import ViewHrProject from "../app/hr/hr-projects/view-projects";

import FormHrEmployee from "../app/hr/hr-employees/edit";
import EmployeeViews from "../app/hr/hr-employees/views";
import FormHrProject from "../app/hr/hr-projects/form";

import ViewHrTask from "../app/hr/hr-task/view-task";

import HrTimesheet from "../app/hr/hr-timesheet";
import Applyleave from "../app/hr/leave/form";
import ProjectPayments from "../app/hr/project payment";
import Client from "../app/hr/hr-client";
import UpdateTask from "../app/hr/hr-task/updatetask";
import FormHrTask from "../app/hr/hr-task/createfromtask";
import HrReport from "../app/hr/hr-report";
import Projectreport from "../app/hr/hr-report/projectreport";
import Salaryreport from "../app/hr/hr-report/salary Report";
import HrPayroll from "../app/hr/hr-payroll";
import Hr_Financeform from "../app/hr/hr-payroll/form";
import Financeview from "../app/hr/hr-payroll/view";

const { Sider } = Layout;
function Navigation() {
  const [collapsed, setCollapsed] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const direction = useSelector((state: any) => state.language.direction);

  return (
    <Layout className="Navigation-container" dir={direction}>
      <Sider
        width={260}
        collapsedWidth={70}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          zIndex: 1000,
          backgroundColor: "#fff",
          borderRight: "2px solid rgb(241, 241, 241)",
        }}
        collapsed={collapsed}
        className="Navigation-Sider"
      >
        <SiderBar collapsed={collapsed} />
      </Sider>
      <Layout
        className="Navigation-layout"
        style={{
          marginLeft: direction === "LTR" ? (collapsed ? 70 : 260) : 0,
          marginRight: direction === "RTL" ? (collapsed ? 70 : 260) : 0,
        }}
      >
        <Header
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
          notification={() => setShowNotification(!showNotification)}
          help={() => setShowHelp(!showHelp)}
        />
        <div>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />;
            {/* credit notes */}
            <Route path="/salesCredit" element={<CreditNotes />} />;
            <Route
              path="/salesCredit/screditform/:id"
              element={<CreditNotesForm />}
            />
            {/* <Route
              path="/salesCredit/details/:id"
              element={<ViewCreditNote />}
            /> */}
            <Route path="/salesCredit/edit/:id" element={<Edit />} />
            <Route path="/salesCredit/view/:id" element={<ViewCreditNote />} />
            <Route
              path="/sales-new-credit-notes"
              element={<SaleNewCreditNotes />}
            />
            ;
            <Route
              path="/sales-reccuring-notification"
              element={<RecoveryNotification />}
            />
            <Route
              path="/sales-reccuring-notification/edit/:id"
              element={<ReccuringEdit />}
            />
            <Route
              path="/purchase-debit-note"
              element={<PurchaseDebitNote />}
            />
            ;
            {/* <Route path="/purchase-debit-note/create" element={<PurchaseDebitNoteCreate />} />; */}
            <Route path="/purchaseInvoice" element={<PurchaseInvoice />} />;
            <Route path="/purchase-fore-assets" element={<PurchaseAsset />} />;
            <Route
              path="/purchase-fore-assets/view/:id"
              element={<PurchaseAssetViewPage />}
            />
            ;
            <Route
              path="/purchase-asset-form/:type/:id"
              element={<PurchaseAssetForm />}
            />
            ;
            <Route path="/product" element={<Product />} /> ;
            <Route path="/productNonStock" element={<ProductNonStock />} />;
            <Route path="/productService" element={<ProductService />} />;
            <Route path="/productStock" element={<ProductStock />} />;
            <Route
              path="/purchaseFixedAsset"
              element={<PurchaseFixedAsset />}
            />
            ;
            <Route
              path="/create-product/:service/:id"
              element={<ProductAdd />}
            />
            ;
            <Route path="/product-view/:type/:id" element={<ProductView />} />;
            <Route path="/contactSuppliers" element={<ContactSuppliers />} />;
            <Route
              path="/contactSuppliers/create"
              element={<CreateSupplier />}
            />
            ;
            <Route
              path="/contactSuppliers/details/:id"
              element={<SupplierDetails />}
            />
            ;
            <Route
              path="/contactSuppliers/edit/:id"
              element={<EditSupplier />}
            />
            ; ;
            <Route path="/contacts" element={<Contacts />} />;
            <Route path="/contactCustomers" element={<ContactCustomers />} />;
            <Route
              path="/contactCustomers/create"
              element={<CreateCutomer />}
            />
            ;
            <Route
              path="/contactCustomers/details/:id"
              element={<CustomerDetails />}
            />
            ;
            <Route
              path="/contactCustomers/edit/:id"
              element={<EditCustomerDetails />}
            />
            ;
            <Route path="/journal" element={<Journal />} />;
            <Route path="/CreateJournal" element={<CreateJournal />} />;
            <Route path="/journal/details/:id" element={<JournalDetails />} />;
            <Route path="/journal/edit/:id" element={<EditJournal />} />;
            <Route path="/cash" element={<Cash />} />;
            <Route path="/cashBank" element={<CashBank />} />;
            <Route path="/cashBank/:id/details/*">
              <Route index element={<Navigate to="transaction" replace />} />
              <Route path=":source" element={<BankDetails />} />
            </Route>
            <Route path="/cashBank/reconcile/:id" element={<ReconcilePage />} />
            ;
            <Route
              path="/cashBank/purchase-reciept/:id"
              element={<PurchasePaymentForm />}
            />
            <Route
              path="/cashBank/reconcile/:id/:status"
              element={<Reconcile />}
            />
            ;
            <Route
              path="/cashBank/bank-transfer/:id"
              element={<BankTransfer />}
            />
            <Route
              path="/cashBank/viewtransfer/:id"
              element={<ViewBankTransfer />}
            />
            ;
            <Route path="/report/sundryCreditors" element={<AgedCreditors />} />
            ;
            <Route path="/report/sundryDebtors" element={<AgedDebtors />} />;
            <Route path="/cashBank/reconcile/:id" element={<ReconcilePage />} />
            ;
            <Route
              path="/cashBank/purchase-reciept/:id"
              element={<PurchasePaymentForm />}
            />
            ;
            <Route
              path="/cashBank/bank-transfer/:id"
              element={<BankTransfer />}
            />
            ;
            <Route
              path="/cashBank/viewtransfer/:id"
              element={<ViewBankTransfer />}
            />
            ;
            <Route path="/cashBank/reconcile/:id" element={<ReconcilePage />} />
            <Route
              path="/cashBank/:id/details/banktransfer/:type"
              element={<BankTransfer />}
            />
            ;
            <Route path="/cashBank/addbank/:id" element={<AddBankDetails />} />
            <Route
              path="/cashBank/:id/details/salesreciept/:tab"
              element={<BankSalesReciept />}
            />
            <Route
              path="/cashBank/:id/details/salesreciept/customer/:update"
              element={<CustomerReciept />}
            />
            <Route
              path="/cashBank/:id/details/salesreciept/other/:update"
              element={<OtherReciept />}
            />
            <Route
              path="/cashBank/:id/details/salesreciept/supplier-refund/:update"
              element={<SupplierRefund />}
            />
            <Route
              path="/cashBank/:id/details/purchasepayment/:tab"
              element={<BankPurchasePayment />}
            />
            <Route
              path="/cashBank/:purchaseType/:id/details"
              element={<ViewPurchasePaymentScreens />}
            />
            <Route
              path="/cashBank/:id/details/purchasepayment/supplier/:update"
              element={<SupplierPayment />}
            />
            <Route
              path="/cashBank/:id/details/purchasepayment/other/:update"
              element={<OtherPayment />}
            />
            <Route
              path="/cashBank/:id/details/purchasepayment/customer/:update"
              element={<CustomerRefund />}
            />
            <Route path="/cashBank/addbank/:id" element={<AddBankDetails />} />
            <Route
              path="/cashBank/viewtransfer/:id"
              element={<ViewBankTransfer />}
            />
            ;
            <Route path="/cash/cashTable/:id" element={<CashTable />} />
            <Route path="/cash/addCash/:id" element={<AddCashDetails />} />
            <Route path="/cash/salesreceipt/:id/*">
              <Route
                index
                element={<Navigate to="customer-receipt" replace />}
              />
              <Route path=":source" element={<CustumerReceiptForm />} />
            </Route>
            <Route path="/cash/purchacepayment/:id/*">
              <Route
                index
                element={<Navigate to="supplier-payment" replace />}
              />
            </Route>
            <Route path="/cash/view/:id/:type" element={<CashView />} />
            <Route path="/cash/salesreceipt/:id/:type/*">
              <Route
                index
                element={<Navigate to="customer-receipt" replace />}
              />
              <Route path=":source" element={<CustumerReceiptForm />} />
            </Route>
            <Route path="/cash/purchacepayment/:id/:type/*">
              <Route
                index
                element={<Navigate to="supplier-payment" replace />}
              />
              <Route path=":source" element={<PurchasePaymentForm />} />
            </Route>
            <Route path="/report" element={<Report />} />;
            <Route path="/report/vatReturns" element={<VatReturns />} />;
            <Route path="/report/BalanceSheet" element={<BalanceSheet />} />;
            <Route path="/report/TrialBalance" element={<TrialBalance />} />;
            <Route
              path="/report/vatReturnView/:id/:sdate/:edate"
              element={<VatReturnView />}
            />
            ;
            <Route
              path="/report/vatNominalView/:id/:ledger/:sdate/:edate/:product"
              element={<VatNominalView />}
            />
            ;
            <Route path="/report/profitloss" element={<ProfitnLoss />} />;
            <Route path="/report/stockSummary" element={<StockSummary />} />;
            <Route
              path="/report/stockSummary/stockmonth/:id"
              element={<StockMonth />}
            />
            ;
            <Route
              path="/report/stockSummary/stockbase/:adminId/:id/:month"
              element={<Stockbase />}
            />
            ;
            <Route
              path="/ledgerDefaultCategory"
              element={<LedgerDefaultCategory />}
            />
            <Route path="/payHead" element={<PayHead />} />;
            <Route path="/payHead/:id" element={<AddPayHeadLedger />} />;
            <Route
              path="/ledgerDefaultLedger"
              element={<LedgerDefaultLedger />}
            />
            ;
            <Route path="/ledger-view/:id" element={<ViewLedger />} />;
            <Route path="/ledgerMyCategory" element={<LedgerMyCategory />} />;
            <Route path="/ledgerMyLedger" element={<LedgerMyLedger />} />;
            <Route path="/payroll" element={<PayRoll />} />;
            <Route path="/payroll/employees" element={<Employees />} />;
            <Route
              path="/payroll/employees/viewPage/:id"
              element={<PayrollEmployeesViewPage />}
            />
            ;
            <Route path="/payroll/form/:source/:id" element={<PayRollForm />} />
            <Route
              path="/payroll/paysheet-view/:id"
              element={<PaySheetView />}
            />
            <Route path="/payroll/paysheet" element={<PaySheet />} />;
            <Route path="/merchant" element={<Merchant />} />;
            <Route path="/settings/*">
              <Route index element={<Navigate to="customize" replace />} />
              <Route path=":source" element={<Settings />} />
            </Route>
            <Route path="/dashboard" element={<Dashboard />} />;
            <Route path="/profile">
              <Route index element={<Navigate to="general" replace />} />
              <Route path=":source" element={<Profile />} />
            </Route>
            <Route path="/enquiry" element={<Enquiry />} />
            {/*/ SALES INVOICE /*/}
            <Route path="/sales-invoice" element={<SalesInvoice />} />
            <Route path="sale-invoice-form/:id" element={<SaleInvoiceForm />} />
            <Route path="sale-invoice-view/:id" element={<SaleInvoiceView />} />
            {/*/ END SALES INVOICE /*/}
            {/* Proforma Invoice  */}
            <Route
              path="proforma-invoice-form/:id"
              element={<ProformaInvoiceForm />}
            />
            <Route
              path="/sales-proforma-invoice"
              element={<SaleInvoiceProforma />}
            />
            {/*/ PURCHACE INVOICE /*/}
            <Route path="/purchace-invoice" element={<PurchaceInvoice />} />
            <Route
              path="purchace-invoice-form/:id"
              element={<PurchaceInvoiceForm />}
            />
            <Route
              path="purchase-invoice-view/:id"
              element={<PurchaceInvoiceView />}
            />
            <Route
              path="purchace-debitnote-form/:id"
              element={<PurchaceDebitnotesForm />}
            />
            <Route
              path="purchace-debitnote-view/:id"
              element={<DebitNotInvoiceView />}
            />
            <Route path="sale-invoice-view/:id" element={<SaleInvoiceView />} />
            <Route path="perfoma-invoice-view/:id" element={<PerfomaView />} />
            {/*/ END SALES INVOICE /*/}
            {/*/ Proposal /*/}
            <Route path="proposal" element={<Proposal />} />
            <Route path="proposal/create" element={<ProposalForm />} />
            <Route path="proposal/edit/:id" element={<EditProposal />} />
            <Route path="proposal/details/:id" element={<ProposalView />} />
            <Route path="proposal/create" element={<ProposalForm />} />
            <Route path="user-manual" element={<UserManual />} />
            {/* NEW ----------------------- */}
            {/*/ HrEmployee /*/}
            <Route path="hr-employees" element={<HrEmployees />} />
            <Route path="hr-employees/edit/:id" element={<FormHrEmployee />} />
            <Route path="hr-employees/view/:id" element={<EmployeeViews />} />
            {/*/ HrProject /*/}
            <Route path="hr" element={<HrDashboard />} />
            <Route path="hr-projects" element={<HrProjects />} />
            <Route path="hr-leave" element={<Leaves />} />
            <Route path="hr-leave-details/:id" element={<LeaveRequestView />} />
            <Route path="hr-projects" element={<HrProjects />} />
            <Route path="hr-dashboard" element={<HrDashboard />} />
            <Route
              path="hr-employees/details/:id"
              element={<EmployeeViews />}
            />
            {/*/ HrProject /*/}
            <Route path="hr" element={<HrDashboard />} />
            <Route path="hr-projects" element={<HrProjects />} />
            <Route path="hr-leave" element={<Leaves />} />
            <Route
              path="hr-leave-details/:id/"
              element={<LeaveRequestView />}
            />
            <Route path="hr-applyleave" element={<Applyleave />} />
            <Route path="hr-notification" element={<Notifications />} />
            <Route path="hr-projects" element={<HrProjects />} />
            <Route path="hr/hr-projects" element={<HrProjects />} />
            <Route path="hr-dashboard" element={<HrDashboard />} />
            <Route path="hr-projects/details/:id" element={<ViewHrProject />} />
            <Route path="hr-projects/:type/:id" element={<FormHrProject />} />
            <Route path="hr-task/create/:proId/:id" element={<FormHrTask />} />
            <Route path="hr-task/edit/:proId/:id" element={<UpdateTask />} />
            {/* `/usr/hr-task/edit/${props?.proId}/${data.id}` */}
            <Route path="hr-task/details/:id" element={<ViewHrTask />} />
            <Route path="hr-timesheet" element={<HrTimesheet />} />
            <Route path="projectpayments" element={<ProjectPayments />} />
            <Route path="hr-client" element={<Client />} />
            <Route path="hr-report" element={<HrReport />} />
            <Route path="hr-report/projectreport" element={<Projectreport />} />
            <Route path="hr-report/salaryreport" element={<Salaryreport />} />
            <Route path="hr-payroll" element={<HrPayroll />} />
            <Route
              path="hr-payroll/hr_finance/create"
              element={<Hr_Financeform />}
            />
            <Route path="hr-finance-details/:id/" element={<Financeview />} />
          </Routes>
        </div>
      </Layout>
      {showNotification ? (
        <Drawer
          title="NOTIFICATIONS"
          placement={"right"}
          width={400}
          onClose={() => setShowNotification(false)}
          open={showNotification}
          zIndex={700}
          mask={false}
        >
          <NotificatonBox />
        </Drawer>
      ) : null}
      {showHelp ? (
        <Drawer
          title="HELP & SUPPORT"
          placement={"right"}
          width={400}
          onClose={() => setShowHelp(false)}
          open={showHelp}
          zIndex={700}
          mask={false}
        >
          <HelpBox closeDrawer={() => setShowHelp(false)} />
        </Drawer>
      ) : null}
    </Layout>
  );
}

export default Navigation;
