import "./styles.scss";
import WebsiteHeader from "../../components/websiteHeader";
import WebsiteFooter from "../../components/websiteFooter";
import { Container, Col, Row } from "react-bootstrap";
import { LiaStoreSolid } from "react-icons/lia";

import API from "../../config/api";
import { useEffect, useState } from "react";
import { GET } from "../../utils/apiCalls";
import { useDispatch } from "react-redux";
import { storeBusinessCategory } from "../../redux/slices/businessCategorySlice";
import { storeCountries } from "../../redux/slices/countriesSlice";
import taxgologo from "../../assets/images/logo2.png";
import { useNavigate } from "react-router-dom";
import Features from "./features";
import TaxgoSystem from "./taxgoSystem";
import Integration from "./integration";
import StayConnected from "./stayConnected";
import DriveUs from "./driveUs";
import ConsultingForm from "../consulting/component/ConsultingForm";
import { withTranslation } from "react-i18next";

function Home(props: any) {
  const navigation = useNavigate();

  const { t } = props;
  return (
    <div className="website-screens">
      <WebsiteHeader />
      <div className="website-Mainbox">
        <div className="website-HomeBox1">
          <Container>
            <Row>
              <Col sm={6} xs={12}>
                <div className="website-HomeBox2">
                  <div className="website-HomeBoxline">
                    <div className="website-HomeBoxItem">
                      <LiaStoreSolid />
                    </div>
                  </div>
                  <br />
                  <div className="website-HomeBoxtxt1">
                    {t("home_page.homepage.simple") +
                      ",  " +
                      t("home_page.homepage.best") +
                      "  &  " +
                      t("home_page.homepage.affordable")}
                    <br />
                    {t("home_page.homepage.accounting") +
                      "  " +
                      t("home_page.homepage.software") +
                      "  " +
                      t("home_page.homepage.solution")}
                  </div>
                  <div className="website-HomeBoxtxt1"></div>
                  <br />
                  <img src={taxgologo} className="website-Homelogo" />
                  <br />
                  <Row>
                    <Col sm={3} xs={6}>
                      <button
                        onClick={() => navigation("/contact")}
                        className="website-HomeBoxBtn1"
                      >
                        {t("home_page.homepage.contact_us")}
                      </button>
                    </Col>
                    <Col sm={4} xs={6}>
                      <button
                        onClick={() => navigation("/signup")}
                        className="website-HomeBoxBtn2"
                      >
                        {t("home_page.homepage.Create Account")}
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col sm={6}>
                <div className="website-HomeBox2">
                  <br />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Features />
        <TaxgoSystem />
        <br />
        <br />
        <Integration />
        <br />
        <br />
        <StayConnected />
        <br />
        <br />
        <DriveUs />
        <br />
        <br />
        <br />
        <ConsultingForm />
      </div>
      <WebsiteFooter />
    </div>
  );
}

export default withTranslation()(Home);
