import "../styles.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { GET } from "../../../utils/apiCalls";
import Edit from "./edit";
import API from "../../../config/api";
import CreatePurchaseAsset from "./create";

function PurchaseAssetForm(props: any) {
  const { type: id, id: type }: any = useParams();
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const [ledgers, setLedgers] = useState([]);
  const [product, setProduct] = useState([]);
  const [banks, setBanks] = useState([]);
  const [details, setDetails] = useState({});
  const [taxList, setTaxlist] = useState([]);
  const [purchace, setPurchace] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getLedgers();
    loadTaxList();
    getBankList();
    if (id !== "create") {
      getDetails();
    }
  }, []);

  useEffect(() => {
    setTimeout(()=>{
      getSuppliers();
    },500)
  }, [searchQuery]);

  const getSuppliers = async () => {
    try {
      let url = API.GET_ALL_LISTS_CONTACTMASTER + adminid;
      const { data: customer }: any = await GET(url, null);
      setSupplier(customer);
    } catch (error) {
      console.log(error);
    }
  };

  const getLedgers = async () => {
    try {
      let url = API.GET_FIXED_ASSET_LEDJERS + user.adminid;
      const response: any = await GET(url, null);
      setLedgers(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getProduct = async (val: any) => {
    let allLedgers = await getLedgers();
    const nominalCode: any =
      allLedgers &&
      allLedgers.length &&
      allLedgers.find((item: any) => item.id === val);
    try {
      let url = API.GET_AACCOUNT_BYID + adminid + `/${nominalCode.nominalcode}`;
      const data: any = await GET(url, null);
      setProduct(data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadTaxList = async () => {
    try {
      let URL = API.TAX_MASTER + user.adminid +'/all';
      const data :any = await GET(URL, null);
      setTaxlist(data);
    } catch (error) {
      console.log(error);
    }
  };
  const getBankList = async () => {
    try {
      let url = "account_master/getBankList/" + adminid;
      const { data }: any = await GET(url, null);
      setBanks(data.bankList);
    } catch (error) {
      console.log(error);
    }
  };
  const getDetails = async () => {
    try {
      let invoiceurl = API.PURCHASE_SUPPLIER_LIST + id + "/stockassets";
      const { data: purchaceDeatails }: any = await GET(invoiceurl, null);
      setPurchace(purchaceDeatails);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {type === "0" ? (
        <CreatePurchaseAsset
          customers={supplier}
          ledgers={ledgers}
          banks={banks}
          adminid={adminid}
          product={product}
          taxList={taxList}
          getProduct={(val: any) => getProduct(val)}
          customerSearch={(val: any) => setSearchQuery(val)}
        />
      ) : (
        <Edit
          customers={supplier}
          ledgers={ledgers}
          banks={banks}
          adminid={adminid}
          product={product}
          taxList={taxList}
          details={details}
          purchace={purchace}
          id={id}
          getProduct={(val: any) => getProduct(val)}
          customerSearch={(val: any) => setSearchQuery(val)}
        />
      )}
      <br />
    </div>
  );
}
export default PurchaseAssetForm;
