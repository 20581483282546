import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Pagination,
  Row,
  Table,
  TableProps,
  notification,
} from "antd";
import { Col, Container } from "react-bootstrap";
import API from "../../../../config/api";
import { POST } from "../../../../utils/apiCalls";
import Meta from "antd/es/card/Meta";

interface DataType {
  employeeId: number;
  name: string;
  salary: number;
  in_amount: number;
  out_amount: number;
  salaryId: number;
}

const DataTable = (props: any) => {
  const [selectedEmployee, setSelectedEmployee] = useState<string>("");
  const [selectedSalary, setSelectedSalary] = useState<string>("");
  const [isLoadibng, setIsLoading] = useState(false);
  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Salary",
      dataIndex: "salary",
      key: "salary",
    },
    {
      title: "In Amount",
      dataIndex: "in_amount",
      key: "in_amount",
    },
    {
      title: "Out Amount",
      dataIndex: "out_amount",
      key: "out_amount",
    },
    {
      title: "Total Amount",
      render: (_: any, record: any) => (
        <span>{record.salary + (record.out_amount - record.in_amount)}</span>
      ),
    },
  ];

  const submitHandler = async (values: any) => {
    try {
      setIsLoading(true);
      let api = API.POST_HR_REPORT_PAY;
      let postRequest: any = await POST(api, []);
      if (postRequest.status) {
        notification.success({ message: " create payroll" });
        setIsLoading(false);
      } else {
        notification.error({ message: "something went wrong" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const filteredDataSource = props?.dataSource?.projects?.filter(
    (item: any) => {
      return (
        (!selectedEmployee || item.employeeId === parseInt(selectedEmployee)) &&
        (!selectedSalary || item.salaryId === parseInt(selectedSalary))
      );
    }
  );

  return (
    <Container>
      <br />
      <Card
        title={
          <>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5 style={{ margin: 0 }}>Salary Report</h5>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={submitHandler}
              >
                Generate Pay Slip
              </Button>
            </div>
            <br />
            <div className="" style={{ gap: 10 }}>
              <Row>
                <Col md="4">
                  <Card>
                    <Meta
                      title="TOTAL IN"
                      description={props?.dataSource?.totals?.total_in ?? 0}
                    />
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <Meta
                      title="TOTAL OUT"
                      description={props?.dataSource?.totals?.total_out ?? 0}
                    />
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <Meta
                      title="TOTAL SALARY"
                      description={props?.dataSource?.totals?.grand_total ?? 0}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
            <br />
          </>
        }
      >
        <Table
          columns={columns}
          dataSource={props?.dataSource?.projects}
          pagination={false}
        />
        <Pagination
          total={props?.meta?.total_count}
          pageSize={props?.meta?.take}
          showTotal={(total) => `Total ${total} salary`}
          onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
          current={props?.meta?.current_page}
          style={{ textAlign: "center", marginTop: "20px" }}
        />
      </Card>
    </Container>
  );
};

export default DataTable;
