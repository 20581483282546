import React, { useEffect, useState } from "react";
import { GET } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { useSelector } from "react-redux";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles.scss";
import Table from "../components/table";
import ExcelImport from "../../../components/ExcelImport";
import { Button, Col, Tooltip } from "antd";
import { SiMicrosoftexcel } from "react-icons/si";
import { useTranslation } from "react-i18next";

const ProductStock = () => {
  const { t } = useTranslation();
  const columns = [
    {
      name: "icode",
      title: `${t("home_page.homepage.Code")}`,
      dataType: "string",
      alignment: "center",
    },
    {
      name: "idescription",
      title: `${t("home_page.homepage.Item_Name")}`,
      dataType: "string",
      alignment: "center",
    },
    {
      name: "itemtype",
      title: `${t("home_page.homepage.Type")}`,
      alignment: "center",
    },
    {
      name: "product_category",
      title: `${t("home_page.homepage.Product_Category")}`,
      dataType: "string",
      alignment: "center",
    },
    {
      name: "stock",
      title: `${t("home_page.homepage.Quantity_in_Stock")}`,
      dataType: "number",
      alignment: "center",
      cellRender: ({ data }: any) => Number(data?.quantity) + Number(data?.stockquantity)
    },
    {
      name: "unit",
      title: `${t("home_page.homepage.Unit")}`,
      dataType: "string",
      alignment: "center",
    },
    {
      name: "c_price",
      title: `${t("home_page.homepage.Rate")}`,
      dataType: "string",
      alignment: "center",
    },
    {
      name: "location",
      title: `${t("home_page.homepage.Location")}`,
      dataType: "string",
      alignment: "center",
    },
    {
      name: "vat",
      title: `${t("home_page.homepage.VAT")}`,
      dataType: "string",
      alignment: "center",
    },
  ];
  let template =
    "https://taxgo.s3.eu-west-1.amazonaws.com/excelTemplates/Product-StockSampleTemplate.xlsx";
  const { user } = useSelector((state: any) => state.User);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [excelModal, setExcelModal] = useState(false);

  const adminid = user?.adminid;

  useEffect(() => {
    loadData(page, take);
  }, [page, take]);

  const loadData = async (page: any, take: any) => {
    setIsLoading(true);
    let product_url =
      API.PRODUCT_MASTER_USER +
      `Stock/${adminid}?order=DESC&page=${page}&take=${take}`;
    const { data }: any = await GET(product_url, null);
    setData(data);
    setIsLoading(false);
  };

  const onPageChange = (page: any, take: any) => {
    setPage(page);
    setTake(take);
  };

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        firstPathLink={location.pathname.replace("/create", "")}
        secondPathLink={location?.pathname}
        secondPathText={t("home_page.homepage.Product_Stock_List")}
        goBack={"/usr/productStock"}
        title={t("home_page.homepage.List_All_Stocks")}
      >
        <div>
        <Tooltip title="Import from Excel">
          <Button onClick={() => setExcelModal(true)}>
            <SiMicrosoftexcel size={20} />
          </Button>
          </Tooltip>
          &nbsp;
          <Button
            type="primary"
            onClick={() => navigate("/usr/create-product/Stock/create")}
          >
            + Add Stock
          </Button>
        </div>
      </PageHeader>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Table
          products={data}
          columns={columns}
          take={take}
          onItemSelect={() => {}}
          onPageChange={(p: any, t: any) => onPageChange(p, t)}
          onSuccess={() => loadData(page, take)}
          title={t("home_page.homepage.Stock")}
        />
      )}
      {excelModal ? (
        <ExcelImport
          visible={excelModal}
          onCancel={() => setExcelModal(false)}
          onSucess={() => loadData(page, take)}
          URL={API.ADD_PRODUCT_VIAEXCEL}
          template={template}
          type={"Stock"}
        />
      ) : null}
    </>
  );
};

export default ProductStock;
