import "../styles.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

import Create from "./create";
import Edit from "./edit";
import { useSelector } from "react-redux";
import { GET } from "../../../utils/apiCalls";
import API from "../../../config/api";

function SaleInvoiceForm(props: any) {
  const { id }: any = useParams();
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const [isLoading, setIsLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [ledgers, setLedgers] = useState([]);
  const [product, setProduct] = useState([]);
  const [banks, setBanks] = useState([]);
  const [taxList, setTaxlist] = useState([]);
  const [reccuring,setReccuring] = useState([])
  const [customerName, setCustomerName] = useState("");

  

  useEffect(() => {
    getCustomers();
    getLedgers();
    loadTaxList();
    getBanks();
    getBankList();
    getReccuring();
  }, []);
  useEffect(() => {
    setTimeout(()=>{
      getCustomers();
    },500)
  }, [customerName]);

  const getCustomers = async () => {
    try {
      let customerapi =
        "contactMaster/searchList/both/" + adminid + `?name=${customerName}`;
      const { data: customer }: any = await GET(customerapi, null);
      setCustomers(customer);
    } catch (error) {}
  };
  const getLedgers = async () => {
    try {
      let url = "account_master/defualt-ledger/sales/" + adminid;
      const { data: bank }: any = await GET(url, null);
      const filterData = bank.filter((item: any) => {
        return (
          item.nominalcode === "4000" ||
          item.nominalcode === "4001"
         //  || item.nominalcode === "4002"
        );
      });
      setLedgers(filterData);
    } catch (error) {}
  };
  const getBankList = async () => {
    try {
      let url = "account_master/getBankList/" + adminid;
      const { data }: any = await GET(url, null);
      setBanks(data.bankList);
    } catch (error) {}
  };

  const getProduct = async (val: any) => {
    try {
      let type = val.length ? val + "/" : "";
      let productuul = `ProductMaster/user/${type}${adminid}`;
      const { data: products }: any = await GET(productuul, null);
      setProduct(products);
    } catch (error) {}
  };
  const loadTaxList = async () => {
    try {
      let URL = API.TAX_MASTER + user.adminid +'/all';
      const data :any = await GET(URL, null);
      setTaxlist(data);
    } catch (error) {}
  };
  const getReccuring = async ()=>{
    try{
      let URL = API.GET_RECCURING + id
      const {data}: any = await GET(URL,null)
      setReccuring(data)
    } catch(error){}
  }

  const getBanks = () => {};

  return (
    <div>
      {id === "0" ? (
        <Create
          customers={customers}
          ledgers={ledgers}
          banks={banks}
          adminid={adminid}
          product={product}
          taxList={taxList}
          customerName={(val: any) => setCustomerName(val)}
          getProduct={(val: any) => getProduct(val)}
        />
      ) : (
        <Edit
          customers={customers}
          ledgers={ledgers}
          banks={banks}
          adminid={adminid}
          product={product}
          taxList={taxList}
          reccuring = {reccuring}
          customerName={(val: any) => setCustomerName(val)}
          id={id}
          getProduct={(val: any) => getProduct(val)}
        />
      )}
      <br />
    </div>
  );
}
export default SaleInvoiceForm;
