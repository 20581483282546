import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  message,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import API from "../../../../../../config/api";
import { GET, POST, PUT } from "../../../../../../utils/apiCalls";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import PageHeader from "../../../../../../components/pageHeader";

function OtherReceipt() {
  const { user } = useSelector((state: any) => state.User);
  const { id, update } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [customerst, setCustomer] = useState([]);
  const [isBtLoading, setIsBtLoading] = useState<any>(false);
  const [amount, setAmount] = useState<any>();
  const [searchQurey, setSearchQurey] = useState<any>("");
  const [detail, setDetail] = useState<any>();
  const [initialLedgerData, setInitialLedgerData] = useState<any>();

  const fetchEntries = async () => {
    try {
      const url = API.GET_ALL_ENTRIES + user.adminid + `?name=${searchQurey}`;
      const { data }: any = await GET(url, null);
      setCustomer(data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelect = async (val: any) => {
    let url = API.GET_CONTACT_MASTER_LIST + user?.adminid + `/${val}`;
    try {
      const data: any = await GET(url, null);
      setDetail(data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLedgerDetails = async () => {
    try {
      form.setFieldsValue({
        sdate: moment(new Date()),
      });
      let URL = API.LEDGER_DEATAILS + update;

      const data: any = await GET(URL, null);
      setInitialLedgerData(data?.data);
      form.setFieldsValue({
        cname: data?.data?.name,
        sdate: dayjs(data?.data?.sdate),
        reference: data?.data?.reference,
        payment_mode: data?.data?.paidmethod,
        amount: data?.data?.debit,
        Details: data?.data?.details,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(()=>{
      fetchEntries();
    },500)
  }, [searchQurey]);

  useEffect(() => {
    fetchLedgerDetails();
  }, []);

  const onFinish = async (val: any) => {
    try {
      let cnamValue;
      try {
        cnamValue = JSON.parse(val.cname);
      } catch (error) {
        cnamValue = val.cname;
      }
      const data = [
        {
          id: {},
          ledger: {},
          laccount: "",
          details: "",
          amount: 0,
          vatamt: 0,
          total: 0,
          vat: 0,
        },
      ];

      setIsBtLoading(true);
      let reqObjPost = {
        userid: user.adminid,
        item: data,
        amount: parseInt(val?.amount),
        [cnamValue.name]: cnamValue.id,
        paidto: Number(id),
        paidmethod: val?.payment_mode,
        sdate: val?.sdate,
        reference: val?.reference,
        receipttype: "Other Receipt",
        adminid: user.adminid,
        logintype: "user",
        userdate: val?.sdate,
        booleantype: cnamValue.name === "cname" ? "7" : "97",
      };

      const amt = Number(initialLedgerData?.credit) - Number(val?.amount);
      const running = Number(initialLedgerData?.running_total) - Number(amt);

      let reqObjPut = {
        [cnamValue.name ? cnamValue.name : null]: cnamValue?.id
          ? cnamValue?.id
          : null,
        debit: Number(val.amount),
        running_total: running,
        reference: val?.reference,
        sdate: val?.sdate,
        amount: parseInt(val?.amount),
        booleantype: cnamValue?.id
          ? cnamValue.name === "cname"
            ? "7"
            : "97"
          : null,
      };
      let obj = update ? reqObjPut : reqObjPost;
      let URL = update
        ? API.UPDATE_BANK_DETAILS + update
        : API.ADD_OTHER_RECEIPT;
      let METHOD = update ? PUT : POST;
      const response: any = await METHOD(URL, obj);
      if (response.status) {
        notification.success({
          message:"Success" ,description: update  ? "Other receipt updated successfully" : "Other receipt created successfully"
        });
        setIsBtLoading(false);
        navigate(`/usr/cashBank/${id}/details/transaction`);
      } else {
        setIsBtLoading(false);
        notification.error({
          message:"Failed" ,description: update ? "Failed to update other receipt" : "Failed to create other receipt"
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message:"Server Error" ,description: update ? "Failed to update other receipt!! Please try again later " : "Failed to create other receipt!! Please try again later"
      });
    }
  };
  let paymentMode = [
    { id: 1, name: "Cheque", value: "Cheque" },
    { id: 2, name: "Electronic", value: "Electronic" },
    { id: 3, name: "Credit Card", value: "Credit Card" },
    { id: 4, name: "Debit Card", value: "Debit Card" },
    { id: 5, name: "PayPal", value: "Paypal" },
  ];

  return (
    <>
      {update && (
        <>
          <PageHeader
            title="Payment"
            firstPathLink={"/usr/cashBank"}
            firstPathText={"Bank"}
            secondPathLink={`/usr/cashBank/${id}/details`}
            secondPathText={"Bank Details"}
            thirdPathLink={`/usr/cashBank/${id}/details/reciept/customer`}
            thirdPathText={"payment"}
            // goback={() => navigate(`/usr/cashBank/${id}/details/transaction`)}
          />
          <br />
        </>
      )}
      <Container>
        <Card>
          <Form onFinish={onFinish} form={form}>
            <Row>
              <Col className="Table-Txt" md={12}>
                {update ? "Update" : "Add"} Other Receipt
              </Col>
              <Col md={12}>
                Manage your non invoice receipts to customer - payment with
                ledgers
              </Col>
              <br />
              <br />
              <hr />
              <Col md={4}>
                <div className="formItem">
                  <label className="formLabel">ACCOUNT NAME</label>
                  <Form.Item
                    name="cname"
                    rules={[
                      {
                        required: true,
                        message: "Please Select a Suplier Name",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      onChange={handleSelect}
                      onSearch={(val) => setSearchQurey(val)}
                      showSearch
                      filterOption={false}
                    >
                      {customerst
                        ?.filter(
                          (item: any) =>
                            item?.name
                              ?.toLowerCase()
                              .includes(searchQurey.toLowerCase()) ||
                            item?.laccount
                              ?.toLowerCase()
                              .includes(searchQurey.toLowerCase())
                        )
                        .map((item: any) => {
                          let obj = {
                            name: item?.laccount
                              ? "ledger"
                              : item?.name
                              ? "cname"
                              : null,
                            id: item.id,
                          };
                          let stringObj = JSON.stringify(obj);
                          return (
                            <Select.Option value={stringObj} key={item.id}>
                              {item.bus_name || item?.laccount}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <br />
              <Col md={4}>
                <div>
                  <label className="formLabel">Payment Method</label>
                  <Form.Item
                    name={"payment_mode"}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select size="large">
                      {paymentMode?.map((item: any) => {
                        return (
                          <Select.Option key={item?.value}>
                            {item?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col md={4}>
                <div className="formItem">
                  <label className="formLabel">REFERENCE</label>
                  <Form.Item name="reference">
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col md={4}>
                <div className="formItem">
                  <label className="formLabel">RECEIPT DATE</label>
                  <Form.Item
                    name="sdate"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a Refund Date",
                      },
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} size="large" />
                  </Form.Item>
                </div>
              </Col>{" "}
              <Col md={4}>
                <div className="formItem">
                  <label className="formLabel">AMOUNT PAID *</label>
                  <Form.Item
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a Amount Paid",
                      },
                    ]}
                  >
                    <InputNumber
                      onChange={(val: any) => setAmount(val)}
                      type="number"
                      controls={false}
                      style={{ width: "100%" }}
                      size="large"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col md={8}></Col>
              {(update || amount) && (
                <Col md={4}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                    loading={isBtLoading}
                  >
                    {update ? "UPDATE" : "SAVE"}
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </Card>
      </Container>
    </>
  );
}

export default OtherReceipt;
