import { useEffect, useState } from "react";
import NoImg from "../../../assets/images/download.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../components/pageHeader";
import { FaPlus } from "react-icons/fa";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Button, Card, Popconfirm, notification } from "antd";
import { HiPencil } from "react-icons/hi2";
import { RiDeleteBinLine } from "react-icons/ri";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import UpdateStock from "./updateStock";
import LoadingBox from "../../../components/loadingBox";
import Barcode from "react-barcode";

const View = (props: any) => {
  const [viewData, setformdata] = useState<any>([]);
  const { type, id } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const data = props.data?.data;

  const showModal = () => {
    setModalVisible(true);
  };
  const handleOk = () => {
    setModalVisible(false);
  };
  const handleCancel = () => {
    setModalVisible(false);
  };
  const onEdit = () => {
    navigate(`/usr/create-product/${viewData?.itemtype}/${viewData?.id}`);
  };

  const Delete = async () => {
    let url = API.DELETE_PRODUCT + id;
    const res: any = await GET(url, null);
    if (res.status) {
      notification.success({message:"Success",description:"Successfully deleted"});
      navigate("/usr/productStock");
    }
  };

  const loadProductById = async () => {
    try {
      setIsLoading(true);
      let URL = API.GET_PRODUCT_MASTER_BY_ID + id;
      const { data }: any = await GET(URL, null);
      setformdata(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error)
      setIsLoading(false);
    }
   
  };

  useEffect(() => {
    loadProductById();
  }, []);
  return (
    <div>
      <PageHeader
        firstPathText="Product View"
        firstPathLink="/usr/productNonStock"
        secondPathText=""
        secondPathLink={location?.pathname}
        buttonTxt=""
        goback="/usr/productNonStock/product-view"
        title="Product Details"
      >
        <div>
          <Button type="primary" onClick={showModal}>
            + Update Stock
          </Button>
        </div>
      </PageHeader>
      <br />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Container>
          <Card>
            <Row>
              <Col md={4} xs={12}>
                <img
                  className="product-View-Img1"
                  src={viewData?.pimage ? viewData?.pimage : NoImg}
                  alt=""
                />
                <br /> <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Button onClick={onEdit} block>
                    <HiPencil
                      size={18}
                      color="#14839c"
                      style={{ paddingRight: "5px" }}
                    />
                    Edit
                  </Button>
                  <Popconfirm
                    title="Delete"
                    description="Are you sure to delete ?"
                    onConfirm={() => Delete()}
                    placement="bottom"
                  >
                    <Button block>
                      <RiDeleteBinLine
                        size={20}
                        color="#ed2828"
                        style={{ paddingRight: "5px" }}
                      />
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
                <br />
                <Card className="Normal-Font">
                  {viewData?.idescription}
                  <br />
                  In Stock: {Number(viewData?.stock) + Number(viewData?.stockquantity)}
                  {""} {viewData?.unit}
                </Card>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: 10,
                    }}
                  >
                    <Barcode value={viewData?.barcode} />
                  </div>
                </Card>
              </Col>
              <Col md={8} xs={12}>
                <h3>{viewData?.idescription}</h3>
                <br />
                <Table responsive bordered size="small">
                  <tbody>
                    <tr>
                      <th>Code:</th>
                      <td>{viewData?.icode}</td>
                    </tr>
                    <tr>
                      <th>Barcode:</th>
                      <td>{viewData?.barcode}</td>
                    </tr>
                    <tr>
                      <th>Sales Price:</th>
                      <td> {viewData?.sp_price || viewData?.rate}</td>
                    </tr>
                    <tr>
                      <th>Item Type :</th>
                      <td>{viewData?.itemtype}</td>
                    </tr>
                    <tr>
                      <th>Product_category:</th>
                      <td> {viewData?.product_category}</td>
                    </tr>
                    <tr>
                      <th>Sales price:</th>
                      <td> {viewData?.c_price}</td>
                    </tr>
                    <tr>
                      <th>Costprice:</th>
                      <td> {viewData?.costprice}</td>
                    </tr>
                    <tr>
                      <th>Wholesale:</th>
                      <td> {viewData?.wholesale}</td>
                    </tr>
                    <tr>
                      <th>Reorder Level:</th>
                      <td> {viewData?.rlevel}</td>
                    </tr>
                    <tr>
                      <th>Reorder Quantity:</th>
                      <td> {viewData?.rquantity}</td>
                    </tr>
                    <tr>
                      <th>Vat%:</th>
                      <td>{viewData?.vat} </td>
                    </tr>
                    <tr>
                      <th>Vatamt</th>
                      <td> {viewData?.vatamt}</td>
                    </tr>
                    <tr>
                      <th>Location </th>
                      <td> {viewData?.location}</td>
                    </tr>
                    <tr>
                      <th>Notes</th>
                      <td> {viewData?.notes}</td>
                    </tr>
                    <tr>
                      <th>Opening Quantity</th>
                      <td> {viewData?.stockquantity}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        </Container>
      )}
      <UpdateStock
        modalVisible={modalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        stock={viewData}
        loadProductById={loadProductById}
      />
      <br />
    </div>
  );
};

export default View;
