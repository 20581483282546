import { Button, Card, notification, Popconfirm } from "antd";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import { useTranslation } from "react-i18next";

function AccountData(props: any) {
  const { user } = useSelector((state: any) => state.User);
  const { t } = useTranslation();
  const clearUserData = async () => {
    try {
      let url = API.CLEAR_USER_DATA + user?.id;
      const response: any = await GET(url, null);
      if (response.status) {
        notification.success({
          message: `${t("home_page.homepage.success")}`,
          description: response.message,
        });
      } else {
        notification.error({
          message: `${t("home_page.homepage.failed")}`,
          description: `${t("home_page.homepage.Failed_to_Clear_User_Data")}`,
        });
      }
      
    } catch (error) {
      console.log(error)
      notification.error({
        message: `${t("home_page.homepage.server_error")}`,
        description: `${t("home_page.homepage.Failed_to_Clear_User_Data")}`,
      });
    }
  
  };

  return (
    <Container>
      <Card>
        <div className="subscription-Txt2">{t("home_page.homepage.Clear_Data")}</div>
        <br />
        <div style={{color:"red"}}>{t("home_page.homepage.Once_you_delete")}</div>
        <br/>
        <div>
        {t("home_page.homepage.This_will_permanently")}
        </div>
        <br />
        <Popconfirm
          title={t("home_page.homepage.Do_you_want_to")}
          onConfirm={clearUserData}
        >
          <Button danger size="large">{t("home_page.homepage.Delete_My_Accouting_Data")}</Button>
        </Popconfirm>
      </Card>
    </Container>
  );
}

export default AccountData;
