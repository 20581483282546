import React, { useEffect, useState } from "react";
import PageHeader from "../../../../components/pageHeader";
import { useParams } from "react-router-dom";
import BankDetails from "./Bankdetails";
import BankTable from "./table";
import { useSelector } from "react-redux";
import moment from "moment";
import { GET } from "../../../../utils/apiCalls";
import API from "../../../../config/api";
import { Container } from "react-bootstrap";
import { Card } from "antd";
import LoadingBox from "../../../../components/loadingBox";

function ReconcilePage() {
  const { id } = useParams();
  const { user } = useSelector((state: any) => state.User);
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>("2000-12-12");
  const [endDate, setEndDate] = useState<any>(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const columns = [
    {
      name: "date",
      title: "Date",
      dataType: "date",
      alignment: "center",
      format: "dd-MM-yyyy",
    },
    {
      name: "customer",
      title: "Particulars",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "reference",
      title: "Reference",
      dataType: "string",
      alignment: "center",
      cellRender: "Reference",
    },
    {
      name: "type",
      title: "Account Type",
      dataType: "string",
      alignment: "center",
    },
    {
      name: "amount",
      title: "Total",
      dataType: "number",
      alignment: "center",
    },
    {
      name: "debit",
      title: "Debit",
      dataType: "number",
      alignment: "center",
    },
    {
      name: "credit",
      title: "Credit",
      dataType: "number",
      alignment: "center",
    },
    {
      name: "runningTotal",
      title: "Running Total",
      dataType: "number",
      alignment: "center",
    },
  ];

  return (
    <div>
      <PageHeader
        firstPathLink={"/usr/cashBank"}
        firstPathText={"Bank"}
        secondPathLink={`/usr/cashBank/${id}/details`}
        secondPathText={"Bank Details"}
        thirdPathLink={`/usr/cashBank/reconcile/${id}`}
        thirdPathText={"Reconcile"}
        goback={-1}
        title="Bank Reconciled"
      />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Container>
          <Card>
            <BankTable columns={columns} products={data} type={"reconiled"} />
          </Card>
        </Container>
      )}
    </div>
  );
}

export default ReconcilePage;
