import { Button, Card, Form, Input, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "../../../components/loadingBox";
import API from "../../../config/api";
import { GET, PUT } from "../../../utils/apiCalls";
import { update } from "../../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import PrefixSelector from "../../../components/prefixSelector";

const { Option } = Select;
function Business(props: any) {
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.User);
  const [isLoading, setIsLoading] = useState(false);
  const [businessCategory, setBusinessCategory] = useState([]);
  const [bankList, setBankList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    GetBusinessCategory();
    fetchBankList();
  }, []);
  const fetchBankList = async () => {
    try {
      setIsLoading(true);
      let bank_list_url = API.GET_BANK_LIST + user.id;
      const { data }: any = await GET(bank_list_url, null);
      const filteredBank = data.bankList.filter(
        (item: any) =>
          item?.list?.acctype === "savings" ||
          item?.list?.acctype === "current" ||
          item?.list?.laccount === "Current" ||
          item?.list?.acctype === "card"
      );
      setBankList(filteredBank);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const initialValues = {
    bname: user?.companyInfo?.bname,
    bcategory: user?.companyInfo?.bcategory,
    website: user?.companyInfo?.website,
    cemail: user?.companyInfo?.cemail,
    cperson: user?.companyInfo?.cperson,
    fulladdress: user?.companyInfo?.fulladdress,
    registerno: user?.companyInfo?.registerno,
    btype: user?.companyInfo?.btype,
    cphoneno: user?.companyInfo?.cphoneno,
    taxno: user?.companyInfo?.taxno,
    defaultBank: user?.bankInfo?.id,
  };

  const onFinish = async (data: any) => {
    try {
      setIsLoading(true);
      let obj = {
        id: user?.id,
        userid: user?.userid,
        bname: data?.bname,
        bcategory: data?.bcategory,
        website: data?.website,
        cemail: data?.cemail,
        cperson: data?.cperson,
        fulladdress: data?.fulladdress,
        registerno: data?.registerno,
        btype: data?.btype,
        cphoneno: data?.cphoneno,
        taxno: data?.taxno,
        defaultBank: data?.defaultBank,
      };
      let url = API.UPDATE_PROFILE + user?.id;
      const response: any = await PUT(url, obj);
      if (response.status) {
        notification.success({
          message: `${t("home_page.homepage.Updated_Successfully")}`,
          description: `${t("home_page.homepage.updated_successfully")}`,
        });
        response.data["bankInfo"] = response.bankInfo;
        dispatch(update(response.data));
        props.onChange()
      }
      setIsLoading(false);
    } catch (err) {
      console.log("err = = = >", err);
      setIsLoading(false);
    }
  };

  const GetBusinessCategory = async () => {
    let URL = API.BUSINESS_CATEGORY;
    const { data }: any = await GET(URL, null);
    if (data) {
      setBusinessCategory(data);
    }
  };

  return (
    <>
      {props?.isLoading ? (
        <LoadingBox />
      ) : (
        <Container>
          <Card>
            <Form onFinish={onFinish} initialValues={initialValues}>
              <Row>
                <Col md={6}>
                  <label className="formLabel">{t("home_page.homepage.Business_Name")}</label>
                  <Form.Item
                    name="bname"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true ,message:'Business name is required'}]}
                  >
                    <Input
                      placeholder={t("home_page.homepage.Business_Name")}
                      size="large"
                    />
                  </Form.Item>

                  <label className="formLabel">{t("home_page.homepage.Business_Category")}</label>
                  <Form.Item name="bcategory" style={{ marginBottom: 10 }}
                   rules={[{ required: true ,message:'Business category is required'}]}
                  >
                    <Select placeholder={t("home_page.homepage.choose")} size="large">
                      {businessCategory.length &&
                        businessCategory?.map((category: any) => (
                          <Option
                            key={category?.id}
                            value={category?.bcategory}
                          >
                            {category?.btitle}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <label className="formLabel">{t("home_page.homepage.Website")}</label>
                  <Form.Item
                    name="website"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true ,message:'Website is required'}]}
                  >
                    <Input placeholder={t("home_page.homepage.Website")} size="large" />
                  </Form.Item>

                  <label className="formLabel">{t("home_page.homepage.Business_Email")}</label>
                  <Form.Item
                    name="cemail"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true ,message:'Business email is required'},
                    {
                      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'Please enter a valid email address',
                    },
                  ]}
                  >
                    <Input placeholder={t("home_page.homepage.Business_Email")} size="large" />
                  </Form.Item>

                  <label className="formLabel">{t("home_page.homepage.Contact_Person")}</label>
                  <Form.Item
                    name="cperson"
                    style={{ marginBottom: 10 }}
                    // rules={[{ required: true }]}
                  >
                    <Input placeholder={t("home_page.homepage.Contact_Person")} size="large" />
                  </Form.Item>

                  <label className="formLabel">{t("home_page.homepage.Business_Address")}</label>
                  <Form.Item
                    name="fulladdress"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true ,message:'Business address is required'}]}
                  >
                    <Input.TextArea
                      placeholder={t("home_page.homepage.Business_Address")}
                      size="large"
                      rows={3}
                    />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <label className="formLabel">{t("home_page.homepage.Registration_Number")}</label>
                  <Form.Item
                    name="registerno"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true ,message:'Registration number is required'}]}
                  >
                    <Input placeholder={t("home_page.homepage.Registration_Number")} size="large" />
                  </Form.Item>

                  <label className="formLabel">{t("home_page.homepage.Type_of_Business")}</label>
                  <Form.Item name="btype" style={{ marginBottom: 10 }}>
                    <Select placeholder={t("home_page.homepage.choose_one")} size="large">
                      <Option value="trader">{t("home_page.homepage.Sole_Trader_Small_Business")}</Option>
                      <Option value="partnership">{t("home_page.homepage.Partnership")}</Option>
                      <Option value="limitcompany">{t("home_page.homepage.Limited_Company_LLP")}</Option>
                    </Select>
                  </Form.Item>

                  <label className="formLabel">{t("home_page.homepage.Business_Phone")}</label>
                  <Form.Item
                    name="cphoneno"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true ,message:'Business phone number is required'}]}
                  >
                    <Input placeholder={t("home_page.homepage.Business_Phone")} size="large"
                    // addonBefore={<PrefixSelector />}
                    type="text"
                      onInput={(e) => {
                      e.currentTarget.value = e.currentTarget.value.replace(
                      /[^0-9]/g,
                      ""
                      );
                  }}
                     />
                  </Form.Item>

                  <label className="formLabel">{t("home_page.homepage.Tax_VAT_Number")}</label>
                  <Form.Item
                    name="taxno"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true ,message:'Tax number is required'}]}
                  >
                    <Input placeholder={t("home_page.homepage.Tax_VAT_Number")} size="large" />
                  </Form.Item>

                  <label className="formLabel">{t("home_page.homepage.Bank_For_Sales_Invoice")}</label>
                  <Form.Item name="defaultBank" style={{ marginBottom: 10 }}
                  rules={[{ required: true ,message:'Bank for invoice is required'}]}
                  >
                    <Select
                      placeholder={t("home_page.homepage.Add_New_Bank")}
                      size="large"
                    >
                      {bankList.length &&
                        bankList.map((item: any) => (
                          <Select.Option
                            key={item.list.id}
                            value={item.list.id}
                          >
                            {item.list.laccount}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Row>
                    <Col md={6}></Col>
                    <Col md={6}>
                      <Button
                        size="large"
                        type="primary"
                        loading={isLoading}
                        style={{
                          height: 45,
                          fontWeight: "600",
                          width: "100%",
                          display: "block",
                          marginTop: 18,
                        }}
                        htmlType="submit"
                      >
                        {t("home_page.homepage.Update")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
          <br />
        </Container>
      )}
    </>
  );
}

export default Business;
