import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { GET } from "../../../utils/apiCalls";
import { useSelector } from "react-redux";
import API from "../../../config/api";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../../../components/loadingBox";
import { Button, notification } from "antd";
import { stockSummaryTemplate } from "./template";
import SendMailModal from "../../../components/sendMailModal";
const StocksummaryTable = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.User);
  const [companyname, setCompanyname] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [create, setCreate] = useState("");
  const [data, setData] = useState([]);
  const [totalvalue, settotalvalue] = useState();
  const [totalQuantity, setTotalQuantity] = useState();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const adminid = user?.adminid;
  const [emailModal, setEmailModal] = useState(false);

  const getDetails = async () => {
    try {
      const userData = API.GET_USER_DETAILS + user.id;
      const response: any = await GET(userData, null);
      if (response && response.data && response.data.companyInfo) {
        const companyName = response.data.companyInfo.bname;
        const companyCreatedAt = response.data.companyInfo.created_at;
        setCreate(companyCreatedAt);
        setCompanyname(companyName);
        // setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
    }
  };

  const loadData = async () => {
    try {
      setIsLoading(true);
      let product_url = API.GET_STOCK_DETAILS + `${adminid}`;
      const data: any = await GET(product_url, null);
      const totalCostPrice = data.reduce(
        (item: any, product: any) => Number(item) + Number(product.value),
        0
      );
      const totalCostQuantity = data.reduce(
        (item: any, product: any) => Number(item) + Number(product.quantity),
        0
      );
      settotalvalue(totalCostPrice);
      setTotalQuantity(totalCostQuantity);
      setData(data);
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const sendMailPdf = async (templates: any, email: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    const encodedString = btoa(templateContent);
    const pdf_url = API.PDF_GENERATE_URL;
    const pdfData = {
      email: email,
      filename: "Sales Invoice",
      html: encodedString,
      isDownload: false,
      sendEmail: true,
      type: "",
      userid: "",
    };
    const token = user.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (response.ok) {
      notification.success({ message: "Email Successfully Sent" });
      setEmailModal(false);
    }
    if (!response.ok) {
      notification.success({
        message:
          "Apologies, there was an error when attempting to send the email.",
      });
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  };

  async function genrateTemplate(type: any, emaildata: any) {
    try {
      setDownloadLoading(true);
      let obj = {
        user,
        personalData: user.companyInfo,
        stockSummary: data,
        totalValue: totalvalue,
        totalQuantity:totalQuantity,
      };
      let templates = stockSummaryTemplate(obj);
      if (type === "email") {
        sendMailPdf(templates, emaildata);
      } else {
        await downLoadPdf(templates);
      }

      setDownloadLoading(false);
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  }
  async function generateTemplate(type: any, emaildata: any) {
    try {
      setDownloadLoading(true);
      let obj = {
        user,
        personalData: user.companyInfo,
        stockSummary: data,
        totalValue: totalvalue,
        totalQuantity:totalQuantity
      };
      let templates = stockSummaryTemplate(obj);
      await downLoadPdf(templates);
      setDownloadLoading(false);
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  }

  const downLoadPdf = async (templates: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    const encodedString = btoa(templateContent);
    const pdf_url = API.PDF_GENERATE_URL;
    const pdfData = {
      filename: "Sales Invoice",
      html: encodedString,
      isDownload: true,
      sendEmail: false,
      type: "",
      userid: "",
    };
    const token = user.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const pdfBlob = await response.arrayBuffer();
    const blob = new Blob([pdfBlob], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `StockSummary${moment(new Date()).format("DD-MM-YYYY")}`;
    a.click();
    URL.revokeObjectURL(url);
  };
  useEffect(() => {
    getDetails();
    loadData();
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <div className="StocksummaryTable-box">
          <div className="StocksummaryTable-box1">
            <div className="StocksummaryTable-head">
              <div className="StocksummaryTable-txt1">
                <b>{companyname}</b>
              </div>

              <div className="StocksummaryTable-txt1">
                <b>
                  {moment(create).format("DD-MM-YYYY")} -{" "}
                  {moment().format("DD-MM-YYYY")}
                </b>
              </div>
              <div>
                <Button
                  onClick={() => generateTemplate("downLoad", {})}
                  className="Report-HeaderButton-dwnld"
                >
                  Print
                </Button>{" "}
                <Button
                  onClick={() => setEmailModal(true)}
                  className="Report-HeaderButton-print"
                >
                  Email
                </Button>
                {/* <Button className="Report-HeaderButton-share">Share</Button> */}
              </div>
            </div>
            <Table bordered hover striped width={"auto"}>
              <thead className="Report-thead">
                <tr>
                  <th className="Report-table-th">PARTICULAR</th>
                  <th className="Report-table-th">PRODUCT CATEGORY</th>
                  <th className="Report-table-th">QUANTITY</th>
                  <th className="Report-table-th">RATE</th>
                  <th className="Report-table-th">VALUE</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any) => (
                  <tr key={item.id}>
                    <td
                      className="Report-td-link"
                      onClick={() =>
                        navigate(
                          `/usr/report/StockSummary/StockMonth/${item?.id}`
                        )
                      }
                    >
                      {item.idescription}
                    </td>
                    <td className="Report-table-td">{item.itemtype}</td>
                    <td className="Report-table-td">{item.quantity}</td>
                    <td className="Report-table-td">
                      {Number(item?.rate).toFixed(2)}
                    </td>
                    <td className="Report-table-td">
                      {Number(item.value).toFixed(2)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="Report-table-td">
                    <b>GRAND TOTAL</b>
                  </td>
                  <td className="Report-table-td"></td>
                  <td className="Report-table-td">{Number(totalQuantity)}</td>
                  <td className="Report-table-td"></td>
                  <td className="Report-table-td">
                    <b>{Number(totalvalue).toFixed(2)}</b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          {emailModal ? (
            <SendMailModal
              open={emailModal}
              close={() => setEmailModal(false)}
              onFinish={(val: any) => genrateTemplate("email", val)}
              ownMail={user.email}
              fileName={`stockSummaryDetails${new Date()}.pdf`}
              Attachment={`${user.companyInfo.bname}_stockSummary_${moment(
                new Date()
              ).format("DD-MM-YYYY")}`}
              defaultValue={{
                to: user.email,
                subject: `Stock Summary`,
                content: `Stock Summary Details`,
              }}
            />
          ) : null}
        </div>
      )}
    </>
  );
};
export default StocksummaryTable;
