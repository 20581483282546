import { useDispatch, useSelector } from "react-redux";
import API from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import { storeBankList } from "../../redux/slices/banklistSlice";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoMdArrowDown, IoMdArrowUp } from "react-icons/io";
import { HiUsers } from "react-icons/hi2";
import { TbGraphFilled } from "react-icons/tb";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { Button, Card, DatePicker, Spin } from "antd";
import PurchaseGraph from "./components/PurchaseGraph";
import SalesGraph from "./components/SalesGraph";
import OrderTable from "./components/OrderTable";
import img1 from "../../assets/images/A.png";
import img2 from "../../assets/images/B.png";
import img3 from "../../assets/images/C.png";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router";
// import "./styles.scss";
import moment from "moment";
import LoadingBox from "../../components/loadingBox";
import { useTranslation } from "react-i18next";
import DataModal from "./components/DataModal";

function Dashboard() {
  const [openModal, setOpenModal] = useState(false);
  const [bankList, setBankList] = useState<any>([]);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.isOpen === undefined) {
      setOpenModal(false);
    } else if (location.state?.isOpen === true) {
      setOpenModal(true);
    }
  }, []);
  const { t } = useTranslation();

  const columns = [
    {
      name: "invoiceno",
      title: `${t("home_page.homepage.Order_No")}`,
      dataType: "string",
      alignment: "center",
    },
    {
      name: "cname",
      title: `${t("home_page.homepage.Customer_Name")}`,
      dataType: "string",
      alignment: "center",
    },
    {
      name: "userdate",
      title: `${t("home_page.homepage.Item_Name")}`,
      dataType: "date",
      alignment: "center",
      format: "dd-MM-yyyy",
    },
    {
      name: "status",
      title: `${t("home_page.homepage.Status")}`,
      alignment: "center",
      cellRender: "status",
    },
    {
      name: "total",
      title: `${t("home_page.homepage.Amount")}`,
      alignment: "center",
      cellRender: "Reference",
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState<any>();

  const [customerData, setCustomerData] = useState<any>([]);

  const [graphData, setGraphData] = useState<any>();
  const [proformaInvoiceData, setProformaInvoiceData] = useState<any>([]);

  const { user } = useSelector((state: any) => state.User);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const today = new Date();
  const startDay = moment(new Date(today.setDate(1))).format("YYYY-MM-DD");

  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [firstDate, setFirstDate] = useState(startDay);

  const adminid = user?.adminid;

  const fetchBankList = async () => {
    try {
      const bank_url = API.GET_BANK_LIST + user.id;
      const { data }: any = await GET(bank_url, null);
      dispatch(storeBankList(data));
      const filteredBank = data.bankList.filter(
        (item: any) =>
          item?.list?.acctype === "savings" ||
          item?.list?.acctype === "current" ||
          item?.list?.laccount === "Current" ||
          item?.list?.acctype === "card"
      );
      setBankList(filteredBank);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const proformaInvoiceList = async () => {
    try {
      setIsLoading(true);
      let proforma_Invoice_List_url =
        API.PROFORMA_INVOICE_LIST + adminid + "/proforma";
      const { data }: any = await GET(proforma_Invoice_List_url, null);
      const latestOrders = data
        ?.sort(
          (a: any, b: any) =>
            new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
        )
        ?.slice(0, 10);
      setProformaInvoiceData(latestOrders);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCustomersInRange = async () => {
    try {
      setIsLoading(true);
      let customer_list_url = API.CONTACT_MASTER + `${adminid}/customer`;
      const { data }: any = await GET(customer_list_url, null);
      setCustomerData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGraphData = async (sdate: any, ldate: any) => {
    try {
      setIsLoading(true);
      let graphData_url = API.GRAPH_DATA;
      const { data }: any = await POST(graphData_url, {
        adminid,
        ldate,
        sdate,
      });
      setGraphData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateRangeChange = (dates: any) => {
    setCurrentDate(dates ? dates[0] : new Date());
    setFirstDate(dates ? dates[1] : new Date());
    fetchGraphData(
      dates ? dates[0] : new Date(),
      dates ? dates[1] : new Date()
    );
  };

  const progressData = async () => {
    try {
      setIsLoading(true);
      const sales_data = API.CUSTOM_DATA;
      const { data }: any = await POST(sales_data, {
        adminid,
        sdate: new Date(),
      });
      setProgress(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    proformaInvoiceList();
    fetchBankList();
    progressData();
    fetchGraphData(firstDate, currentDate);
    fetchCustomersInRange();
  }, []);

  return (
    <>
      <Container className="">
        <br />
        <div className="dashboard-greetig-container">
          <strong className="dashboard-username-text">{`${t(
            "home_page.homepage.Hi"
          )}, ${user?.firstname}`}</strong>
          <DatePicker.RangePicker
            defaultValue={[
              dayjs(currentDate, "YYYY/MM/DD"),
              dayjs(firstDate, "YYYY/MM/DD"),
            ]}
            onChange={handleDateRangeChange}
          />
        </div>
        <br />
        <Row>
          <Col md={4}>
            <Card style={{ height: "100%" }}>
              <div className="d-flex align-items-center justify-content-between pb-2">
                <div className="d-flex align-items-center">
                  <div className="dashboard-icon-container">
                    {isLoading ? (
                      <Spin />
                    ) : (
                      <TbGraphFilled size={24} color="orange" />
                    )}
                  </div>
                  <div>
                    <h5 className="dashboard-icons-text mb-0">
                      {t("home_page.homepage.Total_Sales")}
                    </h5>
                  </div>
                </div>

                <div>
                  <img
                    src={img1}
                    alt="icon"
                    style={{ width: "40px", height: "30px" }}
                  />
                </div>
              </div>
              <div
                className=""
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="dashboard-total-sales-price">
                  {" "}
                  {`${user?.countryInfo?.symbol} ${progress?.totalSales || 0}`}
                </span>
                <p
                  className="dashboard-sales-progress mb-0"
                  style={{
                    backgroundColor: "rgb(238 224 196)",
                    color: "orange",
                  }}
                >
                  <IoMdArrowUp />
                  {Number(progress?.salesPercentageChange)?.toFixed(2) ||
                    0} %{" "}
                </p>
              </div>
              <span style={{ fontSize: "12px" }}>
                {t("home_page.homepage.Increase_total_sales")}{" "}
                {Number(progress?.salesPercentageChange)?.toFixed(2) || 0} %{" "}
                {t("home_page.homepage.from_last_month")}
              </span>
            </Card>
          </Col>
          <Col md={4}>
            <Card style={{ height: "100%" }}>
              <div className="d-flex align-items-center justify-content-between pb-2">
                <div className="d-flex align-items-center">
                  <div className="dashboard-icon-container">
                    {isLoading ? (
                      <Spin />
                    ) : (
                      <HiUsers size={24} color="rgb(119 117 236)" />
                    )}
                  </div>
                  <div>
                    <h5 className="dashboard-icons-text mb-0">
                      {t("home_page.homepage.Total_Customers")}
                    </h5>
                  </div>
                </div>
                <div>
                  <img
                    src={img2}
                    alt="icon"
                    style={{ width: "40px", height: "30px" }}
                  />
                </div>
              </div>
              <div
                className=""
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="dashboard-total-sales-price">
                  {" "}
                  {customerData?.totalCustomers || 0}
                </span>
                <p className="dashboard-sales-progress mb-0">
                  <IoMdArrowUp />
                  {customerData?.percentage?.toFixed(2) || 0}%{" "}
                </p>
              </div>
              <span style={{ fontSize: "12px" }}>
                {t("home_page.homepage.Increase_total_customers")}{" "}
                {customerData?.percentage?.toFixed(2) || 0}%{" "}
                {t("home_page.homepage.from_last_month")}
              </span>
            </Card>
          </Col>
          <Col md={4}>
            <Card style={{ height: "100%" }}>
              <div className="d-flex align-items-center justify-content-between pb-2">
                <div className="d-flex align-items-center">
                  <div
                    className="dashboard-icon-container"
                    style={{ backgroundColor: "#c5e6c5" }}
                  >
                    {isLoading ? (
                      <Spin />
                    ) : (
                      <RiMoneyDollarCircleFill
                        size={28}
                        color="rgb(83 213 83)"
                      />
                    )}
                  </div>
                  <h5 className="dashboard-icons-text mb-0">
                    {t("home_page.homepage.Total_Profit")}
                  </h5>
                </div>

                <div>
                  <img
                    src={img3}
                    alt="icon"
                    style={{ width: "40px", height: "30px" }}
                  />
                </div>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="dashboard-total-sales-price">
                  {progress?.profitPercentage?.toFixed(2) || 0}%
                </span>
                <p
                  className="dashboard-sales-progress mb-0"
                  style={{
                    color: "rgb(83 213 83)",
                    backgroundColor: "#c5e6c5",
                  }}
                >
                  {Number(progress?.profitPercentage) > 0 ? (
                    <IoMdArrowUp />
                  ) : (
                    <IoMdArrowDown />
                  )}
                  {Number(progress?.profitPercentage)?.toFixed(2) || 0}%{" "}
                </p>
              </div>
              <span style={{ fontSize: "12px" }}>
                {`${
                  Number(progress?.profitPercentage) > 0
                    ? t("home_page.homepage.Increase")
                    : "Decrease"
                } ${t("home_page.homepage.total_profit")} ${
                  Number(progress?.profitPercentage)?.toFixed(2) || 0
                }% ${t("home_page.homepage.from_last_month")}`}
              </span>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={6}>
            <Card>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>{t("dashboard.title.sale_preview")}</h4>
                <Button
                  onClick={() => navigate("/usr/sales-invoice")}
                  size="small"
                  type="link"
                >
                  {t("home_page.homepage.View_All")}
                </Button>
              </div>
              <br />
              {graphData ? (
                <SalesGraph data={graphData?.sdatasets} />
              ) : (
                <LoadingBox />
              )}
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>{t("dashboard.title.purchase_preview")}</h4>
                <Button
                  onClick={() => navigate("/usr/purchace-invoice")}
                  size="small"
                  type="link"
                >
                  {t("home_page.homepage.View_All")}
                </Button>
              </div>
              <br />
              {graphData ? (
                <PurchaseGraph data={graphData?.pdatasets} />
              ) : (
                <LoadingBox />
              )}
            </Card>
          </Col>
        </Row>
        <br />
        <Card>
          <OrderTable
            data={proformaInvoiceData}
            columns={columns}
            onItemSelect={() => {}}
            onPageChange={(p: any, t: any) => {}}
            title={t("dashboard.title.latest_order")}
          />
        </Card>

        <br />
      </Container>

      {openModal && (
        <>
          <DataModal
            bankList={bankList}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </>
      )}
    </>
  );
}

export default Dashboard;
