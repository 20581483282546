import React, { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import API from "../../../config/api";
import { DELETE, GET, POST, PUT } from "../../../utils/apiCalls";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Tag,
  notification,
} from "antd";

import { Container, Table } from "react-bootstrap";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";

import { TextArea } from "devextreme-react";
import CommentsDataTable from "./commentsDataTable";
import { useSelector } from "react-redux";
import moment from "moment";

function ViewHrTask() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [Taskdata, setTaskData] = useState<any>([]);
  const [Comments, setComments] = useState<any>([]);
  const { id } = useParams();
  const [TaskId, setTaskId] = useState<number | null>(null);
  const [commentValue, setCommentValue] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [taskHistoryData, setTaskHistoryData] = useState<any>([]);
  const User = useSelector((state: any) => state.User.user);
  const [taskToDelete, setTaskToDelete] = useState<any>(null);
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const submitHandler = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        commentId: "hello",
        text: commentValue,
        created_by: Taskdata?.empinfo?.id,
        task: Number(id),
        parentId: 4,
        status: true,
      };

      let api = API.COMMENTS;
      let postRequest = await POST(api, obj);
      if (postRequest) {
        notification.success({ message: "Comment created successfully" });
      } else {
        notification.error({ message: "Something went wrong" });
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    } finally {
      setIsLoading2(false);
    }
  };

  const getComments = async (page: any, take: any) => {
    try {
      setIsLoading(true);
      let api = API.PAGE_COMMENTS;
      let obj = {
        task: Number(id),
        // query: form.getFieldValue("query"),
        page: page,
        take: 100,
      };
      const Data: any = await POST(api, obj);
      console.log("comments data", Data);
      if (Data?.status) {
        setComments(Data?.data);
        setTaskId(Data?.data?.id);
        setMeta(Data?.meta);
        setIsLoading(false);
      } else {
        setComments({});
        setIsLoading(false);
        notification.error({
          message: "Oops !",
          description: "comments data not found",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      notification.error({
        message: "Oops !",
        description: "Project data not found",
      });
    }
  };

  const getAllTaskData = async () => {
    try {
      let api = API.GET_ONE_TASK + id;
      const Data: any = await GET(api, {});
      console.log("task data", Data);
      if (Data?.status) {
        setTaskData(Data?.data);

        setTaskId(Data?.data?.id);
      } else {
        setTaskData({});
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const pagination = (currentPage: any, pageSize: any) => {
  //   setPage(currentPage);
  //   getComments(currentPage, 12, User.id);
  // };

  useEffect(() => {
    getAllTaskData();
    if (User && User.id) {
      getComments(1, 100);
    }
  }, [page, User]);

  const getAllTaskHistoryData = async () => {
    try {
      let api = API.GET_TASK_HISTORY + id;
      const response: any = await GET(api, {});
      console.log("task history", response);

      if (response?.status) {
        setTaskHistoryData(response.data);
      } else {
        console.error("Failed to fetch data from the API.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: any) => {
    setTaskToDelete(id);
  };

  const confirmDelete = async () => {
    try {
      setIsLoading3(true);
      let api = API.DELETE_TASK + taskToDelete;
      let Delete: any = await DELETE(api);
      if (Delete?.status) {
        setIsLoading3(false);
        notification["success"]({
          message: "Task Deleted",
        });
        getAllTaskData();
        navigate(-1);
      } else {
        setIsLoading3(false);
        notification["error"]({
          message: "Task not deleted",
        });
      }
    } catch (err) {
      setIsLoading3(false);
      notification["error"]({
        message: "Network error. Task not deleted",
      });
    } finally {
      setTaskToDelete(null);
    }
  };
  const cancelDelete = () => {
    setTaskToDelete(null);
  };

  useEffect(() => {
    getAllTaskHistoryData();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <PageHeader firstPathText="Task" title="Task Details" />

          <br />

          <Container>
            <Card>
              <div className="productAdd-Txt1">Task Details</div>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>title :</strong>
                </Col>
                <Col span={18}>
                  <span>{Taskdata?.title}</span>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Description :</strong>
                </Col>
                <Col span={18}>
                  <span>{Taskdata?.description}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Priority :</strong>
                </Col>
                <Col span={18}>
                  <span>{Taskdata?.priority}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Project Id:</strong>
                </Col>
                <Col span={18}>
                  <span>{Taskdata?.projectId}</span>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Start Date :</strong>
                </Col>
                <Col span={18}>
                  <span>{Taskdata?.startDate}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>End Date :</strong>
                </Col>
                <Col span={18}>
                  <span>{Taskdata?.endDate}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Created By :</strong>
                </Col>
                <Col span={18}>
                  <span>{Taskdata?.createdBy}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>status :</strong>
                </Col>
                <Col span={18}>
                  <span>{Taskdata?.status}</span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Assigned To:</strong>
                </Col>
                <Col span={18}>
                  <span>{Taskdata?.empinfo?.fullName}</span>
                </Col>
              </Row>
              <Row justify="end" style={{ marginTop: "16px" }}>
                <Col>
                  <Button
                    onClick={() => {
                      handleDelete(Taskdata?.id);
                    }}
                    // style={{
                    //   position: "absolute",
                    //   top: 10,
                    //   right: 10,
                    //   zIndex: 1,
                    //   // marginRight: "30px",
                    // }}
                    type="primary"
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </Card>
            <br />
            <Card>
              {/* <Form form={form} onFinish={submitHandler}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  rules={[
                    { required: true, message: "Please enter a comment" },
                  ]}
                >
                  <TextArea
                    value={commentValue}
                    onValueChanged={(e) => setCommentValue(e.value)}
                    placeholder="Enter your comments"
                  />
                </Form.Item>
                <Button loading={isLoading2} onClick={submitHandler}>
                  Add Comment
                </Button>
              </Form> */}

              <Form form={form} onFinish={submitHandler}>
                <Form.Item
                  name="comment"
                  style={{ marginBottom: 10 }}
                  rules={[
                    { required: true, message: "Please enter a comment" },
                  ]}
                >
                  <Input.TextArea
                    value={commentValue}
                    onChange={(e: any) => {
                      setCommentValue(e.target.value);
                    }}
                    placeholder="Enter your comments"
                  />
                </Form.Item>
                <Form.Item>
                  <Button loading={isLoading2} htmlType="submit">
                    Add Comment
                  </Button>
                </Form.Item>
              </Form>

              {/* <br /> */}
              {Comments.length > 0 && (
                <CommentsDataTable dataSource={Comments} />
              )}
              <br />
              {/* <Pagination
                  defaultCurrent={meta?.page}
                  total={meta?.total_count}
                  onChange={(page, take) => {
                    getComments(page, take);
                  }}
                /> */}
            </Card>
          </Container>
          <Container>
            <Card>
              <h4 className="productAdd-Txt1">Task History</h4>
              {taskHistoryData?.map((data: any) => {
                return (
                  <>
                    {/* <div className="Name">{data?.employee?.fullName}</div>
                  <div className="">{data?.status}</div>
                  <div className="">{moment(data.createdAt).format("lll")}</div> */}
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <strong>Name:</strong>
                      </Col>
                      <Col span={18}>
                        <span>{data?.employee?.fullName}</span>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <strong>Status:</strong>
                      </Col>
                      <Col span={18}>
                        <Tag
                          color={
                            data?.status === "pending"
                              ? "red"
                              : data?.status === "in_progress"
                              ? "blue"
                              : "green"
                          }
                          key={data?.status}
                        >
                          <a>{data?.status?.toUpperCase()}</a>
                        </Tag>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <strong>Created At:</strong>
                      </Col>
                      <Col span={18}>
                        <span>{moment(data.createdAt).format("lll")}</span>
                      </Col>
                    </Row>
                  </>
                );
              })}
            </Card>
          </Container>
          <Modal
            title="Confirm Deletion"
            visible={taskToDelete !== null}
            onOk={confirmDelete}
            onCancel={cancelDelete}
            okText="Delete"
            cancelText="Cancel"
          >
            <p>Are you sure you want to delete this task?</p>
          </Modal>
        </>
      )}
    </>
  );
}

export default ViewHrTask;
