import { Card } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import TransactionTable from "./TransactionTable";
import { useEffect } from "react";

const columns = [
  {
    name: "date",
    title: "Date",
    dataType: "date",
    alignment: "center",
    format: "dd-MM-yyyy",
  },
  {
    name: "customer",
    title: "Particulars",
    dataType: "string",
    alignment: "center",
    cellRender: ({ data }: any) => data?.customer || data?.customer_name,
  },
  {
    name: "type",
    title: "Account Type",
    dataType: "string",
    alignment: "center",
  },

  {
    name: "credit", //payment should in debit
    title: "Paid",
    dataType: "number",
    alignment: "center",
  },
  {
    name: "debit", //reciept should in credit
    title: "Recieved",
    dataType: "number",
    alignment: "center",
  },
];

function Transaction({ details,fetchBankDetails }: any) {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleOnEdit = (val: any) => {
    if (val?.type === "Customer Receipt" || val?.type === "Customer Reciept") {
      navigate(`/usr/cashBank/${id}/details/salesreciept/customer/${val?.id}`);
    } else if (val?.type === "Other Receipt") {
      navigate(`/usr/cashBank/${id}/details/salesreciept/other/${val?.id}`);
    } else if (val?.type === "Supplier Refund") {
      navigate(
        `/usr/cashBank/${id}/details/salesreciept/supplier-refund/${val?.id}`
      );
    } else if (val?.type === "Supplier Payment") {
      navigate(
        `/usr/cashBank/${id}/details/purchasepayment/supplier/${val?.id}`
      );
    } else if (val?.type === "Other Payment") {
      navigate(`/usr/cashBank/${id}/details/purchasepayment/other/${val?.id}`);
    } else if (val?.type == "Customer Refund") {
      navigate(
        `/usr/cashBank/${id}/details/purchasepayment/customer/${val?.id}`
      );
    } else {
      navigate(`/usr/cashBank/${id}/details/banktransfer/${val?.id}`);
    }
  };

  return (
  
      <Container>
      <Row>
        <Col md="6">
          <Card>
            <h5
              className=""
              style={{
                fontWeight: 600,
                display: "flex",
                justifyContent: "space-between",
                color: "gray",
              }}
            >
              Available Balance
              <span>
                {Number(details.total) + Number(details.opening || 0) ||
                  Number(details.amount) ||
                  0}{" "}
                INR
              </span>
            </h5>
          </Card>
        </Col>

        <Col md="6" />
      </Row>

      <TransactionTable
        columns={columns}
        onEdit={(data: any) => handleOnEdit(data)}
        fetchBankDetails={fetchBankDetails}
      />
      <br />
    </Container>
   
   
  );
}

export default Transaction;
