import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  notification,
} from "antd";
import form from "antd/es/form";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import API from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";
import PageHeader from "../../../components/pageHeader";
import moment from "moment";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function Hr_Financeform() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [file, setFile] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);

  //   const reload = () => {
  //     fetchHrTimesheet(1, 10);
  //   };

  const handleReset = () => {
    form.resetFields();
    setFile([]);
  };

  const fetchEmployees = async () => {
    try {
      setIsLoading(true);
      const { data }: any = await GET(API.GET_HR_EMPLOYEE_LISTING, {});
      setEmployeeData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      const selectedDate = dayjs(values.Date).toISOString();
      const obj = {
        userId: Number(values.userId),
        type: String(values.type),
        amount: Number(values.Amount),
        category: String(values.category),
        date: selectedDate,
      };

      console.log("time", obj);
      let api = API.POST_HRFINANCE;
      let postRequest: any = await POST(api, obj);
      console.log("dattataaaa", postRequest);
      if (postRequest.status) {
        notification.success({ message: "payroll submitted successfully" });
        navigate(-1);
      } else {
        notification.error({ message: "Something went wrong" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something! Please try again later",
      });
    }
  };

  return (
    <>
      <PageHeader title="Hr Finance" />
      <br />
      <div className="screen-box">
        <Container>
          <Card>
            <Form onFinish={handleSubmit} form={form} layout="vertical">
              <Row gutter={9}>
                <Col md={4}>
                  <Form.Item
                    label="Employee Name"
                    name="userId"
                    rules={[
                      { required: true, message: "Please select a Employee" },
                    ]}
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="Select Employee"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      allowClear
                    >
                      {employeeData.map((Employee: any) => (
                        <Select.Option key={Employee?.id} value={Employee?.id}>
                          {Employee?.fullName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item
                    label="type"
                    name="type"
                    rules={[
                      { required: true, message: "Please select a Type" },
                    ]}
                  >
                    <Select placeholder="Select a Type" size="large">
                      <Select.Option value="IN">IN</Select.Option>
                      <Select.Option value="OUT">OUT</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col md={4}>
                  <Form.Item
                    label="category"
                    name="category"
                    rules={[
                      { required: true, message: "Please input a Category" },
                    ]}
                  >
                    <Input.TextArea size="large" rows={3} />
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item
                    label="Amount"
                    name="Amount"
                    rules={[{ required: true, message: "Please input Amount" }]}
                  >
                    <Input size="large" type="string" />
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item label="date" name="date">
                    <DatePicker size="large" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={5}>
                <Col md={7} />
                <Col md={2}>
                  <Form.Item>
                    <Button
                      size="large"
                      style={{ width: "100%" }}
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </Form.Item>
                </Col>

                <Col md={3}>
                  <Form.Item>
                    <Button
                      size="large"
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 24, color: "#fff" }}
                              spin
                            />
                          }
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default Hr_Financeform;
