import React from "react";
import { Button, Card } from "antd";
import { TfiBarChart } from "react-icons/tfi";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";

import "./styles.scss";
import { CardItems } from "./components/cardItems";
import PageHeader from "../../components/pageHeader";

const Report = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div>
        <PageHeader
          firstPathText="Report"
          firstPathLink={location?.pathname}
          title="Tax Go Reporting"
        />
        <br />
        <Container>
          <Row>
            {CardItems?.map((item: any) => {
              return (
                <>
                  <Col md={"4"}>
                    <Card>
                      <div className="Report-cardIcon-view-box">
                        <TfiBarChart className="Report-Icon" />
                        <Button onClick={() => navigate(item?.link)}>
                          View
                        </Button>
                      </div>
                      <br />
                      <div className="width100">
                        <span className="Report-cardSubheader">
                          {item?.name}
                        </span>
                        <br />
                        <span className="Report-cardName">{item?.subheader}</span>
                      </div>
                    </Card>
                    <br />
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Report;
