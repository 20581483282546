import "./styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Logo2 from "../../assets/images/logo2.png";
import { POST } from "../../utils/apiCalls";
import API from "../../config/api";
import { useState } from "react";
function Forgott() {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const url = API.FORGOTPASSWORD;
      const data = {
        email: values.email,
        // password_new: newPassword,
        // userid: user.id,
      };
      const response: any = await POST(url, data);
      if (response.status) {
        notification.success({
          message: "Password Changing",
          description: "A mail has been sent to your Email.",
        });
      }else{
        notification.error({
          message: "Failed",
          description: response.message,
        });
      }
    } catch (error) {
      console.error(error, "something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Container fluid>
        <Row>
          <Col sm={8} style={{ margin: 0, padding: 0 }}>
            <div className="website-ForgotBox1">
              <img src={Logo2} style={{ width: 300 }} alt="taxgo" />
            </div>
          </Col>
          <Col sm={4} style={{ margin: 0, padding: 0 }}>
            <div className="website-ForgotBack" onClick={() => navigation(-1)}>
              <BiArrowBack />
            </div>
            <div className="website-ForgotBox2">
              <div>
                <div className="website-Forgottxt1">Forgott Password ?</div>
                <br />
                <div className="website-Forgottxt2">
                  Enter the email address associated with your account and we
                  will sent you a link to reset your password
                </div>
                <Form onFinish={onFinish}>
                  <Form.Item name="email">
                    <Input placeholder="Enter email" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      block
                      size="large"
                      type="primary"
                      htmlType="submit"
                      style={{ height: 45 }}
                      loading={isLoading}
                    >
                      Reset
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <div className="website-Forgottxt4">
                Not a member yet ?{" "}
                <span
                  className="website-Forgottxt3"
                  onClick={() => navigation("/signup")}
                >
                  Sign Up
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Forgott;
