import React from 'react'
import WebsiteHeader from "../../components/websiteHeader";
import WebsiteFooter from "../../components/websiteFooter";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./styles.scss";
const News = () => {
const { t } = useTranslation();
  return (
    <>
    <WebsiteHeader />
    <div className="website-screens">
    <Container>
    <div className="Consolidate-news">{t("home_page.homepage.Consolidation_News")}</div>
    <div className='Consolidate-news_text'>{t("home_page.homepage.dear")}</div><br/>
    <div className='Consolidate-news_text'>{t("home_page.homepage.opportunity")}</div>
    <br/>
    <div className='Consolidate-news_text'>{t("home_page.homepage.over-view")}</div><br/>
    <div className='Consolidate-news_text'> {t("home_page.homepage.history")}</div><br/>
    <div className='Consolidate-news_text' >{t("home_page.homepage.knowledge_and_intelligent")}
        <br/> <br/>{t("home_page.homepage.in_2018")}
        <br/> <br/>{t("home_page.homepage.in_2019")}
        <br/> <br/>{t("home_page.homepage.in_2020")}
        <br/> <br/>{t("home_page.homepage.in_2024")}
        </div>
    </Container>
    </div>
    <br /><br />
    <WebsiteFooter />
    </>
  )
}
export default News