// const S3config = {};
// export default S3config;
const S3 = {
  bucketName: "bairuha-bucket",
  dirName: "hrapp",
  region: "ap-south-1",
  accessKeyId: "AKIATCKARFJ75G6VOIVF",
  secretAccessKey: "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",
};
export default S3;
