import { useEffect, useState } from "react";
import PageHeader from "../../components/pageHeader";
import columns from "./columns.json";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import LoadingBox from "../../components/loadingBox";
import Table from "./components/table";

const Enquiry = () => {
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const onPageChange = (page: any, take: any) => {
    loadContactuslist(page, take);
    setPage(page);
    setTake(take);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    loadContactuslist(page, take);
  }, []);

  const loadContactuslist = async (page: any, take: any) => {
    try {
      setIsLoading(true);
      let bank_list_url =
        API.GET_ENURIES_LIST + `?order=DESC&page=${page}&take=${take}`;
      const { data }: any = await GET(bank_list_url, null);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="settings-tab-container">
      <PageHeader
        firstPathLink={"/usr/enquiry"}
        firstPathText={"Enquiry"}
        secondPathLink={"/usr/enquiry"}
        goback={-1}
        title="Enquiry"
      />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Table
          products={data}
          columns={columns}
          onPageChange={(p: any, t: any) => onPageChange(p, t)}
        />
      )}
    </div>
  );
};

export default Enquiry;
