import "../styles.scss";
import { useState, useEffect, useRef } from "react";
import DataGrid, {
  Column,
  SearchPanel,
  Export,
  Paging,
  Pager,
  HeaderFilter,
  Selection,
} from "devextreme-react/data-grid";
import { Popover, Tag } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdEditDocument, MdPreview, MdTextSnippet } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { EXPORT } from "../../../utils/exportData";

const Salesproformatable = (props: any) => {
  const dataGridRef: any = useRef(null);
  const [data, setData] = useState(props.List);
  const navigate = useNavigate();

  useEffect(() => {
    setData(props.List);
  }, [props.List]);

  const columns = [
    {
      dataField: "sdate",
      caption: "Invoice Date",
      dataType: "date",
      cellRender: (item: any) => moment(item?.value).format("DD-MM-YYYY"),
    },
    {
      dataField: "invoiceno",
      caption: "Invoice No.",
    },
    {
      dataField: "cname",
      caption: "A/C Name",
    },
    {
      dataField: "total",
      caption: "Total",
    },
    {
      dataField: "status",
      caption: "Status",
      dataType: "number",
      cellRender: (item: any) => {
        let status = null;
        if (item?.value == "5" || item?.value == "0") {
          status = <Tag color="red">Pending</Tag>;
        } else if (item?.value == "4") {
          status = <Tag color="green">Generated</Tag>;
        }
        return status;
      },
    },
    {
      caption: "Action",
      cellRender: (data: any) => {
        return (
          <div className="table-title">
            <Popover
              content={
                <div className="table-actionBox">
                  <div
                    className="table-actionBoxItem"
                    onClick={() => {
                      navigate(`/usr/perfoma-invoice-view/${data?.data?.id}`);
                    }}
                  >
                    <div>View</div>
                    <MdPreview size={18} color="grey" />
                  </div>
                  {data?.data?.status != "4" && (
                    <>
                      <div
                        className="table-actionBoxItem"
                        onClick={() =>
                          navigate(
                            `/usr/proforma-invoice-form/${data?.data?.id}`
                          )
                        }
                      >
                        <div>Edit</div>
                        <MdEditDocument size={18} color="grey" />
                      </div>
                      <div
                        className="table-actionBoxItem"
                        onClick={() =>
                          navigate(`/usr/proforma-invoice-form/generate`, {
                            state: data?.data?.id,
                          })
                        }
                      >
                        <div>Generate</div>
                        <MdTextSnippet size={18} color="grey" />
                      </div>
                    </>
                  )}
                </div>
              }
              placement="leftTop"
              trigger={"click"}
            >
              <BsThreeDotsVertical size={16} cursor={"pointer"} />
            </Popover>
          </div>
        );
      },
    },
  ];

  const exportFormats = ["pdf", "xlsx"];

  const customizeExportCell = (type: any, gridCell: any, cell: any) => {
    if (gridCell.rowType === "data" && gridCell.column.dataField === "status") {
      let status = gridCell.data.status;
      if (status == "5" || status == "0") {
        status = "Pending";
      } else if (status == "4") {
        status = "Generated";
      }
      gridCell.data.status = status;
      if (type === "pdf") {
        cell.text = status;
      } else if (type === "xlsx") {
        cell.value = status;
      }
    }
    if (
      gridCell.column.dataField === "sdate" &&
      gridCell.rowType !== "header" &&
      gridCell.rowType !== "totalFooter"
    ) {
      const sdate = moment(gridCell.data?.sdate)?.format("DD-MM-YYYY");
      if (type === "pdf") {
        cell.text = sdate ?? "";
      } else if (type === "xlsx") {
        cell.value = sdate ?? "";
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#ffff",
        padding: "30px",
        borderEndEndRadius: "5px",
      }}
    >
      <DataGrid
        ref={dataGridRef}
        dataSource={data}
        onExporting={(e) =>
          EXPORT(e, dataGridRef, "Proforma List", customizeExportCell)
        }
        columnAutoWidth={true}
        showBorders={true}
        showRowLines={true}
      >
        <Selection
          mode="multiple"
          selectAllMode="allPages"
          showCheckBoxesMode="always"
        />
        <SearchPanel visible={true} width={240} />
        <HeaderFilter visible={true} />
        {columns.map((column, index) => (
          <Column
            key={index}
            alignment={"center"}
            dataField={column.dataField}
            caption={column.caption}
            cellRender={column?.cellRender}
            allowExporting={column.caption === "Action" ? false : true}
          />
        ))}
        <Paging defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 20, "all"]}
          showInfo={true}
        />
        <Export
          enabled={true}
          allowExportSelectedData={true}
          formats={exportFormats}
        />
      </DataGrid>
    </div>
  );
};
export default Salesproformatable;
