import React, { useEffect, useState } from "react";
import { GET, POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { useSelector } from "react-redux";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles.scss";
import Table from "../component/table";
import moment from "moment";
import { Modal, notification } from "antd";
import PaySheetPaymentmodal from "../component/paymentModal";

const columns = [
  {
    name: "employeeId",
    title: "Employee Id",
    dataType: "number",
    alignment: "center",
  },
  {
    name: "name",
    title: "Employee Name",
    dataType: "string",
    alignment: "center",
    cellRender: "Reference",
  },
  {
    name: "phone",
    title: "Phone Number",
    alignment: "center",
  },
  {
    name: "email",
    title: "Email",
    dataType: "string",
    alignment: "center",
    cellRender: "Reference",
  },
  {
    name: "salaryPackage",
    title: "SalaryPackage",
    dataType: "number",
    alignment: "center",
    cellRender: "Reference",
  },
  {
    name: "date_of_join",
    title: "Date of Join",
    dataType: "date",
    alignment: "center",
  },
  {
    name: "totalEarnings",
    title: "Total Earnings",
    dataType: "string",
    alignment: "center",
    cellRender: "OutstandingCell",
  },
  {
    name: "totalDeduction",
    title: "Total Deduction",
    dataType: "string",
    alignment: "center",
    cellRender: "OutstandingCell",
  },
  {
    name: "netSalary",
    title: "net Salary",
    dataType: "string",
    alignment: "center",
    cellRender: "OutstandingCell",
  },
];
const PaySheet = () => {
  const { user } = useSelector((state: any) => state.User);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBank, setSlectedBank] = useState<any>();
  const [isBtnLoding, setIsBtnLoding] = useState(false);
  const [id, setId] = useState<any>();
  const [amount,setAmount] = useState(0)

  const [add, setAdd] = useState(false);

  const adminid = user?.adminid;

  useEffect(() => {
    fetchPaysheet();
    getBankList();
  }, []);

  const handleType = (val: any, type: any) => {
    setType(type);
  };

  const fetchPaysheet = async () => {
    try {
      setIsLoading(true);
      let url = API.PAYSHEET_LIST_USER + `${adminid}`;
      const data: any = await GET(url, null);
      let modifiedData = data.map((item: any) => {
       return {
        id: item.id,
        employeeId: item?.employeeId,
        name: `${item?.employee?.firstName} ${item?.employee?.lastName}` || "",
        email: item?.employee?.email || "",
        phone: item?.employee?.phone || "",
        date_of_join:
          moment(item?.employee?.date_of_join).format("DD-MM-YYYY") || "",
        Designation: item?.employee?.Designation || "",
        salaryPackage: item?.employee?.salaryPackage || "",
        totalEarnings: item?.totalEarnings || "",
        totalDeduction: item?.totalDeduction || "",
        netSalary: item?.netSalary || "",
      }});
      setData(modifiedData);
      setIsLoading(false);
 
      
    } catch (error) {
      console.log(error);
    }
  };

  const getBankList = async () => {
    try {
      let url = "account_master/getBankList/" + adminid;
      const { data }: any = await GET(url, null);

      setSlectedBank(data.bankList);
    } catch (error) {}
  };

  async function paymentFinish(val: any) {
    try {
      setIsBtnLoding(true);

      let payload = {
        adminid: adminid,
        paidfrom: val?.paymentBank,
        logintype: "usertype",
        type: "payroll",
        date: val?.paymentDate,
        paidmethod: val?.paymentMethod,
        sdate:new Date(),
        userdate: new Date(),
        booleantype:'73'
      };
      let url = API.PAYSHEET_PAYMENT + id;
      let response: any = await POST(url, payload);
      if (response.status) {
        notification.success({ message: response.message });
        setIsBtnLoding(false);
        setIsOpen(false);
      }else{
        notification.error({ message: 'something wrong'});
      }
    } catch (error) {
      console.log(error);
      notification.error({ message: "Something went wrong to your payment." });
      setIsBtnLoding(false);
      setIsOpen(false);
    }
  }

  const handleModalOpen = (val: any) => {
    setIsOpen(true);
    setId(val?.id)
    setAmount(val?.netSalary)
  };

  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <PageHeader
        firstPath={location?.pathname.slice(5)}
        buttonTxt={add ? null : "ADD"}
        onSubmit={
          add ? null : () => navigate("/usr/payroll/form/paysheet/create")
        }
        goBack={-1}
        title="Pay Roll - Pay Sheet"
        secondPathLink ={location.pathname}
        secondPathText ='Pay Roll - Pay Sheet'
      />
      <div className="adminTable-Box1">
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Table
            products={data}
            columns={columns}
            onItemSelect={() => {}}
            title={"Payroll"}
            isOpen={handleModalOpen}
            view = "/usr/payroll/paysheet-view/"
            edit="/usr/payroll/form/paysheet/"
          />
        )}
      </div>
      {isOpen && (
        <Modal
          open={isOpen}
          onCancel={() => setIsOpen(false)}
          width={800}
          maskClosable={false}
          footer={false}
          title="Add Payment"
        >
          <PaySheetPaymentmodal
            onCancel={() => setIsOpen(false)}
            onFinish={(val: any) => paymentFinish(val)}
            bankList={selectedBank}
            loding={isBtnLoding}
            amount={amount}
          />
        </Modal>
      )}
    </>
  );
};

export default PaySheet;
