import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";
import { Card, Col, Form, Input, Row, Select, message } from "antd";
import { Button } from "antd";
import { Container } from "react-bootstrap";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import moment from "moment";

function LeaveRequestView() {
  const [isLoading, setIsLoading] = useState(false);
  const [leaveStatus, setLeaveStatus] = useState("");
  const [remark, setRemark] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [leaveRequestData, setLeaveRequestData] = useState<any>();

  console.log("leaveData----->>>>", leaveRequestData);

  useEffect(() => {
    getAllLeaveRequestData();
  }, []);

  const getAllLeaveRequestData = async () => {
    try {
      let api = API.POST_LEAVE_REQUESTS + id;
      console.log("api-id", api);
      const Data: any = await GET(api, {});
      console.log("DataData---->>>", Data);

      setLeaveRequestData(Data[0]);
    } catch (error) {
      console.log("Error fetching leave request data:", error);
    }
  };

  const handleLeave = async (value: any) => {
    try {
      let obj = {
        id: Number(id),
        status: String(value),
        approver: Number(id),
        remark: remark,
        // document: String(value),
      };
      console.log(value);
      let api = API.UPDATE_LEAVE_REQUEST;
      const Data: any = await POST(api, obj);

      if (Data?.status) {
        setLeaveRequestData(Data?.data);
        navigate("/usr/hr-leave");
      } else {
        message.error("Leave request has already been processed");
      }
    } catch (error) {
      console.error("Error updating leave request:", error);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <PageHeader
            firstPathText="LeaveRequest"
            title="LeaveRequest Details"
          />
          <br />
          <Container>
            <Form form={form}>
              <Card>
                <div className="productAdd-Txt1">Leave Request Details</div>
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <strong>Employee Name:</strong>
                  </Col>
                  <Col span={18}>
                    <span>{leaveRequestData?.employee?.fullName}</span>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <strong>Leave type:</strong>
                  </Col>
                  <Col span={18}>
                    <span>{leaveRequestData?.type}</span>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <strong>From:</strong>
                  </Col>
                  <Col span={18}>
                    <span>
                      {moment(leaveRequestData?.from).format("DD-MM-YYYY")}
                    </span>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <strong>To:</strong>
                  </Col>
                  <Col span={18}>
                    <span>
                      {moment(leaveRequestData?.to).format("DD-MM-YYYY")}
                    </span>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <strong>Description:</strong>
                  </Col>
                  <Col span={18}>
                    <span>{leaveRequestData?.description}</span>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <strong>Approver:</strong>
                  </Col>
                  <Col span={18}>
                    <span>{leaveRequestData?.approver}</span>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <strong>Document:</strong>
                  </Col>
                  {/* <Col span={18}>
                    {leaveRequestData?.document && (
                      <a
                        href={leaveRequestData.document}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Document
                      </a>
                    )}
                  </Col> */}
                  <Col span={18}>
                    {leaveRequestData?.document ? (
                      <a
                        href={leaveRequestData.document}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Document
                      </a>
                    ) : (
                      "No Document"
                    )}
                  </Col>
                </Row>
                <Form.Item
                  label="Status"
                  name="status"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true, message: "Select status" }]}
                >
                  <Col span={11} offset={1}>
                    <Select
                      placeholder="Select Status"
                      onChange={(value) => setLeaveStatus(value)}
                    >
                      <Select.Option value="approved">Approve</Select.Option>
                      <Select.Option value="rejected">Reject</Select.Option>
                    </Select>
                  </Col>
                </Form.Item>

                <Form.Item
                  label="Remark(Optional)"
                  name="remark"
                  style={{ marginBottom: 10 }}
                >
                  <Col span={11} offset={1}>
                    <Input.TextArea
                      size="large"
                      placeholder="remark"
                      rows={4}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </Col>
                </Form.Item>

                <Col md={3}>
                  <Button
                    block
                    style={{ height: 45 }}
                    onClick={() => {
                      handleLeave(leaveStatus);
                      navigate("/usr/hr-leave");
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Card>
            </Form>
          </Container>
        </>
      )}
    </>
  );
}

export default LeaveRequestView;
