import React, { useEffect, useState } from "react";
import JournalListTable from "./JournalTable";
import { useSelector } from "react-redux";
import API from "../../config/api";
import { DELETE, GET } from "../../utils/apiCalls";
import PageHeader from "../../components/pageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingBox from "../../components/loadingBox";
import { notification } from "antd";
const columns = [
  {
    name: "userdate",
    title: "Date",
    dataType: "date",
    alignment: "center",
    format: "dd-MM-yyyy",
  },
  {
    name: "id",
    title: "Voucher No.",
    dataType: "string",
    alignment: "center",
  },
  {
    name: "reference",
    title: "Particulars",
    dataType: "string",
    alignment: "center",
  },
  {
    name: "total",
    title: "Total",
    alignment: "center",
  },
];
const Journal = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  useEffect(() => {
    fetchJournalList();
  }, [page, take]);
  const onPageChange = (page: any, take: any) => {
    setPage(page);
    setTake(take);
  };
  const fetchJournalList = async () => {
    try {
      setIsLoading(true);
      let journal_url =
        API.JOURNAL_LIST + `${adminid}?order=DESC&page=${page}&take=${take}`;
      const { data }: any = await GET(journal_url, null);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchJournalList();
  }, []);
  const navigate = useNavigate();
  const handleDelete = async (id: any) => {
    try {
      setIsLoading(true);
      const url = API.DELETE_JOURNAL + id;
      const response = await DELETE(url);
      fetchJournalList();
      notification.success({ message: "Journal Deleted Successfully" });
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong , Please try aganin later !!!",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <PageHeader
        firstPathLink={location?.pathname}
        firstPathText="Journals"
        buttonTxt="ADD"
        onSubmit={() => navigate("/usr/CreateJournal")}
        goback="/usr/dashboard"
        title="Journal List"
      />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <JournalListTable
            journals={data}
            columns={columns}
            onPageChange={(p: any, t: any) => onPageChange(p, t)}
            handleDelete={handleDelete}
          />
          <br />
        </>
      )}
    </div>
  );
};
export default Journal;