import { Card } from "antd";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Subscription() {
  const { t } = useTranslation();
  return (
    <Container>
      <Card>
        <Card>
          <p>{t("home_page.homepage.YOUR_PLAN")}</p>
          <div className="subscription-Txt2">{t("home_page.homepage.Tax_GO_SPECIAL_FREE_EDITION")}</div>
        </Card>
        <br />
        <Card>
          <p>{t("home_page.homepage.PAYMENT_METHOD")}</p>
          <div className="subscription-Txt2">{t("home_page.homepage.AVAILABLE_FROM_SUMMER")}</div>
        </Card>
        <br />
        <Card>
          <p>{t("home_page.homepage.Tax_GO_PLANS")}</p>
          <div className="subscription-Txt2">
          {t("home_page.homepage.CREATIVE_PACKAGES_ARE_COMING_SOON")}
          </div>
        </Card>
      </Card>
    </Container>
  );
}

export default Subscription;
