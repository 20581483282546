import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Pagination,
  Select,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GET, POST } from "../../../utils/apiCalls";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import API from "../../../config/api";
import DataTable from "./Table";
import PageHeader from "../../../components/pageHeader";
import moment from "moment";

function Applyleave() {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [leaveData, setLeaveData] = useState<any>([]);
  const [leavefilterData, setLeavefilterData] = useState<any>([]);
  const [selectdata, setselectData] = useState([]);
  const [file, setFile] = useState<any>([]);

  const User = useSelector((state: any) => state.User.user);
  console.log(User);

  const handleReset = () => {
    form.resetFields();
  };

  const submitHandler = async (values: any) => {
    try {
      console.log("values --> ", values);

      let obj = {
        type: values.type,
        description: values.description,
        from: values.from,
        to: values.to,
        employeeId: values.employeeId,
        createdBy: "Admin",
        duration: values.duration,
      };

      console.log(obj);
      let api = API.POST_LEAVE_REQUEST;

      let postRequest: any = await POST(api, obj);
      if (postRequest.status) {
        notification.success({
          message: "Leave request submitted successfully",
        });
      } else {
        notification.error({ message: "Something went wrong" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    }
  };

  const fetchHrEmployee = async () => {
    try {
      setIsLoading(true);
      const url = API.GET_HR_EMPLOYEE_LIST + "?order=ASC&page=1&take=100";
      const { data }: any = await GET(url, null);
      setselectData(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchHrEmployee();
  }, []);

  return (
    <div className="screen-box">
      <PageHeader firstPathText="Leave" title="Create Leave Request List" />
      <Container>
        <Card>
          <Form onFinish={submitHandler} form={form} layout="vertical">
            <Row gutter={9}>
              <Col md={4}>
                {selectdata && (
                  <Form.Item
                    label="Employee Name"
                    name="employeeId"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Select an employee" size="large">
                      {selectdata?.map((users: any, i: any) => (
                        <Select.Option
                          key={users?.id}
                          value={Number(users?.id)}
                        >
                          {users?.fullName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col md={4}>
                <Form.Item
                  label="Leave Type"
                  name="type"
                  rules={[{ required: true, message: "Select leave type" }]}
                >
                  <Select size="large" placeholder="Select leave type">
                    <Select.Option value="Sick leave">Sick leave</Select.Option>
                    <Select.Option value="Casual leave">
                      Casual leave
                    </Select.Option>
                    <Select.Option value="Maternity leave">
                      Maternity leave
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={2}>
                <Form.Item
                  label="From"
                  name="from"
                  rules={[{ required: true, message: "Select Date" }]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col md={2}>
                <Form.Item
                  label="To"
                  name="to"
                  rules={[{ required: true, message: "Select Date" }]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item
                  label="Leave duration"
                  name="duration"
                  rules={[{ required: true, message: "Select leave duration" }]}
                >
                  <Select size="large" placeholder="Select leave duration">
                    <Select.Option value="Half day">Half day</Select.Option>
                    <Select.Option value="Full day">Full day</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col md={8}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: "Fill Description" }]}
                >
                  <Input.TextArea size="large" rows={3} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={5}>
              <Col md={7} />
              <Col md={2}>
                <Form.Item>
                  <Button
                    size="large"
                    style={{ width: "100%" }}
                    onClick={handleReset}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </Col>
              <Col md={3}>
                <Form.Item>
                  <Button
                    size="large"
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <br />
      </Container>
    </div>
  );
}

export default Applyleave;
