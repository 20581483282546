import React, { useRef, useState } from "react";
import DataGrid, {
  Column,
  SearchPanel,
  Export,
  Paging,
  Pager,
  HeaderFilter,
  Selection,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import moment from "moment";
import { Card, Pagination, Popover, Space, Table, TableProps, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { EXPORT } from "../../../utils/exportData";
import ViewPopover from "../../../components/viewPopover";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdRemoveRedEye } from "react-icons/md";

const DataTable = (props: any) => {
  console.log("PROPS ---> ", props);

  const dataGridRef: any = useRef(null);
  const [selectedRows, setSelectedRows] = useState();

  const navigate = useNavigate();

  const onSelectionChanged = (e: any) => {
    setSelectedRows(e.selectedRowsData.length);
  };

  const columns = [
    { title: "name", dataIndex: "name", key: "name" },
    { title: "company", dataIndex: "company", key: "company" },
    { title: "country", dataIndex: "country", key: "country" },
    { title: "phone", key: "phone", dataIndex: "phone" },
    { title: "email", dataIndex: "email", key: "email" },
    {
      title: "contactPerson",
      dataIndex: "contactPerson",
      key: "contactPerson",
    },
    { title: "altPhone", dataIndex: "altPhone", key: "altPhone" },
  ];

  return (
    <Container>
      <br />
      <Card>
        <h4 style={{ textAlign: "center" }}>Client details</h4>
        <br />
        <h6>Total {props?.meta?.total_count} Clients</h6>
        <br />
        <Table
          pagination={false}
          columns={columns}
          dataSource={props?.dataSource}
        />
      </Card>
      <Pagination
        total={props?.meta?.total_count}
        pageSize={props?.meta?.take}
        onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
        style={{
          display: props?.meta?.total_count > 10 ? "block" : "none",
          textAlign: "right",
          marginTop: "20px",
        }}
      />
    </Container>
  );
};
export default DataTable;
