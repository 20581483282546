import { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  notification,
} from "antd";
import { useSelector } from "react-redux";
import API from "../../../../config/api";
import { GET, POST } from "../../../../utils/apiCalls";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";

const ContraVoucher = ({
  modalVisible,
  handleOk,
  handleCancel,
  id,
  type,
  ledger,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state: any) => state.User);
  const [bankList, setBankList] = useState([]);
  const [form] = Form.useForm();

  const loadBank = async () => {
    if (type === "create") {
      form.setFieldsValue({
        sdate: dayjs(new Date()),
      });
    }
    let URL = API.GET_BANK_LIST + user.adminid;
    const { data }: any = await GET(URL, null);
    if (data) {
      setBankList(data.bankList);
    }
  };

  const LoadLedgerDeatails = async () => {
    let URL = API.LEDGER_DEATAILS + id;
    const data: any = await GET(URL, null);
    form.setFieldsValue({
      paidfrom: data?.data?.baseid ? Number(data?.data?.paidfrom) : Number(ledger),
      paidto: data?.data?.baseid ? Number(ledger) : Number(data?.data?.paidfrom),
      sdate: dayjs(data.data.sdate),
      reference: data.data.reference,
      amount: Math.abs(data.data.total),
      paidmethod: data.data.paidmethod,
    });
  };

  useEffect(() => {
    loadBank();
    if (type !== "create") {
      LoadLedgerDeatails();
    }
  }, []);

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      let reqObjPost = {
        paidfrom: Number(val.paidfrom),
        paidto: Number(val.paidto),
        amount: val.amount.toString(),
        reference: val.reference,
        sdate: val.sdate,
        description: val.description,
        paidmethod: val.paidmethod,
        userid: user.id,
        adminid: user.adminid,
        type: '1',
        ledger: Number(id),
        id: Number(id),
      };
      let URL = type == 'create' ? API.BANK_TRANSFER : `ledger_details/updateContraVoucher/${id}`;
      const response: any = await POST(URL, reqObjPost);
      if (response.status) {
        notification.success({message:"Success",description:`Contra voucher ${type === "create" ? 'created' : 'updated'} successfully`});
        setIsLoading(false);
        handleOk();
      } else {
        notification.error({message:"Failed",description:`Failed to ${type === "create" ? 'create' : 'update'} contra voucher`});
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error)
      notification.error({message:"Server Error",description:`Failed to ${type === "create" ? 'create' : 'update'} contra voucher!! Please try again later`});
    }
  };
  return (
    <Modal
      title="Contra Voucher"
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      width={1000}
    >
      <Form onFinish={onFinish} form={form}>
        <Row>
          <Col md={6}>
            <div className="formItem">
              <label className="formLabel">PAID FROM *</label>
              <Form.Item
                name="paidfrom"
                rules={[
                  {
                    required: true,
                    message: "Please choose an account",
                  },
                ]}
              >
                <Select size="large">
                  {bankList.length &&
                    bankList.map((item: any) => (
                      <Select.Option key={item.list.id} value={item.list.id}>
                        {`${item.list.nominalcode}-${item.list.laccount}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="formItem">
              <label className="formLabel">PAID METHOD</label>
              <Form.Item
                name="paidmethod"
                rules={[
                  {
                    required: true,
                    message: "Please Select The Paid Method",
                  },
                ]}
              >
                <Select size="large">
                  <Select.Option key={"cash"}>Cash</Select.Option>
                  <Select.Option key={"cheque"}>Cheque</Select.Option>
                  <Select.Option key={"electronic"}>Electronic</Select.Option>
                  <Select.Option key={"card"}>Credit/Debit Card</Select.Option>
                  <Select.Option key={"loan"}>PayPal</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="formItem">
              <label className="formLabel">AMOUNT TRANSFERED *</label>
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please Enter The Amount",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  size="large"
                  controls={false}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
            {/* <div className="formItem">
              <label className="formLabel">DESCRIPTION</label>
              <Form.Item name="description">
                <Input
                  type="text"
                  size="large"
                />
              </Form.Item>
            </div> */}
          </Col>
          <Col md={6}>
            <div className="formItem">
              <label className="formLabel">PAID TO *</label>
              <Form.Item
                name="paidto"
                rules={[
                  {
                    required: true,
                    message: "Please Enter The Amount",
                  },
                ]}
              >
                <Select size="large">
                  {bankList.length &&
                    bankList.map((item: any) => (
                      <Select.Option key={item.list.id} value={item.list.id}>
                        {`${item.list.nominalcode}-${item.list.laccount}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="formItem">
              <label className="formLabel">PAYMENT DATE</label>
              <Form.Item name="sdate">
                <DatePicker size="large" style={{ width: "100%" }} />
              </Form.Item>
            </div>
            <div className="formItem">
              <label className="formLabel">REFERENCE</label>
              <Form.Item name="reference">
                <Input type="text" size="large" />
              </Form.Item>
            </div>
          </Col>
          <Col md={6}></Col>
          <Col md={3}>
            <br />
            <Button
              loading={isLoading}
              size="large"
              type="primary"
              htmlType="submit"
              block
            >
              {type === "create" ? "Create" : "Update"}
            </Button>
          </Col>
          <Col md={3}>
            <br />
            <Button onClick={handleCancel} size="large" block>
              cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ContraVoucher;
