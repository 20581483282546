import { exportDataGrid } from "devextreme/excel_exporter";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { jsPDF } from "jspdf";
import saveAs from "file-saver";
import { notification } from "antd";

function EXPORT(e: any, dataGridRef: any, doc: any, customizeCell: any) {
  if (!dataGridRef?.current?.props?.dataSource?.length) {
    notification.error({  
      message: "No Rows Selected",
    });
    return;
  }
  if (e.format === "pdf") {
    const document: any = new jsPDF();
    const dataGrid = dataGridRef.current.instance;
    exportDataGridToPdf({
      jsPDFDocument: document,
      component: dataGrid,
      customizeCell({ gridCell, pdfCell }) {
        customizeCell("pdf",gridCell, pdfCell);
      },
    }).then(() => {
      document.save(`${doc}.pdf`);
    });
  } else if (e.format === "xlsx") {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function ({excelCell,gridCell}) {
        excelCell.font = { name: "Arial", size: 12 };
        excelCell.alignment = { horizontal: "left" };
        customizeCell("xlsx",gridCell, excelCell);
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${doc}.xlsx`
        );
      });
    });
  }
}

export { EXPORT };
