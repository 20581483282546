import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import { notification } from "antd";
import { VscVerifiedFilled } from "react-icons/vsc";
import { TbXboxX } from "react-icons/tb";
import "./styles.scss";
import LoadingBox from "../../components/loadingBox";
const UpdateEmail = () => {
  const { userId, token } = useParams();
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  
  const emailVerify = async () => {
    let url = API.VERIFY_EMAIL + userId + `/${token}`;
    try {
      const response: any = await GET(url, null);
      if (response) {
        setData(response);
                notification.success({
          message: "Updated email",
          description: "Your email has been updated successfully.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to update email. Please try again later.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    emailVerify();
  }, []);

  return (
    <div className="updateEmail-Box1">
      {isLoading ? (
        <LoadingBox />
      ) : data ? (
        <div className="updateEmail-Box2">
          <VscVerifiedFilled color="#18a762" size={50} />
          Email Verified
        </div>
      ) : (
        <div className="updateEmail-Box2">
          <TbXboxX color="red" size={50} />
          Email Not Verified
        </div>
      )}
    </div>
  );
};

export default UpdateEmail;
