import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import API from "../../../config/api";
import { DELETE, GET } from "../../../utils/apiCalls";

import Table from "../component/table";
import "../styles.scss";
import moment from "moment";
import { notification } from "antd";

const columns = [
  // {
  //   name: "id",
  //   title: "Employee Id",
  //   dataType: "number",
  //   alignment: "center",
  // },
  {
    name: "fullName",
    title: "Full Name",
    dataType: "string",
    alignment: "center",
  },
  {
    name: "email",
    title: "Email",
    dataType: "string",
    alignment: "center",
    cellRender: "Reference",
  },
  {
    name: "phone",
    title: "Phone",
    alignment: "center",
  },
  {
    name: "date_of_join",
    title: "Date Of Join",
    dataType: "string",
    alignment: "center",
    cellRender: "Reference",
  },
  {
    name: "Designation",
    title: "Designation",
    dataType: "string",
    alignment: "center",
    cellRender: "Reference",
  },
  {
    name: "employeeGroup",
    title: "Employee Group",
    dataType: "string",
    alignment: "center",
    cellRender: "Reference",
  },
  {
    name: "salaryPackage",
    title: "Salary Package",
    dataType: "number",
    alignment: "center",
    cellRender: "Reference",
  },
];
const Employees = () => {
  const { user } = useSelector((state: any) => state.User);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const adminid = user?.adminid;

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      setIsLoading(true);
      let url = API.EMPLOYEES_LIST_USER + `${adminid}`;
      const data: any = await GET(url, null);
      let modifiedData = data.map((item: any) => ({
        id: item?.id,
        fullName: `${item?.firstName} ${item?.lastName}` || "",
        email: item?.email || "",
        phone: item?.phone || "",
        date_of_join:
          moment(item.date_of_join).format("DD-MM-YYYY") || "",
        Designation: item?.Designation || "",
        employeeGroup: item?.employeeGroupDetails?.emplyeeCategory || "",
        salaryPackage: item?.salaryPackage,
      }));
      setData(modifiedData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const location = useLocation();
  const navigate = useNavigate();

  const handleDelete = async(id:any) =>{
    try {
      setIsLoading(true)
      const url = API.DELETE_EMPLOYEE + id;
      const response:any = await DELETE(url)
      if(response.status){
        notification.success({
          message: "Success",
          description: response.message,
        });
        fetchEmployees()
      }else{
        notification.error({
          message: "Error",
          description: response.message,
        });
      }
    } catch (error) {
      console.log(error)
      notification.error({
        message: "Server Error",
        description: 'Something went wrong in server',
      });
    }finally{
      setIsLoading(false)
    }
  }
  return (
    <>
      <PageHeader
        firstPath={location?.pathname.slice(5)}
        buttonTxt={"ADD"}
        onSubmit={() => navigate("/usr/payroll/form/employees/create")}
        title={"Pay Roll - Employees"}
        secondPathLink ={location.pathname}
        secondPathText ='Pay Roll - Employees'
      />
      <div className="adminTable-Box1">
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Table
            products={data}
            columns={columns}
            onItemSelect={() => {}}
            title={"Employees"}
            view = "/usr/payroll/employees/viewPage/"
            edit="/usr/payroll/form/employees/"
            onDelete = {handleDelete}
          />
        )}
      </div>
    </>
  );
};

export default Employees;
