import { Form, Input } from "antd";

function UnitFrom() {
  return (
    <div>
      <label className="formLabel">Formal Name</label>
      <Form.Item
        name="formalName"
        rules={[
          {
            required: true,
            message: "Please enter a name",
          },
        ]}
      >
        <Input size="large"/>
      </Form.Item>

      <label className="formLabel">Symbol</label>
      <Form.Item
        name="unit"
        rules={[
          {
            required: true,
            message: "Please enter a Unit",
          },
        ]}
      >
        <Input size="large"/>
      </Form.Item>

      <label className="formLabel">Decimal Value</label>
      <Form.Item name="decimalValues">
        <Input defaultValue={0} />
      </Form.Item>
    </div>
  );
}

export default UnitFrom;
