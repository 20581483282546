import "./styles.scss";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Card } from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import BankCard from "./components/bankCard";

function CashBank() {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const [bank, setBank] = useState<any>([]);
  const navigate = useNavigate();

  const fetchBankList = async () => {
    try {
      setIsLoading(true);
      let bank_list_url = API.GET_BANK_LIST + adminid;
      const { data }: any = await GET(bank_list_url, null);

      let banklist = data?.bankList.filter(
        (item: any) => item.list.nominalcode !== "1200"
      );
      setBank(banklist);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBankList();
  }, []);

  return (
    <div>
      <PageHeader
        firstPathLink={"/usr/bank"}
        firstPathText={"Bank Statement"}
        secondPathLink={"asse"}
        goback={-1}
        title="Bank Statement"
      />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Container>
          <br />
          <Row>
            {bank?.map((item: any) => {
              return (
                <Col md={4} style={{ marginBottom: 20 }}>
                  <BankCard bankDetails={item} />
                </Col>
              );
            })}
            <Col md={2} style={{ marginBottom: 20 }}>
              <Card className="addBank-Card" style={{ height: "100%" }}>
                <div
                  onClick={() =>
                    navigate(`/usr/cashBank/addbank/create`, {
                      state: { type: "1" },
                    })
                  }
                >
                  <IoIosAddCircleOutline size={60} color="orange" />
                  <div>ADD BANK</div>
                </div>
              </Card>
            </Col>
          </Row>
          <br />
        </Container>
      )}
    </div>
  );
}

export default CashBank;
