import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/pageHeader";
import { useNavigate } from "react-router-dom";
import { POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import DataTable from "./datatable";
import { Col, Container, Row } from "react-bootstrap";
import select from "antd/es/select";
import dayjs from "dayjs";
import exportToExcel from "../../../utils/exportexcel";

function HrPayroll() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState<any>([]);
  const [finacedata, setFinaceData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});
  const [finacemeta, setFinaceMeta] = useState<any>({});
  const [form] = Form.useForm();
  const navigate = useNavigate();

  console.log("finacedata=======", finacedata);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCreate = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    fetchHrFinance();
    searchFinace({}, 1, 10);
  }, [page, take]);
  const fetchHrFinance = async () => {
    try {
      setIsLoading(true);
      let obj = {
        page: page,
        take: 10,
      };
      const url = API.POST_PAGE_HRFINANCE;
      const response: any = await POST(url, obj);
      if (response?.status) {
        setData(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notification.error({
          message: "Oops !",
          description: "Project data not found",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      notification.error({
        message: "Oops !",
        description: "Project data not found",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const searchFinace = async (values: any, page: any, take: any) => {
    console.log("values --> ", values);
    try {
      setIsLoading(true);
      let url = API.POST_PAGE_HRFINANCE;
      let obj = {
        page: page,
        take: 10,
        userId: values.userId,
        date: values.date,
        // ...(values && values.userId && { userId: values.userId }),
        // ...(values && values.date && { date: values.date }),
      };
      console.log("obj-----", obj);
      var User: any = await POST(url, obj);
      console.log("User------>>>>>>", User);

      if (User.data) {
        setFinaceData(User?.data);
        setFinaceMeta(User.meta);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  // const handleExportToExcel = () => {
  //   exportToExcel(finacedata, "exported_data.xlsx");
  // };

  const handleExportToExcel = () => {
    const data = finacedata?.map((item: any) => {
      return {
        EmployeeName: item?.user?.fullName,
        amount: item?.amount,
        type: item?.type,
        category: item?.category,
        date: item?.date,
      };
    });

    exportToExcel(data, "exported_data.xlsx");
  };
  return (
    <>
      <PageHeader
        firstPathText="Payroll"
        buttonTxt={"CREATE"}
        onSubmit={() => navigate("/usr/hr-payroll/hr_finance/create")}
        title="Payroll"
      />
      <br />
      <Modal
        title="payroll"
        visible={isModalOpen}
        onOk={() => {
          handleCreate();
        }}
        onCancel={handleCancel}
        width={1000}
      ></Modal>
      <div>
        <div className="">
          <Container>
            <Card>
              <br />
              <Form
                form={form}
                layout="vertical"
                // onChange={(data: any) => console.log("------> ", data)}
                onFinish={(values: any) => searchFinace(values, page, take)}
              >
                <Row style={{ marginTop: "20px" }}>
                  <Col md="3">
                    <Form.Item name="userId">
                      {/* <Select
                        size="large"
                        showSearch
                        style={{ width: "200px" }}
                        placeholder="Select Employee"
                        // onChange={(values: any) =>
                        //   searchFinace({ userId: values }, page, take)
                        // }
                        allowClear
                      > */}

                      <Select
                        size="large"
                        showSearch
                        placeholder="Select Employee"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        allowClear
                      >
                        {Array.from(
                          new Map(
                            data.map((usr: any) => [usr.userId, usr])
                          ).values()
                        ).map((usr: any) => (
                          <Select.Option key={usr?.userId} value={usr?.userId}>
                            {usr?.user?.fullName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md="3">
                    <Form.Item name={"date"} noStyle>
                      <DatePicker
                        size="large"
                        picker="month"
                        style={{ width: "250px" }}
                        // onChange={(values: any) =>
                        //   searchFinace({ date: values }, page, take)
                        // }
                        allowClear
                      />
                    </Form.Item>
                  </Col>

                  <Col md="3">
                    <Form.Item>
                      <Button size="large" htmlType="submit">
                        Search
                      </Button>
                      <Input bordered={false} />
                    </Form.Item>
                  </Col>
                  <Col md="3">
                    <Button type="primary" onClick={handleExportToExcel}>
                      Export to Excel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Container>
        </div>
      </div>
      <DataTable
        dataSource={finacedata}
        isLoading={isLoading}
        meta={meta}
        onPageChange={(page: number, pageSize: number) => {
          setIsLoading(true);
          setPage(page);
          setTake(pageSize);
        }}
      />
    </>
  );
}

export default HrPayroll;
