import { Button, Checkbox, Tooltip, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Table } from "react-bootstrap";
import "../styles.scss";
import { useEffect, useState } from "react";
import moment from "moment";
import { GET } from "../../../utils/apiCalls";
import PageHeader from "../../../components/pageHeader";
import LoadingBox from "../../../components/loadingBox";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoIosCloseCircle } from "react-icons/io";
import { MdAttachEmail, MdFileDownload } from "react-icons/md";
import { useSelector } from "react-redux";
import { template1, template2, template3 } from "../components/templates";
import API from "../../../config/api";
import SendMailModal from "../../../components/sendMailModal";

function PerfomaView() {
  const { id }: any = useParams();
  const [isFullLoading, setIsFullLoading] = useState(false);
  const [details, setDetails] = useState<any>({});
  const [subTottal, setSubTottal] = useState<any>();
  const [taxAmount, setTaxAmount] = useState<any>();
  const [roundOff, setRoundOff] = useState<any>();
  const [emailModal, setEmailModal] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const User = useSelector((state: any) => state.User);
 
  let _tatalVat = 0;
  
  let discountAmount: any = 0;
  let amountAdded = 0;

  let overalDiscount = 0;
  let subAllTottal = 0;
  const navigate = useNavigate();

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  async function genrateTemplate(type: any, emaildata: any) {
    try {
      setDownloadLoading(true);
      let obj = {
        user: User.user,
        customer: details?.invoiceDetails?.customer,
        sale: details?.invoiceDetails,
        productlist: details?.invoiceItems,
        bankList: details?.banking,
        vatTotal: _tatalVat,
        netTotal: subAllTottal,
        Discount: overalDiscount,
        round: amountAdded,
        total: details?.invoiceDetails?.total,
        vatRate: _tatalVat,
        isPaymentInfo: false,
        pagetype: "Proforma Invoice",
        selectedBank: User.user.bankInfo,
      };
      let templates: any = null;
      if (!User.user.companyInfo.defaultinvoice) {
        notification.error({
          message: <div>Please select an email template</div>,
          description: (
            <Button
              type={"link"}
              onClick={() => navigate("/usr/settings/customize")}
            >
              Click to select
            </Button>
          ),
        });
        return;
      }
      if (!User.user.bankInfo) {
        notification.error({
          message: <div>Please select default Bank </div>,
          description: (
            <Button
              type={"link"}
              onClick={() => navigate("/usr/profile/business")}
            >
              Click to select
            </Button>
          ),
        });
        return;
      }
      if (User.user.companyInfo.defaultinvoice === "1") {
        templates = template1(obj);
      } else if (User.user.companyInfo.defaultinvoice === "2") {
        templates = template2(obj);
      } else if (User.user.companyInfo.defaultinvoice === "3") {
        templates = template3(obj);
      }
      if (type === "email") {
        sendMailPdf(templates, emaildata);
      } else {
        await downLoadPdf(templates);
      }

      setDownloadLoading(false);
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  }
  const downLoadPdf = async (templates: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    const encodedString = btoa(templateContent);
    const pdf_url = API.PDF_GENERATE_URL;
    const pdfData = {
      filename: "Sales Invoice",
      html: encodedString,
      isDownload: true,
      sendEmail: false,
      type: "",
      userid: "",
    };
    const token = User.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const pdfBlob = await response.arrayBuffer();
    const blob = new Blob([pdfBlob], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `performa${details?.invoiceDetails?.customer?.bus_name}_${
      details?.invoiceDetails?.invoiceno
    }_${moment(new Date()).format("DD-MM-YYYY")}`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const sendMailPdf = async (templates: any, email: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    const encodedString = btoa(templateContent);
    const pdf_url = API.PDF_GENERATE_URL;
    const pdfData = {
      email: email,
      filename: "Sales Invoice.pdf",
      html: encodedString,
      isDownload: false,
      sendEmail: true,
      type: "",
      userid: "",
    };
    const token = User.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (response.ok) {
      notification.success({ message: "Email Successfully Sent" });
      setEmailModal(false);
    }
    if (!response.ok) {
      notification.success({
        message:
          "Apologies, there was an error when attempting to send the email.",
      });
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  };

  const getInvoiceDetails = async () => {
    setIsFullLoading(true);
    try {
      let url = "SaleInvoice/viewInvoice/" + id + "/proforma";
      const { data: invoiceDatas, status }: any = await GET(url, null);

      if (status) {
        setDetails(invoiceDatas);
        setSubTottal(
          invoiceDatas?.invoiceItems?.reduce((sum: any, item: any) => {
            if (item.includevat === 0) {
              return sum + Number(item.costprice) * Number(item.quantity);
            } else {
              return (
                sum +
                (Number(item.costprice) - Number(item.vatamt)) *
                  Number(item.quantity)
              );
            }
          }, 0)
        );

        

        setTaxAmount(
          invoiceDatas?.invoiceItems?.reduce(
            (sum: any, item: any) => sum + Number(item.vatamt),
            0
          )
        );
        let total = invoiceDatas?.invoiceItems?.reduce(
          (sum: any, item: any) => sum + Number(item.total),
          0
        );
        setRoundOff(
          Number(invoiceDatas?.invoiceDetails?.total) - Number(total)
        );
        setIsFullLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsFullLoading(false);
    }
  };
  return (
    <>
      <PageHeader
        title="Performa Invoice View"
        goBack={"/dashboard"}
        secondPathText="Performa Invoice View"
        secondPathLink={`/usr/perfoma-invoice-view/${id}`}
        firstPathText="Performa Invoice"
        children={
          <div>
            <Tooltip
              title="Download Invoice"
              mouseEnterDelay={0.5}
              arrow={false}
              color="white"
              overlayClassName="toolTip-Card"
              overlayInnerStyle={{
                color: "#000000",
                marginTop: 5,
                fontSize: "14px",
              }}
              placement={"bottom"}
            >
              <Button
                onClick={() => genrateTemplate("downLoad", {})}
                loading={downloadLoading}
              >
                <MdFileDownload size={20} />
              </Button>
            </Tooltip>
            &nbsp;
            <Tooltip
              title="Mail invoice"
              mouseEnterDelay={0.5}
              arrow={false}
              color="white"
              overlayClassName="toolTip-Card"
              overlayInnerStyle={{
                color: "#000000",
                marginTop: 5,
                fontSize: "14px",
              }}
              placement={"bottom"}
            >
              <Button onClick={() => setEmailModal(true)}>
                <MdAttachEmail size={20} />
              </Button>
            </Tooltip>
          </div>
        }
      />
      <br />

      <Container className="p-1">
        {isFullLoading ? (
          <LoadingBox />
        ) : (
          <div
            style={{
              padding: "10px",
              backgroundColor: "white",
            }}
          >
            <Row>
              <div className="items-head">
                <strong>Invoice</strong>
              </div>

              <Col md="12">
                <Table bordered>
                  <tbody>
                    <tr>
                      <td className="items-head">Invoice No</td>
                      <td>
                        <strong>{details?.invoiceDetails?.invoiceno}</strong>
                      </td>
                      <td className="items-head">Customer Name</td>
                      <td>
                        <strong>{details?.invoiceDetails?.cname}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="items-head"> Invoice Date</td>
                      <td className="items-value">
                        {moment(details?.invoiceDetails?.sdate).format(
                          "DD MMMM YYYY"
                        )}
                      </td>
                      <td className="items-head">Notes</td>
                      <td className="items-value">
                        {details?.invoiceDetails?.quotes}
                      </td>
                    </tr>
                    <tr>
                      <td className="items-head"> Delivery Address</td>
                      <td className="items-value">
                        {details?.invoiceDetails?.deladdress}
                      </td>
                      <td className="items-head">Invoice Address</td>
                      <td className="items-value">
                        {details?.invoiceDetails?.inaddress}
                      </td>
                    </tr>
                    <tr>
                      <td className="items-head"> Terms and Conditions</td>
                      <td className="items-value">
                        {details?.invoiceDetails?.terms}
                      </td>

                      <td className="items-head"> Reference</td>
                      <td className="items-value">
                        {details?.invoiceDetails?.reference}
                      </td>
                    </tr>
                    <tr>
                      <td className="items-head">Status</td>
                      <td className="items-value">
                        {details?.invoiceDetails?.status === 5
                          ? "Pending"
                          : details?.invoiceDetails?.status === 5
                          ? "Generated"
                          : "No Status"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col md={12}>
                <div>
                  <strong>Invoice Items</strong>
                  <br />
                  <br />
                </div>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>PRODUCT</th>
                      <th>QUANTITY</th>
                      <th>PRICE</th>
                      <th>TAX</th>
                      <th>TAX AMT</th>
                      <th>INC TAX</th>
                      <th>DISC %</th>
                      <th>DISC AMT</th>
                      <th>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {details?.invoiceItems?.length &&
                      details?.invoiceItems.map((item: any) => {
                        let discountAmount = 0;
                        let total = 0;
                        if (item.includevat === 0) {
                          total =
                            Number(item.costprice) * Number(item.quantity) +
                            Number(item.vatamt);
                          subAllTottal =
                            subAllTottal +
                            Number(item.costprice) * Number(item.quantity);
                        } else {
                          total =
                            Number(item.costprice) * Number(item.quantity);
                          subAllTottal =
                            subAllTottal +
                            Number(item.costprice) * Number(item.quantity) -
                            item.vatamt;
                        }
                        if (item.discount > 0) {
                          const discountRate = Number(item.discount) / 100;
                          discountAmount = Number(total) * discountRate;
                          overalDiscount = overalDiscount + discountAmount;
                        }

                        return (
                          <tr>
                            <td>{item?.product?.idescription}</td>
                            <td>{Number(item.quantity)}</td>
                            <td>{Number(item.costprice)}</td>
                            <td>{Number(item.vat)} %</td>
                            <td>{Number(item.vatamt)}</td>
                            <td>
                              <Checkbox
                                disabled
                                checked={item.includevat === 1 ? true : false}
                              />
                            </td>
                            <td>{Number(item.discount)} %</td>
                            <td>
                              {Math.round(Number(discountAmount))?.toFixed(2)}
                            </td>
                            <td>{Number(item.total)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Col>

              <Col md={8}></Col>
              <Col md={4}>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>TAXABLE VALUE</td>
                      <td>{subAllTottal?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>TOTAL VAT</td>
                      <td>{taxAmount?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>OVERALL DISCOUNT</td>
                      <td>{Math.round(overalDiscount)?.toFixed(2)}</td>
                    </tr>
                    {/* <tr>
                      <td>ROUND OFF</td>
                      <td>{roundOff?.toFixed(2)}</td>
                    </tr> */}
                    <tr>
                      <td>TOTAL AMOUNT</td>
                      <td>{details?.invoiceDetails?.total}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <hr />
          </div>
        )}
        {emailModal ? (
          <SendMailModal
            open={emailModal}
            close={() => setEmailModal(false)}
            onFinish={(val: any) => genrateTemplate("email", val)}
            ownMail={User.user.email}
            Attachment={`${details?.invoiceDetails?.customer?.bus_name}_${
              details?.invoiceDetails?.invoiceno
            }_${moment(new Date()).format("DD-MM-YYYY")}`}
            defaultValue={{
              to: details?.invoiceDetails.customer.email,
              subject: `Sales Invoice ${details?.invoiceDetails?.invoiceno}`,
              content: User.user.companyInfo.defaultmail,
            }}
            fileName={`PerformaInvoice${new Date()}.pdf`}
          />
        ) : null}
      </Container>
    </>
  );
}

export default PerfomaView;
