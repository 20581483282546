import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  message,
  notification,
} from "antd";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import API from "../../../../../../config/api";
import { GET, POST } from "../../../../../../utils/apiCalls";
import PageHeader from "../../../../../../components/pageHeader";
import LoadingBox from "../../../../../../components/loadingBox";

const { Option } = Select;

function BankTransfer() {
  const [isLoading, setIsLoading] = useState(false);
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const [bank, setBank] = useState<any>([]);
  const location = useLocation();
  const [form] = Form.useForm();
  let editData = location?.state?.editData;
  const fetchBankList = async () => {
    try {
      setIsLoading(true);
      let bank_list_url = API.GET_BANK_LIST + adminid;
      const { data }: any = await GET(bank_list_url, null);
      setBank(data.bankList);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchBankList();
  }, []);

  const laccountList = bank.map((item: any) => item?.list?.id);

  const submitHandler = async (values: any) => {
    try {
      setIsLoading(true);
      const url = type == 'create' ? API.BANK_TRANSFER : `ledger_details/updateContraVoucher/${type}`;

      const data: any = await POST(url, {
        ...values,
        adminid,
        userid: user.id,
        type: "1",
      });
      if (data.status) {
        notification.success({ message: "Transfer Completed Successfully" });
        navigate(`/usr/cashBank/${id}/details/transaction`);
      } else {
        notification.error({
          message: "Something went wrong! Please try again later",
        });
      }
    } catch (error) {
      notification.error({
        message: "Something went wrong! Please try again later",
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const LoadLedgerDeatails = async () => {
    await fetchBankList()
    let URL = API.LEDGER_DEATAILS + type;
    const data: any = await GET(URL, null);
    form.setFieldsValue({
      paidfrom: data?.data?.baseid ? Number(data?.data?.paidfrom) : Number(id),
      paidto: data?.data?.baseid ? Number(id) : Number(data?.data?.paidfrom),
      sdate: dayjs(data.data.sdate),
      reference: data.data.reference,
      amount: Math.abs(data.data.total),
      paidmethod: data.data.paidmethod,
      description: data.data.description,
    });
  };

  useEffect(() => {
    if (type !== "create") {
      LoadLedgerDeatails();
    }
  }, []);
  return (
    <div>
      <PageHeader
        title="Bank Transfer"
        firstPathLink={"/usr/cashBank"}
        firstPathText={"Bank"}
        secondPathLink={`/usr/cashBank/${id}/details`}
        secondPathText={"Bank Details"}
        thirdPathLink={`/usr/cashBank/${id}/details/banktransfer/create`}
        thirdPathText={`Bank Transfer`}
      />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Container>
          <br />
          <Card>
            <Form
              onFinish={submitHandler}
              form={form}
            >
              <Row>
                <Col md="6">
                <div className="formItem">
                  <label className="formLabel">Paid From</label>
                  <Form.Item
                    name="paidfrom"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="From" size="large">
                      {bank.map((item: any) => (
                        <>
                          <Select.Option key={item.list.id} value={item.list.id}>
                            {item.list.laccount}
                          </Select.Option>
                        </>
                      ))}
                    </Select>
                  </Form.Item>
                  </div>
                  <div className="formItem">
                  <label className="formLabel">Paid Method</label>
                  <Form.Item
                    name="paidmethod"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="choose one" size="large">
                      <Option value="cash">Cash</Option>
                      <Option value="current">Current</Option>
                      <Option value="cheque">Cheque</Option>
                      <Option value="other">Electronic</Option>
                      <Option value="card">Credit/Debit Card</Option>
                      <Option value="loan">PayPal</Option>
                      {/* <Option value="loan">PayPal</Option>   something wrong in value  */}
                    </Select>
                  </Form.Item>
                  </div>
                  <div className="formItem">
                  <label className="formLabel">Amount Transferred</label>
                  <Form.Item
                    name="amount"
                    style={{ marginBottom: 10 }}
                    rules={[
                      {
                        type: "number",
                        min: 1,
                        message: "Please enter a number greater than zero",
                        transform: (value) => parseFloat(value),
                      },
                    ]}
                  >
                    <Input type="number" placeholder="Amount" size="large" />
                  </Form.Item>
                  </div>
                  <div className="formItem">
                  <label className="formLabel">Description</label>
                  <Form.Item name="description" style={{ marginBottom: 10 }}>
                    <Input placeholder="Description" size="large" />
                  </Form.Item>
                  </div>
                </Col>
                <Col md="6">
                <div className="formItem">
                  <label className="formLabel">Paid To</label>
                  <Form.Item
                    name="paidto"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="To" size="large">
                      {bank.map((item: any) => (
                        <>
                          <Select.Option key={item.list.id} value={item.list.id}>
                            {item.list.laccount}
                          </Select.Option>
                        </>
                      ))}
                    </Select>
                  </Form.Item>
                  </div>
                  <div className="formItem">
                  <label className="formLabel">Payment Date</label>
                  <Form.Item
                    name="sdate"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder="Payment Date"
                      size="large"
                      style={{ width: "100%" }}
                      // defaultValue={dayjs(new Date())}
                      format="YYYY-MM-DD"
                    />
                  </Form.Item>
                  </div>
                  <div className="formItem">
                  <label className="formLabel">Reference</label>
                  <Form.Item
                    name="reference"
                    style={{ marginBottom: 10 }}
                  // rules={[{ required: true }]}
                  >
                    <Input placeholder="Reference" size="large" />
                  </Form.Item>
                        </div>
                        <br />
                  <Row>
                    <Col md="6" >
                    <Button size="large" block>
                      cancel
                    </Button>
                    </Col>
                    <Col md="6">
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        // loading={isLoading}
                        block
                      >
                        {editData ? "Update" : "Sumbit"}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Container>
      )}
    </div>
  );
}

export default BankTransfer;
