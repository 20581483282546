import WebsiteHeader from "../../components/websiteHeader";
import WebsiteFooter from "../../components/websiteFooter";
function Accounting() {
  return (
    <div className="website-screens">
      <WebsiteHeader />
      <div className="website-Mainbox">Accounting</div>
      <WebsiteFooter />
    </div>
  );
}

export default Accounting;
