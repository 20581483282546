import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingBox from "../../../components/loadingBox";
import PageHeader from "../../../components/pageHeader";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import LedgerTable from "../component/table";
import AddDefualtCategory from "./addCategory";
import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const LedgerMyCategory = () => {
  const location = useLocation();
  const { user } = useSelector((state: any) => state.User);
  const [edit, setEdit] = useState() as any
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const adminid = user.adminid;

  useEffect(() => {
    fetchMyCategoryList();
  }, []);

  const navigate = useNavigate()

  const fetchMyCategoryList = async () => {
    try {
      setIsLoading(true);
      let url = API.GET_LEDGER_CATEGORY + `myLedgerCategory/${adminid}`;
      const { data }: any = await GET(url, null);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      name: "category",
      title: "Category Name",
      dataType: "string",
      alignment: "center",
      allowEditing: false,
    },
    {
      name: "categorygroup",
      title: "Category Group",
      dataType: "string",
      alignment: "center",
      allowEditing: false,
    },
  ];

  const handleOnEdit = (val: any) => {
    setEdit(val?.row?.data);
    setOpen(true);
  };

  const handleAdd = () => {
    setEdit(null);
    setOpen(true);
  };

  const handleView = (val:any) =>{
    console.log(val)
    navigate(`/usr/ledger-view/${val}`)
  }

  return (
    <>
      <PageHeader
        title={"My Category"}
        secondPathLink ={location.pathname}
        secondPathText ='My Category'
        firstPath={location?.pathname.slice(5)}
        goBack={"/usr/ledgerMyCategory"}
      >
        <div>
        <Button type="primary" onClick={() => handleAdd()}>
            + Add Category
          </Button>
        </div>
        </PageHeader>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <LedgerTable
          products={data}
          columns={columns}
          onItemSelect={() => {}}
          onPageChange={(p: any, t: any) => {}}
          title={"My Category"}
          defualtCategoryOnEdit={(item: any) => handleOnEdit(item)}
          handleView={(val: any) => handleView(val)}
        />
      )}
      {open && (
        <AddDefualtCategory
          onOpen={open}
          onClose={() => setOpen(false)}
          onEdit={edit}
          refresh={() => fetchMyCategoryList()}
        />
      )}
    </>
  );
};

export default LedgerMyCategory;
