import React, { useEffect, useState } from "react";
import Table from "../components/table";
import API from "../../../config/api";
import { DELETE, GET } from "../../../utils/apiCalls";
import { useSelector } from "react-redux";
import LoadingBox from "../../../components/loadingBox";
import { useNavigate } from "react-router-dom";
import CreateSettings from "../components/form";
import { notification } from "antd";

function Unit() {
  const { user } = useSelector((state: any) => state.User);
  const [isLoading, setIsLoading] = useState(true);
  const [isForm, setIsForm] = useState(false);
  const [data, setData] = useState([]);
  const [id, setId] = useState("create");
  const [initalValue, setInitalValue] = useState({});
  const navigate = useNavigate();
  const adminid = user?.adminid;

  const columns = [
    {
      name: "formalName",
      title: "Formal Name",
      dataType: "string",
      alignment: "center",
      cellRender: "Reference",
    },

    {
      name: "unit",
      title: "Unit",
      dataType: "string",
      alignment: "center",
      cellRender: "Reference",
    },

    {
      name: "decimalValues",
      title: "Decimal Value",
      dataType: "number",
      alignment: "center",
      cellRender: "Reference",
    },
  ];
  useEffect(() => {
    fetchUnits();
  }, []);
  const fetchUnits = async () => {
    try {
      setIsLoading(true);
      let unit_url = API.UNIT_LIST_USER + `${adminid}`;
      const data: any = await GET(unit_url, null);
      setData(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUnitDelete = async (id: any) => {
    try {
      let url = API.UNIT_DELETE + id;
      const data: any = await DELETE(url);
      if (data) {
        notification.success({
          message: "Deleted Succesfully",
          description: "Your location has been deleted successfully.",
        });
        fetchUnits();
      }
    } catch(error) {
      console.log(error);
    }
  };
  const uniitGetById = async (id: any) => {
    try {
      let url = API.UNIT_GETBY_ID + id;
      let data: any = await GET(url, {});
      let inital = {
        formalName: data?.formalName,
        unit: data?.unit,
        decimalValues: data?.decimalValues,
      };
      setInitalValue(inital);
      setId(id);
      setTimeout(() => {
        setIsForm(true);
      }, 100);
    } catch (error) {}
  };

  return (
    <div>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Table
          data={data}
          columns={columns}
          title={"Units"}
          onBtnClick={() => {
            setIsForm(true);
            setId("create");
          }}
          handleDeleteClick={fetchUnitDelete}
          handleEditClick={uniitGetById}
        />
      )}
      {isForm && (
        <CreateSettings
          open={isForm}
          close={() => {
            setIsForm(false);
            setInitalValue({});
          }}
          source={"unit"}
          reload={() => fetchUnits()}
          id={id}
          initalValue={initalValue}
        />
      )}
    </div>
  );
}

export default Unit;
