import { Button, Form, Input, InputNumber, notification } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { GET, POST } from "../../../../utils/apiCalls";
import API from "../../../../config/api";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import PageHeader from "../../../../components/pageHeader";
import PrefixSelector from "../../../../components/prefixSelector";

function CreateSupplier() {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state: any) => state.User);
  const adminid = user.adminid;
  const navigate = useNavigate();
  const location = useLocation();

  const submitHandler = async (values: any) => {
    try {
      setIsLoading(true);
      const add_supplier_url = API.CONTACT_MASTER + `add`;
      let mobile = `${values.code} ${values.mobileNumber}`;
      const {  status,message }: any = await POST(add_supplier_url, {
        ...values,
        adminid,
        mobile: values.mobileNumber ? mobile : "",
        type: "supplier",
        ledger_category: 4,
      });
      if (status) {
        navigate("/usr/contactSuppliers");

        notification.success({
          message:"Success",
          description: "New supplier created successfully",
        });
      } else {
        notification.error({
          message:"Failed",description:`Failed to create new supplier(${message})`
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Server Error",
       description: "Something went wrong in server!! Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const initialValues = {
    name: "",
    reference: "",
    bus_name: "",
    vat_number: "",
    email: "",
    code: user?.countryInfo?.phoneCode,
    mobileNumber: null,
    telephone: null,
    town: "",
    address: "",
    postcode: "",
    notes: "",
    opening_balance: 0,
  };

  const isVatExists = async(value:any) =>{
    console.log(value)
    try {
      let url = API.VAT_EXISTS + adminid + `/${value}`;
      const response :any = await GET(url,null)
      if(response.status){
        notification.error({
          message: "Duplicate VAT Number",
          description: "The VAT number you entered already exists. Please choose a unique VAT number.",
        });
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <PageHeader
        firstPathLink={location.pathname.replace("/create", "")}
        firstPathText="Suppliers List"
        secondPathLink={location?.pathname}
        secondPathText="Create Supplier"
        goback="/usr/contactSuppliers"
        title="Create Supplier"
      />

      <div className="adminTable-Box1">
        <div className="create-customer-container">
          <div>
            <Form
              onFinish={submitHandler}
              layout="vertical"
              initialValues={initialValues}
            >
              <p>The informations can be edited</p>
              <Row>
                <Col md={4}>
                  <label className="formLabel">Name</label>
                  <Form.Item
                    name="name"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder="Supplier Name"
                      size="large"
                      className="input-field"
                    />
                  </Form.Item>
                  <label className="formLabel">Reference Code</label>
                  <Form.Item
                    name="reference"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder="Reference Code"
                      size="large"
                      className="input-field"
                    />
                  </Form.Item>
                  <label className="formLabel">Business Name</label>
                  <Form.Item
                    name="bus_name"
                    rules={[{ required: true }]}
                    style={{ marginBottom: 10 }}
                  >
                    <Input
                      placeholder="Business Name"
                      size="large"
                      className="input-field"
                    />
                  </Form.Item>
                  <label className="formLabel">VAT Number</label>
                  <Form.Item
                    name="vat_number"
                    style={{ marginBottom: 10 }}
                    rules={[
                      {
                        required: true,
                      },
                      {
                        pattern: new RegExp("^[A-Z0-9]+$"),
                        message:
                          "Please enter only capital letters and numbers",
                      },
                    ]}
                  >
                    <Input
                      placeholder="VAT Number"
                      size="large"
                      className="input-field"
                      onChange={(e) => {
                        const filteredValue = e.target.value.replace(/[^A-Z0-9]/g,"");
                        e.target.value = filteredValue;
                        isVatExists(e.target.value)
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <label className="formLabel">Email Address</label>
                  <Form.Item name="email" style={{ marginBottom: 10 }}
                   rules={[
                   {
                     pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                     message: 'Please enter a valid email address',
                   },
                 ]}
                  >
                    <Input
                      placeholder="Email"
                      size="large"
                      className="input-field"
                    />
                  </Form.Item>
                  <label className="formLabel">Mobile Number</label>
                  <Form.Item name="mobileNumber" style={{ marginBottom: 10 }}>
                    <Input
                      placeholder="Mobile Number"
                      size="large"
                      addonBefore={<PrefixSelector />}
                      type="text"
                      onInput={(e) => {
                      e.currentTarget.value = e.currentTarget.value.replace(
                      /[^0-9]/g,
                      ""
                      );
                     }}      
                    />
                  </Form.Item>
                  <label className="formLabel">Telephone Number</label>
                  <Form.Item name="telephone" style={{ marginBottom: 10 }}>
                    <Input
                      placeholder="Telephone Number"
                      size="large"
                      className="input-field"
                      type="text"
                      onInput={(e) => {
                      e.currentTarget.value = e.currentTarget.value.replace(
                      /[^0-9]/g,
                      ""
                      );
                  }}      
                    />
                  </Form.Item>
                  <label className="formLabel">Town/city</label>
                  <Form.Item name="city" style={{ marginBottom: 10 }}>
                    <Input
                      placeholder="Town/city"
                      size="large"
                      className="input-field"
                    />
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <label className="formLabel">Address</label>
                  <Form.Item name="address" style={{ marginBottom: 10 }}>
                    <Input.TextArea
                      placeholder="Address"
                      size="large"
                      className="input-field"
                      rows={3}
                    />
                  </Form.Item>
                  <label className="formLabel">Postal Code</label>
                  <Form.Item name="postcode" style={{ marginBottom: 10 }}>
                    <Input
                      placeholder="Postal Code"
                      size="large"
                      className="input-field"
                    />
                  </Form.Item>
                  <label className="formLabel">Notes</label>
                  <Form.Item name="notes" style={{ marginBottom: 10 }}>
                    <Input.TextArea
                      placeholder="Notes"
                      size="large"
                      className="input-field"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>
              <label className="formLabel">Opening Balance(As on Business starting Date)</label>
                <Form.Item name="opening_balance" style={{ marginBottom: 10}}>
                  <InputNumber
                    placeholder="Enter Amount"
                    size="large"
                    className="input-field"
                    style={{ width: '100%' }}
                    controls={false}
                  />
                </Form.Item>
              </Col>
                <Col md={4} />
                <Col md={2}>
                  <br />
                  <Button
                    size="large"
                    type="default"
                    block
                    onClick={()=>navigate('/usr/contactSuppliers')}
                  >
                    Close
                  </Button>
                </Col>
                <Col md={2}>
                  <br />
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                    block
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateSupplier;
